// import { Button, ButtonProps, makeStyles } from "@material-ui/core"
//
//
// const CustomButton = ({ color = "primary", variant = "contained", children, ...rest }: ButtonProps): JSX.Element => {
//   const buttonStyles = useButtonStyles()
//   return (
//     <Button {...rest} color={color} variant={variant} classes={buttonStyles}>
//       {children}
//     </Button>
//   )
// }
//
// export default CustomButton
// import React, {ReactChildren, ReactChild, HTMLAttributes} from 'react';

import "./styles/index.scss"
import {useRef, useState} from "react";

export interface Iprops {
    children?: any;
    className?: any;
    onClick?: () => void;
    customOnClick?: () => void;
    endIcon?: JSX.Element;
    startIcon?: JSX.Element;
    fullWidth?: any;
    type?: any;
    disabled?: any;
    ref?: any;
}

const CustomButton = ({children, className, disabled, fullWidth, endIcon, startIcon, customOnClick, ...rest}: Iprops): JSX.Element => {
    const [spanClass, setSpanClass] = useState('');
    const spanRef = useRef<HTMLButtonElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const clickButton = (e: React.MouseEvent) => {
        spanRef.current!.style.top = e.pageY - buttonRef.current!.offsetTop + 'px'
        spanRef.current!.style.left = e.pageX - buttonRef.current!.offsetLeft + 'px'
        setSpanClass("active-span")
        if (customOnClick) {
            customOnClick()
        }
        setTimeout(() => {
            setSpanClass("")
        }, 500)
    };

    return (
        <button
            onClick={(e) => clickButton(e)}
            ref={buttonRef}
            className={`custom-button-default ${disabled ? "disabled" : ''} ${fullWidth ? "fullWidth" : ''} ${className}`}
            {...rest}
        >
            <span ref={spanRef} className={`custom-button-default-span ${spanClass}`}></span>
            {children}
            {endIcon}
            {startIcon}
        </button>
    )
}
export default CustomButton
