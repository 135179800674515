import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface JiliPopUpItem {
  competitionId: string;
  eventTypeId: string;
  id?: string | undefined | any;
  ID?: string | undefined | any;
  name?: string;
  redirect: string;
  startTime: string;
  type: string;
  en?: string;
}

const INIT_JILI_POP_UP = 'INIT_JILI_POP_UP';

type InitJiliPopUpAction = Action<typeof INIT_JILI_POP_UP> & {
  jiliPopUp: JiliPopUpItem;
};

export const initJiliPopUp = (jiliPopUp: JiliPopUpItem): InitJiliPopUpAction => ({
  type: INIT_JILI_POP_UP,
  jiliPopUp,
});

// type AlowwedActions = InitJiliPopUpAction;

const JiliPopUpReducer = (jiliPopUp: JiliPopUpItem = {} as JiliPopUpItem, action: AnyAction): JiliPopUpItem => {
  switch (action.type) {
    case INIT_JILI_POP_UP:
      return action.jiliPopUp;
    default:
      return jiliPopUp;
  }
};

export default JiliPopUpReducer;
