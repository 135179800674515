import { ReactNode, useRef, useState } from "react"

interface ScrollSliderProps {
  className?: string;
  children: ReactNode;
}

const ScrollSlider = ({ children, className }: ScrollSliderProps) => {
  const slider = useRef<any>(null)
  const [isDown, setIsDown] = useState(false)
  const [isSlide, setIsSlide] = useState(false)
  const [startX, setStartX] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<any>()

  return (
    <div  className={className}  ref={slider}
         onMouseDown={e => {
           setIsDown(true)
           setStartX(e.pageX - slider.current.offsetLeft)
           setScrollLeft(slider.current.scrollLeft)
         }}
         onMouseUp={() => {
           if (isSlide) {
             setTimeout(() => setIsSlide(false), 500)
           }
           setIsDown(false)
         }}
         onMouseMove={e => {
           if (!isDown) return
           e.preventDefault()
           setIsSlide(true)
           const x = e.pageX - slider.current.offsetLeft
           const walk = (x - startX) * 3
           slider.current.scrollLeft = scrollLeft - walk
         }}
         onMouseLeave={() => {
           setIsDown(false)
           setIsSlide(false)
         }}
    >
      { children }
    </div>
  )
}

export default ScrollSlider
