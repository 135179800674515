import { useHistory } from "react-router-dom"
import useAuth from '../../../utils/useAuth'
import { googleLogout } from "@react-oauth/google";
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import { memo } from "react";
import { useDispatch } from 'react-redux';
import { setGlobalRules } from "../../../redux/UI/openPopUps.slice";
import { useSelector } from "react-redux";

import "./styles/index.scss"

import { USERNAME } from "../../../utils/constants"
import IconClose from "../../../static/images/sideaccount/close.svg"

// uncoment for next version
// import IconReport from "../../../static/images/actions/report.svg"


const actionsItems = [
  { image: "unsettled-bet", title: "Transactions", page: "/user/transactions" },
  { image: "statement", title: "Account Statement", page: "/user/account-statement" },
  { image: "profit", title: "Profit Loss Report", page: "/user/profit-loss-report" },
  { image: "history", title: "Bet History", page: "/user/bet-history" },
  { image: "unsettled-bet", title: "Unsettled Bet", page: "/user/unsettled-bet" },
  { image: "set-values", title: "Set Button Values", page: "/user/set-button-values" },
  { image: "change-password", title: "Change Password", page: "/user/change-password" },
];

const SideAccount = memo(({ onChangeShowComponent }: { onChangeShowComponent: (state: "sideAccount") => void }) => {
  const username = localStorage.getItem(USERNAME);
  const dispatch = useDispatch();
  const history = useHistory();
  const { logout } = useAuth();
  const {
    user: { isAuthenticated },
  } = useSelector((state: IRootState) => ({ user: state.user }));

  return (
    <ClickAwayListener onClickAway={() => onChangeShowComponent("sideAccount")}>
      <aside className="side-account">
        <div className="top" onClick={() => onChangeShowComponent("sideAccount")}>
          <div className="text" onClick={() => { onChangeShowComponent("sideAccount"); }}>
            {username}
          </div>
          <div className="close">
            <img src={IconClose} alt="close" />
          </div>
        </div>
        {isAuthenticated &&
        <div className="depositWithdrawal-container">
          <div className="depositWithdrawal-container__button"
            onClick={() => {
              history.push("/user/deposit");
              onChangeShowComponent("sideAccount");
          }}>Deposit</div>
          <div className="depositWithdrawal-container__button" onClick={() => {
              history.push("/user/withdrawal");
              onChangeShowComponent("sideAccount");
          }}>Withdraw</div>
        </div>}
        <div className="content">
          <div className="list">
            <div className="actions">
              <div className="action-wrapper">
                {actionsItems.map(({ image, title, page }) => (
                  <div className="action-inner-wrapper" key={"action" + title}>
                    {title === "Change Password"
                      ?
                      <div className="action"
                        onClick={() => { dispatch(setGlobalRules({isOpenPopUp: true})); }}
                      >
                        <span className={`icon side-rules-button`}></span>
                        <span>Rules</span>
                      </div>
                      : ""
                    }
                    <div className="action"
                      onClick={() => {
                        history.push(page);
                        onChangeShowComponent("sideAccount");
                      }
                      }
                    >
                      <span className={`icon icon-${image}`}></span>
                      <span>{title}</span>
                    </div>
                    {title === "Change Password"
                      ?
                      <div className="action"
                        onClick={() => {
                          // @ts-ignore
                          window.Ybug.open("feedback");
                          onChangeShowComponent("sideAccount");
                        }}
                      >
                        <span className={`icon side-rules-feedback`}></span>
                        <span>Feedback</span>
                      </div>
                      : ""
                    }
                  </div>
                ))}
              </div>
              <div className="out">
                <div className="divider" />
                <div className="action" onClick={() => {
                  logout();
                  googleLogout();
                  onChangeShowComponent("sideAccount");
                  }}
                >
                  <span className="icon icon-out"></span>
                  <span>Sign Out</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </ClickAwayListener>
  );
});

export default SideAccount;
