import { Checkbox, useMediaQuery } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import cn from "classnames";
import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { GeneralHandler } from "../../../api/general";
import CustomButton from "../../../components/UI/Button";
import { setVersion } from "../../../redux/menu/userBonus.slice";
import arrowIMG from "../../../static/images/arrowRight.svg";
import closeIMG from "../../../static/images/closeIconPopUp.svg";
import FormikField from "../../Login/components/FormikField";
import "./styles/index.scss";
import { MetaPages, useHelmetDataContext } from "../../../utils/Meta"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface FormValues {
  password: string;
  new: string;
  confirm: string;
  errors?: any;
}

const initialValues: FormValues = {
  password: "",
  new: "",
  confirm: "",
};

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .required("This field is required")
    .matches(/^[\w- !$%^&*#@()_+|~=`{}/:";'<>?,.]+$/i, "This field can contain letters(a-z), numbers(0-9) or special symbols.")
    .min(6, "Password must contain minimum 6 characters")
    .max(36, "Password must contain maximum 36 characters"),
  new: Yup.string()
    .required('This field is required')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/])[a-zA-Z\d!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/]+$/, "This field can contain letters(a-z), numbers(0-9) or special symbols.")
    .min(8, 'Password length should be between 8 to 15.')
    .max(15, 'Password length should be between 8 to 15.'),
  confirm: Yup.string()
    .required("This field is required")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/])[a-zA-Z\d!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/]+$/, "This field can contain letters(a-z), numbers(0-9) or special symbols.")
    .min(6, "Password must contain minimum 6 characters")
    .max(36, "Password must contain maximum 36 characters")
    .oneOf([Yup.ref("new")], "Passwords must match. Try again"),
});

const ChangePassword = ({ onClose = () => {}}) => {
  const [success, setSuccess] = useState(false);
  const [checked, setChecked] = useState(true);
  const [fail, setFail] = useState(false);
  const isPhone = useMediaQuery("all and (max-width: 1024px)");
  const [errMsg, setErrMsg] = useState("");
  const [animation, setAnimation] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  // Helmet start
  useHelmetDataContext(MetaPages.CHANGE_PASSWORD);
  // Helmet end

  const handleSubmit = async (values: FormValues) => {
    try {
      await GeneralHandler.changePassword(values.password, values.new, values.confirm).then(res => {
        if (res?.success) {
          setSuccess(true);
        }
      });
    } catch (error: any) {
      setErrMsg( error?.response?.data?.error?.oldPassword[0]?.description);
      setTimeout(() => setErrMsg(""), 3100);
      setFail(true);
    }
  };

  const handleClose = () => {
    setFail(false);
    setSuccess(false);
    setTimeout(() => setErrMsg(""), 200);
  };
  const closeFunc = () => {
    setAnimation(true);
    setTimeout(() => {
      onClose();
      setAnimation(false);
    }, 500);
  };

  return (
    <>
      <Snackbar open={success}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                style={{ width: "300px", margin: "0 auto", transform: "translateY(-25%)" }}
                resumeHideDuration={500}
                disableWindowBlurListener={true}>
        <Alert onClose={handleClose} severity="success">
          Password successfully updated!
        </Alert>
      </Snackbar>
      <Snackbar open={fail}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                style={{ width: "300px", margin: "0 auto", transform: "translateY(-25%)" }}
                resumeHideDuration={500}
                disableWindowBlurListener={true}>
        <Alert onClose={handleClose} severity="error">
          {errMsg
            ? errMsg
            : "Password updating error. Please try again"
          }
        </Alert>
      </Snackbar>
      <div onClick={closeFunc} className={cn("change-password", {
        "mobile": isPhone,
      })}>
        <div className={cn("change-password__change-password-form", {
          "close": animation,
        })}
             onClick={e => e.stopPropagation()}
        >
          {!isPhone &&
            <div className="change-password__change-password-form-header">
              <span>Change Password</span>
              <img onClick={closeFunc} src={closeIMG} alt="closeIMG" />
            </div>
          }
          <div className="change-password__title">Change Password</div>
          <div className="change-password__text">
            Fill the following information to reset your password.
          </div>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={LoginSchema}
          >
            {({ dirty, isValid, errors }) => {
              return (
                <Form className="change-password__form">
                  <div className="change-password__form-item">
                    <div className="change-password__form-item-title">Old Password</div>
                    <FormikField
                      errors={errors}
                      name="password"
                      label="*********"
                      type="password"
                    />
                  </div>
                  <div className="change-password__form-item">
                    <div className="change-password__form-item-title">New Password</div>
                    <FormikField
                      errors={errors}
                      name="new"
                      label="*********"
                      type="password"
                    />
                  </div>
                  <div className="change-password__form-item">
                    <div className="change-password__form-item-title">Confirm Password</div>
                    <FormikField
                      errors={errors}
                      name="confirm"
                      label="*********"
                      type="password"
                    />
                  </div>
                  <CustomButton
                    className="change-password__form-button"
                    type="submit"
                    disabled={!dirty || !isValid || !checked}
                  >
                    Submit
                    <img src={arrowIMG} alt="arrowIMG" />
                  </ CustomButton>
                  <div className="change-password__privacy-policy">
                    <Checkbox checked={checked} onChange={e => setChecked(e.target.checked)} />
                    <div>
                      <span>This site is protected by reCAPTCHA and the Google. </span>
                      <span onClick={() => {
                        closeFunc();
                        dispatch(setVersion(""));
                        history.push("/privacy-policy");
                      }} className="change-password__privacy-policy-link">Privacy Policy</span>
                      <span> and </span>
                      <span onClick={() => {
                        closeFunc();
                        dispatch(setVersion(""));
                        history.push("/privacy-policy");
                      }}  className="change-password__privacy-policy-link">Terms of Service</span>
                      <span> apply.</span>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
