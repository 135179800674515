import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface BannersList {
  result: {} | any
  status: {}
  success: boolean
}

const INIT_BANNERS = 'INIT_BANNERS';

type InitBannersAction = Action<typeof INIT_BANNERS> & {
  banners: BannersList;
};

export const initBanner = (banners: BannersList): InitBannersAction => ({
  type: INIT_BANNERS,
  banners,
});

// type AlowwedActions = InitBannersAction;

const BannerReducer = (banners: BannersList =  {} as BannersList, action: AnyAction): BannersList => {
  switch (action.type) {
    case INIT_BANNERS:
      return action.banners;
    default:
      return banners;
  }
};

export default BannerReducer;
