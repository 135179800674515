import { useEffect } from "react"
import {Route, useHistory} from "react-router-dom"
import { useSelector } from "react-redux"

export const PrivateRoute = (props: any) => {

  const { component: RouteComponent, ...rest } = props

  //  !!! public version
  const history = useHistory()
  const { isAuthenticated } = useSelector((state: IRootState) => state.user)
  useEffect(() => {
    if (!isAuthenticated){
      // history.push("/home")
    }
  }, [isAuthenticated, history])

  return <Route {...rest} render={routeProps => <RouteComponent {...routeProps} />} />
}
