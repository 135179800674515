import { Reducer } from "@reduxjs/toolkit";
import { FC, useEffect } from "react";
import { useDispatch, useStore } from "react-redux";

export type ReducersList = {
  [name in IRootStateKey]?: Reducer;
};

interface IDynamicPageLoaderProps {
  reducers: ReducersList;
  removeAfterUnmount?: boolean;
}

export const DynamicPageLoader: FC<IDynamicPageLoaderProps> = (props) => {
  const { children, reducers, removeAfterUnmount } = props;
  const store = useStore() as ReduxStoreWithManager;
  const dispatch = useDispatch();

  useEffect(() => {
    Object.entries(reducers).forEach(([name, reducer]: [string, Reducer]) => {
      store.reducerManager.add(name as IRootStateKey, reducer);
      dispatch({ type: `@Init LAZY ${name}` });
    });

    return () => {
      if (removeAfterUnmount) {
        Object.entries(reducers).forEach(([name, reducer]: [string, Reducer]) => {
          store.reducerManager.remove(name as IRootStateKey);
          dispatch({ type: `@Destroy LAZY ${name}` });
        });
      }
    };
  }, []);

  return <>{children}</>;
};
