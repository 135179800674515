import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface SidemenuGamesList {
  competitionId: string;
  eventTypeId: string;
  id: string;
  name: string;
  startTime: string;
  type: string;
}

const INIT_SIDE_MENU_GAMES = 'INIT_SIDE_MENU_GAMES';

type InitSideMenuGamesAction = Action<typeof INIT_SIDE_MENU_GAMES> & {
  sideMenuGames: SidemenuGamesList[];
};

export const initSideMenuGames = (sideMenuGames: SidemenuGamesList[]): InitSideMenuGamesAction => ({
  type: INIT_SIDE_MENU_GAMES,
  sideMenuGames,
});

// type AlowwedActions = InitSideMenuGamesAction;

const SideMenuGamesReducer = (sideMenuGames: SidemenuGamesList[] = [], action: AnyAction): SidemenuGamesList[] => {
  switch (action.type) {
    case INIT_SIDE_MENU_GAMES:
      return action.sideMenuGames;
    default:
      return sideMenuGames;
  }
};

export default SideMenuGamesReducer;
