import { useEffect, useState } from "react"
import { Snackbar, useMediaQuery } from "@material-ui/core"
import "./styles/index.scss"
import arrowIMG from "../../static/images/arrowRight.svg"
import closeIMG from "../../static/images/closeIconPopUp.svg"
import timeIMG from "../../static/images/time.svg"
import timeWhiteIMG from "../../static/images/timeWhite.svg"
import arrowDownIMG from "../../static/images/arrowDown.svg"
import cn from "classnames"
import CustomButton from "../UI/Button"
import GetTime from "../HeaderNew/GetTime"
import { useDispatch, useSelector } from "react-redux"
import { GeneralHandler } from "../../api/general"
import { setServerTimeSuccess, setTimeZoneName } from "../../redux/menu/timeSettings.slice"
import moment from "moment"
import { createPortal } from "react-dom"
import { Alert } from "@material-ui/lab"
import { useHistory } from "react-router"


const modalRoot = document.querySelector("#modal-root")
const UserTimeSettings = ({ onClose = () => {}}) => {
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const [animation, setAnimation] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const { timeZoneName, serverTime } = useSelector((state: IRootState) => state.timeSettings)
  const [chosenZone, setChosenZone] = useState(timeZoneName)
  const dispatch = useDispatch()
  const [snackBar, setSnackBar] = useState({ isError: false, msg: "" })
  const history = useHistory()

  useEffect(() => {
    if (!serverTime) {
      GeneralHandler.getServerTime().then((res) => dispatch(setServerTimeSuccess(res)))
    }
  }, [])

  const handleClick = (e: any) => {
    setChosenZone(e.target.title)
  }
  const getTitle = (zone: string ) => {
    if (zone === "system") return "System time - (GMT +00:00)"
    if (zone === "device") return `Your device time - (GMT ${moment().format("Z")})`
    if (zone === "india") return "India Standard Time - (GMT +05:30)"
    return ""
  }

  const closeFunc = () => {
    setAnimation(true)
    setTimeout(() => {
      onClose()
      if (isPhone) {
        history.push("/home")
      }
      setAnimation(false)
    }, 1000)
  }

  const save = () => {
    if (chosenZone !== timeZoneName) {
      dispatch(setTimeZoneName(chosenZone))
      setSnackBar({ isError: false, msg: "Time settings updated successfully" })
      closeFunc()
    } else {
      setSnackBar({ isError: true, msg: "You don't change anything" })
    }
  }

  return (
    <>
      <div onClick={closeFunc} className={cn("user-time-settings")}>
        <Snackbar open={!!snackBar?.msg}
                  autoHideDuration={1000}
                  onClose={() => setSnackBar({ isError: false, msg: "" })}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  // style={{transform: "translate(-50%,100px"}}
                  resumeHideDuration={500}
                  disableWindowBlurListener={true}>
          <Alert onClose={() => setSnackBar({ isError: false, msg: "" })} severity={snackBar.isError ? "error" : "success" }>
            {snackBar?.msg}
          </Alert>
        </Snackbar>
        <div className={cn("user-time-settings__content", {
          "close": animation
        })}
             onClick={e => e.stopPropagation()}
        >
          {!isPhone &&
            <div className="user-time-settings__content-header">
              <span>Time Settings</span>
              <img onClick={closeFunc} src={closeIMG} alt="closeIMG" />
            </div>
          }
          <div className="user-time-settings__content-img">
            <img src={isPhone ? timeWhiteIMG : timeIMG} alt="timeIMG" />
          </div>
          <div className="user-time-settings__content-date">
            <GetTime withTitles={true} timeZoneName={timeZoneName} />
          </div>
          <div className="user-time-settings__content-time">
            Time Zone: {getTitle(timeZoneName)}
          </div>
          <div className="user-time-settings__customize">
            <div className="user-time-settings__customize-title">
              Customize time zone
            </div>
            <div onClick={() => setShowDropdown(!showDropdown)} className={cn("user-time-settings__customize-dropdown", {
              "active" : showDropdown
            })}>
              <span>{getTitle(chosenZone)}</span>
              <div className={cn("user-time-settings__customize-dropdown-arrow", {
                "active" : showDropdown
              })}>
                <img src={arrowDownIMG} alt="arrowDownIMG" />
              </div>
              {!isPhone && <ul className={cn("user-time-settings__customize-dropdown-items", {
                "show-dropdown": showDropdown
              })}>
                <li className={cn({ "chosen": timeZoneName === "system" })} title="system" onClick={handleClick}>{getTitle("system")}</li>
                <li  className={cn({ "chosen": timeZoneName === "device" })} title="device" onClick={handleClick}>{getTitle("device")}</li>
                <li  className={cn({ "chosen": timeZoneName === "india" })} title="india" onClick={handleClick}>{getTitle("india")}</li>
              </ul>}
              {isPhone  && showDropdown && createPortal(
                <div onClick={() => setShowDropdown(!showDropdown)}
                     className={cn("user-time-settings__customize-dropdown-items-wrapper")}
                >
                  <ul className={cn("user-time-settings__customize-dropdown-items", {
                    "hide-dropdown": !showDropdown
                  })}>
                    <li className={cn({ "chosen": timeZoneName === "system" })} title="system" onClick={handleClick}>{getTitle("system")}</li>
                    <li  className={cn({ "chosen": timeZoneName === "device" })} title="device" onClick={handleClick}>{getTitle("device")}</li>
                    <li  className={cn({ "chosen": timeZoneName === "india" })} title="india" onClick={handleClick}>{getTitle("india")}</li>
                  </ul>
                </div>
                , modalRoot as Element)}
            </div>
            <CustomButton
              className="user-time-settings__content-button"
              type="submit"
              onClick={save}
            >
              <div className="user-time-settings__content-button-title">  {isPhone ? "Save" : "Submit"}</div>
              <img src={arrowIMG} alt="arrowIMG" />
            </ CustomButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserTimeSettings
