import { createSlice, PayloadAction} from '@reduxjs/toolkit'


export interface MessageItem {
  id: number;
  level: string;
  order: number;
  text: string;
  userId: string;
}
export interface NotificationInterface {
  messages: MessageItem[],
  notificationPopUp: boolean
}

const uiInitialState: NotificationInterface = {
  messages: [],
  notificationPopUp: false
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: uiInitialState,
  reducers: {
    setMessages: (state, {payload}: PayloadAction<MessageItem[]>) => {
      state.messages = payload
    },
    setNotificationPopUp: (state, {payload}: PayloadAction<boolean>) => {
      state.notificationPopUp = payload
    },

  },
})

export const { setMessages, setNotificationPopUp } = notificationSlice.actions

export default notificationSlice.reducer
