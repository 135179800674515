import { FC } from "react"
import { useHistory } from "react-router-dom"
import telegramImg from "../../static/images/footer/telegram.png"
import instaImg from "../../static/images/footer/insta.png"
import tweeterImg from "../../static/images/footer/tweeter.png"
import faceBookImg from "../../static/images/footer/faceBook.png"
import phoneImg from "../../static/images/footer/phone.svg"
import upiImg from "../../static/images/footer/upi.svg"
import curacao from "../../static/images/curacao.png"
import bankImg from "../../static/images/footer/bank.svg"
import beGambleImg from "../../static/images/footer/beGamble.svg"
import gamblingImg from "../../static/images/footer/gambling.svg"
import cograImg from "../../static/images/footer/cogra.svg"
import whatsAppImg from "../../static/images/footer/whatsApp.png"
import mailImg from "../../static/images/footer/mail.svg"
import "./styles/index.scss"

export const Footer: FC = () => {
  const history = useHistory()


  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-social-wrapper">
          <div className="footer__social">
            <div className="footer__social-download">
              {/* <div className="footer__social-download-title">
                <span>Download</span>
                <span>Mobile Application</span>
              </div> */}
               <div className="footer__social-download-button">
                <a href={`https://s3.ap-south-1.amazonaws.com/cdn.mac1j.com/apks/Funexch-score.apk`}
                   className="footer__social-row-socials-item telegram"
                >
                  <div className="footer__social-row-socials-item-appBox">
                  <div className="footer__social-row-socials-item-appBox-android"></div>
                    <div className="footer__social-row-socials-item-appBox-textRow">
                      <div className="footer__social-row-socials-item-appBox-textRow-title">Download</div>
                      <div className="footer__social-row-socials-item-appBox-textRow-subTitle">SCORE APP</div>
                    </div>
                  </div>
                </a>
              </div>
              <div className="footer__social-download-button">
                <a href={`https://cdn.mac1j.com/apks/FUNEXCH1.0.3.apk`}
                   className="footer__social-row-socials-item telegram"
                >
                  <div className="footer__social-row-socials-item-appBox">
                  <div className="footer__social-row-socials-item-appBox-android"></div>
                    <div className="footer__social-row-socials-item-appBox-textRow">
                      <div className="footer__social-row-socials-item-appBox-textRow-title">Download</div>
                      <div className="footer__social-row-socials-item-appBox-textRow-subTitle">ANDROID APP</div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="footer__social-row">
              <div className="footer__social-row-whatsapp-title">
                Follow us on:
              </div>
              <div className="footer__social-row-socials">
                <a href={`https://instagram.com/_u/funexchofficial`}
                   target="_blank"
                   className="footer__social-row-socials-item instagram"
                >
                  <img src={instaImg} alt="instaImg" />
                </a>
                <a href={`https://telegram.me/funexchofficial`}
                   target="_blank"
                   className="footer__social-row-socials-item telegram"
                >
                  <img src={telegramImg} alt="telegramImg" />
                </a>
                <a href={`https://www.facebook.com/funexchofficial`}
                   target="_blank"
                   className="footer__social-row-socials-item instagram"
                >
                  <img src={faceBookImg} alt="faceBookImg" />
                </a>
                <a href={`https://twitter.com/funexchofficial`}
                   target="_blank"
                   className="footer__social-row-socials-item telegram"
                >
                  <img src={tweeterImg} alt="tweeterImg" />
                </a>
              </div>
            </div>
            <div className="footer__social-row phones">
              <div className="phones-wrapper">
                <div className="phones-wrapper-header">
                  Contact us:                </div>
              </div>
              <div className="phones-wrapper">
              <div className="footer__social-row-whatsapp-title">
                24/7 Helpline:
              </div>
              <div className="footer__social-row-socials">
                <a href="tel:+919126388888"
                   target="_blank"
                   className="footer__social-row-socials-item whatsApp-phone"
                >
                  <img src={phoneImg} alt="phoneImg" />
                </a>
                <a href={`https://api.whatsapp.com/send?phone=919126388888`}
                   target="_blank"
                   className="footer__social-row-socials-item whatsApp-phone"
                >
                      <img src={whatsAppImg} alt="whatsAppImg" />
                </a>
                <div className="footer__social-row-socials-phones-wrapper">
                  <span>
                    <a href="tel:+919126388888 "> +91-912 638 8888 </a>
                  </span>
                </div>
              </div>
            </div>
              <div className="phones-wrapper">
                <div className="footer__social-row-whatsapp-title">
                 Email
                </div>
                <div className="footer__social-row-socials">
                  <a
                    href="mailto:support@funexch.com"
                    className="footer__social-row-socials-item whatsApp-phone"
                    target="_blank"
                  >
                    <img src={mailImg} alt="mailImg" />
                  </a>
                  <div className="footer__social-row-socials-phones-wrapper">
                    <span>
                      <a href="mailto:support@funexch.com">support@funexch.com</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer__info">
            <div className="footer__info-logo">
            </div>
            <p>
              Funexch Holdings Ltd, 4, Avenida de La Velada, Campo de Gibraltar, acting for processing purposes on behalf of Winzi Corporation.
            </p>
            <p>
              Funexch.com operates with the licence of Winzi Corporation, Willemstad, Curaçao, which is licensed by the government of Curaçao under the licence 8048/NAZ2018-018, issued for the provision of sports betting and casino. Funexch is a registered trade mark.            </p>
            <p>
              Any and all content on the website (desktop and mobile), including sports odds, are proprietary and may not be copied or disseminated without the express written consent of Funexch. All Rights Reserved.            </p>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img style={{ height: 70, width: 150 }} src={curacao} alt="upiImg" />
        </div>
        <div className="footer__paynents">
          <div className="footer__paynents-item">
            <img src={upiImg} alt="upiImg" />
          </div>
          <div className="footer__paynents-item">
            <img src={bankImg} alt="bankImg" />
          </div>
          <div className="footer__paynents-item">
            <img src={beGambleImg} alt="beGambleImg" />
          </div>
          <div className="footer__paynents-item">
            <img src={gamblingImg} alt="gamblingImg" />
          </div>
          <div className="footer__paynents-item">
            <img src={cograImg} alt="cograImg" />
          </div>
        </div>
        <div className="footer__term">
          <div className="footer__term-rules">
            <span onClick={() => history.push("/rules-regulations")}>Rules & Regulations</span> © 2016-2023 v. 9.56
          </div>
        </div>
      </div>
    </div>
  )
}
