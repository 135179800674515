import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { initJiliPopUp } from "../../../../redux/pages/jiliPopUp";
import { ItemsChildrenList } from "./children"
import { setIsOpenLoginPopUp, setIsOpenPopUp, setIsOpenSideBar } from "../../../../redux/UI/openPopUps.slice"


interface RedirectOptions {
  name?: string;
  type?: string;
  Id?: string;
  eventType?: string;
  redirect?: string;
  item?: any;
}
export interface ItemsList {
    type: string;
    name: string;
    id: string;
    countryCode?: string;
    startTime?: string;
    altType?: string;
    children?: ItemsChildrenList[] | undefined;
    altName?: string;
    altId?: string
  }

const useRedirect = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: IRootState) => state.user.isAuthenticated);
  const handleRedirect = ({ name, type, Id, eventType, redirect, item }: RedirectOptions) => {
    if (!isAuthenticated && Id &&  /^\d{5}$/.test(Id)){
        dispatch(setIsOpenSideBar(false))
        dispatch(setIsOpenLoginPopUp(true));
        return;
    }
    let link = "";
   if (type === 'CUSTOM_PAGE' && name === "Virtual Sports") {
      link = '/home/slots-page/55';
    } else if (type === 'GROUPED_LIVE_GAMES') {
      if (Id?.length === 5) {
        link = `/card-game/${Id}`;
      } else {
        link = `/home/slots-page/${Id}`;
      }
    } else if (type === 'EVENT' && eventType === "144001") {
      link = `/card-game/${Id}`;
    } else if (type === 'EVENT' && Id?.length === 5) {
        link = `/card-game/${Id}`;
    } else if (type === 'EVENT' && eventType !== "144001") {
      link = `/sport-event-detail/${Id}`;
    } else if (type === 'EVENT' && eventType === "1444005") {
      link = `/sportsbook`;
    } else if (type === 'CUSTOM_PAGE' && name === 'Live Casino') {
      link = `/home/live-casino-page/${Id}`;
    } else if (type === 'CUSTOM_PAGE') {
      link = "/home" + redirect;
    } else if (type === 'CASINO_GAME') {
      link = `/casino-game/${Id}`;
    } else if (type === "RACE") {
        link = `/sport-event-detail/${Id}`
    } else {
      link = `/event-list/${Id}`;
    }

    if ((item?.id.includes("JILI") || item?.id.includes("jili") || item?.type === 'CASINO_JILI')) {
      if (!isAuthenticated) {
        dispatch(setIsOpenLoginPopUp(true));
        return;
      }
      dispatch(setIsOpenPopUp(true));
      dispatch(initJiliPopUp(item));
    } else {
      history.push({
        pathname: link,
        state: {
          sportId: eventType,
          eventName:  item.venue ? item.venue : name,
          children: item?.children,
          name: item.venue ? item.venue : name,
          isLeft: true,
          backPath: location.pathname,
        },
      });
    }
  };

  return handleRedirect;
};

export default useRedirect;
