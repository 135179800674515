import { createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface OrderListInterface {
  orderList: {
    matched: any[],
    unmatched: any[]
  },
  orderCount: number,
}

const uiInitialState: OrderListInterface = {
  orderList: {
    matched: [],
    unmatched: []
  },
  orderCount: 0
}

export const orderListSlice = createSlice({
  name: 'olrderList',
  initialState: uiInitialState,
  reducers: {
    setOrderList: (state, {payload}: PayloadAction<any>) => {
      state.orderList = payload
    },
    setOrderCount: (state, {payload}: PayloadAction<number>) => {
      state.orderCount = payload
    }
  },
})

export const { setOrderList, setOrderCount } = orderListSlice.actions

export default orderListSlice.reducer
