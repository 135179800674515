import { useEffect } from "react";
import { useHistory } from "react-router";

const useGoBack = (
  goBackElementId= "backButton" ,
  onClick?: () => void | undefined,
): void => {
  const history = useHistory();
  const goBackElement = document.getElementById(goBackElementId);

  useEffect((): any => {

    if (goBackElement !== null) {
      const handleClick = (event: Event): void => {
        event.stopPropagation();
        onClick ?  onClick() : history.goBack();
      };

      goBackElement.addEventListener("click", handleClick);

      return () => {
        goBackElement.removeEventListener("click", handleClick);
      };
    }
  }, [goBackElementId, onClick]);
};

export default useGoBack;
