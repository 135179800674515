interface IProps {
  desktopText: any;
}
export const DesktopRuleText = ({desktopText}:IProps) => {
  return (
    <article>
      {[...desktopText].map((ruleTextItem) => {
        return (
          <p>{ruleTextItem}</p>
        );
      })}
    </article>
  );
};