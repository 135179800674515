import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface ProjectionItem {
  event_name: string | null | undefined,
  selection: string | null | undefined,
  odds: number,
  stake: number,
  marketId: string | null | undefined,
  back: number | null | undefined;
}

const INIT_PROJECTION = 'INIT_PROJECTION';

type InitProjectionAction = Action<typeof INIT_PROJECTION> & {
  projection: ProjectionItem;
};

export const InitProjection = (projection: ProjectionItem ): InitProjectionAction => ({
  type: INIT_PROJECTION,
  projection,
});

// type AlowwedActions = InitProjectionAction;

const ProjectionReducer = (projection: ProjectionItem = {} as ProjectionItem, action: AnyAction): ProjectionItem => {
  switch (action.type) {
    case INIT_PROJECTION:
      return action.projection;
    default:
      return projection;
  }
};

export default ProjectionReducer;
