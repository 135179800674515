import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { GeneralHandler } from "../../../api/general"
import { initBanner } from "../../../redux/menu/banners"
import Carousel from "nuka-carousel"
import "./styles/index.scss"
import { useMediaQuery } from "@material-ui/core"

export const PromotionBaners = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const banners = useSelector((state: IRootState) => state.banners)
  const isdesktop = useMediaQuery("all and (min-width: 1024px)")
  const url = history.location.pathname
  const [actualBannes, setActualBannes] = useState([])


  useEffect(() => {
    if (url && url.includes("live-casino-page")) {
      return setActualBannes(banners?.result?.specialLiveGames)
    }
    if (url && url.includes("slots-page")) {
      return  setActualBannes(banners?.result?.specialSlotGames)
    }
    if (url && url.includes("card-games")) {
      return  setActualBannes(banners?.result?.specialCardGames)
    }
    setActualBannes(banners?.result?.inPlaySlidingBanners)
  }, [url, banners])

  useEffect(() => {
    GeneralHandler.getPromoBaners().then(res => {
      dispatch(initBanner(res))
    })
    return ( ) => {

    }
  }, [])

  const renderLeftArrow = (onClick: any, left: any) => {
    if (isdesktop) {
      return (
        <button onClick={onClick} className="banners_arrowControl">
          <span className={`banners_arrowControl-arrow ${left ? "left" : ""}`}></span>
        </button>
      )
    } else {
      return
    }
  }

  let bannerList =
  actualBannes &&
  actualBannes.map((item: any) => {
    return (
    <div className="slider_banner__item" key={item.index} >
          <img
            className="slider_banner__item_img"
            src={isdesktop ? item?.desktopImages || item?.desktopImage : item.image}
            alt=""
             onClick={() => {
                gotoEvent(item?.redirect?.includes('casino_live') ? `card-game/${item?.redirect?.split('/')?.[2]}/${item?.redirect?.split('/')?.[3]}` : item?.redirect)
             }}
      />
    </div>)
  })

  const gotoEvent = (redirect:string) => {
    if(redirect) history.push(`/${redirect}`)
  }

  return (
    <div className="banners">
      <Carousel
        dragging
        swiping
        autoplay
        autoplayInterval={5000}
        wrapAround
        renderCenterLeftControls={({ previousSlide }) => renderLeftArrow(previousSlide, true)}
        renderCenterRightControls={({ nextSlide }) => renderLeftArrow(nextSlide, false)}
      >
        {bannerList}
      </Carousel>
    </div>
  )
}