import { push } from "connected-react-router"
import { all, call, delay, put, takeEvery } from "redux-saga/effects"
import { ID_TOKEN } from "../../utils/constants"

import { REFRESH_TOKEN } from "./user.actions"
import * as userAPI from "./user.requests"
import { setAuthenticate } from "./user.slice"

function* refreshToken() {
  try {
    const token = localStorage.getItem(ID_TOKEN)
    yield delay(1000)
    if (!!token) {
      yield call(userAPI.refreshToken, token)
      yield put(setAuthenticate(true))
    } else {
      // yield all([put(push("/login")), put(setAuthenticate(false))])
      if (window.location.href.includes('intercom')){
        yield all([put(push("/intercom")), put(setAuthenticate(false))])
      } else {
        yield all([put(push("/home/sports/4")), put(setAuthenticate(false))])
      }
    }
  } catch {}
}

export default function* userSaga() {
  yield call(refreshToken)
  yield takeEvery(REFRESH_TOKEN, refreshToken)
}
