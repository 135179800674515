import { createContext, useContext, useEffect } from "react";
import { HelmetDataContextType, MetaPages } from "./meta-types";
import { MetaData } from "./metaData";

export const HelmetDataContext = createContext<HelmetDataContextType | undefined>(undefined);

export const useHelmetDataContext = ( page?: MetaPages | undefined) => {
  const context = useContext(HelmetDataContext);

  if (context === undefined) {
    throw new Error("useHelmetDataContext must be used within a HelmetDataProvider");
  }

  useEffect(() => {
    if (page) {
      context.setHelmetData(MetaData[page]);
    }

    return () => {
      context.setDefault();
    };
  }, [page]);

  return context;
};
