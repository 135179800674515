import { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useLocation, useHistory } from "react-router-dom"
import LoadingSpinner from "../../components/loadingSpinner"
import { GeneralHandler } from "../../api/general"
import { initSlotsList } from "../../redux/menu/slotsCategories"
import { initJiliPopUp } from "../../redux/pages/jiliPopUp"
import { useMediaQuery } from "@material-ui/core"
import PointPopUp from "../LiveCasinoGames/PointPopUp/PointPopUp"
import slotGamesImgDesk from "../../static/images/homeMenu/slotGamesDesk.webp"
import slotGamesImg from "../../static/images/homeMenu/slotGames.webp"
import "./styles/index.scss"
import { setIsOpenLoginPopUp, setIsOpenPopUp } from "../../redux/UI/openPopUps.slice"
import { MetaPages, useHelmetDataContext } from "../../utils/Meta"

const Slots = () => {
  const location = useLocation()
  const history = useHistory()
  const slotCategories = (localStorage.getItem("slot_categories"))
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const categories = useSelector((state: IRootState) => state.slotsList).sort((a, b) => a.name.localeCompare(b.name))
  const id = location.pathname.split("/").slice(-1)[0] || location.pathname.split("/").slice(-2)[0]
  const [gamesList, setGamesList] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [currentCategories, setCurrentCategories] = useState(slotCategories)
  const [notMoreGame, setNotMoreGame] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [resCategories, setResCategories] = useState({ slots: [{ id: "" }], live: [{ id: "" }] })
  const [allSortedCategories, setAllSortedCategories] = useState<any>([])
  const { user: { isAuthenticated } } = useSelector((state: IRootState) => ({ user: state.user }))
  const isdesktop = useMediaQuery("all and (min-width: 1024px)")
  const [showPointPopUp, setShowPointPopUp] = useState(false)

  useHelmetDataContext(MetaPages.SLOTS_PAGE);

  useEffect(() => {
    if (!isFetching) return
    if (gamesList.length > 0) {
      setCurrentPage(currentPage + 1)
    }
  }, [isFetching])

  const initData = () => {
    GeneralHandler.getSlotsAndLiveCategories(isAuthenticated).then(res => {
      setResCategories(res)
      console.log("res", res)
      let allSortedCategoriess
      // @ts-ignore

      setAllSortedCategories([...res?.slots].sort(item => item.name))
      allSortedCategoriess = [...res?.slots].sort(item => item.name)
      setLoading(false)

      dispatch(initSlotsList(allSortedCategoriess))
      const check = allSortedCategoriess.filter((item: any) => item.id === id)
      if (id && check.length) {
        setCurrentCategories(check[0].id)
        localStorage.setItem("slot_categories", id)
      } else {
        localStorage.setItem("slot_categories", `${allSortedCategoriess[0]?.id}`)
        setCurrentCategories(allSortedCategoriess[0]?.id)
      }
    })
  }

  console.log("allSortedCategories", allSortedCategories)

  useEffect(() => {
    setLoading(true)
    setShowPointPopUp(true)
    initData()
    return (() => {
      localStorage.removeItem("slot_categories")
    })
  }, [])

  // check url for id

  useEffect(() => {
    const check = categories.filter(item => item.id === id)
    if (id && check.length) {
      setCurrentCategories(id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const GamesListHandler = (id: string, page: number) => {
    if (page === 1) {
      setLoading(true)
    }

    const pageSize = 18
    const isSlots = !!resCategories.slots.find(item => item.id === id)

    GeneralHandler.getSlotsAndLiveGames(id, isSlots, isAuthenticated).then(res => {
      let result = []
      if (page === 1) {
        result = [...res]
      } else {
        result = [...gamesList, ...res]
      }

      res.length < pageSize ? setNotMoreGame(true) : setNotMoreGame(false)

      setGamesList(result)
      setLoading(false)
    })
    setIsFetching(false)
  }

  useEffect(() => {
    if (categories.length > 0) {
      if (currentCategories) {
        GamesListHandler(currentCategories, currentPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, currentPage, currentCategories])

  const gamesProps = useMemo(() => ({
    slots: {
      name: "Slot Games",
      key: "slotGames",
      image: slotGamesImg,
      imageDesk: slotGamesImgDesk,
      action: () => history.push("/slots-page")
    }
  }), [])
  console.log("gamesProps", gamesProps);
  return (
    <>
      <div className="slot__in-page-home">
      </div>
      {loading
        ? <div className="centered">
          <LoadingSpinner />
        </div>
        : <>
          {isdesktop && <div className="home-page__games-wrapper">
          </div>}
          <div className="live-casino__game">
            <p className="live-casino__game-title">All Slots Games</p>
            <p className="live-casino__game-descr">Take a seat and play against all real dealers to enjoy casino
              games</p>
            <p className="live-casino__game-text">Discover our very own Live Casino with Teen Patti, Rummy, Andar Bahar,
              Dragon Tiger, Crazy Time, Blackjack, Roulette and more. Meet our professional live dealers, including
              Hindi speaking dealers for Andar Bahar and Roulette, and enjoy the real deal!</p>
          </div>
          <div className="slot__body">
            {gamesList.map((item: any, index: number) => {

              return (
                <div key={index}>
                  {(item.id.includes("JILI") || item.id.includes("jili") || item.type === "CASINO_JILI")
                    ? (
                      <Link
                        to="#"
                        onClick={() => {
                          if (!isAuthenticated) {
                            dispatch(setIsOpenLoginPopUp(true))
                            return
                          }
                          dispatch(setIsOpenPopUp(true))
                          dispatch(initJiliPopUp(item))
                        }}
                      >
                        <div className="slot__body-cards-wrapp">
                          <img src={item.image} alt="" className="slot__body-cards-image" />
                          {item.image === "" ? item.name : ""}
                          {/*<div className="slot__body-cards-btn">Play</div>*/}
                        </div>
                        <p className="slot__body-cards-name">
                          {item.name}
                        </p>
                      </Link>
                    )
                    : (
                      <Link className="slot__body-cards" key={index}
                            to="#"
                            onClick={() => {
                              if (!isAuthenticated) {
                                dispatch(setIsOpenLoginPopUp(true))
                                return
                              }
                              history.push(`/casino-game/${item.id}`, {
                                gameName: `${item.name}`,
                                backPath: `${location.pathname}`
                              })
                            }}
                      >
                        <div className="slot__body-cards-wrapp">
                          <img src={item.image} alt="" className="slot__body-cards-image" />
                          {item.image === "" ? <div className="slot__body-cards-second-name">{item.name}</div> : ""}
                          {/*<div className="slot__body-cards-btn">Play</div>*/}
                        </div>
                        <p className="slot__body-cards-name">
                          {item.name}
                        </p>
                      </Link>
                    )
                  }
                </div>
              )
            })}
          </div>
          <div
            style={{ display: "none" }}
            className={`slot__bottom-load`}>
            {!notMoreGame ? <LoadingSpinner /> : ""}
            {notMoreGame ? <button className={`slot__button `} disabled={true}>No more games</button> : ""}
          </div>
        </>
      }
      {isAuthenticated && showPointPopUp && <PointPopUp setShowPointPopUp={setShowPointPopUp}/>}
    </>
  )
}

export default Slots
