import { createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface UserBonusInterface {
  version: string;
  walletType: any;
}

const uiInitialState: UserBonusInterface = {
  version: "",
  walletType: null,
};

export const userBonusSlice = createSlice({
  name: "userBonus",
  initialState: uiInitialState,
  reducers: {
    setVersion: (state, {payload}: PayloadAction<string>) => {
      state.version = payload;
    },
    setWalletType: (state, {payload}: PayloadAction<any>) => {
      state.walletType = payload;
    },

  },
});

export const { setVersion, setWalletType } = userBonusSlice.actions;

export default userBonusSlice.reducer;
