import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface BetsList {
  result: any
  status: {}
  success: boolean
}

const INIT_BETS = 'INIT_BETS';

type InitBetsAction = Action<typeof INIT_BETS> & {
  bets: BetsList;
};

export const initBets = (bets: BetsList): InitBetsAction => ({
  type: INIT_BETS,
  bets,
});

// type AlowwedActions = InitBetsAction;

const BetsReducer = (bets: BetsList =  {} as BetsList, action: AnyAction): BetsList => {
  switch (action.type) {
    case INIT_BETS:
      return action.bets;
    default:
      return bets;
  }
};

export default BetsReducer;
