import axios from "axios";
import {API_URL, BANERS_API, GAMES_GILI, IFRAME, PUBLIC_URL, PUBLIC_URL_MATCH_CENTER} from "../utils/config";
import {ID_TOKEN} from "../utils/constants" ;

// export const instance = axios.create({ baseURL: token ? API_URL : PUBLIC_URL});
export const instance = axios.create({ baseURL: API_URL});

export const instanceGemexch = axios.create({ baseURL: BANERS_API });


instanceGemexch.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(`${ID_TOKEN}`)
    config.headers.authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)


instance.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem(`${ID_TOKEN}`)
      // console.log("config", config);

          // console.log("PUBLIC_URL", PUBLIC_URL)
      if (!token && !config?.url?.includes("auth") && !config?.url?.includes("config") && !config?.url?.includes("teenpattiCategory")  && !config?.url?.includes("server-time") ) {
        // console.log("PUBLIC_URL", PUBLIC_URL)
        // console.log("config 2@@@@@@@@@@@@", {...config, baseURL: PUBLIC_URL });
        return {...config, baseURL: PUBLIC_URL }
    }

      config.headers.authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
instance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response?.status === 403) {
      const localToken = localStorage.getItem(ID_TOKEN)

      if (localToken)
        setTimeout(() => {
          window.location.reload()
        }, 1000)

      localStorage.removeItem(ID_TOKEN)
    }

    return Promise.reject(error)
  }
)


export const IframeGamesInstance = axios.create({baseURL: IFRAME})

IframeGamesInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(`${ID_TOKEN}`)
    config.headers.authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
IframeGamesInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response?.status === 403) {
      const localToken = localStorage.getItem(ID_TOKEN)

      if (localToken)
        setTimeout(() => {
          window.location.reload()
        }, 1000)

      localStorage.removeItem(ID_TOKEN)
    }

    return Promise.reject(error)
  }
)

export const GamesJiliInstance = axios.create({ baseURL: GAMES_GILI });

GamesJiliInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem(`${ID_TOKEN}`)
    config.headers.authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
GamesJiliInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response?.status === 403) {
      const localToken = localStorage.getItem(ID_TOKEN)

      if (localToken)
        setTimeout(() => {
          window.location.reload()
        }, 1000)

      localStorage.removeItem(ID_TOKEN)
    }

    return Promise.reject(error)
  }
)

export const MatchCenterInstance = axios.create({ baseURL: API_URL });

MatchCenterInstance.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem(`${ID_TOKEN}`)
      if (!token && !config?.url?.includes("auth") && !config?.url?.includes("config") && !config?.url?.includes("teenpattiCategory")) {
        return {...config, baseURL: PUBLIC_URL_MATCH_CENTER }
    }

      config.headers.authorization = `${token}`
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptor for API calls
MatchCenterInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    if (error.response?.status === 403) {
      const localToken = localStorage.getItem(ID_TOKEN)

      if (localToken)
        setTimeout(() => {
          window.location.reload()
        }, 1000)

      localStorage.removeItem(ID_TOKEN)
    }

    return Promise.reject(error)
  }
)
