import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import GameTable from "../../SportEvents/GameTable"
import { EventHandler } from "../../../api/events"
import { initEvents, EventsList } from "../../../redux/pages/eventListPage(sports)"
import LoadingSpinner from "../../../components/loadingSpinner";
import "./sport.scss"
import { CompetitionList } from "../../../components/CompetitionList"
import { MetaPages, useHelmetDataContext } from "../../../utils/Meta"

export const SportHome = () => {
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const dispatch = useDispatch();
  const [competitionFilter, setCompetitionFilter] = useState("");
  const [eventsForFilter, setEventsForFilter] = useState({});
  const id = location.pathname.split("/").slice(-1)[0] || location.pathname.split("/").slice(-2)[0];
  const eventsCachedId = useSelector((state: IRootState) => state.events.id)


  const initData = () => {
    if (id !== eventsCachedId) {
      setLoading(true)
    }
    const res: EventsList = {
      result: [],
      status: {},
      success: false,
    }
    dispatch(initEvents(res))

    if (id === '7' || id === '4339') {
      EventHandler.getEventsHorseListRequest(id).then(res => {
        dispatch(initEvents(res))
        setLoading(false)
      }).then(()=> setLoading(false))
    } else {
      EventHandler.getEventsListRequest(id).then(res => {
        dispatch(initEvents(res))
        setLoading(false)
      }).then(()=> setLoading(false))
    }
  }

  useEffect(() => {
    initData()

    const eventList = setIntervalAsync(async () => {
      if (id === '7' || id === '4339') {
        await EventHandler.getEventsHorseListRequest(id).then(res => {
          dispatch(initEvents(res))
        })
      } else {
        await EventHandler.getEventsListRequest(id).then(res => {
          dispatch(initEvents(res))
        })
      }
    }, 1000);

    return () => {
      clearIntervalAsync(eventList)
      // const res: EventsList = {
      //   result: [],
      //   status: {},
      //   success: false,
      // }
      // dispatch(initEvents(res))
    }
    // eslint-disable-next-line
  }, [id])
  const events = useSelector((state: IRootState) => state.events.result)
  const [competitionEvents, setCompetitionEvents] = useState([]);

  const getMeta = (id: string) => {
    if (id === '1') {
      return MetaPages.SPORTS_FOOTBALL;
    }
    if (id === '2') {
      return MetaPages.SPORTS_TENNIS;
    }
    if (id === '4') {
      return MetaPages.HOME;
    }
    if (id === '7') {
      return MetaPages.SPORTS_HORSERACING;
    }
    if (id === '4339') {
      return MetaPages.SPORTS_GREYHOUND;
    }
    if (id === '2378962') {
      return MetaPages.POLITICS;
    }
    return MetaPages.HOME;
  }
  useHelmetDataContext(getMeta(id));


  const findUniqueCompetitions = (data: any) => {
    const uniqueCompetitions: any = [];

    if (data?.length) {
      for (const item of data) {
        const competitionId =  (id === '7' || id === '4339') ? item?.id : item?.competition?.id;

      const existingCompetition = uniqueCompetitions.find(
        (comp: any) =>
          (id === '7' || id === '4339') ?

          comp?.id?.split('.')?.[0] === item?.id?.split('.')?.[0]
          :
             comp.id === competitionId
      );

      if (!existingCompetition) {
        uniqueCompetitions.push({ id: competitionId, name: item?.competition?.name ||  item?.venue, startTime: item?.startTime, venue: item?.venue, countryCode: item?.countryCode})
      }
    }

    return uniqueCompetitions;
  } else {
    return data
  }
  }

  useEffect(() => {
    if ( events?.length && (id === '7' || id === '4339')) {
        setCompetitionEvents(competitionFilter ? events.filter((event: any) => event.id?.split('.')?.[0] === competitionFilter?.split('.')?.[0]) : events)
    // @ts-ignore
      } else if ( events?.length && events[0]?.eventTypeId === id){
      setCompetitionEvents(competitionFilter ? events.filter((event: any) => event.competition.id === competitionFilter) : events)
    }
    setEventsForFilter(findUniqueCompetitions(events))
  }, [ events, competitionFilter]);

  return (
    <div className="sport-home-page" >
      {loading
        ? (
          <div className={`centered`}>
            <LoadingSpinner />
          </div>
        )
        : (
          <>
            <div className="sport-home-page__top-section">
              {/* Temporary we dont need this */}
            <CompetitionList competitionFilter={competitionFilter} setCompetitionFilter={setCompetitionFilter} eventsForFilter={eventsForFilter}/>
              <GameTable events={( id !== '7' && id !== '4339' && id !== '1444001') ? competitionEvents : competitionEvents} featuredEvents={events} />
            </div>
          </>
        )}
    </div>
  )
}
