import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface OrderList {
  result: []
  status: {}
  success: boolean
}

const INIT_ORDER = 'INIT_ORDER';

type InitOrderAction = Action<typeof INIT_ORDER> & {
  order: OrderList;
};

export const initOrder = (order: OrderList): InitOrderAction => ({
  type: INIT_ORDER,
  order,
});

// type AlowwedActions = InitOrderAction;

const OrderReducer = (order: OrderList =  {} as OrderList, action: AnyAction): OrderList => {
  switch (action.type) {
    case INIT_ORDER:
      return action.order;
    default:
      return order;
  }
};

export default OrderReducer;
