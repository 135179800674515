import { createSlice, PayloadAction} from '@reduxjs/toolkit'
export interface HomeMenuSliceInterface {
  mainMenu: string,
  subMenu: string,
  sortMenu: string,
}

const uiInitialState: HomeMenuSliceInterface = {
  mainMenu: "",
  subMenu: "",
  sortMenu: "",
}

export const homeMenuSlice = createSlice({
  name: 'homeMenu',
  initialState: uiInitialState,
  reducers: {
    setMainMenu: (state, {payload}: PayloadAction<string>) => {
      state.mainMenu = payload
    },
    setSubMenu: (state, {payload}: PayloadAction<string>) => {
      state.subMenu = payload
    },
    setSortMenu: (state, {payload}: PayloadAction<string>) => {
      state.sortMenu = payload
    },
  },
})

export const { setMainMenu, setSubMenu, setSortMenu  } = homeMenuSlice.actions

export default homeMenuSlice.reducer
