import { useState, useEffect } from "react";

function useSearchDebounce(array = [], searchText, delay = 500, blockFields = []) {
  const [searchResult, setSearchResult] = useState([]);

  const debouncedSearchText = useDebounce(searchText, delay);

  // useEffect(() => {
  //   const result = array?.filter((obj) => {
  //     const values = Object.values(obj).map((value) =>
  //       value?.toString()?.toLowerCase()
  //     );
  //     return values?.some((val) => val?.includes(debouncedSearchText.toLowerCase()));
  //   });
  //   setSearchResult(result);
  // }, [array, debouncedSearchText]);

  useEffect(() => {
    const result = array?.filter((obj) => {
      const temp = {...obj}
      blockFields.forEach(block => delete temp[block])
      const values = Object.values(temp).map((value) =>
        value?.toString()?.toLowerCase()
      );
      if (values?.some((val) => val?.includes(debouncedSearchText.toLowerCase()))) {
        return obj
      }
    });
    setSearchResult(result);
  }, [array, debouncedSearchText]);

  return searchResult;
}

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default useSearchDebounce;
