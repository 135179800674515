import ReCAPTCHA from "react-google-recaptcha";
import { PATH } from "../utils/constants";
import { instance } from "./instance";

export class ForgotPasswordHandler {
  static async ForgotPasswordRequestOTP(mobileNumber: string, countryCode: string, recaptchaResponse: ReCAPTCHA | string | null | undefined) {
    const response = await instance.post(`/${PATH.AUTH}/${PATH.FORGOT}`, { recaptchaResponse, mobileNumber, countryCode });
    return response
  };

  static async ForgotPasswordRegistration(mobileNumber: string, countryCode: string, OTP: string, password: string, confirmPassword: string) {
    const response = await instance.post(`/${PATH.AUTH}/${PATH.VERIFYFORGOT}`, { mobileNumber, countryCode, OTP, password, confirmPassword });
    return response
  };

  static async ResendOTP(mobileNumber: string, countryCode: string) {
    const response = await instance.post(`/${PATH.AUTH}/${PATH.RESENDOTP}`, { mobileNumber, countryCode });
    return response
  };

}