import { push } from "connected-react-router"
import { useDispatch } from "react-redux"
import { StakesSettings } from "../redux/login/stakeSettings"
import { setAuthenticate } from "../redux/user/user.slice"
import { ID_TOKEN, MEMBER_CODE, USERNAME, STAKES, MEMBER_ID, PREFERENCES } from "./constants"
import { balanceItem, initBalance } from "../redux/menu/balance"
import { setPreferences } from "../redux/login/preferences.slice"
import { googleLogout } from "@react-oauth/google"
import { setIsOpenForgotPassword, setIsOpenLoginPopUp, setIsOpenSignupPopUp } from "../redux/UI/openPopUps.slice"

const useAuth = () => {
  const dispatch = useDispatch()
  const login = (token: string, memberCode: string, loginName: string, stakes: StakesSettings, memberId:any, preferences:any) => {
     localStorage.setItem(ID_TOKEN, token)
     localStorage.setItem(MEMBER_CODE, memberCode)
     localStorage.setItem(MEMBER_ID, memberId)
     localStorage.setItem(USERNAME, loginName)
     localStorage.setItem(STAKES, JSON.stringify(stakes))
     localStorage.setItem(PREFERENCES, preferences)

     if(token) {
         dispatch(setIsOpenLoginPopUp(false))
         dispatch(setIsOpenSignupPopUp(false))
         dispatch(setIsOpenForgotPassword(false))
         // dispatch([setAuthenticate(true), push("/")])
         dispatch([setAuthenticate(true)])

    }
    try {
      var loginNameInter = loginName;

      if (loginNameInter.includes(".")) {
        loginNameInter = loginNameInter.split(".")[1];
      }

      if (loginNameInter.includes("_")) {
        loginNameInter = loginNameInter.split("_")[1];
      }


      (window as any).intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: "dem7be2z",
          name: loginNameInter,
          user_id: memberCode
        };
        (window as any).Intercom("update");

    } catch (e) {
      console.log(e);
    }
  }

  const logout = () => {
    localStorage.removeItem(ID_TOKEN)
    localStorage.removeItem(MEMBER_CODE)
    localStorage.removeItem(MEMBER_ID)
    localStorage.removeItem(USERNAME)
    localStorage.removeItem(STAKES)
    localStorage.removeItem(PREFERENCES)
    dispatch(initBalance({} as balanceItem));
    dispatch(setPreferences(false))
    googleLogout();

    const token = localStorage.getItem(ID_TOKEN)
    if(!token) {
      dispatch([setAuthenticate(false), push("/home/sports/4")])
    }
  }

  const changePassword = () => {
    // logic is in component
  }

  return { login, logout, changePassword }
}

export default useAuth
