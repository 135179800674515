import { Tooltip, useMediaQuery } from "@material-ui/core";
import cn from "classnames";
import Lottie from "lottie-react";
import moment from "moment";
import {useEffect, useMemo, useRef, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NoDataToDisplay } from "../../../components/NoDataToDisplay";
import { OddBtn } from "../../../components/OddBtn";
import useTime from "../../../hooks/useTime";
import { InitBetInfo } from "../../../redux/pages/EventDetail/betInfo";
import { InitBet } from "../../../redux/pages/EventDetail/placeBet";
import { cacheEvents } from "../../../redux/pages/eventListPage(sports)";
import favoritesStarEmpty from "../../../static/images/header/favoritesStarEmpty.svg";
import { BetSlip } from "../../SportsEventDetails/Betslip";
import { betInfo, placeBet } from "../../SportsEventDetails/Betslip/BetHelpers";
import lottieAnimation from "./liveIcon.json";
import "./styles/style.scss";

interface Iprops {
  events: any;
  featuredEvents?: any;
}

const GameTable = ({ events, featuredEvents}: Iprops) => {
  const location = useLocation();
  const history = useHistory();
  const isPhone = useMediaQuery("all and (max-width: 1024px)");
  const isBig = useMediaQuery("all and (max-width: 1240px)");
  const id = location.pathname.split("/").slice(-1)[0] || location.pathname.split("/").slice(-2)[0];
  const rasing = (id === "7" || id === "4339") ? true : false;
  const pageSport = (location.pathname.split("/")[1] || location.pathname.split("/")[2]) === "sports";
  const scrollRef = useRef<any>(null);
  const reduxTimezone = useSelector((state: IRootState) => state.timeSettings.timeZone);
  const eventsState = useSelector((state: IRootState) => state.events);

  const [filteredEvents, setFilteredEvents] = useState(eventsState.cachedData || events);
  const [filteredFeaturedEvents, setFilteredFeaturedEvents] = useState(eventsState.cachedData || featuredEvents);
  const [colapsed, setColapsed] = useState(true);
  const { getTime } = useTime();
  const dispatch = useDispatch();

  const eventsRef = useRef();

  useEffect(() => {
    eventsRef.current = events;
  }, [events]);

  useEffect(() => {
    return () => {
      if (eventsRef.current) {
        dispatch(cacheEvents(eventsRef.current, id));
      }
    };
  }, []);

  useEffect(() => {
    if (events) {
      const filtered = events;
      setFilteredEvents(filtered.length ? filtered : null);
    }
    if (featuredEvents) {
      const filtered = featuredEvents;
      setFilteredFeaturedEvents(filtered.length ? filtered : null);
    }
  }, [events, featuredEvents]);

  const getTimeFormat = useMemo(() => (time: any, short = false, isRacing = false) => {
      const currentTime = moment(getTime());
    const targetTime = moment(getTime(time));
      const duration = moment.duration(targetTime.diff(currentTime));
      const days = duration.days();
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

    if (targetTime.isSameOrBefore(currentTime)) {
        if (!short) {
          return (
            <>
            <div className={`eventCard_live ${isRacing && "racingLive"}`}>
            <Lottie style={{width: isPhone ? "45px" : "55px", height: isPhone ? "15"  : "20"}} animationData={lottieAnimation}  loop={true} autoplay={true}/>
            </div>
            {isRacing &&
              <div className="eventCard_header-infoBox-matchInfo">
                {targetTime.format("hh:mm a")}
              </div>
            }
            </>
          );
          } else {
            return "";
          }
      // } else if (short && duration.asHours() < 24) {
      } else if (short) {
      return (
      <div className="eventCard_header-infoBox-matchInfo">
          {`${days ? days + "d" : ""} ${hours ? hours + "h" : ""} ${minutes ? minutes + "m" : ""} ${seconds ? seconds + "s" : ""}`}
      </div>)
      ;
    } else if (!short && targetTime.isSame(currentTime, "day")) {
      return (
        <div className="eventCard_futureEventTag">
            <div className="eventCard_futureEventTag-day">Today</div>
            <div className="eventCard_futureEventTag-time">{targetTime.format("hh:mm a")}</div>
        </div>
      );
    } else if (!short && targetTime.isSame(currentTime.clone().add(1, "day"), "day")) {
      return (
        <div className="eventCard_futureEventTag tomorrow">
            <div className="eventCard_futureEventTag-day tomorrow">Tomorrow</div>
            <div className="eventCard_futureEventTag-time">{targetTime.format("hh:mm a")}</div>
        </div>
      );
    } else if (!short){
      return (
        <div className="eventCard_futureEventTag later">
            <div className="eventCard_futureEventTag-day later">{targetTime.format("DD  MMMM").replace(/(\s\w)/g, match => match.toUpperCase().replace(/\s/g, ""))}</div>
            <div className="eventCard_futureEventTag-time">{targetTime.format("hh:mm a")}</div>
        </div>
      );
    } else {
      return;
  }

  }, [reduxTimezone]);

  const getShortName = useMemo(() => (fullName: string) => {
    const name = fullName;
    const words = name?.split(" ");

    let abbreviation = "";
    if (words && words.length > 0) {
      abbreviation = words.map((word: string, index: number) => {
        if (word?.[0]?.includes("(")){
          return word;
        }
        return word[0]?.toUpperCase();
      }).join("");
    }
  return abbreviation;
  }, []);
  const handleImageError = (event: any) => {
    event?.target?.parentNode?.removeChild(event?.target);
  };

  return (
    <div className="game-table global-desktop-container" ref={scrollRef}>
      {filteredEvents && (
        <>
          {filteredEvents.length !== 0 ? (
            <>
              <div className="game-table__body">
                {filteredEvents && Object.values(((!colapsed && isPhone) || !isPhone ) ? filteredEvents : filteredEvents.slice(0, 5)).map((item: any, index) => {
                  const today = new Date();
                  const startTime = item?.start ? item?.start : item?.startTime;
                  const startTimeItem = new Date(startTime);
                  const sportId = item?.eventTypeId && item.eventTypeId;
                  const eventId = item?.event && item.event.id;

                  return (
                     !rasing ? <>
                    <div className="eventCard" onClick={() => {
                      if (rasing) {
                        history.push(`${`/sport-event-detail/${item.id}`}`, {
                          sportId: id,
                          eventName: item.venue,
                        });
                    } else {
                      history.push(`${id === "1444001" ? `/exchange-game/${eventId}` : `/sport-event-detail/${eventId}`}`, {
                        sportId,
                        eventName: item?.event?.name,
                      });
                    }
                      }}>
                        <div  className={cn( {
                      eventCard_mobileWrapper: isPhone,
                    })} >

                   {!isPhone && getTimeFormat(startTimeItem)}
                    { !isPhone &&
                      <div className="eventCard_eventSpecialChars" style={startTime <= today ? {top: "38px"} : {}}>
                        {item.isFancy === true && <div className="eventCard_eventSpecialChars-char">F</div>}
                        {item.isBookmaker === true && <div className="eventCard_eventSpecialChars-char">BM</div>}
                        {item.isBetRadar === true && <div className="eventCard_eventSpecialChars-char">P</div>}
                      </div>
                    }
                      <div className="eventCard_header">
                        <img src={favoritesStarEmpty} alt="Add" />
                        <div className="eventCard_header-infoBox">
                          <div className="eventCard_header-infoBox-runners">{isPhone ? `${item?.runners?.[0]?.name} vs ${item?.runners?.[1]?.name?.toLowerCase().includes("draw") ? item?.runners?.[2]?.name : item?.runners?.[1]?.name}` : item?.event?.name?.replace(" v ", " vs ")?.replace(" vs. ", " vs ")}</div>
                          <div className={cn({
                      "eventCard_header-infoBox-runners-phoneBox": isPhone,
                    })} >
                          {getTimeFormat(startTimeItem, true)}
                          {isPhone && getTimeFormat(startTimeItem)}
                          </div>
                        </div>
                      </div>
                     {!isPhone && <div className="eventCard_marketTitles">
                      <div className="eventCard_marketTitles_item">
                        <div className="eventCard_marketTitles_item-teamFlag">
                          <img className="eventCard_marketTitles_item-teamFlag" onError={handleImageError} src={`https://jeetoindia1.s3.ap-south-1.amazonaws.com/${item?.event?.id}-${item?.runners?.[0]?.id}`}/>
                        </div>
                        <div className="eventCard_marketTitles_item-teamName">{getShortName(item?.runners?.[0]?.name)}</div>
                      </div>
                      <div className="eventCard_marketTitles_item">
                        <div className="eventCard_marketTitles_item-teamName draw">Draw</div>
                      </div>
                      <div className="eventCard_marketTitles_item">
                      <div className="eventCard_marketTitles_item-teamFlag">
                        <img className="eventCard_marketTitles_item-teamFlag" onError={handleImageError} src={`https://jeetoindia1.s3.ap-south-1.amazonaws.com/${item?.runners?.[1]?.name?.toLowerCase().includes("draw") ? (item?.event?.id + "-" + item?.runners?.[2]?.id) : (item?.event?.id + "-" + item?.runners?.[1]?.id)}`}/>
                      </div>

                        <div className="eventCard_marketTitles_item-teamName">{getShortName(item?.runners?.[1]?.name?.toLowerCase().includes("draw") ? item?.runners?.[2]?.name : item?.runners?.[1]?.name)}</div>
                      </div>
                    </div>}
                    </div>
                    { isPhone &&
                      <div className="eventCard_eventSpecialChars" style={(startTime <= today && !isPhone) ? {top: "38px"} : {}}>
                        {item.isFancy === true && <div className="eventCard_eventSpecialChars-char">F</div>}
                        {item.isBookmaker === true && <div className="eventCard_eventSpecialChars-char">BM</div>}
                        {item.isBetRadar === true && <div className="eventCard_eventSpecialChars-char">P</div>}
                      </div>
                    }
                    <div className={cn("eventCard_marketButtons", {
                      inactive: !item?.isBettable && !isPhone,
                    })}
                    onClick={(e: any) => {
                      if(!item?.isBettable){
                        e.preventDefault();
                      }
                    }}
                    >
                      <div className="eventCard_marketButtons_item">
                      {isPhone && <div className="eventCard_marketButtons_item-teamInfo">{getShortName(item?.runners?.[0]?.name)}</div>}

                        <div style={isPhone ? {width: "30%", height: "100%"} : {width: "50%", borderRadius: "0 0 0 5px", overflow: "hidden"}}
                        className={cn({
                          "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["0"]?.back?.["0"]),
                        })}
                          onClick={(e: any) => {
                            e.stopPropagation();
                              if (item?.isBettable) {
                                dispatch(InitBetInfo(betInfo(item.runners[0]?.name, true, item.name, item.mtype, item.runners?.["0"]?.back?.["0"], item.id, item.runners)));
                                dispatch(InitBet(placeBet(item, true, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["0"]?.back?.["0"], item.runners["0"].id)));
                            }
                          }}>
                          <OddBtn runner={item?.runners?.[0]} isBack={true}/>
                        </div>
                        <div style={isPhone ? {width: "30%", height: "100%", borderRadius: "0 5px 0 0", overflow: "hidden"} : {width: "50%"}}
                         className={cn({
                          "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["0"]?.lay?.["0"]),
                        })}
                         onClick={(e: any) => {
                          e.stopPropagation();
                            if (item?.isBettable) {
                              dispatch(InitBetInfo(betInfo(item.runners[0]?.name, false, item.name, item.mtype, item.runners?.["0"]?.lay?.["0"], item.id, item.runners)));
                              dispatch(InitBet(placeBet(item, false, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["0"]?.lay?.["0"], item.runners["0"].id)));
                          }
                        }}>
                          <OddBtn runner={item?.runners?.[0]} isBack={false} />
                        </div>
                      </div>
                      <div className="eventCard_marketButtons_item">
                      {isPhone && <div className="eventCard_marketButtons_item-teamInfo">Draw</div>}
                      <div style={isPhone ? {width: "30%",  height: "100%"} : {width: "50%"}}
                        className={cn({
                          "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["2"]?.back?.["0"]),
                        })}
                        onClick={(e: any) => {
                          e.stopPropagation();
                            if (item?.isBettable) {
                              dispatch(InitBetInfo(betInfo(item.runners[2]?.name, true, item.name, item.mtype, item.runners?.["2"]?.back?.["0"], item.id, item.runners)));
                              dispatch(InitBet(placeBet(item, true, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["2"]?.back?.["0"], item.runners["2"].id)));
                          }
                        }}>
                          <OddBtn runner={item?.runners?.[2]} isBack={true} />
                        </div>
                        <div style={isPhone ? {width: "30%",  height: "100%"} : {width: "50%"}}
                          className={cn({
                            "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["2"]?.lay?.["0"]),
                          })}
                        onClick={(e: any) => {
                          e.stopPropagation();
                            if (item?.isBettable) {
                              dispatch(InitBetInfo(betInfo(item.runners[2]?.name, false, item.name, item.mtype, item.runners?.["2"]?.lay?.["0"], item.id, item.runners)));
                              dispatch(InitBet(placeBet(item, false, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["2"]?.lay?.["0"], item.runners["2"].id)));
                          }
                        }}>
                          <OddBtn runner={item?.runners?.[2]} isBack={false} />
                        </div>
                      </div>
                      <div className="eventCard_marketButtons_item">
                      {isPhone && <div className="eventCard_marketButtons_item-teamInfo">{getShortName(item?.runners?.[1]?.name?.toLowerCase().includes("draw") ? item?.runners?.[2]?.name : item?.runners?.[1]?.name)}</div>}
                      <div style={isPhone ? {width: "30%",  height: "100%"} : {width: "50%"}}
                        className={cn({
                          "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["1"]?.back?.["0"]),
                        })}
                         onClick={(e: any) => {
                          e.stopPropagation();
                            if (item?.isBettable) {
                              dispatch(InitBetInfo(betInfo(item.runners[1]?.name, true, item.name, item.mtype, item.runners?.["1"]?.back?.["0"], item.id, item.runners)));
                              dispatch(InitBet(placeBet(item, true, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["1"]?.back?.["0"], item.runners["1"].id)));
                          }
                        }}>
                          <OddBtn runner={item?.runners?.[1]} isBack={true} />
                        </div>
                        <div style={isPhone ? {width: "30%",  height: "100%", borderRadius: "0 0 5px 0", overflow: "hidden"} : {width: "50%", borderRadius: "0 0 5px 0", overflow: "hidden"}}
                          className={cn({
                            "eventCard_marketButtons_inactiveItem": (!item?.isBettable || !item.runners?.["1"]?.lay?.["0"]),
                          })}
                        onClick={(e: any) => {
                          e.stopPropagation();
                            if (item?.isBettable) {
                              dispatch(InitBetInfo(betInfo(item.runners[1]?.name, false, item.name, item.mtype, item.runners?.["1"]?.lay?.["0"], item.id, item.runners)));
                              dispatch(InitBet(placeBet(item, false, item.btype, item.oddsType, item.eventTypeId, item.id, item.betDelay, item.runners?.["1"]?.lay?.["0"], item.runners["1"].id)));
                          }
                        }}>
                          <OddBtn runner={item?.runners?.[1]} isBack={false} />
                        </div>
                      </div>
                    </div>
                    </div>
                    </>
                    :
                    ( rasing) && <>
                    <div className="eventCard" style={{minHeight: "unset", padding: "7px"}} onClick={() => {
                      console.log("sportId0", sportId,
                        "eventName:", item.venue);
                        history.push(`${`/sport-event-detail/${item.id}`}`, {
                          sportId: id,
                          eventName: item.venue,
                        });
                      }}>

                      {!isPhone && getTimeFormat(startTimeItem)}
                      <div className="eventCard_header">
                        <img src={favoritesStarEmpty} alt="Add" />
                        <div className="eventCard_header-infoBox racing">
                      <span className="eventCard_marketTitles_item-teamName">{item.venue}</span>
                      <div className={cn({
                          "eventCard_header-infoBox-runners-phoneBox": isPhone,
                        })} >
                          {getTimeFormat(startTimeItem, true)}
                        { getTimeFormat(startTimeItem, false, true)}
                        </div>
                      </div>
                    </div>
                    </div>
                    </>
                  );
                })}
              </div>
              {(isPhone && filteredEvents.length > 5 ) && <div className={cn("game-table_collapsed", {
                  open: !colapsed,
                })} onClick={() => {
                  if (!colapsed) {
                    scrollRef.current.scrollIntoView({ behavior: "smooth"});
                  }
                  setColapsed(!colapsed);}}>
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
                  <path d="M3.91728 1.6875C5.51728 0.5625 7.30478 0 9.27978 0C11.2548 0 13.0298 0.5625 14.6048 1.6875C16.2048 2.7875 17.3548 4.225 18.0548 6C17.3548 7.775 16.2048 9.225 14.6048 10.35C13.0298 11.45 11.2548 12 9.27978 12C7.30478 12 5.51728 11.45 3.91728 10.35C2.31729 9.225 1.16729 7.775 0.467285 6C1.16729 4.225 2.31729 2.7875 3.91728 1.6875ZM6.42978 8.85C7.22978 9.625 8.17978 10.0125 9.27978 10.0125C10.3798 10.0125 11.3173 9.625 12.0923 8.85C12.8673 8.05 13.2548 7.1 13.2548 6C13.2548 4.9 12.8673 3.9625 12.0923 3.1875C11.3173 2.3875 10.3798 1.9875 9.27978 1.9875C8.17978 1.9875 7.22978 2.3875 6.42978 3.1875C5.65478 3.9625 5.26728 4.9 5.26728 6C5.26728 7.1 5.65478 8.05 6.42978 8.85ZM7.55478 4.3125C8.02978 3.8375 8.59228 3.6 9.24228 3.6C9.91728 3.6 10.4923 3.8375 10.9673 4.3125C11.4423 4.7625 11.6798 5.325 11.6798 6C11.6798 6.675 11.4423 7.25 10.9673 7.725C10.4923 8.175 9.91728 8.4 9.24228 8.4C8.59228 8.4 8.02978 8.175 7.55478 7.725C7.10478 7.25 6.87978 6.675 6.87978 6C6.87978 5.325 7.10478 4.7625 7.55478 4.3125Z" fill="#00A826"/>
                </svg>
                VIEW ALL LEAGUE
                </div>}
            </>
          ) : (
            <>
            {filteredFeaturedEvents?.length !== 0 ? (
              <>
                {!isPhone && !rasing && (
                  <div className="game-table__header">
                    <div className="game-table__header-title">Events</div>
                    <div></div>
                    <div></div>
                    <div className="game-table__header-result">
                      <div>1</div>
                      <div>X</div>
                      <div>2</div>
                    </div>
                  </div>
                )}
                {!isPhone && rasing && (
                  <div className="game-table__header">
                    <div className="game-table__header-title">Events</div>
                  </div>
                )}

                <div className="game-table__body">
                  {filteredFeaturedEvents && Object.values(filteredFeaturedEvents).map((item: any, index) => {
                    const today = new Date();
                    const todayDay = today.getDate();
                    const startTime = item?.start ? item?.start : item?.startTime;
                    const startTimeItem = new Date(startTime);
                    const startTimeDay = startTimeItem.getDate();
                    const sportId = item?.eventTypeId && item.eventTypeId;
                    const eventId = item?.event && item.event.id;
                    let eventLink;
                    if (rasing) {
                      eventLink = <Link
                        to={{
                          pathname: `${`/sport-event-detail/${item.id}`}`,
                          state: {
                            sportId: id,
                            eventName: item.venue,
                          },
                        }}
                      >
                        {item.venue}
                      </Link>;
                    } else {
                      eventLink = <Link
                        to={{
                          pathname: `${id === "1444001" ? `/exchange-game/${eventId}` : `/sport-event-detail/${eventId}`}`,
                          state: {
                            sportId,
                            eventName: item?.event?.name,
                          },
                        }}
                      >
                        {item?.event?.name}
                      </Link>;
                    }

                    return (
                      !item.hidden && (
                        <div className={`game-table__body-tr ${(pageSport && !isBig) ? "sport" : ""}`} key={index}>
                          <div className="game-table__body-td game-table__body-time">
                            {
                              startTime <= today ? (
                                <span className="live">Live</span>
                              ) :
                                startTimeDay === todayDay ? (
                                  <span className="wrapper">
                                    <span>
                                      {startTimeItem.getHours() < 10 ? ("0" + startTimeItem.getHours()).slice(-2) : startTimeItem.getHours()}:
                                      {startTimeItem.getMinutes() < 10 ? ("0" + startTimeItem.getMinutes()).slice(-2) : startTimeItem.getMinutes()}
                                    </span>
                                    <span className="label">Today</span>
                                  </span>) :
                                  startTimeDay === (todayDay + 1) ? (
                                    <span className="wrapper">
                                      <span>
                                        {startTimeItem.getHours() < 10 ? ("0" + startTimeItem.getHours()).slice(-2) : startTimeItem.getHours()}:
                                        {startTimeItem.getMinutes() < 10 ? ("0" + startTimeItem.getMinutes()).slice(-2) : startTimeItem.getMinutes()}
                                      </span>
                                      <span className="label">Tomorrow</span>
                                    </span>
                                  ) : (
                                    <span className="wrapper">
                                      <span className="time">
                                        {startTimeItem.getHours() < 10 ? ("0" + startTimeItem.getHours()).slice(-2) : startTimeItem.getHours()}:
                                        {startTimeItem.getMinutes() < 10 ? ("0" + startTimeItem.getMinutes()).slice(-2) : startTimeItem.getMinutes()}
                                      </span>
                                      <span className="date">
                                        {("0" + startTimeItem.getDate()).slice(-2) + " "}
                                        {startTimeItem.toLocaleString("default", { month: "short" })}
                                      </span>
                                    </span>
                                  )
                            }
                          </div>
                          <div className="game-table__body-td game-table__body-eventName">
                            {eventLink}
                          </div>
                          <div className="game-table__body-td game-table__body-icons">
                            <Tooltip title="Card Markets" arrow placement="top">
                              <span className="cards">
                                {item.isCardsGaming === true && <span className="icon icon-cards-gaming"></span>}
                              </span>
                            </Tooltip>
                            <Tooltip title="Egaming Markets" arrow placement="top">
                              <span className="egaming">
                                {item.isEgaming === true && <span className="icon icon-egaming"></span>}
                              </span>
                            </Tooltip>
                            <Tooltip title="Premium Markets" arrow placement="top">
                              <span className="premium">
                                {item.isBetRadar === true && <span className="icon icon-premium"></span>}
                              </span>
                            </Tooltip>
                            <Tooltip title="Fancy Markets" arrow placement="top">
                              <span className="fancy">
                                {item.isFancy === true && <span className="icon icon-F"></span>}
                              </span>
                            </Tooltip>
                            <Tooltip title="Bookmaker Markets" arrow placement="top">
                              <span className="bookmaker">
                                {item.isBookmaker === true && <span className="icon icon-BM"></span>}
                              </span>
                            </Tooltip>
                            <Tooltip title="Live Stream" arrow placement="top">
                              <span className="stream">
                                {item.isStreamAvailable === true && <span className="icon icon-television"></span>}
                              </span>
                            </Tooltip>
                          </div>
                          <div className={`game-table__body-td game-table__body-result ${isPhone && rasing ? "game-table__body-result--none" : ""}`}>
                            {isPhone && !rasing && (
                              <div className="game-table__body-result-header">
                                <span>1</span>
                                <span>X</span>
                                <span>2</span>
                              </div>
                            )}
                            {item.status === "OPEN" ?
                              <>
                                <div className={`game-table__body-result-cell game-table__body-result-first ${item.isBettable ? "" : "lock"}`}>
                                  <OddBtn runner={item.runners[0]} isBack={true} />
                                  <OddBtn runner={item.runners[0]} isBack={false} />
                                </div>

                                <div className={`game-table__body-result-cell game-table__body-result-middle ${item.isBettable ? "" : "lock"}`}>
                                  <OddBtn runner={item.runners[2]} isBack={true} />
                                  <OddBtn runner={item.runners[2]} isBack={false} />
                                </div>

                                <div className={`game-table__body-result-cell game-table__body-result-second ${item.isBettable ? "" : "lock"}`}>
                                  <OddBtn runner={item.runners[1]} isBack={true} />
                                  <OddBtn runner={item.runners[1]} isBack={false} />
                                </div>
                              </>
                              : item.status === "SUSPENDED" ?
                                <div className="game-table__body-result-error game-table__body-result-suspended">
                                  SUSPENDED
                                </div>
                                : item.status === "CLOSED" &&
                                <div className="game-table__body-result-error game-table__body-result-closed">
                                  Closed
                                </div>}
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </>
            ) : (
              <NoDataToDisplay msg="No data to display" />
              )}
          </>
          )}
        </>
      )}
      {/* {!filteredEvents && <NoDataToDisplay msg="No data to display" /> } */}
      <BetSlip id={id}/>
    </div>
  );
};

export default GameTable;
