import { FC, useMemo, useEffect, memo } from "react";
import betSlipImg from "../../static/images/footerBar/betSlip.svg";
import arrowImg from "../../static/images/footerBar/arrow.svg";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import cn from "classnames";
import { GeneralHandler } from "../../api/general";
import { setOrderList } from "../../redux/menu/orderList.slice";
import { initOrder } from "../../redux/pages/orderList";
import { PARAMS } from "../../utils/constants";
import { setOrderCount } from "../../redux/menu/orderList.slice";
import { setFooterBets } from "../../redux/menu/footerBets.slice";
// import useCheckBonusWallets from "../../hooks/useCheckBonusWallets";
import "./index.scss";

const FooterBar: FC = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isAndroid = navigator.userAgent.includes("Android");

  const orderListCount = useSelector((state: IRootState) => state.orderList.orderCount);
  const isAuthenticated = useSelector((state: IRootState) => state.user.isAuthenticated);
  // const currentWalletState = useCheckBonusWallets()
  const memoizedOrderListCount = useMemo(() => orderListCount, [orderListCount]);


  useEffect(() => {
    if(isAuthenticated) {
      const params = PARAMS.OPEN_BETS;
        GeneralHandler.getOrderList1(params).then((list: any) => {
          dispatch(initOrder(list));
        const listMatched = Object.values(list?.result?.matched)?.map((item: any) =>
          item?.markets?.map((market: any) => market?.selections)
        )?.flat(3);
        const listUnmatched = Object.values(list?.result?.unmatched)?.map((item: any) =>
          item?.markets?.map((market: any) => market?.selections)
        )?.flat(3);
          dispatch(setOrderList({
            matched : listMatched,
            unmatched: listUnmatched,
        })
        );
        dispatch(setOrderCount(listMatched?.length + listUnmatched?.length));
      });
    } else {
      dispatch(setOrderCount(0));
    }
  }, [isAuthenticated]);

  return (
    <div className={cn("footer-bar", {
      "android": isAndroid,
    })}>
      <div className={cn("footer-bar__bet-slip", {
          disabled: history.location.pathname.includes("card-game/"),
          "android": isAndroid,
        })}
        onClick={() => {
          // if (currentWalletState) {
          //   history.push("/user/deposit");
          // } else {
             dispatch(setFooterBets(true));
          // }
        }}
      >
        <div className="footer-bar__bet-slip-title-block">
          <div className="footer-bar__bet-slip-title-block-img">
            <img src={betSlipImg} alt="BetSlip" />
          </div>
          <span>Bet Slip</span>
        </div>
        <div className="footer-bar__bet-slip-dropdawn-block">
          <div className="footer-bar__bet-slip-dropdawn-block-title">
            {memoizedOrderListCount ? memoizedOrderListCount : "Empty"}
          </div>
          <div className="footer-bar__bet-slip-dropdawn-block-arrow">
            <img src={arrowImg} alt="arrowImg" />
          </div>
        </div>
      </div>
          </div>
  );
});

export default FooterBar;
