import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from "moment/moment"

export interface TimeSettingsSliceInterface {
  timeZoneName: string;
  serverTime: string;
  timeZone: string;
}

const uiInitialState: TimeSettingsSliceInterface = {
  timeZoneName: 'device',
  timeZone: moment().format("Z"),
  serverTime: '',
};

export const timeSettingsSlice = createSlice({
  name: 'timeSettings',
  initialState: uiInitialState,
  reducers: {
    setTimeZoneName: (state, { payload }: PayloadAction<string>) => {
      state.timeZoneName = payload;
      if (payload === "system") state.timeZone = "+00:00"
      if (payload === "device") state.timeZone = moment().format("Z")
      if (payload === "india") state.timeZone = "+05:30"
    },
    setServerTimeSuccess: (state, { payload }: PayloadAction<any>) => {
      state.serverTime = payload;
    },
  },
});

export const { setTimeZoneName, setServerTimeSuccess } = timeSettingsSlice.actions;

export default timeSettingsSlice.reducer;
