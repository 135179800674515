import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface GamesOddsList {
  result: any[]
  status: {}
  success: boolean
}

const INIT_GAMES_ODDS = 'INIT_GAMES_ODDS';

type InitGamesOddsAction = Action<typeof INIT_GAMES_ODDS> & {
  gamesOdds: GamesOddsList;
};

export const initGamesOdds = (gamesOdds: GamesOddsList): InitGamesOddsAction => ({
  type: INIT_GAMES_ODDS,
  gamesOdds,
});

// type AlowwedActions = InitGamesOddsAction;

const GamesOddsReducer = (gamesOdds: GamesOddsList =  {} as GamesOddsList, action: AnyAction): GamesOddsList => {
  switch (action.type) {
    case INIT_GAMES_ODDS:
      return action.gamesOdds;
    default:
      return gamesOdds;
  }
};

export default GamesOddsReducer;
