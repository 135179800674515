import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface BetItem {
  side : number | null | undefined,
  price : string | null | undefined,
  sectionId : number | null | undefined,
  bType : string | null | undefined,
  oddsType : string | null | undefined,
  eventTypeId : string | null | undefined,
  marketId : string | null | undefined,
  totalSize: number | null | undefined,
  betSlipRef : number | null | undefined,
  fromOneClick : number | null | undefined,
  betDelay : number | null | undefined,
  line : string | null | undefined,
  eventId?: string | null | undefined,
}

const INIT_BET = 'INIT_BET';

type InitBetAction = Action<typeof INIT_BET> & {
  bet: BetItem;
};

export const InitBet = (bet: BetItem ): InitBetAction => ({
  type: INIT_BET,
  bet,
});

// type AlowwedActions = InitBetAction;

const BetReducer = (bet: BetItem = {} as BetItem, action: AnyAction): BetItem => {
  switch (action.type) {
    case INIT_BET:
      return action.bet;
    default:
      return bet;
  }
};

export default BetReducer;
