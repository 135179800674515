import noData from "../../static/images/noData.svg"
import "./style.scss"

interface Iprops {
  msg?: string;
}

export const NoDataToDisplay = ({msg}: Iprops): JSX.Element => {
  return(
    <div className="noData noData__container">
      <img src={noData} alt="No Data" className="noData__img"/>
      {msg ? (
        <p className="noData__text">
        {msg}
      </p>  
      ) : (
      <p className="noData__text">
        No data to display. Try changing filters and search again
      </p>
      )}
    </div>
  )
}