import { StrictMode } from "react"
import { render } from "react-dom"
import "./index.scss"
import App from "./App"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// import * as Sentry from "@sentry/react";
import { init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: "https://1417fd2f5bbf495289fe2c0ec42a91b8@o1113431.ingest.sentry.io/6144059",
    release: 'v1.0',
    integrations: [new BrowserTracing()],
    
    // Adjust the tracesSampleRate in production
    tracesSampleRate: 0.2,

    // Add any additional options or configurations here
    
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'Non-Error exception captured',
      'Non-Error promise rejection captured'
    ],
  });
}




render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById("root")
)

serviceWorkerRegistration.register();
