import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface LiveCasinoItem {
  id: string;
  name: string;
  order: string;
  gamesCount: number;
  image: string;
  columns: number
}

const INIT_LIVE = 'INIT_LIVE';

type InitLiveAction = Action<typeof INIT_LIVE> & {
  liveList: LiveCasinoItem[];
};

export const initLiveList = (liveList: LiveCasinoItem[]): InitLiveAction => ({
  type: INIT_LIVE,
  liveList,
});

// type AlowwedActions = InitLiveAction;

const LiveListReducer = (liveList: LiveCasinoItem[] = [], action: AnyAction): LiveCasinoItem[] => {
  switch (action.type) {
    case INIT_LIVE:
      return action.liveList;
    default:
      return liveList;
  }
};

export default LiveListReducer;
