import { createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface CasinoLiveInterface {
  url: string
  urlForSportbook:any
}

const uiInitialState: CasinoLiveInterface = {
  url: '',
  urlForSportbook: []
}

export const casinoLiveSlice = createSlice({
  name: 'casinoLive',
  initialState: uiInitialState,
  reducers: {
    getCasinoLiveSuccess: (state, {payload}: PayloadAction<any>) => {
     state.url = payload
    },
    getUrlForSportbook: (state, {payload}: PayloadAction<any>) => {
     state.urlForSportbook = payload
    },
    
  },
})

export const { getCasinoLiveSuccess } = casinoLiveSlice.actions

export default casinoLiveSlice.reducer
