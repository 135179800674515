import cn from "classnames";
import { memo, useEffect, useState } from "react"
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { GeneralHandler } from "../../../../api/general";
import Button from "../../../../components/UI/Button";
import { setWalletType } from "../../../../redux/menu/userBonus.slice";
import { getCurrencyFormat } from "../../../../utils/getCurrencyFormat.helper";
import "./BonusDetailsPopUp.scss";
interface Iprops {
  walletType?: any;
}
interface IDetails {
  wagerRemaining?: any;
  bonus?: any;
  issued?: any;
  expiry?: any;
  promoDetails?: any;
}

const modalRoot = document.querySelector("#modal-root");

const BonusDetailsPopUp = ({ walletType }: Iprops) => {
  const dispatch = useDispatch();
  const [details, setDetails] = useState<IDetails | null>(null);
  const [showTerms, setShowTerms] = useState(false);
  const balance = useSelector((state: IRootState) => state.balance);
  const [loading, setLoading] = useState(false);
  const [animClose, setAnimClose] = useState(false);

  useEffect(() => {
    GeneralHandler.getWalletDetails(walletType.type)
      .then(res =>  setDetails(res))
      .catch((e) => console.log("e", e));
  }, []);

  const changeWallet = (memberCode: string, bool: boolean, type: string) => {
    setLoading(true);
    GeneralHandler.changeWalletStatus(memberCode, bool, type)
      .then(() =>  setTimeout(() => {
        dispatch(setWalletType(null));
      }, 1500))
      .catch((e) => console.log("e", e))
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 700);
      });
  };

  const expSum = () => {
    try {
      let sum = 0;
      if (details && details?.wagerRemaining) {
        sum += details?.wagerRemaining;
      }
      return Math.abs(sum);
    } catch (e) {
      return 0;
    }
  };
  function createMarkup(info:any) {
    try{
      if(typeof info === "string") {
        return { __html: info?.replaceAll("\\n", "") };
      } else { return { __html: "<div></div>" }; }
    } catch (e) {
      return { __html: "<div></div>" };
    }
  }

  return walletType && balance && balance[walletType.key] ?
    createPortal ( <div className="bonus-details-pop-up-wrapper" onClick={(e) => {
      if (e.currentTarget === e.target) {
        setAnimClose(true);
        setTimeout(() =>   dispatch(setWalletType(null)), 500);
      }
    }}>
      <div className={cn("bonus-details-pop-up", {
        close: animClose,
        createMarkup: showTerms,
      })}>
        {!showTerms && <>
        <div className="bonus-details-pop-up__title">Bonus Information</div>
        <ul className="bonus-details-pop-up__balance">
          <li><span>Balance:</span> <span>{getCurrencyFormat(balance[walletType.key]?.availBalance || 0)}</span></li>
          <li><span>Net Exposure:</span>
            <span>{getCurrencyFormat(balance[walletType.key]?.deductedExposure || 0)}</span></li>
          <li>
            <span>Use Bonus</span>
            <div className={cn("bonus-details-pop-up__toggle", {
              "not-clickable": loading,
            })}>
              <label className="switch">
                <input checked={balance[walletType.active]}
                       onClick={() => changeWallet(balance[walletType.key]?.memberCode, !balance[walletType.active], walletType?.type)}
                       type="checkbox" />
                <span className={cn("slider", {
                  active: loading,
                })}></span>
              </label>
            </div>
          </li>
        </ul>
        <div className="bonus-details-pop-up__bonus-title">{walletType.name}</div>
        <ul className="bonus-details-pop-up__bonus">
          <li><span>Wagering Remaining</span> <span>{getCurrencyFormat(expSum())}</span></li>
          <li><span>Bonus Amount</span> <span> {getCurrencyFormat(details?.bonus || 0)}</span></li>
          <li className="bonus-details-pop-up__bonus-date">Bonus Issued
            <span>
                {
                details?.issued?.replace("T", " ")
               .toLocaleString("en-GB",{
                  year: "numeric",
                  month: "short",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                })
                }
                </span>
          </li>
          <li className="bonus-details-pop-up__bonus-date">Bonus Expiry
              <span>
                {details?.expiry?.replace("T", " ")
                  .toLocaleString("en-GB",{
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "numeric",
                      minute: "numeric",
                  })
                 }
              </span>
          </li>
        </ul>
        <div className="bonus-details-pop-up__terms-and-conds" onClick={() => setShowTerms(true)}>
          <span>Terms and Conditions</span>
        </div>
        </>}
        {showTerms &&
        <>
          <div className="bonus-details-pop-up__title">Bonus Information</div>
          <div className="createMarkup-content" dangerouslySetInnerHTML={createMarkup(details?.promoDetails?.info)}/>
          <Button
            className="createMarkup-button"
            customOnClick={() => setShowTerms(false)}
          >
            <div>Back</div>
          </Button>
        </>
        }
      </div>
    </div>, modalRoot as Element)
    : <></>;
};

export default memo(BonusDetailsPopUp);
