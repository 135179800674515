import { createSlice, PayloadAction} from '@reduxjs/toolkit'
export interface PreferencesInterface {
  confirmation: any;
}
const uiInitialState: PreferencesInterface = {
  confirmation: localStorage.getItem('preferences') && JSON.parse(localStorage.getItem('preferences') || '')
  // confirmation: localStorage.getItem('preferences')
}
export const preferencesSlice = createSlice({
  name: 'preferences',
  initialState: uiInitialState,
  reducers: {
    setPreferences: (state, {payload}: PayloadAction<boolean>) => {
      state.confirmation = payload
    },
  },
})
export const { setPreferences } = preferencesSlice.actions
export default preferencesSlice.reducer
