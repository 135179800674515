import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Snackbar } from "@material-ui/core"
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { EventDetailHandler } from '../../api/event-details';
import closeIcon from "../../static/images/CloseIcon.svg"
import "./styles/index.scss"
import { setIsOpenPopUp } from "../../redux/UI/openPopUps.slice"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface FormValues {
  stake: string;
}

const FormikInputSchema = Yup.object().shape({
  stake: Yup.string()
    .required("Value can't be empty")
    .matches(/^[0-9][0-9]*$/, 'Only digits')
    .min(0, 'min 0 characters')
    .max(9, 'max 9 symbols or Maximum deposit is 999,999,999.99')
    .test('positive', 'Value must be positive', value => {
      if (value === undefined) return true; // Skip validation if the value is undefined
      const parsedValue = parseFloat(value);
      return parsedValue > 0;
    }),
});

export interface JiliDeposit {
  line: null,
  marketId: string,
  oddsType: null,
  price: number,
  selectionId: number,
  side: number,
  totalSize: number
}


export const JiliPopUp = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [valueStake, setValueStake] = useState<string>("");
  const [ err, setError ] = useState(false)
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [ errMsg, setErrMsg ] = useState("");
  const [loading, setLoading] = useState(false);
  const initialValues: FormValues = {
    stake: valueStake
  }
  const balance = useSelector((state: IRootState) => state.balance);
  const isOpenPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenPopUp);
  const item = useSelector((state: IRootState) => state.jiliPopUp);

  const handleChangeStake = (e: ChangeEvent<HTMLInputElement>) => {
    setValueStake(e.target.value);
  }

  useEffect(() => {
    if(+valueStake > balance.availBalance) {
      setError(true)
    } else {
      setError(false)
    }
  }, [valueStake, balance])

  const handleClick = () => history.push(`/casino-game/JILI`, {
    backPath: `${history.location.pathname}`,
    name: item?.en || item?.name
  });

  const handleSubmit = async (values: FormValues) => {
    const value:JiliDeposit = {
      line: null,
      marketId: "7.7",
      oddsType: null,
      price: 1,
      selectionId: 1,
      side: 1,
      totalSize: +values.stake
    }
    setLoading(true)
    try {
      const res = await EventDetailHandler.depositJili([value])
      if (res) setSuccess(true);
      localStorage.setItem("JILI", item?.ID || item?.id || '')
      setLoading(false)
      handleClick()
      setTimeout(() => dispatch(setIsOpenPopUp(false)), 1000)

    } catch (error:any) {
      if (error.response?.data?.error?.errorMessage) {
        setLoading(false)
                  setTimeout( () => setErrMsg(error.response.data.error.errorMessage), 10)
                }
      setFail(true)
    }
  }

  const handleCloseAlert = () => {
    setSuccess(false)
    setFail(false)
    setTimeout(() => setErrMsg(""),1000)
  }

  return (
    <div
      className={`${isOpenPopUp ? "jili-popup active" : "jili-popup"}`}
      onClick={() => {
        dispatch(setIsOpenPopUp(false))
      }}
    >
      <div
        className={`${isOpenPopUp ? "jili-popup-content active" : "jili-popup-content"}`}
        onClick={e => e.stopPropagation()}
      >
        <Snackbar
          open={success}
          className="betslip_message betslip_message-success"
          onClose={handleCloseAlert}
          disableWindowBlurListener={true}
          autoHideDuration={2000}
        >
          <Alert onClose={handleCloseAlert} severity="success">
            Transfer was successful
          </Alert>
        </Snackbar>
        <Snackbar open={fail}
          className="betslip_message betslip_message-error"
          onClose={handleCloseAlert}
          disableWindowBlurListener={true}
          autoHideDuration={6000}
          style={{top: '-200px'}}
        >
          <Alert onClose={handleCloseAlert} severity="error">
            {errMsg
              ? <div>{errMsg}</div>
              : "Transfer failed, try again"
            }
          </Alert>
        </Snackbar>
        <div className="jili-popup-container">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={ (values) => {
              handleSubmit(values)
            }}
            validationSchema={FormikInputSchema}
          >
            {({isValid}) => {
              return (
                <>
                  <div className="jili-popup-container-center jili-popup-container-heading">
                    <span>Transfer funds for {item.name}</span>
                    <button className="jili-popup-header__close" onClick={() => dispatch(setIsOpenPopUp(false))}>
                      <img src={closeIcon} alt="close" className="jiliClose"/>
                    </button>
                  </div>
                  <div className="jili-popup-container-center jili-popup-container-text">
                    To continue the game, top up your balance
                  </div>
                  <div className="jili-popup-container-description">
                    Deposit = 15 sec, Withdrawal = 2 min after exit
                  </div>
                  <div className="jili-popup-container-center jili-popup-container__input-wrapper">
                    <div className="jili-popup-container__input-wrapper-balance">
                      Balance {balance.availBalance}
                    </div>
                    <Form>
                      <div className="jili-popup-container__input-wrapper__input">
                        <Field name="stake" type="number" placeholder="Amount" value={valueStake} onChange={handleChangeStake} />
                        <div className='betslip__body-place-bet_error'>
                          <ErrorMessage className='change-password__form_box_error' name="stake" />
                          {err && (
                            <div  className='change-password__form_box_error'>
                              Insufficient funds
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  </div>
                  <div className="jili-popup-container-btns">
                    <button
                      className={`jili-popup-container-btns--button `}
                      disabled={!isValid || err || loading}
                      onClick={() => {
                        handleSubmit({stake: valueStake})
                      }}
                    >
                      {`Accept & launch`}
                     {loading && <div className='jili-popup-container-btns--button-loading'></div>}
                    </button>
                  </div>
                </>
              )
            }}
          </Formik>
        </div>
      </div>
    </div>
  )
}
