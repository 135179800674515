import { useMediaQuery } from "@material-ui/core";
import cn from "classnames";
import { memo, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { GeneralHandler } from "../../../../api/general";
import ScrollSlider from "../../../../components/ScrollSlider/ScrollSlider";
import UserTimeSettings from "../../../../components/UserTimeSettings";
import useGoBack from "../../../../hooks/useGoBack";
// import useGoBack from "../../../../hooks/useGoBack";
import { setVersion, setWalletType } from "../../../../redux/menu/userBonus.slice";
import arrow from "../../../../static/images/bonus/arrow.svg";
import avatar from "../../../../static/images/bonus/avatar.png";
import bonusStatement from "../../../../static/images/bonus/bonus-statement.svg";
import changePassword from "../../../../static/images/bonus/change-password.svg";
import close from "../../../../static/images/bonus/close.svg";
import editImg from "../../../../static/images/bonus/edit.svg";
import info from "../../../../static/images/bonus/info.svg";
import logoutNewSVG from "../../../../static/images/bonus/logoutnew.svg";
import minimize from "../../../../static/images/bonus/minimizeFull.svg";
import openbets from "../../../../static/images/bonus/open-bets.svg";
import rulesRegulations from "../../../../static/images/bonus/rules-regulations.svg";
import screen from "../../../../static/images/bonus/screen.svg";
import settings from "../../../../static/images/bonus/settings.svg";
import signOut from "../../../../static/images/bonus/sign_out.svg";
import statement from "../../../../static/images/bonus/statement.svg";
import timeSettings from "../../../../static/images/bonus/time-settings.svg";
import transactions from "../../../../static/images/bonus/transactions.png";
import transferStatement from "../../../../static/images/bonus/transfer-statement.svg";
import withHistory from "../../../../static/images/bonus/withHistory.svg";
import { getCurrencyFormat } from "../../../../utils/getCurrencyFormat.helper";
import useAuth from "../../../../utils/useAuth";
import ChangePassword from "../../ChangePassword";
import BonusDetailsPopUp from "../BonusDetailsPopUp";
import "./userBonus.scss";

interface Iprops {
  onCloseSideBar?: () => void;
}

const modalRoot = document.querySelector("#modal-root");
const UserBonus = ({ onCloseSideBar }: Iprops) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { logout } = useAuth();
  const balance = useSelector((state: IRootState) => state.balance);
  const userBonus = useSelector((state: IRootState) => state?.userBonus);
  const [loading, setLoading] = useState(false);
  const isPhone = useMediaQuery("all and (max-width: 1024px)");
  const [popUpOpen, setPopUpOpen] = useState<any>(null);
  useGoBack("backButton", () => {
    if ( userBonus?.version === "mobile") {
      dispatch(setVersion(""));
    }else {
      history.push("/home");
      dispatch(setVersion(""));
    }
  });

  const changeWallet = (memberCode: string, bool: boolean, type: string) => {
    setLoading(true);
    GeneralHandler.changeWalletStatus(memberCode, bool, type).then(res => {
    }).catch((e) => console.log("e", e))
      .finally(() => setTimeout(() => setLoading(false), 1000));
  };

  const goLogOut = () => {
    dispatch(setVersion(""));
    logout();
  };

  const openBonusDetails = (item: any) => {
    dispatch(setWalletType(item));
  };

  const getDifferent = (lastActivitySeconds: any) => {
    const now = new Date().getTime();
    const lastActivityDate = new Date(lastActivitySeconds * 1000).getTime();
    const diffMs = now - lastActivityDate;
    const diffSeconds = Math.round(diffMs / 1000);
    const diffMinutes = Math.round(diffMs / (1000 * 60));
    const diffHours = Math.round(diffMs / (1000 * 60 * 60));
    const diffDays = Math.round(diffMs / (1000 * 60 * 60 * 24));
    const diffMonths = Math.round(diffMs / (1000 * 60 * 60 * 24 * 30));
    let unit;
    let value;
    if (diffSeconds < 60) {
      unit = "seconds";
      value = diffSeconds;
    } else if (diffMinutes < 60) {
      unit = "minutes";
      value = diffMinutes;
    } else if (diffHours < 24) {
      unit = "hours";
      value = diffHours;
    } else if (diffDays < 30) {
      unit = "days";
      value = diffDays;
    } else {
      unit = "months";
      value = diffMonths;
    }
    return `Last updated ${value} ${unit} ago`;
  };

  const getAvailBalance = () => {
    switch (true) {
      case balance && balance.useBWallet:
        return getCurrencyFormat(balance.bWallet.availBalance);
      case balance && balance.useSWallet:
        return getCurrencyFormat(balance.sWallet.availBalance);
      case balance && balance.useCWallet:
        return getCurrencyFormat(balance.cWallet.availBalance);
      default:
        return getCurrencyFormat(balance.availBalance);
    }
  };

  const actionsItems = useMemo(() => {
    return [
      {
        image: transactions,
        title: "Transactions",
        action: () => history.push("/user/transactions"),
        openPopUp: false,
      },
      {
        image: withHistory,
        title: "Withdraw Amount",
        action: () => history.push("/user/withdrawal"),
        openPopUp: false,
      },
      { image: openbets, title: "Open Bets", action: () => history.push("/user/unsettled-bet"), openPopUp: false },
      {
        image: statement,
        title: "Account Statement",
        action: () => history.push("/user/account-statement"),
        openPopUp: false,
      },
      {
        image: bonusStatement,
        title: "Bonus Statement",
        action: () => history.push("/user/bonus-statement"),
        openPopUp: false,
      },
      {
        image: transferStatement,
        title: "Transfer Statement",
        action: () => history.push("/user/transfer-statement"),
        openPopUp: false,
      },
      {
        image: timeSettings,
        title: "Time Settings",
        action: () => history.push("/user/time-settings"),
        openPopUp: !isPhone,
      },
      {
        image: rulesRegulations,
        title: "Rules & Regulations",
        // if needed old popUp with rules here > uncoment below
        // action: () => dispatch(setIsOpenRulesPopUp(true)),
        action: () => history.push("/rules-regulations"),
        openPopUp: false,
      },
      { image: settings, title: "Settings", action: () => history.push("/user/set-button-values"), openPopUp: false },
      {
        image: changePassword,
        title: "Change Password",
        action: () => history.push("/user/change-password"),
        openPopUp: !isPhone,
      },
    ];
  }, [isPhone]);

  const walletsItems = useMemo(() => {
    return [
      { name: "Bonus wallet", key: "bWallet", active: "useBWallet", type: "B" },
      { name: "Sports wallet", key: "sWallet", active: "useSWallet", type: "S" },
      { name: "Casino wallet", key: "cWallet", active: "useCWallet", type: "C" },
    ];
  }, [balance]);

  return (
    <div className={cn("user-bonus", {
      "sideBar-desktop": userBonus?.version === "sideBar-desktop",
      "sideBar-mobile": userBonus?.version === "mobile",
      "page-mobile": history.location.pathname === "/user/profile",
      "with-details": (userBonus?.walletType && history.location.pathname === "/user/profile"),
    })}>
      {userBonus?.walletType && history.location.pathname === "/user/profile" && <BonusDetailsPopUp walletType={userBonus?.walletType} />}
      <div className={cn({
        "sideBar-desktop-container": userBonus?.version === "sideBar-desktop",
        "sideBar-mobile-container": userBonus?.version === "mobile",
        "page-mobile-container": history.location.pathname === "/user/profile",
      })}>
        <div className="user-bonus__user">
          <div
            onClick={() => onCloseSideBar?.()}
            className={cn("user-bonus__user-hide img-button", {
              "invisible": (userBonus?.version === "page-desktop" || userBonus?.version === "sideBar-desktop" || history.location.pathname === "/user/profile"),
            })}>
            <img loading="lazy" src={arrow} alt="arrow" />
          </div>
          <div className="user-bonus__user-content">
            <div className="user-bonus__user-menu">
              <div
                onClick={() => dispatch(setVersion(userBonus?.version === "sideBar-desktop" ? "page-desktop" : "sideBar-desktop"))}
                className={cn(
                  "user-bonus__user-menu-screen img-button", {
                    "minimize": userBonus?.version !== "sideBar-desktop",
                  })}>
                <img loading="lazy" src={userBonus?.version === "sideBar-desktop" ? screen : minimize} alt="screen" />
              </div>
              <div className="user-bonus__user-menu-img">
                <img loading="lazy" src={avatar} alt="avatar" />
              </div>
              <div onClick={goLogOut} className="user-bonus__user-menu-log-out img-button" style={{
                    backgroundImage: `url(${logoutNewSVG})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}>
                <img loading="lazy"/>
              </div>
            </div>
            <div className="user-bonus__user-info">
              <div className="user-bonus__user-info-name">
                  <span>
                  {!!localStorage.getItem("userName") && "+" + (localStorage.getItem("userName")?.includes(".") ? localStorage.getItem("userName")?.split(".")[1]?.replace("_", "-") : localStorage.getItem("userName")?.replace("_", "-"))}
                  </span>
                <div className={cn("user-bonus__user-info-name-img img-button")} style={{
                    backgroundImage: `url(${editImg})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                }}>
                  <img loading="lazy" />
                </div>
              </div>
              <div className="user-bonus__user-info-id">
                {!!localStorage.getItem("userName") && "+" + (localStorage.getItem("userName")?.includes(".") ? localStorage.getItem("userName")?.split(".")[1]?.replace("_", "-") : localStorage.getItem("userName")?.replace("_", "-"))}
              </div>
            </div>
            <div className="user-bonus__user-amound">
              <span>Total Balance ₹ {getAvailBalance()}</span>
              {localStorage.getItem("userName")?.slice(4, 6) !== "YO" && <div className="user-bonus__user-amound-button"
                   onClick={() => {
                     setTimeout(() => history.push("/user/deposit"), 300);
                     onCloseSideBar?.();
                   }}
              >
                Deposit
              </div>}
            </div>
          </div>
          <div onClick={() => dispatch(setVersion(""))}
               className={cn("user-bonus__user-close img-button", { "invisible": (userBonus?.version === "sideBar-desktop" || userBonus?.version === "mobile") })}>
            <img loading="lazy" src={close} alt="close" />
          </div>
        </div>
        {balance && <ScrollSlider className="user-bonus__wallets">
          <div className="user-bonus__wallet">
            <div className="user-bonus__wallet-control-row">
              <div className="user-bonus__wallet-control-row-title">
                <div className="user-bonus__wallet-control-row-title-with-info">
                  Main wallet
                </div>
                <span className="sub-title">{getDifferent(balance?.lastActivityDate)}</span>
              </div>
              <div className="user-bonus__wallet-control-row-buttons">
                <div className={cn(" user-bonus__wallet-control-row-buttons-toggle", {
                  "not-clickable": (!balance?.useBWallet && !balance?.useSWallet && !balance?.useCWallet),
                })}>
                  <label className="switch">
                    <input checked={!balance?.useBWallet && !balance?.useSWallet && !balance?.useCWallet}
                           onClick={() => changeWallet(balance.memberCode, (!balance?.useBWallet && !balance?.useSWallet && !balance?.useCWallet), "B")}
                           type="checkbox" />
                    <span className={cn("slider", {
                      "active-loading": loading,
                    })}></span>
                    <span className="slider-text">ON</span>
                    <span className="slider-text off">OFF</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="user-bonus__wallet-odds-row">
              <div className="user-bonus__wallet-odds credit">
                <span><span>₹</span>{getCurrencyFormat(balance?.balanceWithoutBonus)}</span>
                <span>Balance</span>
              </div>
              <div className="user-bonus__wallet-odds credit">
                <span><span>₹</span>{getCurrencyFormat(balance?.bonusAmount)}</span>
                <span>Free cash</span>
              </div>
              <div className="user-bonus__wallet-odds exposure">
                <span>{getCurrencyFormat(balance?.deductedExposure)}</span>
                <span>Exposure</span>
              </div>
            </div>
          </div>
          {walletsItems.map((item: any) => balance[item.key] && <div className="user-bonus__wallet">
            <div className="user-bonus__wallet-control-row">
              <div className="user-bonus__wallet-control-row-title">
                <div onClick={() => openBonusDetails(item)}
                     className="user-bonus__wallet-control-row-title-with-info">{item.name}
                  <img loading="lazy" src={info} alt="info" className="img-button" />
                </div>
                <span className="sub-title">{getDifferent(balance[item.key]?.lastActivityDate)}</span>
              </div>
              <div className="user-bonus__wallet-control-row-buttons">
                <div className="user-bonus__wallet-control-row-buttons-toggle">
                  <label className="switch">
                    <input checked={balance[item?.active]}
                           onClick={() => changeWallet(balance[item.key]?.memberCode, !balance[item?.active], item?.type)}
                           type="checkbox" />
                    <span className={cn("slider", {
                      "active-loading": loading,
                    })}></span>
                    <span className="slider-text">ON</span>
                    <span className="slider-text off">OFF</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="user-bonus__wallet-odds-row">
              <div className="user-bonus__wallet-odds credit">
                <span><span>₹</span>{getCurrencyFormat(balance[item.key]?.availBalance)}</span>
                <span>Available Credits</span>
              </div>
              <div className="user-bonus__wallet-odds exposure">
                <span>{getCurrencyFormat(balance[item.key]?.deductedExposure)}</span>
                <span>Net Exposure</span>
              </div>
            </div>
          </div>)}
        </ScrollSlider>}
        <div className="user-bonus__side-menu">
          {actionsItems.map(item => {
            if (item.title === "Withdraw Amount" && localStorage.getItem("userName")?.slice(4, 6) === "YO" ) {
              return;
            }
            return (
            <div key={item.title}
                 onClick={() => {
                   if (item.openPopUp) {
                     setPopUpOpen(item);
                   } else {
                     setTimeout(() => item.action(), 300);
                     onCloseSideBar?.();
                   }
                 }}
                 className="user-bonus__side-menu-item">
              <div className="user-bonus__side-menu-item-img" style={item.image === transactions ?{
                    backgroundImage: `url(${item.image})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "25px 25px",
                } : {
                  backgroundImage: `url(${item.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                }}>
                <div className="user-bonus__side-menu-item-img-icon">
                  <img loading="lazy"/>
                </div>
              </div>
              <div className="user-bonus__side-menu-item-title">
                {item.title}
              </div>
            </div>
            );
            },
            )
          }
          <div onClick={goLogOut} className="user-bonus__side-menu-item sign-out">
            <div className="user-bonus__side-menu-item-img" style={{
                    backgroundImage: `url(${signOut})`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "25px 25px",
                }}>
              <div className="user-bonus__side-menu-item-img-icon">
                <img loading="lazy" />
              </div>
            </div>
            <div className="user-bonus__side-menu-item-title">
              Sign Out
            </div>
          </div>
        </div>
        {popUpOpen && popUpOpen.title === "Change Password" && createPortal(<ChangePassword onClose={() => setPopUpOpen(null)} />, modalRoot as Element)}
        {popUpOpen && popUpOpen.title === "Time Settings" && createPortal(<UserTimeSettings onClose={() => setPopUpOpen(null)} />, modalRoot as Element)}
      </div>
    </div>
  );
};

export default memo(UserBonus);
