import { FC } from "react";
import useRedirect from "./useRedirect";
import trending from '../../../../static/images/sidemenu/trending.svg'
import "./styles/index.scss"


interface ItemProps {
  name: string;
  type?: string;
  Id?: string;
  eventType: string;
  children?: any;
  redirect?: string;
  item?: any;
  isLeft?: boolean;
}

export const Item: FC<ItemProps> = ({ name, type, Id, eventType, children, redirect, item }) => {
  const handleRedirect = useRedirect();

  return (
    <>
      <div
        className={`menu-section__list-item-title }`}
        onClick={() => handleRedirect({ name, type, Id, eventType, redirect, item })}
            >
        {name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')}
            </div>
      {item?.isTrending && <img src={trending} alt="" className="trendingItem" />}
    </>
  );
};
