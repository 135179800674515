import { GeneralHandler } from "../../api/general"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import bannerDesktop from "../../static/images/offersPage/bonus-creative.jpeg"
import bannerMobile from "../../static/images/offersPage/bonus-creative-mob.jpeg"
import righArrow from "../../static/images/arrowRight.svg"
import Carousel from "nuka-carousel"
import { Footer } from "../../components/Footer"
import LoadingSpinner from "../../components/loadingSpinner";
import "./styles.scss"
import { MetaPages, useHelmetDataContext } from "../../utils/Meta"

const SpecialOffers = () => {
  const [offersList, setoffersList] = useState<any>([])
  const [loading, setLoading] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false);
  const width = window.innerWidth
  const history = useHistory();

  useHelmetDataContext(MetaPages.USER_BONUS);

  const isMobile = Boolean(width <= 767)
  // Toggle the state to expand or collapse the content
  const toggleExpand = (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();
      setIsExpanded(!isExpanded);
  };

  const OffersListHandler = () => {
    GeneralHandler.getofferslist().then(res => {
        let result = []
        result = [...res]
      setoffersList(result)
      setLoading(false)
    })
  }

  useEffect(() => {
    setLoading(true)
    OffersListHandler()
  }, [])

  return (
    <>
      <div className="special-offers_page">
        <div className="top_banner">
          <div className="desktop_only">
            <img className="tb_image" src={bannerDesktop} alt="" />
          </div>
          <div className="mobile_only">
            <img className="tb_image" src={bannerMobile} alt="" />
          </div>
        </div>
        <div className="global-desktop-container">
          <div className="offer_wrapper">
            {loading ? <div className="centered"><LoadingSpinner /></div> :
              <Carousel slidesToShow={!isMobile ? 3 : 1} cellSpacing={10}>
                  {console.log(offersList)}
                {offersList.map((item: any, index: number) => {
                    const readmoretext = (
                    <>
                      <p>{item.tagLine}</p>
                      <p><strong>Promo Type: </strong>{item.promoType}</p>
                      <p><strong>Validity: </strong> Valid for {item.validity} days</p>
                      <p><strong>Conditions </strong></p>
                      <p></p>
                      <div dangerouslySetInnerHTML={{ __html: item.info }} />
                    </>
                  );
                  return (
                    <div key={index} className="item-box">
                      <div className="image">
                        <img src={item.promoImageUrl} alt="" />
                      </div>
                      <div className="content">
                        <h3 className="heading">{item["short-info"]}</h3>
                        <div className="text">{isExpanded ? readmoretext  : item.tagLine }</div>
                        {item.promoCode?.includes("AUTO-DEPOSIT-Z8") ?
                        <a className="rm" onClick={() => history.push('/realCash')}>
                           {isExpanded ? 'Show less' : 'Read more'}
                        </a>
                        :
                        <a className="rm" onClick={toggleExpand}>
                           {isExpanded ? 'Show less' : 'Read more'}
                        </a>}
                        <button className="button" onClick={() => {
                          history.push({
                            pathname: '/user/deposit',
                            state: {
                                from: item.promoCode
                            }
                          })
                        }}>
                          {item.promoCode?.includes("AUTO-DEPOSIT-Z8") ? "DEPOSIT NOW" : "AVAIL BONUS"}
                          {!item.promoCode?.includes("AUTO-DEPOSIT-Z8") && <img src={righArrow} alt="" />}
                        </button>
                      </div>
                    </div>
                  )
                })}

              </Carousel>
            }
          </div>
        </div>
      </div>
      {width >= 1024 && <Footer />}
    </>
  )
}

export default SpecialOffers
