import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setTimeZoneName } from "../../../redux/menu/timeSettings.slice"
import cn from "classnames"
import moment from "moment"

const RenderTimeList = ({ timeUTC, gmtDevice, showTime }: any) => {
  const [gmtTime, setGmtTime] = useState("")
  const dispatch = useDispatch()
  const {timeZoneName} = useSelector((state: IRootState) => state.timeSettings)

  const getTitle = (zone: string ) => {
    if (zone === "system") return setGmtTime("+00:00")
    if (zone === "device") return   setGmtTime( moment().format('Z'))
    if (zone === "india") return   setGmtTime("+05:30")
    return ""
  };

  useEffect(() => {
    getTitle(timeZoneName)
  }, [timeZoneName])


  return (
    <>
      <span className="date-small__yellow">
        <span className="date-small__yellow-symbol"></span>
        <span className="date-small__yellow-time">
          {gmtTime ? gmtTime : moment().format('Z')}
        </span>
        <span className="date-small__yellow-arrow"></span>
        <span className="date-small__yellow-symbolright"></span>
      </span>

          <div className={cn("timeList", {
            active : timeUTC
          })}>
            <div
              className={cn("timeList__option", {
                active: timeZoneName === "system"
              })}
              onClick={() => {
                dispatch(setTimeZoneName('system'))
              }}
            >
              <div className="timeList-text timeList-text-first">
                System time - (GMT +00:00)
              </div>
            </div>

            <div
              className={cn("timeList__option", {
                active: timeZoneName === "device"
              })}
              onClick={() => {
                dispatch(setTimeZoneName('device'))
              }}
            >
              <div className="timeList-text">
                Your device time - ({gmtDevice})
              </div>
            </div>

            <div
              className={cn("timeList__option", {
                active: timeZoneName === "india"
              })}
              onClick={() => {
                dispatch(setTimeZoneName('india'))
              }}
            >
              <div className="timeList-text timeList-text-last">
                India Standard Time - (GMT +05:30)
              </div>
            </div>
          </div>
    </>
  )
}

export default RenderTimeList
