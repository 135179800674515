import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface balanceSettingsItem {
  volumeMultiplier: [];
  betLimits: [];
  multiplier: number;
  settings: any;
}

const INIT_BALANCE_SETTINGS = 'INIT_BALANCE_SETTINGS';

type InitBalanceSettingsAction = Action<typeof INIT_BALANCE_SETTINGS> & {
  balance: balanceSettingsItem;
};

export const initBalanceSettings = (balance: balanceSettingsItem ): InitBalanceSettingsAction => ({
  type: INIT_BALANCE_SETTINGS,
  balance,
});

// type AlowwedActions = InitBalanceSettingsAction;

const BalanceSettingsReducer = (balance: balanceSettingsItem = {} as balanceSettingsItem, action: AnyAction): balanceSettingsItem => {
  switch (action.type) {
    case INIT_BALANCE_SETTINGS:
      return action.balance;
    default:
      return balance;
  }
};

export default BalanceSettingsReducer;
