import { ConnectedRouter } from "connected-react-router"
import { Provider } from "react-redux"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import Cookies from "js-cookie";
import { GoogleOAuthProvider } from '@react-oauth/google';

import "../src/static/scss/globalStyles.scss"

import { store, history } from "./redux"
import AuthWrapper from "./components/AuthWrapper"
// import Routes from "./routes"
import { IntercomProvider } from 'react-use-intercom';


import * as Sentry from "@sentry/react";
import RoutesNew from "./routes-new";
import { HelmetDataProvider, HelmetWrapper } from "./utils/Meta";
// import Routes from "./routes"
localStorage.theme = 'light'
const App = () =>
{

  window.onerror = async (message, file, line, col, error) => {
    try {
      console.log("CRASH DETECTED " + file + ":" + line + ":" + col)
      console.log("CRASH DETECTED", message);
      console.log("error", error);
      // setOpenErrorPopUp(true);
      const userIP = await fetch("https://api.ipify.org/?format=json").then(res => res.json());
      if (message !== "ResizeObserver loop limit exceeded") {
        Sentry.captureMessage(`IMPORTANT ${window.location.host} ${localStorage.getItem("page")} ${localStorage.getItem("versionNow")} userIP:${userIP?.ip} ` + error);
        // window.location.reload();
        // localStorage.removeItem("pageStack")
        // localStorage.removeItem("page")
      }

    } catch (e) {
      console.log(e);
    }

  };

  const INTERCOM_APP_ID = 'dem7be2z';
  return (
    <GoogleOAuthProvider clientId="978590584847-gci18nvvja97am4076p1prkjgs1o8r0o.apps.googleusercontent.com">
    <HelmetDataProvider>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <AuthWrapper>
                <IntercomProvider appId={INTERCOM_APP_ID}>
                {/*<Routes />*/}
                  <HelmetWrapper />
                  <RoutesNew />
                {/*<RoutesNew />*/}
                </IntercomProvider>
            </AuthWrapper>
          </MuiPickersUtilsProvider>
        </ConnectedRouter>
      </Provider>
    </HelmetDataProvider>
    </GoogleOAuthProvider>
  );};

try{
  // set cookies if have locationSearch
  if (window.location.search) {
    // @ts-ignore
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    parameters.forEach((value, key, urlParam) => {
      if (key === "atag") {
        Cookies.set(`${key}`, `${value}`, { expires: 60 });
        localStorage.setItem(`${key}`, `${value}`);
      }
      if (key === "clickId") {
        Cookies.set(`${key}`, `${value}`, { expires: 60 });
        localStorage.setItem(`${key}`, `${value}`);
      }
    });
  }

  if (Cookies.get("atag")) {
    // @ts-ignore
    localStorage.setItem("atag", Cookies.get("atag"));
  }
  if (Cookies.get("clickId")) {
    // @ts-ignore
    localStorage.setItem("clickId", Cookies.get("clickId"));
  }

  if(window.location.href.includes('intercom')){
    history.push('/intercom')
  }

} catch (e) {
  console.log(e);
}

export default Sentry.withProfiler(App)
