import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface EventMarketDataList {
  result: []
  status: {}
  success: boolean
}

const INIT_EVENT_MARKET_DATA = 'INIT_EVENT_MARKET_DATA';

type InitEventsAction = Action<typeof INIT_EVENT_MARKET_DATA> & {
  EventMarketData: EventMarketDataList;
};

export const initEventMarketData = (EventMarketData: EventMarketDataList): InitEventsAction => ({
  type: INIT_EVENT_MARKET_DATA,
  EventMarketData,
});

// type AlowwedActions = InitEventsAction;

const EventMarketDataReducer = (EventMarketData: EventMarketDataList =  {} as EventMarketDataList, action: AnyAction): EventMarketDataList => {
  switch (action.type) {
    case INIT_EVENT_MARKET_DATA:
      return action.EventMarketData;
    default:
      return EventMarketData;
  }
};

export default EventMarketDataReducer;
