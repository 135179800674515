import cn from "classnames"
import { useEffect, useRef, useState } from "react"
import arrowImg from "../../static/images/arrowDown.svg"
import "./DropDownLogin.scss"
import { useMediaQuery } from "@material-ui/core"
import phoneIcon from './phoneLogin.svg';
import userIcon from './userIconLogin.svg';


export const DropDownLogin = ({ values, chosen, setChosen, classProps, showInMobile = true }: any) => {
  const [isClosed, setIsClosed] = useState(true)
  const [title, setTitle] = useState(chosen || values[0])
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const dropDownRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    setIsClosed(false)
  }, [])

  const chooseItem = (item: any) => {
    setChosen(item)
    setTitle(item)
    setTimeout(() => setIsClosed(false), 200)
  }

  const handler = (event: any) => {
    if( dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setTimeout(() => setIsClosed(false), 200)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handler, true)
    return () => {
      document.removeEventListener("click", handler, true)
    }
  }, [])

  useEffect(() => {
    if (chosen) {
      setTitle(chosen)
    }
  }, [chosen]);

  return (
      <div ref={dropDownRef} className="drop-down-login">
        <div className="drop-down-login__dropdown" onClick={() => setIsClosed(!isClosed)}>
          <div className="drop-down-login__dropdown-content">
              <div className="drop-down-login__dropdown-img">
                <img src={chosen !== 'ID' ? phoneIcon : userIcon} alt={chosen}/>
              </div>
            <div className={cn("drop-down-login__dropdown-content-image", {
              active: isClosed
            })}>
              <img src={arrowImg} alt="arrowImg" />
            </div>
          </div>
        </div>
        {(!isPhone || showInMobile) &&
          <ul className={cn("drop-down-login__dropdown-content-items", {
            [classProps]: classProps,
            active: isClosed
          })}>
            {values?.map((item: any) => {
              return (
                <li className={cn("drop-down-login__dropdown-content-items-one", {
                    [chosen]: chosen,
                    active: item === title
                  })}
                  onClick={() => chooseItem(item)}
                >
                  <div className={item === 'Mobile number' ? 'phoneIcon' : 'userIcon'}></div>
                  {item}
                </li>
              )
            })}
          </ul>
        }
      </div>
  )
}
