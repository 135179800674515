import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface MessageItem {
  id: number;
  level: string;
  order: number;
  text: string;
  userId: string;
}

const INIT_MESSAGE = 'INIT_MESSAGE';

type InitMessageAction = Action<typeof INIT_MESSAGE> & {
  messageList: MessageItem[];
};

export const initMessageList = (messageList: MessageItem[]): InitMessageAction => ({
  type: INIT_MESSAGE,
  messageList,
});

// type AlowwedActions = InitMessageAction;

const MessageListReducer = (menuList: MessageItem[] = [], action: AnyAction): MessageItem[] => {
  switch (action.type) {
    case INIT_MESSAGE:
      return action.messageList;
    default:
      return menuList;
  }
};

export default MessageListReducer;
