import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { CloseReason, VariantType } from "notistack"

export interface Notifier {
  message: string
  key: number
  options: NotifierOptions
  dismissed?: boolean
}

interface NotifierOptions {
  key: number
  variant: VariantType
  action: (key: number) => void
  onClose?: (
    event: React.SyntheticEvent<any, Event> | null,
    reason: CloseReason,
    myKey: string | number | undefined
  ) => void
}

const notifierSlice = createSlice({
  name: "notifier",
  initialState: [] as Notifier[],
  reducers: {
    create: {
      reducer: (state, { payload }: PayloadAction<Notifier>) => {
        state.push(payload)
      },
      prepare: ({ message, options }: { message: string; options: NotifierOptions }) => ({
        payload: {
          key: options.key,
          message,
          options
        } as Notifier
      })
    },
    remove: (state, { payload }: PayloadAction<number>) => {
      const index = state.findIndex(notify => notify.key === payload)
      if (index !== -1) {
        state.splice(index, 1)
      }
    },
    close: (state, { payload }: PayloadAction<number>) => {
      state.forEach((notify, index) => {
        if (notify.key === payload) {
          state[index].dismissed = true
        }
      })
    }
  }
})

export const { create: createNotify, remove: removeSnackbar, close: closeNotify } = notifierSlice.actions

export default notifierSlice.reducer
