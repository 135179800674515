import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useDispatch, useSelector } from "react-redux";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { GeneralHandler } from "../../../api/general";
import { initBalance } from "../../../redux/menu/balance";
import { initBalanceSettings } from "../../../redux/menu/balance-settings";
import { MEMBER_CODE } from "../../../utils/constants";
import { getCurrencyFormat } from "../../../utils/getCurrencyFormat.helper";
import useAuth from "../../../utils/useAuth";
import "./styles/index.scss";

export const BalanceNew = () => {
  const dispatch = useDispatch();
  const {user: { isAuthenticated }} = useSelector((state: IRootState) => ({ user: state.user }));
  const [value, setValue] = useState<any>("");
  const { logout } = useAuth();

  const initData = () => {
    GeneralHandler.getBalance().then(list => {
      if(list) {
        if(list.balanceItem) {
          dispatch(initBalance(list));
        }
      }
    });
    GeneralHandler.getBalanceSettings().then(list => {
      dispatch(initBalanceSettings(list));
    });
  };
  // const  memberCode = localStorage.getItem(MEMBER_CODE)
  // useEffect(() => {
  //   if (memberCode) {
  //     logout()
  //   }
  // }, [memberCode])

  useEffect( () => {
    if(isAuthenticated ) {
      initData();
    }
    const balSet = isAuthenticated ? setIntervalAsync(async () => {
      await GeneralHandler.getBalanceSettings().then(list => {
        dispatch(initBalanceSettings(list));
      });
    }, 60000) : null;
    const bal = isAuthenticated ? setIntervalAsync(async () => {
      if (localStorage.getItem(MEMBER_CODE)) {
        await GeneralHandler.getBalance().then(list => {
          dispatch(initBalance(list));
        });
      } else {
        logout();
      }
    }, 1400) : null;

    return () => {
      if (balSet && bal) {
        clearIntervalAsync(balSet);
        clearIntervalAsync(bal);
      }
    };
  }, [isAuthenticated] );

  const balance = useSelector((state: IRootState) => state.balance);

  const getAvailBalance = () => {
    if (!Object.keys(balance).length) { return setValue(""); }
    switch (true) {
      case balance && balance?.useBWallet:
        return setValue(getCurrencyFormat(balance?.bWallet?.availBalance?.toFixed(0)));
      case balance && balance?.useSWallet:
        return setValue(getCurrencyFormat(balance?.sWallet?.availBalance?.toFixed(0)));
      case balance && balance?.useCWallet:
        return setValue(getCurrencyFormat(balance?.cWallet.availBalance?.toFixed(0)));
      default:
        return setValue(getCurrencyFormat(balance?.availBalance?.toFixed(0)));
    }
  };
  useEffect(() => {
    getAvailBalance();
  }, [balance]);

  return (
    <>
      <div className="balance-new">
        { ( value === 0 || value)?
          <div>
          <span>₹</span>
          <span>{value}</span>
        </div>
        :
          <Skeleton
            width={40}
            baseColor={"#00A82680"}
          />
        }
      </div>
    </>
  );
};
