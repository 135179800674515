import cn from "classnames";
import { memo, useRef, useState } from "react"
import { useLocation } from "react-router-dom";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { setVersion } from "../../../../redux/menu/userBonus.slice";
import BonusDetailsPopUp from "../BonusDetailsPopUp";
import UserBonus from "../UserBonus";
import "./bonusPopUp.scss";

const modalRoot = document.querySelector("#modal-root");

const BonusPopUp = () => {
  const dispatch = useDispatch();
  const userBonus = useSelector((state: IRootState) => state?.userBonus);
  const [closeSidebar, setCloseSidebar] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();

  console.log('pathname', pathname);


  const onCloseSideBar = () => {
    setCloseSidebar(true);
    setTimeout(() => {
      dispatch(setVersion(""));
      setCloseSidebar(false);
    }, 300);
  };

  return userBonus?.version ? (
    createPortal(
      <div
        ref={ref}
        className={cn("bonus-pop-up", {
          "sidebar-pop-up": userBonus?.version === "sideBar-desktop" || userBonus?.version === "mobile",
          "with-details": userBonus?.walletType,
          "iframe":  pathname?.includes("casino-game/") || pathname?.includes("card-game/"),
          mobile: userBonus?.version === "mobile",
          "close-sidebar": closeSidebar,
        })}
      >
        <div
          onClick={e => {
            if (e.currentTarget === e.target) {
              onCloseSideBar();
            }
          }}
          className="bonus-pop-up__wrapper"
        >
          <UserBonus onCloseSideBar={onCloseSideBar} />
        </div>
        {userBonus?.walletType && <BonusDetailsPopUp walletType={userBonus?.walletType} />}
      </div>,
      modalRoot as Element,
    )
  ) : (
    <></>
  );
};

export default memo(BonusPopUp);
