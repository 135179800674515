import { useState, useMemo, memo } from "react";
import { Item } from "./Item";
import { ItemsList } from "./Item/useRedirect";
import "./styles/index.scss";

interface List {
  type: string;
  name: string;
  id: string;
  startTime?: string;
  eventType?: string;
  competitionId?: string;
  eventTypeId: string;
  children?: ItemsList[] | undefined;
  sortOrder?: number;
  redirect: string;
}

interface Iprops {
  name: string;
  list: List[];
}

export const OthersList = memo(({ name, list }: Iprops): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(true);

  const memoizedList = useMemo(() => {
    return list;
  }, [list]);

  return (
    <>
      <div className="menu-section">
        <div
          className={menuOpen ? "menu-section__title menu-section__title--active" : "menu-section__title" }
          onClick={() => setMenuOpen((prevValue) => !prevValue)}
        >
          <span className="icon icon-popular"></span>
          {name}
        </div>
        <ul className="menu-section__list">
          {menuOpen &&
            memoizedList.map((item) => (
              <li key={item.id} className="menu-section__list-item">
                  <Item item={item}name={item.name} Id={item.id} type={item.type} eventType={item.eventTypeId} children={item.children} redirect={item.redirect}/>
                </li>
              ))}
        </ul>
      </div>
    </>
  );
});
