import { configureStore, Middleware, ReducersMapObject } from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory, History } from "history";
import { TypedUseSelectorHook, useDispatch, useSelector as useAppSelector } from "react-redux";
import createSagaMiddleware from "redux-saga";
import preferencesSlice from "./login/preferences.slice";
import resetPasswordSlice from "./login/resetPassword.slice";
import StakesSettingsReducer from "./login/stakeSettings";
import BalanceReducer from "./menu/balance";
import BalanceSettingsReducer from "./menu/balance-settings";
import BannerReducer from "./menu/banners";
import CardsReducer from "./menu/cards";
import footerBetsSlice from "./menu/footerBets.slice";
import GoogleConfigReducer from "./menu/googleCfg";
import MenuListReducer from "./menu/headerMenu";
import homeMenuSlice from "./menu/homeMenu.slice";
import LiveListReducer from "./menu/liveCategories";
import MessageListReducer from "./menu/message";
import notificationSlice from "./menu/notification.slice";
import orderListSlice from "./menu/orderList.slice";
import SideMenuGamesReducer from "./menu/sideMenuGames";
import SideMenuOthersReducer from "./menu/sideMenuOthers";
import SideMenuReducer from "./menu/sideMenuSports";
import SlotsListReducer from "./menu/slotsCategories";
import timeSettingsSlice from "./menu/timeSettings.slice";
import notifierReducer from "./Notifier/notifier.slice";
import casinoLiveReducer from "./pages/casinoLive/casinoLive";
import BetInfoReducer from "./pages/EventDetail/betInfo";
import EventMarketDataReducer from "./pages/EventDetail/eventMarketData";
import ExposureReducer from "./pages/EventDetail/exposure";
import GamesOddsReducer from "./pages/EventDetail/gamesOdds";
import GamesPastResultsReducer from "./pages/EventDetail/gamesPastResult";
import BetsReducer from "./pages/EventDetail/myBets";
import BetReducer from "./pages/EventDetail/placeBet";
import ProjectionReducer from "./pages/EventDetail/projectionBetSlip";
import StatsReducer from "./pages/EventDetail/stats";
import EventsReducer from "./pages/eventListPage(sports)";
import JiliPopUpReducer from "./pages/jiliPopUp";
import OrderReducer from "./pages/orderList";
import DepositTransactionReducer from "./pages/transactions/detailedDepositTransaction";
import AddAccountReducer from "./pages/withdrawal/addAccount";
import { createReducerManager } from "./reducerManager";
import saga from "./saga";
import ErrMsgReducer from "./UI/betErrMsg";
import SuccessSlice from "./UI/betSuccess.slice";
import headerTabSlice from "./UI/headerTab.slice";
// import globalRulesSlice from "./UI/globalRules.slice";
// import MarketPopUpReducer from "./pages/marketPopUp";
// import resetPasswordSlice from "./login/resetPassword.slice";
// import ProfitLossListReducer from "./pages/profitLoss";
// import FailSlice from "./UI/betFail.slice";
// import jiliSlice from "./UI/jili.slice";
// import withdrawalAccountsSlice from "./user/withrawalAccounts.slice";
import openPopUpsSlice from "./UI/openPopUps.slice";
// import uiSlice from "./UI/ui.slice";
import userReducer from "./user/user.slice";

const actionArrayProcessing: Middleware<{}, IRootState> = ({ dispatch }: { dispatch: (action: IAction) => void }) => (
  next: (action: IAction) => void,
) => (action: IAction | IAction[]) => {
  if (Array.isArray(action)) {
    action.map(el => dispatch(el));
  } else {
    next(action);
  }
};

export const history: History<{}> = createBrowserHistory();
const reactRouterMiddleware = routerMiddleware(history);
//
const sagaMiddleware = createSagaMiddleware();

// const rootReducer = () =>
//   combineReducers({
//     router: connectRouter(history),
//     notifier: notifierReducer,
//     user: userReducer,
//     ui: uiSlice,
//     jili: jiliSlice,
//     globalRules: globalRulesSlice,
//     loginPopUp: loginPopUpSlice,
//     singupPopUp: singupPopUpSlice,
//     forgotPassword: forgotPasswordSlice,
//     menuList: MenuListReducer,
//     sideMenu: SideMenuReducer,
//     sideMenuGames: SideMenuGamesReducer,
//     sideMenuOthers: SideMenuOthersReducer,
//     messageList: MessageListReducer,
//     balance: BalanceReducer,
//     statementList: StatementListReducer,
//     balanceSettings: BalanceSettingsReducer,
//     order: OrderReducer,
//     casinoReport: CasinoReportReducer,
//     stakes: StakesSettingsReducer,
//     profitLoss: ProfitLossListReducer,
//     events: EventsReducer,
//     marketPopUp: MarketPopUpReducer,
//     eventMarketData: EventMarketDataReducer,
//     stats: StatsReducer,
//     bet: BetReducer,
//     betInfo: BetInfoReducer,
//     bets: BetsReducer,
//     exposurePnl: ExposureReducer,
//     cards: CardsReducer,
//     projection: ProjectionReducer,
//     gamesOdds: GamesOddsReducer,
//     gamesPastResults: GamesPastResultsReducer,
//     slotsList: SlotsListReducer,
//     jiliPopUp: JiliPopUpReducer,
//     success: SuccessSlice,
//     fail: FailSlice,
//     errMsg: ErrMsgReducer,
//     casinoLive: casinoLiveReducer,
//     banners: BannerReducer,
//     liveList: LiveListReducer,
//     headerTab: headerTabSlice,
//     config: GoogleConfigReducer,
//     depositReq: DepositReqReducer,
//     detailedDepositTransaction: DepositTransactionReducer,
//     userBonus: userBonusSlice,
//     timeSettings: timeSettingsSlice,
//     notification: notificationSlice,
//     homeMenu: homeMenuSlice,
//     addAccount: AddAccountReducer,
//     bonusStatementList: BonusStatementListReducer,
//     orderList: orderListSlice,
//     footerBets: footerBetsSlice,
//     withdrawalAccounts: withdrawalAccountsSlice,
//     preferences: preferencesSlice,
//     resetPassword: resetPasswordSlice,
//   });

// @ts-ignore
const rootReducers: ReducersMapObject<IRootState> = {
      router: connectRouter(history),
      notifier: notifierReducer,
      user: userReducer,
      menuList: MenuListReducer,
      sideMenu: SideMenuReducer,
      sideMenuGames: SideMenuGamesReducer,
      sideMenuOthers: SideMenuOthersReducer,
      messageList: MessageListReducer,
      balance: BalanceReducer,
      balanceSettings: BalanceSettingsReducer,
      order: OrderReducer,
      stakes: StakesSettingsReducer,
      events: EventsReducer,
      eventMarketData: EventMarketDataReducer,
      stats: StatsReducer,
      bet: BetReducer,
      betInfo: BetInfoReducer,
      bets: BetsReducer,
      exposurePnl: ExposureReducer,
      cards: CardsReducer,
      projection: ProjectionReducer,
      gamesOdds: GamesOddsReducer,
      gamesPastResults: GamesPastResultsReducer,
      slotsList: SlotsListReducer,
      jiliPopUp: JiliPopUpReducer,
      success: SuccessSlice,
      errMsg: ErrMsgReducer,
      // @ts-ignore
      casinoLive: casinoLiveReducer,
      banners: BannerReducer,
      liveList: LiveListReducer,
      headerTab: headerTabSlice,
      config: GoogleConfigReducer,
      detailedDepositTransaction: DepositTransactionReducer,
      timeSettings: timeSettingsSlice,
      notification: notificationSlice,
      homeMenu: homeMenuSlice,
      addAccount: AddAccountReducer,
      orderList: orderListSlice,
      footerBets: footerBetsSlice,
      preferences: preferencesSlice,
      resetPassword: resetPasswordSlice,
      openPopUps: openPopUpsSlice,
      // withdrawalAccounts: withdrawalAccountsSlice,
      // globalRules: globalRulesSlice,
      // ui: uiSlice,
      // marketPopUp: MarketPopUpReducer,
      // fail: FailSlice,
      // casinoReport: CasinoReportReducer,
      // userBonus: userBonusSlice,
      // jili: jiliSlice,
      // singupPopUp: singupPopUpSlice,
      // forgotPassword: forgotPasswordSlice,
      // bonusStatementList: BonusStatementListReducer,
      // statementList: StatementListReducer,
      // : DepositReqReducer,
      // loginPopUp: loginPopUpSlice,
      // profitLoss: ProfitLossListReducer,
};

const reducerManager = createReducerManager(rootReducers);
export const store = configureStore<IRootState>({
      // @ts-ignore
  reducer: reducerManager.reduce,
      // @ts-ignore
  middleware: [ actionArrayProcessing, reactRouterMiddleware, sagaMiddleware],
});

// @ts-ignore
store.reducerManager = reducerManager;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
export const useSelector: TypedUseSelectorHook<IRootState> = useAppSelector;

sagaMiddleware.run(saga);
