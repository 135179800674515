import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface MenuItem {
  type: string;
  name: string;
  id: string;
  sortOrder: number;
  children?: any;
}

const INIT_MENU = 'INIT_MENU';

type InitMenuAction = Action<typeof INIT_MENU> & {
  menuList: MenuItem[];
};

export const initMenuList = (menuList: MenuItem[]): InitMenuAction => ({
  type: INIT_MENU,
  menuList,
});

// type AlowwedActions = InitMenuAction;

const MenuListReducer = (menuList: MenuItem[] = [], action: AnyAction): MenuItem[] => {
  switch (action.type) {
    case INIT_MENU:
      return action.menuList;
    default:
      return menuList;
  }
};

export default MenuListReducer;
