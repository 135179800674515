import "./styles/index.scss"
import horseImg from "../../../static/images/homeMenu/sideBarHorse.svg"
import greyHoundImg from "../../../static/images/homeMenu/sideBarGreyhound.svg"
import politicsImg from "../../../static/images/homeMenu/polotics.svg"
import liveCardsImg from "../../../static/images/homeMenu/liveCards.svg"
import virtualCardsImg from "../../../static/images/homeMenu/virtualCards.svg"
import cricketLottieAnimation from "./lottieAnimations/cricketLottieAnimation.json"
import footballLottieAnimation from "./lottieAnimations/footballLottieAnimation.json"
import tennisLottieAnimation from "./lottieAnimations/tennisLottieAnimation.json"
import cn from "classnames"
import { useHistory } from "react-router"
import { useSelector } from "react-redux"
import { useEffect, useState, useRef } from "react"
import ScrollSlider from "../../../components/ScrollSlider/ScrollSlider"
import lottie from "lottie-web"
import { useMediaQuery } from "@material-ui/core"

export const HomeMenuNew = () => {
  const [pageMenu, setPageMenu]: any = useState()
  const history = useHistory()
  const pageId = history.location.pathname.split("/").slice(-1)[0]
  const sideMenu = useSelector((state: IRootState) => state.sideMenu)
  const slotsList = useSelector((state: IRootState) => state.slotsList).sort((a, b) => a.name.localeCompare(b.name))
  const liveList = useSelector((state: IRootState) => state.liveList).sort((a, b) => a.name.localeCompare(b.name))
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const [containersReady, setContainersReady] = useState(false)

  const lottieContainers = {
    Tennis: useRef(null),
    Cricket: useRef(null),
    Football: useRef(null)
  }

  useEffect(() => {
    setContainersReady(false)
    const allContainersReady = Object.values(lottieContainers).every(container => container.current !== null)

    if (allContainersReady) {
      setContainersReady(true)
    }
  }, [pageMenu])

  useEffect(() => {
    if (containersReady) {
      const initializeLottie = (name: any, animationData: any) => {
        if (lottieContainers[name].current) {
          const animation = lottie.loadAnimation({
            container: lottieContainers[name].current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData
          })

          return () => animation.destroy()
        }
        return () => {}
      }

      const tennisCleanup = initializeLottie("Tennis", tennisLottieAnimation)
      const cricketCleanup = initializeLottie("Cricket", cricketLottieAnimation)
      const footballCleanup = initializeLottie("Football", footballLottieAnimation)

      return () => {
        tennisCleanup()
        cricketCleanup()
        footballCleanup()
      }
    } else {
      return
    }
  }, [containersReady])

  useEffect(() => {
    if (history.location.pathname.includes("live-casino-page")) {
      setPageMenu(liveList)
    } else if (history.location.pathname.includes("slots-page")) {
      setPageMenu(slotsList)
    } else if (history.location.pathname.includes("card-games")) {
      setPageMenu([
        { name: "Live games", id: "live" },
        { name: "Virtual games", id: "virtual" }
      ])
    } else {
      setPageMenu(sideMenu)
    }
  }, [history.location.pathname, slotsList, liveList, sideMenu])

  const mobMenu = [
    {
      name: "Sports",
      id: "0",
      active: history.location.pathname.includes("sports/"),
      action: () => {
        history.push("/home/sports/4")
      }
    },
    // {
    //   name: "KABADDI",
    //   id: "138",
    //   active: false,
    //   action: () => {
    //     history.push("//sportsbook/1444005?url=/305", {
    //       sportId: "138",
    //       ventName: "28569726"
    //     })
    //   }
    // },
    // {
    //   name: "KABADDI",
    //   id: "138",
    //   active: false,
    //   action: () => {
    //     history.push("/sportsbook?bt-path=%2Fkabaddi-138");
    //   }
    // },
    {
      name: "Aura Casino",
      id: "98789",
      active: history.location.pathname.includes("card-game/"),
      action: () => {
        history.push("/card-game/98789/FAWK");
      }
    },
    // {
    //   name: "Mines",
    //   id: "700118",
    //   active: history.location.pathname.includes("card-game/"),
    //   action: () => {
    //     history.push("/card-game/700118/FAWK");
    //   }
    // },
    {
      name: "Limbo",
      id: "700048",
      active: history.location.pathname.includes("card-game/"),
      action: () => {
        history.push("/card-game/700048/FAWK");
      }
    },
    {
      name: "Aviator",
      id: "67722-2",
      active: history.location.pathname.includes("card-game/"),
      action: () => {
        history.push("/card-game/67722-2/FAWK")
      }
    },
    {
      name: "Indian Card",
      id: "2",
      active: history.location.pathname.includes("card-games"),
      action: () => history.push("/home/card-games/live")
    },
    {
      name: "Live casino",
      id: "3",
      active: history.location.pathname.includes("live-casino-page"),
      action: () => history.push("/home/live-casino-page/17")
    },
    {
      name: "Slots",
      id: "4",
      active: history.location.pathname.includes("slots-page"),
      action: () => history.push("/home/slots-page/51")
    },
    {
      name: "Sportsbook",
      id: "Sportsbook",
      active: history.location.pathname.includes("sportsbook"),
      action: () => history.push("/sportsbook")
    }
  ]
  const getTabImg = (name: string, isPhone: any, lottieContainers: any): any => {
    const style = isPhone ? { width: 32, height: 32 } : { width: 40, height: 40 }

    switch (name) {
      case "Cricket":
        return <div ref={lottieContainers.Cricket} style={style} />
      case "Football":
        return <div ref={lottieContainers.Football} style={style} />
      case "Tennis":
        return <div ref={lottieContainers.Tennis} style={style} />
      case "Horse Racing":
        return <img src={horseImg} alt={name} />
      case "Greyhound Racing":
        return <img src={greyHoundImg} alt={name} />
      case "Politics":
        return <img src={politicsImg} alt={name} />
      case "Virtual games":
        return <img src={virtualCardsImg} alt={name} />
      case "Live games":
        return <img src={liveCardsImg} alt={name} />
      default:
        return null
    }
  }

  return (
    <div className="home-menu-new">
      <div className="home-menu-new__content top">
        <ScrollSlider className="home-menu-new__main">
          {mobMenu.map(item => (
            <div
              className={cn("home-menu-new__main-item", {
                active: item.active,
                new: item.id === "700118",
                wc: item.id === "1",
              })}
              key={item.id}
              onClick={item.action}
            >
              {item.name}
            </div>
          ))}
        </ScrollSlider>
        <ScrollSlider className="home-menu-new__sub">
          {pageMenu?.length > 0 &&
            pageMenu.map((tab: any) => {
              if (!["Sportsbook", "Exchange Game"].includes(tab.name)) {
                return (
                  <div
                    key={tab.id}
                    className={cn("home-menu-new__sub-item", {
                      active: pageId === tab.id
                    })}
                    onClick={() => {
                      history.push(tab.id)
                    }}
                  >
                    <div className="home-menu-new__sub-item-img">{getTabImg(tab.name, isPhone, lottieContainers)}</div>
                    <span>{tab.name === "All Slot" ? "All" : tab.name}</span>
                  </div>
                )
              }
              return null
            })}
        </ScrollSlider>
      </div>
    </div>
  )
}
