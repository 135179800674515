import { useEffect, useState, memo } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setHeaderTab } from "../../redux/UI/headerTab.slice"
import IconMenu from "../../static/images/header/menu.svg"
import { setIsOpenSideBar } from "../../redux/UI/openPopUps.slice"

const HeaderNewUser = memo(() => {
  const history = useHistory()
  const { pathname } = useLocation();

  const dispatch = useDispatch()
  const [width, setWidth] = useState(window.innerWidth)
  const handleResize = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const getPage = (): string => {
    if (pathname.includes("FAWK")) return ""
    if (pathname.includes("change-password")) return "Change Password"
    if (pathname.includes("time-settings")) return "Time Settings"
    if (pathname.includes("transfer-statement")) return "Transfer Statement"
    if (pathname.includes("account-statement")) return "Account Statement"
    if (pathname.includes("bonus-statement")) return "Bonus Statement"
    if (pathname.includes("set-button-values")) return "Settings"
    if (pathname.includes("rules-regulations")) return "Rules & Regulations"
    if (pathname.includes("privacy-policy")) return "Privacy Policy"
    if (pathname.includes("unsettled-bet")) return "Open Bets"
    if (pathname.includes("withdrawal")) return "Withdrawal"
    if (pathname.includes("transactions")) return "Transactions"
    if (pathname.includes("realCash")) return "6% Real Cash on Every Deposit"
    return ""
  }

  return (
    <>
      <header>
        <div className="header-container">
            <>
              <div className="header__left-side">
                {!history.location.pathname.includes("/home/sports/") ? (
                  <div className="menu home" id="backButton" onClick={() => history.goBack()}>
                  </div>
                ) : (
                  <div className="menu" onClick={() => dispatch(setIsOpenSideBar(true))}>
                    <img src={IconMenu} alt="menu" />
                  </div>
                )}
                {getPage() && width < 1024 && width > 340 ?
                  <div className="header__left-side-page"
                       style={{maxWidth: "unset"}}
                       onClick={
                         () => {
                           history.push("/")
                           dispatch(setHeaderTab("IN_PLAY"))
                         }}
                  >
                    {getPage()}
                  </div>
                  :
                  <div className="logo-wrapper" onClick={
                    () => {
                      history.push("/")
                      dispatch(setHeaderTab("IN_PLAY"))
                    }}
                  >
                    <div className="logo" />
                  </div>
                }
              </div>
            </>
        </div>
      </header>
    </>
  )
})

export default HeaderNewUser
