import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface SidemenuOthersList {
  competitionId: string;
  countryCode: string;
  eventTypeId: string;
  id: string;
  name: string;
  redirect: string;
  startTime: string;
  type: string;
}

const INIT_SIDE_MENU_OTHERS = 'INIT_SIDE_MENU_OTHERS';

type InitSideMenuOthersAction = Action<typeof INIT_SIDE_MENU_OTHERS> & {
  sideMenuOthers: SidemenuOthersList[];
};

export const initSideMenuOthers = (sideMenuOthers: SidemenuOthersList[]): InitSideMenuOthersAction => ({
  type: INIT_SIDE_MENU_OTHERS,
  sideMenuOthers,
});

// type AlowwedActions = InitSideMenuOthersAction;

const SideMenuOthersReducer = (sideMenuOthers: SidemenuOthersList[] = [], action: AnyAction): SidemenuOthersList[] => {
  switch (action.type) {
    case INIT_SIDE_MENU_OTHERS:
      return action.sideMenuOthers;
    default:
      return sideMenuOthers;
  }
};

export default SideMenuOthersReducer;
