import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface StakesSettings {
  settings: number[];
  mobileStakesExchangeGameSettings: [];
  mobileStakesSettings: [];
}

const INIT_STAKES_SETTINGS = 'INIT_STAKES_SETTINGS';

type InitStakesSettingsAction = Action<typeof INIT_STAKES_SETTINGS> & {
  stakes: StakesSettings;
};

export const initStakesSettings = (stakes: StakesSettings ): InitStakesSettingsAction => ({
  type: INIT_STAKES_SETTINGS,
  stakes,
});

// type AlowwedActions = InitStakesSettingsAction;

const StakesSettingsReducer = (stakes: StakesSettings = {} as StakesSettings, action: AnyAction): StakesSettings => {
  switch (action.type) {
    case INIT_STAKES_SETTINGS:
      return action.stakes;
    default:
      return stakes;
  }
};

export default StakesSettingsReducer;
