import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface UserInterface {
  username: string
  isAuthenticated: boolean
  isLoadingAuthentication: boolean
  role: "USER" | "ADMIN"
}

const userInitialState: UserInterface = {
  username: "",
  isAuthenticated: false,
  isLoadingAuthentication: true,
  role: "USER"
}

const userSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setAuthenticate: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload
      state.isLoadingAuthentication = false
    },
    setUserProperties: (state, { payload }: PayloadAction<Partial<UserInterface>>) => {
      Object.keys(payload).forEach(name => (state[name] = payload[name]))
    }
  }
})

export const { setAuthenticate, setUserProperties } = userSlice.actions

export default userSlice.reducer
