import { Checkbox, Paper } from "@material-ui/core";
import { Snackbar } from "@material-ui/core";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import cn from "classnames";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { createPortal } from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { LoginHandler } from "../../api/login";
import { SingupHandler } from "../../api/singup";
import CustomButton from "../../components/UI/Button";
import { GeneralHandler } from "../../api/general"
import FormikField from "../../pages/Login/components/FormikField";
import { initStakesSettings } from "../../redux/login/stakeSettings";
// import { GoogleLogin } from '@react-oauth/google';
import arrowIcon from "./arrowIcon.svg";
import successIcon from "../../static/images/successIcon.svg";
import useAuth from "../../utils/useAuth";
import SignUpLogoIcon from "./signUpLogo.svg";
import WAIcon from "./waIcon.svg";
import MobileIcon from "./mobileIcon.svg";
import ArrowIcon from "./arrow.svg";
import GoogleIcon from './gmailIcon.svg';
import { setPreferences } from "../../redux/login/preferences.slice";
import "./index.scss";
import { setIsOpenLoginPopUp, setIsOpenSignupPopUp } from "../../redux/UI/openPopUps.slice"

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface CustomWindow extends Window {
  srtmCommands: { push: (arg: any) => void };
}
declare const window: CustomWindow;
declare const google: any;

const modalRoot = document.querySelector("#modal-root");
interface FormValues {
    mobileNumber: string;
    countryCode: string;
    OTP1: any;
    OTP2: any;
    OTP3: any;
    OTP4: any;
    password: string;
    confirmPassword: string;
}

const initialValues: FormValues = {
    mobileNumber: "",
    countryCode: "+91",
    OTP1: "",
    OTP2: "",
    OTP3: "",
    OTP4: "",
    password: "",
    confirmPassword: "",
};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const Step1Shema = Yup.object().shape({
    mobileNumber: Yup.string()
        .required("This field is required.")
        .matches(phoneRegExp, "Phone number is invalid.")
        .min(10, "Mobile number must be 10 digits.")
        .max(10, "Mobile number must be 10 digits."),
});

const Step2Shema = Yup.object().shape({
      OTP1: Yup.string()
          .required("This field is required.")
          .min(1, "OTP must be 1 digits.")
          .max(1, "OTP must be 1 digits."),
      OTP2: Yup.string()
          .required("This field is required.")
          .min(1, "OTP must be 1 digits.")
          .max(1, "OTP must be 1 digits."),
      OTP3: Yup.string()
          .required("This field is required.")
          .min(1, "OTP must be 1 digits.")
          .max(1, "OTP must be 1 digits."),
      OTP4: Yup.string()
          .required("This field is required.")
          .min(1, "OTP must be 1 digits.")
          .max(1, "OTP must be 1 digits."),
      password: Yup.string()
          .required("This field is required")
          .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d])(?=.*[!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/])[a-zA-Z\d!$%^&*#@()_+|~=`{}\[\]:;"'<>,.?\/]+$/, "This field must contain letters(A-z), numbers(0-9) and special symbols.")
          .min(8, "Password length should be between 8 to 15.")
          .max(15, "Password length should be between 8 to 15."),
      confirmPassword: Yup.string()
          .required("This field is required.")
          .oneOf([Yup.ref("password"), null], "Passwords must match."),
  });

const SignupPopUp = () => {
  const [alert, setAlert] = useState(false);
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState<any>("");
  const dispatch = useDispatch();
  const isOpenLoginPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenSignupPopUp);
  const recaptchaRef = useRef(null);
  const [step, setStep] = useState(0);
  const { login } = useAuth();
  const [mobileNumber, setMobileNumber] = useState("");
  const config = useSelector((state: IRootState) => state.config);
  const [otpSent, setOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messegeOtp, setMessegeOtp] = useState("");
  const [wrongOTP, setWrongOTP] = useState(false);
  const [expiredOTP, setExpiredOTP] = useState(false);
  const [weakPassword, setWeakPassword] = useState(false);
  const [animation, setAnimation] = useState(false);
  const history = useHistory();
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState<any>()
  const [googleLoginWrapper, setGoogleLoginWrapper] = useState<any>()

  const initialMinute = 0,
  initialSeconds = 59;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    if (otpSent){
    const myInterval= setInterval(() => {
      console.log("minusSet");
      if (seconds > 0) {
        setSeconds(seconds - 1);
        console.log("minus1");
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }
  else { return; }
  }, [seconds, otpSent]);

  useEffect(() => {
    setGoogleLoginWrapper(createGoogleWrapper());
  }, [])

  const getElementByID1: HTMLInputElement | null = document.getElementById("OTP1") as HTMLInputElement | null;
  const getElementByID2: HTMLInputElement | null = document.getElementById("OTP2") as HTMLInputElement | null;
  const getElementByID3: HTMLInputElement | null = document.getElementById("OTP3") as HTMLInputElement | null;
  const getElementByID4: HTMLInputElement | null = document.getElementById("OTP4") as HTMLInputElement | null;

  getElementByID1?.addEventListener("input", (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 1) {
      setTimeout(() => {
      getElementByID2?.focus();
      }, 50);
    }
  });

  getElementByID2?.addEventListener("input", (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 1) {
      getElementByID3?.focus();
    }
    else if (!target.value.length) {
      getElementByID1?.focus();
    }
  });

  getElementByID3?.addEventListener("input", (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (target.value.length === 1) {
      getElementByID4?.focus();
    }
    else if (!target.value.length) {
      getElementByID2?.focus();
    }
  });

  getElementByID4?.addEventListener("input", (event: Event) => {
    const target = event.target as HTMLInputElement;
    if (!target.value.length) {
      setTimeout(() => {
      getElementByID3?.focus();
      }, 50);
    }
  });

  useEffect(() => {
    if (config) {setRecaptchaSiteKey(config?.recaptchaConfig?.recaptchaSiteKey);}
  }, [config]);

  const handleClose = () => {
    setTimeout(() =>{
      setSuccess(false);
    }, 2000);
  };

  const clickNextButton = async (mobileNumber: string, countryCode: string) => {

    setLoading(true);
    window.srtmCommands.push({event: "track.user.registration",payload: {action: "start"}})
    try {
      // @ts-ignore
      const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
      console.log("recapcha", recaptchaResponse);
      const response = await SingupHandler.SingupRequestOTP(mobileNumber, countryCode, recaptchaResponse);
      if (response.data.result) {
        setMobileNumber(mobileNumber);
        setOtpSent(true);
        setStep(2);
        setMessegeOtp(response.data.result);
        setSuccess(true);
      }
      console.log("response", response);
    } catch (error: any) {

      setError(error?.response?.data?.error);
      setTimeout(() => {
        setError("");
      }, 3100);
      setAlert(true);
      setTimeout(() => {
        setAlert(false);
      }, 3000);
      // @ts-ignore
      recaptchaRef?.current?.reset();
    } finally {
      setLoading(false);
    }
  };

  const resendOTP = async () => {

    if (seconds === 0) {
      try {
        const response = await SingupHandler.ResendOTP(mobileNumber, "+91");
        console.log(response);
        setMessegeOtp(response.data.result);
        setSuccess(true);
      } catch (error: any) {

        if (error) {
          if (error.response) {
            if (error.response.data) {
              console.log("eeerrr", error.response.data);
              setError(error.response.data?.error);
              setTimeout(() => {
                setError("");
              }, 3100);
            }
          }
        }

        setAlert(true);
        setTimeout(() => {
          setAlert(false);
        }, 3000);
        // @ts-ignore
        recaptchaRef?.current?.reset();
      }
    }
  };

  console.log("step", step);

  const clickSingupButton = async (countryCode: string, OTP1: any, OTP2: any, OTP3: any, OTP4: any, password: string, confirmPassword: string) => {
    const otp = [OTP1, OTP2, OTP3, OTP4].join("");
    setLoading(true);

    try {
        let deviceId, atag, clickId;
        if (localStorage.getItem("deviceId") !== null) {
          // @ts-ignore
          deviceId = localStorage.getItem("deviceId");
        }

      if (localStorage.getItem("atag") !== null) {
        // @ts-ignore
        atag = localStorage.getItem("atag");
      }

if (localStorage.getItem("clickId") !== null) {
        // @ts-ignore
        clickId = localStorage.getItem("clickId");
      }

        // @ts-ignore
        const register = await SingupHandler.SingupRegistration(mobileNumber, countryCode, otp, password, confirmPassword, deviceId, atag, clickId);
        console.log("register", register);
        if (register.data.result) {
window.srtmCommands.push({event: "track.user.registration",payload: {action: "complete", userId: register?.data?.result?.memberCode}});
            handleSubmit();
            setStep(3)

            // @ts-ignore
            const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
            console.log("recapcha", recaptchaResponse);

          let deviceId;
          if (localStorage.getItem("deviceId") !== null) {
            // @ts-ignore
            deviceId = localStorage.getItem("deviceId");
          }

            // @ts-ignore
          const response = await LoginHandler.LoginRequest(`91_${mobileNumber}`, password, recaptchaResponse, deviceId);
            if (response.data.result) {
                const endSignUp = window.srtmCommands.push({event: "track.user.registration",payload: {action: "complete", userId: response?.data?.result?.memberCode}});
                console.log('!! endSign', endSignUp)
                const stakes = response.data.result;
                dispatch(initStakesSettings(stakes.settings.presetStakeSettings));
                login(
                  response.headers.authorization, response.data.result.memberCode,
                  response.data.result.loginName, stakes.settings.presetStakeSettings,
                  stakes.memberId, stakes?.settings?.preferences?.confirmation,
                );
          setStep(3);
            }

            }
    } catch(error:any) {

        if (error) {
          if (error.response) {
            if (error.response.data) {
              console.log("eeerrr", error.response.data);
              setError(error.response.data?.error);
              if (error.response.data.status.statusCode === "AUTH001") {
                 setExpiredOTP(true);
              } else if (error.response.data.status.statusCode === "AUTH0075") {
                setWeakPassword(true);
              } else {
                setWrongOTP(true);
              }
              setTimeout(() => {
                setError("");
              }, 3100);
            }
          }
        }
        setAlert(true);
        setTimeout(() => {
          setWrongOTP(false);
          setWeakPassword(false);
          setExpiredOTP(false);
          setAlert(false);
        }, 3000);
    } finally {
      setLoading(false);
    }
    console.log("send", countryCode, mobileNumber, otp, password, confirmPassword);
  };

  const createGoogleWrapper = () => {
    google.accounts.id.initialize({
      client_id: "978590584847-gci18nvvja97am4076p1prkjgs1o8r0o.apps.googleusercontent.com",
      callback: async (response: any) => {
        const token = response.credential;
        // console.log(token);

        // @ts-ignore
        const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
        GeneralHandler.credentialResponse({
          "token": token,
          "recaptchaResponse": recaptchaResponse,
        }).then((response: any) => {
          const stakes = response.data.result
          window.srtmCommands.push({
            event: "track.user.login",
            payload: {
                action: "complete",
                userId: response.data.result.memberCode
              }
            });
          dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
          dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
          login(
            response.headers.authorization, response.data.result.memberCode,
            response.data.result.loginName, stakes.settings.presetStakeSettings,
            stakes.memberId, stakes?.settings?.preferences?.confirmation
          )
          setLoading(false)
        })
      },
      auto_select: false,
      ux_mode: 'popup'
    });
    const btn = document.createElement('div');
    btn.style.display = 'none';
    btn.classList.add('custom-google-button');
    console.log(btn);
    document.body.appendChild(btn);
    google.accounts.id.renderButton(btn, {
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      width: '100%',
    });
    const googleLoginWrapper = btn.querySelector("div[role=button]") as HTMLElement;
    setGoogleButtonWrapper(btn.querySelector("div[role=button]"))
    return {
      click: () => {
        googleLoginWrapper.click();
      }
    }
  }

  const handleSubmit = () => {
    console.log("submit");
  };
  if (!isOpenLoginPopUp) { return <></>; }

  const glogin = async () => {
    googleButtonWrapper.click();
    googleLoginWrapper.click();
}

  return createPortal(
    <div className="sing-up">
      {success && (
        <Snackbar open={success}
                  className="betslip_message betslip_message-success"
                  disableWindowBlurListener={true}
                  autoHideDuration={2000}
                  onClose={handleClose}
        >
          <Alert severity="success" onClose={handleClose}>
            {messegeOtp}
          </Alert>
        </Snackbar>
      )}
      <div className="sing-up_wrapper">
            <Paper
              variant="outlined"
              className="sing-up-form__alert"
              style={{ display: alert ? "flex" : "none" }}>
              <p>{error ? error : "Sign-up failed. Please try again!"}</p>
            </Paper>
        <div style={step === 3 ? {background:"#00A826"} : {}} className={cn("sing-up", {
                  "closed": animation,
                })}>
          <div className="sing-up-content">
            {<div className="sing-up-closeIcon"
              onClick={() => {
                setAnimation(true);
                setTimeout(() => {
                  dispatch(setIsOpenSignupPopUp(false));
                  setStep(1);
                  setAnimation(false);
                }, 300);
              }}>
              <svg
                width="21"
                height="21"
                viewBox="0 0 31 31"
                fill="#fff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#fff" />
                <path
                  d="M11.2045 10.0005C10.9659 10.0008 10.7328 10.0722 10.5349 10.2057C10.3371 10.3391 10.1835 10.5285 10.0938 10.7496C10.0042 10.9707 9.98244 11.2136 10.0314 11.4471C10.0804 11.6806 10.1979 11.8943 10.3689 12.0607L13.7205 15.4122L10.3689 18.7638C10.2538 18.8744 10.1618 19.0068 10.0985 19.1534C10.0351 19.2999 10.0017 19.4576 10.0001 19.6173C9.99844 19.7769 10.0287 19.9353 10.089 20.0831C10.1494 20.2309 10.2386 20.3652 10.3515 20.4781C10.4644 20.591 10.5987 20.6802 10.7465 20.7406C10.8943 20.8009 11.0527 20.8312 11.2123 20.8296C11.372 20.8279 11.5297 20.7945 11.6763 20.7311C11.8228 20.6678 11.9552 20.5758 12.0658 20.4607L15.4174 17.1091L18.7689 20.4607C18.8795 20.5758 19.012 20.6678 19.1585 20.7311C19.3051 20.7945 19.4628 20.8279 19.6224 20.8296C19.7821 20.8312 19.9405 20.801 20.0883 20.7406C20.2361 20.6803 20.3704 20.591 20.4833 20.4781C20.5962 20.3652 20.6854 20.2309 20.7457 20.0831C20.8061 19.9353 20.8363 19.7769 20.8347 19.6173C20.8331 19.4577 20.7996 19.2999 20.7363 19.1534C20.6729 19.0068 20.581 18.8744 20.4658 18.7638L17.1143 15.4122L20.4658 12.0607C20.6392 11.8921 20.7577 11.6751 20.8056 11.4381C20.8536 11.2011 20.8288 10.9551 20.7345 10.7324C20.6403 10.5097 20.481 10.3206 20.2774 10.19C20.0739 10.0595 19.8357 9.99339 19.5939 10.0005C19.2822 10.0098 18.9863 10.1401 18.7689 10.3638L15.4174 13.7154L12.0658 10.3638C11.9539 10.2487 11.8199 10.1573 11.672 10.0949C11.524 10.0325 11.3651 10.0004 11.2045 10.0005V10.0005Z"
                  fill="#00A826"
                />
              </svg>
            </div>}
            {step === 1 && <div className="sing-up-backIcon" onClick={() => {
              setStep(0);
              }}>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.58337 7H13.5M8.91671 1.5L13.7685 6.35182C14.1265 6.7098 14.1265 7.2902 13.7685 7.64818L8.91671 12.5" stroke="#F8F9FB" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </div>}
            {step === 2 && <div className="sing-up-backIcon" onClick={() => {
              setStep(1);
              }}>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.58337 7H13.5M8.91671 1.5L13.7685 6.35182C14.1265 6.7098 14.1265 7.2902 13.7685 7.64818L8.91671 12.5" stroke="#F8F9FB" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </div>}
            {step === 1 || step === 2 && <div onClick={() => {
                setStep(1);
                }}>
            </div>}
            {step !== 3 && <div className="sing-up-form-header">
              {(step === 1 || step === 2 || step === 0) &&  <div className="sign-up-form-header__logo">
                <img src={SignUpLogoIcon} alt="" />
              </div>}
              {(step === 1 || step === 2) ?
              <div className="animatedText">
                <div className="sing-up-form-header__text">Create an account</div>
                <div className="sing-up-form-header__description">Fill the following information step by step to create an account with us</div>
              </div>
              :
              <div className='animatedText1'>
                <div className="sing-up-form-header__text">Signup with </div>
                <div className="sing-up-form-header__description">Choose any one of the following options to signup</div>
              </div>}
            </div>}
            {step === 0 &&
              <div  className="signUpWith">
                <div className="signUpWith-container">
                  <div className="signUpWith-container-row" onClick={() => {
                      window.location.href = `https://funexch.authlink.me/?redirectUri=http://${window.location.host}`
                    }}>
                    <div className="iconTitle">
                      <img className="icon" src={WAIcon} alt="" />
                      <div className="title">WhatsApp</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>

                    {/* <GoogleLogin
                        onSuccess={async (credentialResponse: any) => {
                          if(credentialResponse) {
                          // @ts-ignore
                          const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
                          GeneralHandler.credentialResponse({
                            "token": credentialResponse?.credential,
                            "recaptchaResponse": recaptchaResponse,
                          }).then((response) => {
                            const stakes = response.data.result
                            window.srtmCommands.push({
                              event: "track.user.login",
                              payload: {
                                  action: "complete",
                                  userId: response.data.result.memberCode
                                }
                              });
                            dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
                            dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
                            login(
                              response.headers.authorization, response.data.result.memberCode,
                              response.data.result.loginName, stakes.settings.presetStakeSettings,
                              stakes.memberId, stakes?.settings?.preferences?.confirmation
                            )
                            setLoading(false)
                            GeneralHandler.getWithdrawalAccounts().then(acc => {
                                dispatch(setWithdrawalAccounts(acc));
                            }).catch(() =>
                              dispatch(setWithdrawalAccounts(null))
                            )
                            setAnimation(true);
                            setTimeout(() => {
                              dispatch(setIsOpenSignupPopUp(false));
                              setStep(1);
                              setAnimation(false);
                            }, 300);
                          })
                          }
                          console.log('google', credentialResponse)
                          }}
                          shape='rectangular'
                          onError={() => {
                            console.log('Login Failed');
                        }}
                      /> */}

                  <div className="signUpWith-container-row" onClick={() => {
                      glogin();
                      // dispatch(setIsOpenSignupPopUp(false));
                  }}>
                    <div className="iconTitle">
                      <img className="icon" src={GoogleIcon} alt="" />
                      <div className="title">Gmail</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>
                  <div className="signUpWith-container-row" onClick={() => setStep(1)}>
                    <div className="iconTitle">
                      <img className="icon" src={MobileIcon} alt="" />
                      <div className="title">Mobile Number</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>
                </div>
                <div className="signUpWith-redirect" onClick={() => {
                      console.log("loginfunc");
                      dispatch(setIsOpenSignupPopUp(false));
                      dispatch(setIsOpenLoginPopUp(true))
                  }}>
                  You already have an account? <span>Log in</span>
                </div>
              </div>
            }
            {step === 1 &&
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                if (!loading) {
                  clickNextButton(values.mobileNumber, values.countryCode);
                }
              }}
              validationSchema={Step1Shema}
            >
              {({ dirty, isValid, errors }) => {
                return (
                  <Form className="sing-up-form">
                    <div className="sing-up-form__content">
                      <div className="sing-up-form__content-text">Mobile number</div>
                      <FormikField
                        errors={errors}
                        onlyNumber={true}
                        name="mobileNumber"
                        label="(---) (---) (----)"
                        id="mobileNumber"
                        type="tel"
                        inputMode="numeric"
                      />

                      <CustomButton
                        className={!loading ? "sing-up-form__log-in-button arrow" : "sing-up-form__log-in-button loading"}
                        fullWidth={true}
                        type="submit"
                      >
                        Next
                      </CustomButton>
                       <div className="signUpDivider">
                        <div className="signUpDivider-line leftLine"></div>
                        <div className="signUpDivider-text">OR</div>
                        <div className="signUpDivider-line"></div>

                      </div>
                      <button
                          className="signUpWhatsAppNext "
                          type="submit"
                          onClick={() => {
                            window.location.href = `https://go.wa.link/funexchofficial`
                          }}
                        >
                          Get Instant ID on Whatsapp
                          <img className="signUpWhatsAppNext-img" src={WAIcon} alt="" />
                        </button>
                      <div className="sing-up-form__privacy-policy" >
                        <Checkbox checked disabled />
                        <div>
                        <span className="privacyText">This site is protected by reCAPTCHA and the Google. </span>
                        <span className="sing-up-form__privacy-policy--allocated"
                          onClick={() => {
                            setTimeout(() => {
                              dispatch(setIsOpenSignupPopUp(false))
                              setStep(1)
                              setAnimation(false)
                              history.push("/privacy-policy")
                            }, 300)
                          }}
                        >Privacy Policy</span>
                        <span className="privacyText"> and </span>
                        <span className="sing-up-form__privacy-policy--allocated"
                          onClick={() => {
                            setTimeout(() => {
                              dispatch(setIsOpenSignupPopUp(false))
                              setStep(1)
                              setAnimation(false)
                              history.push("/privacy-policy")
                            }, 300)
                          }}
                        >Terms of Service</span>
                        <span className="privacyText"> apply.</span>
                        </div>
                      </div>
                    </div>
                    <div className="signUpWith-redirect" onClick={() => {
                      console.log("loginfunc");
                      dispatch(setIsOpenSignupPopUp(false));
                      dispatch(setIsOpenLoginPopUp(true));
                    }}>You already have an account? <span>Log in</span>
                    </div>
                  </Form>
                );
              }}
            </Formik>}
            {step === 2 &&
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                if (!loading) {
                  clickSingupButton(values.countryCode, values.OTP1, values.OTP2, values.OTP3, values.OTP4, values.password, values.confirmPassword);
                }
              }}
              validationSchema={Step2Shema}
            >
              {({ dirty, isValid, errors }) => {
                return (
                  <Form className="sing-up-form">
                    <div style={{marginBottom: "10px", position: "relative"}} className="sing-up-form__content">
                      <div className="sing-up-form__content-otpTitle">We have sent a OTP to +91-{mobileNumber}</div>
                      <div className="sing-up-form__content-otpBox">
                        <div className="otpVerification">OTP Verification</div>
                        <div className="resendOTP">
                        <div
                          style={
                            seconds === 0
                              ? { textDecoration: "underline", cursor: "pointer" }
                              : {}
                          }
                          onClick={() => (seconds === 0 ? setSeconds(59) : null)}
                        >
                          <span
                            className="otpResent"
                            onClick={resendOTP}
                          >
                            Resend OTP
                          </span>
                          {minutes === 0 && seconds === 0 ? null : (
                            <span>
                              {" "}
                              in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                            </span>
                          )}
                        </div>
                      </div>
                      </div>
                      <div className="otpInputs">
                     <FormikField
                        errors={errors}
                        id="OTP1"
                        name="OTP1"
                        label=""
                        type="otp"
                        inputMode="numeric"
                      />
                      <FormikField
                        errors={errors}
                        id="OTP2"
                        name="OTP2"
                        label=""
                        type="otp"
                        inputMode="numeric"
                      />
                      <FormikField
                        errors={errors}
                        id="OTP3"
                        name="OTP3"
                        label=""
                        type="otp"
                        inputMode="numeric"
                      />
                      <FormikField
                        errors={errors}
                        id="OTP4"
                        name="OTP4"
                        label=""
                        type="otp"
                        inputMode="numeric"
                      />
                      </div>
                      {wrongOTP && <div className="wrongOTP">Wrong OTP</div>}
                      {expiredOTP && <div className="wrongOTP">Expired OTP</div>}
                      {weakPassword && <div className="wrongOTP">Please set a strong password.</div>}
                    </div>
                    <div className="sing-up-form__content">
                      <div className="sing-up-form__content-otherTitle">Other information</div>

                      <div className="password2step">Password</div>
                      <FormikField
                        errors={errors}
                        name="password"
                        label="Password"
                        type="text"
                        id="password"
                      />
                      <div className="password2step">Confirm Password</div>
                      <FormikField
                        errors={errors}
                        name="confirmPassword"
                        label="Confirm Password"
                        type="text"
                        id="confirmPassword"
                      />
                      <CustomButton
                        className={!loading ? "sing-up-form__log-in-button arrow" : "sing-up-form__log-in-button loading"}
                        fullWidth={true}
                        type="submit"
                        disabled={!dirty || !isValid}
                      >
                        Finish
                      </CustomButton>
                      <div className="sing-up-form__privacy-policy" >
                        <Checkbox checked disabled />
                        <div>
                        <span className="privacyText">This site is protected by reCAPTCHA and the Google. </span>
                        <span className="sing-up-form__privacy-policy--allocated"
                          onClick={() => {
                            setTimeout(() => {
                              dispatch(setIsOpenSignupPopUp(false))
                              setStep(1)
                              setAnimation(false)
                              history.push("/privacy-policy")
                            }, 300)
                          }}
                        >Privacy Policy</span>
                        <span className="privacyText"> and </span>
                        <span className="sing-up-form__privacy-policy--allocated"
                          onClick={() => {
                            setTimeout(() => {
                              dispatch(setIsOpenSignupPopUp(false))
                              setStep(1)
                              setAnimation(false)
                              history.push("/privacy-policy")
                            }, 300)
                          }}
                        >Terms of Service</span>
                        <span className="privacyText"> apply.</span>
                        </div>
                      </div>
                    </div>
                    <div className="signUpWith-redirect" onClick={() => {
                      console.log("loginfunc");
                      dispatch(setIsOpenSignupPopUp(false));
                      dispatch(setIsOpenLoginPopUp(true));
                    }}>You already have an account? <span>Log in</span>
                    </div>
                  </Form>
                );
              }}
            </Formik>}
          </div>
        {step === 3 &&
         <div className="signUpSuccess">
         <img className="signUpSuccess-img" src={successIcon} alt="" />
         <div className="signUpSuccess-title">Congratulation!</div>
         <div className="signUpSuccess-descr">We are pleased to inform you that you are added our one of the best customer into our application</div>
         <div className="signUpSuccess-descr">We request you to follow the following guide to understand, how to play the games and</div>
         <div className="signUpSuccess-buttons">
           <div className="signUpSuccess-buttons-button" onClick={() => {
             document.getElementById('celebrityBox')?.scrollIntoView({ behavior: 'smooth'})
             dispatch(setIsOpenSignupPopUp(false));
           }}>Introduction</div>
           <div className="signUpSuccess-buttons-button" onClick={() => {
             history.push('/user/deposit')
             dispatch(setIsOpenSignupPopUp(false));
             }}>Deposit</div>
           <div className="signUpSuccess-buttons-button" onClick={() => {
             history.push('/specialoffers')
             dispatch(setIsOpenSignupPopUp(false));
             }}>Offers</div>
         </div>
         <CustomButton
             className="sing-up-form__log-in-button"
             fullWidth={true}
             type="submit"
             onClick={() => {
               dispatch(setIsOpenSignupPopUp(false));
             }}
           >
             Skip
             {/* <ExitToAppIcon className="sing-up-form__log-in-button-icon" /> */}
             <img style={{margin: "0 -20px 0 20px"}} src={arrowIcon} alt="" />
           </CustomButton>
       </div>}
        </div>
        {recaptchaSiteKey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            size="invisible"
          />
          )}
      </div>
    </div>,
    // @ts-ignore
    modalRoot,
  );
};

export default SignupPopUp;
