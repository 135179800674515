import { MetaPages } from "./meta-types";

export const MetaData = {
  [MetaPages.SPORTS_CRICKET] : {
    title:  "Indian Cricket Betting Websites | Today Online Cricket Betting",
    meta: [
      {
        name: "keywords",
        content: "Indian Cricket Betting Websites Today Online Cricket Betting",
      },
      {
        name: "description",
        content: "Explore top Indian cricket betting website for today's online cricket betting. Bet on your favorite matches with confidence.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/4",
      },
    header: "Indian Cricket Betting Websites for Today's Online Cricket Betting",
  },
  [MetaPages.SPORTS_TENNIS] : {
    title:  "Tennis Betting Online | Best Tennis Prediction Site FunExch",
    meta: [
      {
        name: "keywords",
        content: "best tennis betting online best tennis prediction site",
      },
      {
        name: "description",
        content: "Tennis betting online is now more easy and reliable! Place your bet on tennis matches at best tennis prediction site, explore today's betting games at FunExch",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/2",
      },
    header: "Best Tennis Betting Online & Premier Tennis Prediction Site",
  },
  [MetaPages.SPORTS_FOOTBALL] : {
    title:  "Football Betting Online India | Football Live Bet at FunExch",
    meta: [
      {
        name: "keywords",
        content: "football betting online India football live bet",
      },
      {
        name: "description",
        content: "Live football betting online India, Win real cash on live football match. Explore the best football online betting site India, Start football prediction game today!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/1",
      },
    header: "Football Betting Online, Place Your Bet on Live Matches",
  },
  [MetaPages.CASINOS] : {
    title:  "Online Casino Win Real Money | Real Slots Online | Play & Win!",
    meta: [
      {
        name: "keywords",
        content: "online casino real money real slots online online roulette gambling",
      },
      {
        name: "description",
        content: "Play real slots online roulette gambling and win real money, Explore live Casino games at FunExch, Get 6% Extra on every deposit! Play and win big!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/casino-game/",
      },
    header: "Online Casino Win Real Money, Join and Get 6% Extra on Every Deposit",
  },
  [MetaPages.AVIATOR] : {
    title:  "Online Aviator Betting Game India | Best Aviator Game App",
    meta: [
      {
        name: "keywords",
        content: "online aviator betting game india best aviator game app in india",
      },
      {
        name: "description",
        content: "Experience the ✈️ Aviator Game online - simple, exciting, and fair gameplay with demo mode, Play to win big at the best aviator game app in india!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/card-game/67722-2/FAWK",
      },
    header: "Play Aviator Game Online! Live Demo",
  },
  [MetaPages.HOME] : {
    title:  "FunExch - India's Biggest Online Casino & Sportsbook",
    meta: [
      {
        name: "keywords",
        content: "Online Casino India, sports betting game",
      },
      {
        name: "description",
        content: "Register Now At FunExch, India's Biggest Online Casino & Sportsbook. 1000+ Live Casino And Sports Betting Games. Sign Up And Start Your Winning!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/4",
      },
    header: "India's Biggest Online Casino & Sports Betting Website",
  },
  [MetaPages.POLITICS] : {
    title:  "Daily news on politics, sports, & current affairs at best betting site",
    meta: [
      {
        name: "keywords",
        content: "Sports news, Current affairs",
      },
      {
        name: "description",
        content: "Discover the latest in sports news, current affairs, and politics in India. Explore our online sports betting site for more information.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/2378962",
      },
  },
  [MetaPages.SPORTS_HORSERACING] : {
    title:  "Online Horse Race Betting in India | Racing Odds Today | Play @FunExch",
    meta: [
      {
        name: "keywords",
        content: "Online horse race betting in India, racing odds today",
      },
      {
        name: "description",
        content: "Discover the excitement of online horse race betting in India on FunExch. Experience the thrill of the race with the latest racing odds to win big.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/7",
      },
  },
  [MetaPages.SPORTS_GREYHOUND] : {
    title:  "Greyhound Racing | Live Greyhound Racing | Play to win @FunExch",
    meta: [
      {
        name: "keywords",
        content: "Greyhound racing, Live greyhound racing",
      },
      {
        name: "description",
        content: "Bet on live Greyhound racing events and enjoy the excitement of Greyhound racing. Place your bets and see your favorite dogs sprint to the finish line!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/sports/4339",
      },
  },
  [MetaPages.LIVE_CASINO_PAGE] : {
    title:  "Play, Win, Repeat at the Best Live Casino! | Live Casino Real Money ",
    meta: [
      {
        name: "keywords",
        content: "Live casino real money, Best live casino games in india",
      },
      {
        name: "description",
        content: "Play with live casino real money and immerse yourself in dealer games. Win big and repeat the excitement with the best live casino experience - FunExch!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/live-casino-page/17",
      },
  },
  [MetaPages.CARD_GAMES] : {
    title:  "Play, Win, and Repeat at the Best Indian Card Games on FunExch",
    meta: [
      {
        name: "keywords",
        content: "Online teen patti, Poker online free",
      },
      {
        name: "description",
        content: "Join FunExch to win big playing online Teen Patti and Poker. Get ready to challenge yourself or other players, while playing online Indian card games!",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/card-games/live",
      },
    header: "",
  },
  [MetaPages.SLOTS_PAGE] : {
    title:  "Spin and Win Real Money at the Best Live Slots on FunExch",
    meta: [
      {
        name: "keywords",
        content: "live slots real money, online slots real money",
      },
      {
        name: "description",
        content: "Indulge in the adventure of online slots for real money. Spin the reels, collect massive wins, & enjoy the victory at live slots for real money.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/home/slots-page/51",
      },
    header: "",
  },
  [MetaPages.USER_BONUS] : {
    title:  "Maximize Your Winnings with the Best Online Betting Offers at FunExch",
    meta: [
      {
        name: "keywords",
        content: "Best online betting, Online betting in India",
      },
      {
        name: "description",
        content: "Explore a wide range of bonuses designed for Indian players on the best online betting site. Experience the excitement of online betting in India.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/specialoffers",
      },
    header: "",
  },
  [MetaPages.SPORTSBOOK] : {
    title:  "Play More, Win More, at  the Best Online Sports Betting Site: FunExch",
    meta: [
      {
        name: "keywords",
        content: "Best online betting sites, online sports bettting",
      },
      {
        name: "description",
        content: "Discover a new world of online sports betting with our sportsbook. Bet on all your favorite sports and regain your winning streak on every game.",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/sportsbook",
      },
    header: "",
  },
  [MetaPages.BLOG] : {
    title:  "Expert Match Reviews & Sports Predictions! | Top Sports News | FunExch",
    meta: [
      {
        name: "keywords",
        content: "sports headlines, top sports news",
      },
      {
        name: "description",
        content: "Get an edge in sports betting with updated sports headlines from our sports blog. Make informed betting decisions with the top sports news. ",
      },
    ],
    link:
      {
        rel: "canonical",
        href: "https://www.funexch.com/blog",
      },
    header: "",
  },
  // old
  [MetaPages.ACCOUNT_STATEMENT] : {
    title:  "FunExch  - AccountStatement",
    meta: [
      {
        name: "description",
        content: "Access your account statement on our gambling and casino website. Review your financial transactions, including deposits, withdrawals, and bonuses, for a complete overview of your gaming activity.",
      },
    ],
  },
  [MetaPages.BET_HISTORY]: {
    title:  "FunExch - Betting",
    meta: [
      {
        name: "description", content: "Explore your betting history on our gambling and casino platform. Track your wagers, wins, and losses for a comprehensive record of your betting activity.",
      },
    ],
  },
  [MetaPages.SET_BUTTON_VALUES]: {
    title:  "FunExch - SetButtonValues",
    meta: [
      {
        name: "description", content: "Customize button values on our website. Tailor your user experience by setting button values according to your preferences.",
      },
    ],
  },
  [MetaPages.CHANGE_PASSWORD]: {
    title:  "FunExch - ChangePassword",
    meta: [
      {
        name: "description", content: "Securely update your password on our website. Protect your account with a new, strong password for enhanced security and peace of mind.",
      },
    ],
  },
  [MetaPages.UNSETTLED_BET]: {
    title: "FunExch - UnsettledBets",
    meta: [
      {
        name: "description", content: "View your unsettled bets on our platform. Monitor your open wagers and pending outcomes to stay up to date with your betting activity.",
      },
    ],
  },
  [MetaPages.DEPOSIT]: {
    title:  "FunExch - Deposit",
    meta: [
      {
        name: "description", content: "Make a deposit securely on our website. Add funds to your account to enjoy exciting gaming and betting experiences.",
      },
    ],
  },
  [MetaPages.TRANSACTIONS]: {
    title:  "FunExch - Transactions",
    meta: [
      {
        name: "description", content: "View your transaction history on our online casino and betting site. Explore your deposits, withdrawals, and bonuses to ensure a transparent and secure gaming experience.",
      },
    ],
  },
  [MetaPages.WITHDRAWAL]: {
    title:  "FunExch - Withdrawal",
    meta: [
      {
        name: "description", content: "Initiate secure withdrawals on our website. Access your winnings and funds with ease, ensuring a seamless financial experience.",
      },
    ],
  },
  [MetaPages.ROUND_RESULTS]: {
    title:  "FunExch - RoundResults",
    meta: [
      {
        name: "description", content: "Discover the outcomes of recent rounds on our platform. Review the results of games and bets to stay informed about your gaming history.",
      },
    ],
  },
  [MetaPages.BONUS_STATEMENT]: {
    title:  "FunExch - BonusStatement",
    meta: [
      {
        name: "description", content: "Check your bonus statement on our gambling and casino site. Review your bonus history, rewards, and offers to maximize your gaming experience.",
      },
    ],
  },
  [MetaPages.TRANSFER_STATEMENT]: {
    title:  "FunExch - TransferStatement",
    meta: [
      {
        name: "description", content: "Review your transfer statement on our website. Explore your financial transactions, transfers, and account balances for a comprehensive overview of your financial activity.\"",
      },
    ],
  },












};
