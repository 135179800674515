import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";

interface GetTimeProps {
  timeZoneName: string;
  withTitles?: boolean;
}

const GetTime: React.FC<GetTimeProps> = ({ timeZoneName, withTitles = false }) => {
  const [currentTime, setCurrentTime] = useState<Moment>(moment());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(moment());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const formatTime = (time: number) => (time < 10 ? `0${time}` : time);

  const getTimeByTimeZone = (): JSX.Element => {
    let time: Moment;

    if (timeZoneName === "system") {
      time = currentTime.utc();
    } else if (timeZoneName === "device") {
      time = currentTime;
    } else {
      time = currentTime.utcOffset(330);
    }

      return (
        <div className="date-small">
          {withTitles && <span>Date: </span>}
        {time.format("MMM Do, YYYY")} {" - "}
          {withTitles && <span>Time: </span>}
          <span className="date-big">
          {`${formatTime(time.hours())}:${formatTime(time.minutes())}:${formatTime(time.seconds())}`}
          </span>
        </div>
    );
  };

  return getTimeByTimeZone();
};

export default GetTime;
