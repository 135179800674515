import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";

interface Props {
    runner: any;
    isBack: boolean;
    border?: string;
}

export const OddBtn = ({ runner, isBack, border }: Props) => {
    const [oldPrice, setOldPrice] = useState<null | number>()
    const [isChanged, setIsChanged] = useState(false)
    const history = useHistory()


    //price :number or null
    let  newPrice: number | null
    if (isBack) {
        newPrice = runner && [...runner.back].sort((a, b) => { return a.price < b.price ? 1 : -1 })[0]?.price;
    } else {
        newPrice = runner && [...runner.lay].sort((a, b) => {  return a.price > b.price ? 1 : -1 })[0]?.price;
    }

    useEffect(() => {
        if (newPrice !== oldPrice) {
            setIsChanged(true);
            setTimeout(() => {
                setIsChanged(false);
            }, 300)
        }

        setOldPrice(newPrice)
        return () => {
            setOldPrice(null)
          };
    })

    const styleBtn = {
        backgroundColor: isChanged ? '#ffffe2' : '',
        borderRadius: border ? border : ''
    }

    return (
        history.location.pathname.includes('home') ?
        <div className={`eventCard_marketButtons_item-${isBack ? 'back' : 'lay'}`} style={styleBtn}>
            <span className="game-table__body-result-first-back" >
                {newPrice ? newPrice : ' - '}
            </span>
        </div>
        :
        <span className="game-table__body-result-first-back" style={styleBtn} >
            {newPrice ? newPrice : ' - '}
        </span>
    )
}
