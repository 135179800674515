import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface EventsList {
  result: []
  status: {}
  success: boolean
  cachedData?: null
  id?: string
}

const INIT_EVENTS = 'INIT_EVENTS';
const CACHE_EVENTS = 'CACHE_EVENTS';


type InitEventsAction = Action<typeof INIT_EVENTS> & {
  Events: EventsList;
};

type CacheEventsAction = Action<typeof CACHE_EVENTS> & {
  cachedData: EventsList;
  id: string;
};

export const initEvents = (Events: EventsList): InitEventsAction => ({
  type: INIT_EVENTS,
  Events,
});

export const cacheEvents = (cachedData: EventsList, id: string): CacheEventsAction => ({
  type: CACHE_EVENTS,
  cachedData,
  id
});


// type AlowwedActions = InitEventsAction;

const EventsReducer = (Events: EventsList =  {} as EventsList, action: AnyAction): EventsList => {
  switch (action.type) {
    case INIT_EVENTS:
      return {...Events, ...action.Events};
    case CACHE_EVENTS:
      return {...Events, cachedData : action.cachedData, id : action.id}
    default:
      return Events;
  }
};

export default EventsReducer;
