import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface HeaderTabInterface {
  headerTab: any
}

const uiInitialState: HeaderTabInterface = {
  headerTab: false,
}

const headerTabSlice = createSlice({
  name: "headerTab",
  initialState: uiInitialState,
  reducers: {
    setHeaderTab: (state, { payload }: PayloadAction<any>) => {
      state.headerTab = payload
    },
  }
})

export const { setHeaderTab } = headerTabSlice.actions

export default headerTabSlice.reducer
