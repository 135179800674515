import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

const useTime = (timezone) => {
    const reduxTimezone = useSelector((state) => state.timeSettings.timeZone);
    const selectedTimezone = timezone || reduxTimezone;

    const getTime = (date) => {
        let zonedDate = date ? new Date(date) : new Date();
        zonedDate.setMinutes(zonedDate.getMinutes() + zonedDate.getTimezoneOffset());
        const [hours, minutes] = selectedTimezone.split(':').map(Number);
        zonedDate.setHours(zonedDate.getHours() + hours);
        zonedDate.setMinutes(zonedDate.getMinutes() + minutes);
        return zonedDate;
    };

    const [today, setToday] = useState(getTime());

    const setDay = (day) => {
        const date = new Date(today);
        date.setDate(date.getDate() + day);
        return getTime(date);
    };

    const memoizedZonedTime = useMemo(() => getTime(new Date()), [selectedTimezone]);


    useEffect(() => {
        setToday(memoizedZonedTime);
    }, [memoizedZonedTime]);

    return {
        today,
        getTime,
        setDay
    };
};

export default useTime;

