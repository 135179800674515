import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface SidemenuList {
  type: string;
  name: string;
  id: string;
  children: [];
  sortOrder: number;
}

const INIT_SIDE_MENU = 'INIT_SIDE_MENU';

type InitSideMenuAction = Action<typeof INIT_SIDE_MENU> & {
  sideMenu: SidemenuList[];
};

export const initSideMenu = (sideMenu: SidemenuList[]): InitSideMenuAction => ({
  type: INIT_SIDE_MENU,
  sideMenu,
});

// type AlowwedActions = InitSideMenuAction;

const SideMenuReducer = (sideMenu: SidemenuList[] = [], action: AnyAction): SidemenuList[] => {
  switch (action.type) {
    case INIT_SIDE_MENU:
      return action.sideMenu;
    default:
      return sideMenu;
  }
};

export default SideMenuReducer;
