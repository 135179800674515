import { useState, memo, useEffect } from "react"; // Add useEffect
import { useMediaQuery } from "@material-ui/core";
import Carousel from "nuka-carousel/lib/carousel";
import { createPortal } from "react-dom";
import closeIcon from "../../../static/images/globalrules/rulesCloseIcon.svg";
import richa1 from "../../../static/images/icons/richa1 (1).webp";
// import sapna1 from "../../../static/images/icons/sapna1 (1).webp";
// import richa2 from "../../../static/images/icons/richa2 (1).webp";
import sapna2 from "../../../static/images/icons/sapna2 (1).webp";
import surbhi from "../../../static/images/icons/surbhi1 (1).webp";
import poonam1 from "../../../static/images/icons/poonam1.webp";
import Ravi from "../../../static/images/icons/Ravi.webp";
// import poonam2 from "../../../static/images/icons/poonam 2.webp";
import raza1 from "../../../static/images/icons/raza murad 1.webp";
// import raza2 from "../../../static/images/icons/raza murad 2.webp";
// import hina1 from "../../../static/images/icons/heena1.webp";
import hina2 from "../../../static/images/icons/heena2.webp";
import meet1 from "../../../static/images/icons/meet bros 1.webp";
// import meet2 from "../../../static/images/icons/meet bros 2.webp";
import arjun from "../../../static/images/icons/arjun.webp";
import ankita from "../../../static/images/icons/Ankita.webp";
import arjun2 from "../../../static/images/icons/Arjun 2.webp";
import monalisa from "../../../static/images/icons/Monalisa.webp";
import sophie from "../../../static/images/icons/Sophie.webp";
import namrita from "../../../static/images/icons/Namrita.webp";

const VideoFrame = memo(({ close, url }: any) => {
  console.log("🚀 ~ file: Celebrity.tsx:60 ~ VideoF:", url);

  const modalRoot = document.querySelector("#modal-root") as HTMLElement;

  return createPortal(
    url && (
      <div className="iframeVideoBG" onClick={() => close()}>
        <div className="iframeVideo">
          <img  loading="lazy" className="iframeVideoBG_closeIcon" src={closeIcon} alt="X" />
          <iframe src={url} className="iframeVideo" allow="autoplay; picture-in-picture"></iframe>
        </div>
      </div>
    ),
    modalRoot
  );
});


export const Celebrity = () => {
  const isPhone = useMediaQuery("all and (max-width: 1024px)");
  const [openVideo, setOpenVideo] = useState("");
  const [userInteracted, setUserInteracted] = useState(false); // Track user interaction

  useEffect(() => {
    // Reset user interaction flag when the video is closed
    if (!openVideo) {
      setUserInteracted(false);
    }
  }, [openVideo]);

  const closeIframe = () => {
    setOpenVideo("");
  };

  const videos = [
    { src: "https://player.vimeo.com/video/874054488", image: ankita },
    { src: "https://player.vimeo.com/video/880145306", image: Ravi },
    { src: "https://player.vimeo.com/video/874054750", image: monalisa },
    { src: "https://player.vimeo.com/video/874072831", image: namrita },
    { src: "https://player.vimeo.com/video/874054690", image: arjun2 },
    { src: "https://player.vimeo.com/video/874054919", image: sophie },
    { src: "https://player.vimeo.com/video/861969797", image: arjun },
    // { src: "https://player.vimeo.com/video/858928735", image: hina1 },
    { src: "https://player.vimeo.com/video/858928763", image: hina2 },
    { src: "https://player.vimeo.com/video/860617077", image: meet1 },
    // { src: "https://player.vimeo.com/video/860616968", image: meet2 },
    { src: "https://player.vimeo.com/video/854359581", image: surbhi },
    { src: "https://player.vimeo.com/video/855736601", image: poonam1 },
    // { src: "https://player.vimeo.com/video/855720796", image: poonam2 },
    // { src: "https://player.vimeo.com/video/852679839", image: sapna1 },
    { src: "https://player.vimeo.com/video/852668305", image: richa1 },
    { src: "https://player.vimeo.com/video/852696950", image: sapna2 },
    // { src: "https://player.vimeo.com/video/852668489", image: richa2 },
    { src: "https://player.vimeo.com/video/857474183", image: raza1 },
    // { src: "https://player.vimeo.com/video/857474282", image: raza2 },
  ];

  return (
    <>
      <div className={`home-games `}>
        <div className="global-desktop-container">
          <div className="home-games__header-celebrity" id='celebrityBox'>
            <div className="home-games__header-info-wrapper-celebrity">
              <div className="home-games__header-title">Celebrity Videos</div>
              <div className="home-games__header-info">Let's have a look what celebrities say about FunExch!</div>
              <div className="home-games__header-info-desk">Let's have a look what celebrities say about FunExch!</div>
            </div>
          </div>

          <div className="carousel-container">
            <Carousel
              dragging
              swiping
              slidesToShow={isPhone ? 3 : 5}
              withoutControls
              wrapAround
              autoplay={!userInteracted}
              autoplayInterval={3000}
              afterSlide={() => setUserInteracted(false)}
            >
              {videos.map((item, index) => (
                <div className="video-slide" key={index}>
                  <div className="playVideoIcon" onClick={() => setOpenVideo(item.src)}></div>
                  <img loading="lazy" src={item.image} alt={`Video ${index}`} />
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
      {openVideo && <VideoFrame close={closeIframe} url={openVideo} />}
    </>
  );
};
