import { useMediaQuery } from "@material-ui/core";
import cn from "classnames";
import { FC, memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearIntervalAsync, setIntervalAsync } from "set-interval-async/dynamic";
import { GeneralHandler } from "../../api/general";
import { setMessages, setNotificationPopUp } from "../../redux/menu/notification.slice";
import "./index.scss";

const Ticker: FC = memo(() => {
  const isPhone = useMediaQuery("all and (max-width: 1024px)");
  const { messages } = useSelector((state: IRootState) => state.notification);
  const [notificationClosed, setNotificationClosed] = useState(false);
  const dispatch = useDispatch();
  const startTicker = messages.length > 0;
  const tickerRef = useRef<HTMLDivElement>(null);
  const tickerPhoneRef = useRef<HTMLDivElement>(null);
  const {
    user: { isAuthenticated },
  } = useSelector((state: IRootState) => ({ user: state.user }));

  useEffect(() => {
    if (isAuthenticated) {
      GeneralHandler.getMessage().then(list => {
        const messageList = list;
        dispatch(setMessages(messageList));
      });
    }

    const msg = isAuthenticated ? setIntervalAsync(async () => {
      await GeneralHandler.getMessage().then(list => {
        dispatch(setMessages(list));
      });
    }, 120000) : null;

    return () => {
      if (msg) {
        clearIntervalAsync(msg);
      }
    };
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      GeneralHandler.getMessage().then(list => {
        dispatch(setMessages(list));
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (startTicker) {
      setTimeout(() => {
        const tickerBlockWidth = Number(tickerRef?.current?.parentElement?.clientWidth);
        const tickerPhoneBlockWidth = Number(tickerPhoneRef?.current?.parentElement?.clientWidth);
        const speedRate = 15;
        const distance = Number(tickerRef?.current?.clientWidth) + tickerBlockWidth;
        const duration = distance * speedRate;
        moveTicker(tickerRef.current, distance, duration);
        const distancePhone = Number(tickerPhoneRef?.current?.clientWidth) + tickerPhoneBlockWidth;
        const durationPhone = distancePhone * speedRate;
        moveTicker(tickerPhoneRef.current, distancePhone, durationPhone);
      }, 2000);
    }
  }, [startTicker]);

  const moveTicker = (tickerElement: any, distance: number, duration: number) => {
    const direction = "left";
    let isNegated = true;

    if (direction) {
      isNegated = true;
      distance *= -1;
    } else {
      isNegated = false;
    }

    let elStyle = window?.getComputedStyle(tickerElement);
    let value = elStyle.getPropertyValue(direction).replace("px", "");
    const destination = Number(value) + distance;
    const frameDistance = distance / (duration / 10);

    const move = () => {
      elStyle = window?.getComputedStyle(tickerElement);
      value = elStyle.getPropertyValue(direction).replace("px", "");
      const newLocation = Number(value) + frameDistance;
      const beyondDestination = ((!isNegated && newLocation >= destination) || (isNegated && newLocation <= destination));

      if (beyondDestination) {
        tickerElement.style[direction] = "100%";
      } else {
        tickerElement.style[direction] = newLocation + "px";
    }
    };
    setInterval(move, 10);
  };

  return ( !!messages.length && !!messages?.[0]?.text && !notificationClosed)  ?  (
    <div className={cn("ticker-wrapper")}>
      <div className="ticker-body">

        <div className={`ticker ${isPhone && "phone"}`} onClick={() => dispatch(setNotificationPopUp(true))}>
          <div className="ticker__line" ref={tickerRef}>
            {messages.map((item, index) => (
              <span className={(index >= 1) ? "ticker__item ticker__item--next" : "ticker__item"}
                    key={"messagesItems" + index}>
              {item.text}
            </span>
            ))}

          </div>
        </div>
        <div className="header_notificationBox-close" onClick={() => {
          dispatch(setMessages([]));
          setNotificationClosed(true);
        }}>&#10005;</div>
      </div>
    </div>
  ) : <></>;
});

export default Ticker;
