import { useState, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { GeneralHandler } from "../../../api/general"
import { setServerTimeSuccess } from "../../../redux/menu/timeSettings.slice"
import GetTime from "../GetTime"
import RenderTimeList from "../RenderTimeList"
import timeImg from "../../../static/images/header/time.svg"
import "./styles/index.scss"

const TimeSettings = () => {
  const { timeZoneName } = useSelector((state: IRootState) => state.timeSettings)
  const [timeUTC, setTimeUTC] = useState(false)
  const [gmtDevice, setGmtDevice] = useState("")
  const [showTime, setShowTime] = useState("")
  const dispatch = useDispatch()
  const onTimeUTC = () => {
    setTimeUTC(!timeUTC)
  }

  useEffect(() => {
    GeneralHandler.getServerTime().then((res) => dispatch(setServerTimeSuccess(res)))
    const now = new Date()
    const offset1 = `${now}`
      .replace(
        /^[\w]+ [\w]+ [0-9]{2} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} ([\w]+)([+-][0-9]{2})([0-9]{2})()/,
        "$1 $2:$3$4"
      )
      .replace(/^([\w]+ [+-][0-9]{2}:[0-9]{2}) (.*)/, "$1")

    setGmtDevice(offset1)

    const offset2 = `${now}`
      .replace(
        /^[\w]+ [\w]+ [0-9]{2} [0-9]{4} [0-9]{2}:[0-9]{2}:[0-9]{2} ([\w]+)([+-][0-9]{2})([0-9]{2})()/,
        "$1 $2:$3$4"
      )
      .replace(/^([\w]+ [+-][0-9]{2}:[0-9]{2}) (.*)/, "$1")
      .slice(3, 11)

    setShowTime(offset2)
  }, [])

  // close time dropdown after click somewhere
  const contentDateRef = useRef(null)
  const closeTime = (e: any) =>
    !e.path?.includes(contentDateRef.current) ? setTimeUTC(false) : ""

  useEffect(() => {
    if (timeUTC) {
      window.addEventListener("click", closeTime)
      return () => window.removeEventListener("click", closeTime)
    }
    return () => window.removeEventListener("click", closeTime)
  }, [timeUTC])
  // END close time dropdown after click somewhere

  return (
    <div
      className="time-settings"
      onClick={() => {
        onTimeUTC()
      }}
      ref={contentDateRef}
    >
      <img className="time-settings__img" src={timeImg} alt="timeImg" />
      <GetTime timeZoneName={timeZoneName} />
      <RenderTimeList timeUTC={timeUTC} gmtDevice={gmtDevice} showTime={showTime} />
    </div>
  )
}

export default TimeSettings
