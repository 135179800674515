import { createPortal } from "react-dom"
import cn from "classnames"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setNotificationPopUp } from "../../../redux/menu/notification.slice"
import CloseIcon from "../../../static/images/header/closeWhite.svg"
import "./index.scss"

const modalRoot = document.querySelector("#modal-root")

export const NotificationPopUp = () => {
  const [isClosed, setIsClosed] = useState(true)
  const dispatch = useDispatch()
  const { messages } = useSelector((state: IRootState) => state.notification)

  useEffect(() => {
    setIsClosed(false)
  }, [])

  return createPortal(
    <>
      {
        <div
          className="notificationPopUp__popUpMobile"
          onClick={e => {
            setIsClosed(true)
            setTimeout(() => {
              setIsClosed(false)
              dispatch(setNotificationPopUp(false))
            }, 300)
          }}
        >
          <div
            className={cn("notificationPopUp", {
              closed: isClosed
            })}
          >
            <div className="notificationPopUp_header">
              <div className="notificationPopUp_header-box">
              <span>All notification</span>
              <img
                src={CloseIcon}
                alt=""
                onClick={e => {
                  setIsClosed(true)
                  setTimeout(() => {
                    setIsClosed(false)
                    dispatch(setNotificationPopUp(false))
                  }, 300)
                }}
                />
                </div>
            </div>
            <div className="notificationPopUp_content">
             { messages.map((item: any) => {

              return (<div className="notificationPopUp_content_item">
                {item.text}
              </div>)
              })
              }
            </div>
          </div>
        </div>
      }
    </>,
    // @ts-ignore
    modalRoot
  )
}
