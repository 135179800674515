import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { ItemsChildrenList } from "./children"
import { useDispatch, useSelector } from "react-redux";
import "./styles/index.scss"
import { setIsOpenLoginPopUp } from "../../../../redux/UI/openPopUps.slice"

export interface ItemsList {
  type: string;
  name: string;
  id: string;
  countryCode?: string;
  startTime?: string;
  altType?: string;
  children?: ItemsChildrenList[] | undefined;
  altName?: string;
  altId?: string
}

export interface Iprops {
  name: string;
  list: ItemsList[] | undefined
  sportId: string
  itemId?: string
}

export const Item = ({ name, list, sportId , itemId} : Iprops): JSX.Element => {
  const [menuOpen, setmenuOpen] = useState(false)
  const [ link, setLink ] = useState<string | undefined>("")
  const { isAuthenticated } = useSelector((state: IRootState) => state.user)
  const dispatch=useDispatch()
  // if (!isAuthenticated) {
  //   dispatch(setIsOpenLoginPopUp(true))
  //   return
  // }
  // dispatch(setIsOpenPopUp(true))
  useEffect(() => {
    if (sportId === '1444001') {
      setLink(`/card-game/${itemId}`)
    } else if (sportId === '1444005') {
      setLink(`/sportsbook`)
    } else {
      setLink(`/sport-event-detail/${itemId}`)
    }
  }, [])
  return (
    <>
    {list && name !== 'Sportsbook' && (list.length !==0)
    ? (
      <>
        <div
            className={`menu-section__list-item-title ${list && (list.length !==0) ? "full" : "" } ${menuOpen ? "menu-section__list-item-title--active" : ""}`}
            onClick={() => setmenuOpen((prevValue) => !prevValue)}
          >
            {name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')}
        </div>
      </>
    )
    : name !== 'Sportsbook' ? (
      <>
        <div
          className={`menu-section__list-item-title ${list && (list.length !==0) ? "full" : "" } ${menuOpen ? "menu-section__list-item-title--active" : ""}`}
        >
          {
            !isAuthenticated
            ? <div onClick={()=>dispatch(setIsOpenLoginPopUp(true))}>
            {name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')}
          </div>
            : <Link
            to={{
              pathname: `${link}`,
              state: {
                sportId: sportId,
                eventName: name
              }
            }}
          >
            {name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')}
          </Link>
          }

        </div>
      </>
    ) : (null)}
      {list && (
        <ul className="menu-section__list-item-children" >
        {menuOpen && (
          <>
            {list.map((item) => {
              if (item.name !== "Sportsbook"){
                return (
                  <li key={item.id} >
                    <Item name={item.name} list={item.children} sportId={sportId} itemId={item.id}/>
                  </li>
                )
              } else {
                return
              }
              }
            )}
          </>
        )}
      </ul>
      )}
    </>
  )
}

