import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface balanceItem {
  availBalance: number;
  creditLimit: number;
  deductedExposure: number;
  lastActivityDate: number;
  memberCode: string;
  totalBalance: number;
  useBWallet?: boolean;
  useSWallet?: boolean;
  useCWallet?: boolean;
  bWallet?: any;
  sWallet?: any;
  cWallet?: any;
  balanceWithoutBonus?: any;
  bonusAmount?: any;
}

const INIT_BALANCE = 'INIT_BALANCE';

type InitBalanceAction = Action<typeof INIT_BALANCE> & {
  balance: balanceItem;
};

export const initBalance = (balance: balanceItem ): InitBalanceAction => ({
  type: INIT_BALANCE,
  balance,
});

// type AlowwedActions = InitBalanceAction;

const BalanceReducer = (balance: balanceItem = {} as balanceItem, action: AnyAction): balanceItem => {
  switch (action.type) {
    case INIT_BALANCE:
      return action.balance;
    default:
      return balance;
  }
};

export default BalanceReducer;
