import { useMediaQuery } from "@material-ui/core";
import { memo, useEffect } from "react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GeneralHandler } from "../../api/general";
import { setGlobalRules } from "../../redux/UI/openPopUps.slice";
import infoIcon from "../../static/images/globalrules/infoIcon.svg";
import globalRulesCloseIcon from "../../static/images/globalrules/rulesCloseIcon.svg";
import { DesktopRuleText } from "./helpers/desktoptext";
import "./styles/index.scss";

interface IrulesActiveItem {
  id: string;
  name: string;
  rules: any[];
}

export const GlobalRulesPopUp = memo(() => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery("all and (max-width: 851px)");
  const isOpenPopUp = useSelector((state: IRootState) => state?.openPopUps?.globalRules?.isOpenPopUp);
  const data = useSelector((state: IRootState) => state?.openPopUps?.globalRules?.data);

const headerRef = useRef<HTMLDivElement>(null);

  const [rulesActiveItem, setRulesActiveItem] = useState<IrulesActiveItem>(
    {id: "1",
              name: "Football Fancy",
              rules: [],
            });

  useEffect(() => {
    data?.length > 0 && setRulesActiveItem(data?.[0]);
  }, [data]);

  useEffect(() => {
    if(headerRef){
      headerRef?.current?.scrollIntoView({behavior: "smooth"});
    }
  }, [rulesActiveItem]);

  useEffect(() => {
    GeneralHandler.getGeneralRules().then(res => {
      dispatch(setGlobalRules({data :res.result}));
    });
  }, []);

  if (!isMobile) {
    return (
      <div className={`${isOpenPopUp ? "global-rules-popup active" : "global-rules-popup"}`} onClick={() => { dispatch(setGlobalRules({isOpenPopUp: false})); }}>
        <div className={`${isOpenPopUp ? "global-rules-popup-content active" : "global-rules-popup-content"}`} onClick={e => e.stopPropagation()}>
          <div className="global-rules-popup-header" ref={headerRef}>
            <div className="global-rules-popup-header__text">
              <img src={infoIcon} alt="info" className="globalRulesInfoIcon" />
              <span>Rules</span>
            </div>
            <button className="global-rules-popup-header__close" onClick={(() => dispatch(setGlobalRules({isOpenPopUp: false})))}>
              <img src={globalRulesCloseIcon} alt="close" className="globalRulesClose" />
            </button>
          </div>
          <div className="global-rules-popup-container">
            <div className="global-rules-popup-container__rules-list-wrapper">
              <ul className="global-rules-popup-container__rules-list">
                {data?.length && [...data].map((ruleItem: any) => {
                  return (
                    <li key={`item-ruls-${ruleItem.id}`} className={`global-rules-popup-container__rules-list-item ${rulesActiveItem.id === ruleItem.id ? "active" : ""}`}
                      onClick={() => {
                        setRulesActiveItem(ruleItem);
                      }}
                    >
                      <h3 className="global-rules-popup-container__rules-list-item-title">
                        {ruleItem.name}
                      </h3>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="global-rules-popup-container__desktop-rules-text-wrapper">
              <h4 className="global-rules-popup-container__desktop-rules-text-title">
                <span>
                  {
                    rulesActiveItem.name
                  }
                </span>
              </h4>
              <div>
                {
                  rulesActiveItem.rules.length > 0 &&
                  <DesktopRuleText desktopText={rulesActiveItem.rules} />
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className={`${isOpenPopUp ? "global-rules-popup active" : "global-rules-popup"}`} onClick={() => { dispatch(setGlobalRules({isOpenPopUp: false})); }}>
        <div className={`${isOpenPopUp ? "global-rules-popup-content active" : "global-rules-popup-content"}`} onClick={e => e.stopPropagation()}>
          <div className="global-rules-popup-header">
            <div className="global-rules-popup-header__text">
              <img src={infoIcon} alt="info" className="globalRulesInfoIcon" />
              <span>Rules</span>
            </div>
            <button className="global-rules-popup-header__close" onClick={(() => dispatch(setGlobalRules({isOpenPopUp: false})))}>
              <img src={globalRulesCloseIcon} alt="close" className="globalRulesClose" />
            </button>
          </div>
          <div className="global-rules-popup-container">
            <div className="global-rules-popup-container__rules-list-wrapper">
              <ul className="global-rules-popup-container__rules-list">
                {data?.length && [...data].map((ruleItem: any) => {
                  return (
                    <li key={`item-ruls-${ruleItem.id}`} className={`global-rules-popup-container__rules-list-item ${rulesActiveItem.id === ruleItem.id ? "active" : ""}`}
                      onClick={() => {
                        if(rulesActiveItem.id === ruleItem.id) {
                          return setRulesActiveItem({id: "", rules: [], name: ""});
                        }
                        else {
                          return setRulesActiveItem(ruleItem);
                        }
                      }}
                    >
                      <h3 className="global-rules-popup-container__rules-list-item-title">
                        {ruleItem.name}
                      </h3>
                      <article>
                        {ruleItem.rules.length && [...ruleItem.rules].map((ruleTextItem, index) => {
                          return (
                            <p key={`item-ruls-text-${index}`} >{ruleTextItem}</p>
                          );
                        })}
                      </article>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
});
