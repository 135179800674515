import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface googleConfigItem {
        gaConfig: {
            forceSendHits: boolean;
            trackingId: string;
            mobileTrackingId: string;
        },
        recaptchaConfig: {
            recaptchaSiteKey: string;
            recaptchaEnabled: boolean;
        },
        appConfig: {
            0: {
                moduleName: string;
                model: {
                    appKey: string;
                }
            },
            1: {
                moduleName: string;
                model: {
                    'lot-version': string;
                    'dia-version': string;
                    'lot-version-test': string;
                }
            }
        },
        isB2C: true
  }

  const INIT_GOOGLE_CONFIG = 'INIT_GOOGLE_CONFIG';

  type InitGoogleConfigAction = Action<typeof INIT_GOOGLE_CONFIG> & {
      config: googleConfigItem;
  };

  export const initGoogleCfg = (config: googleConfigItem ): InitGoogleConfigAction => ({
    type: INIT_GOOGLE_CONFIG,
    config,
  });

  // type AlowwedActions = InitGoogleConfigAction;

  const GoogleConfigReducer = (config: googleConfigItem = {} as googleConfigItem, action: AnyAction): googleConfigItem => {
    switch (action.type) {
      case INIT_GOOGLE_CONFIG:
        return action.config;
      default:
        return config;
    }
  };

  export default GoogleConfigReducer;
