import { MEMBER_CODE, PATH } from "../utils/constants";
import {  GamesJiliInstance, MatchCenterInstance, instance } from "./instance";
import { BetItem } from "../redux/pages/EventDetail/placeBet";
import { JiliDeposit } from "../components/CasinoJILI_popUp";

const MemberCode = localStorage.getItem(MEMBER_CODE)

const betConfig = {
  headers: {
    'x-user-id' : MemberCode,
    // temporary hardcoded
    'x-client' : 'mobile',
    'x-client-id' : '476843353.1602776689',
    'x-client-info': 'f582f6103068ba74fe67f8ee1e0ee274',
    'x-log-timing': true,
    'x-xid': 'bc1cac1e-94d2-6b09-e09f-70f42ea11fd5'
  }
}


export class EventDetailHandler {
  static async getEventStatsVideoRequest(id:string, eventId: string) {
    const response = await instance.get(`/${PATH.MATCH_CENTER}/${PATH.STATS}/${id}/${eventId}/${PATH.D_VIDEO}`);
    return response.data.result
  };

  static async placeBet(betData?: BetItem[]) {
    const response = await instance.post(`/${PATH.EXCHANGE}/${PATH.ORDER}`,  betData , betConfig);

    return response.data.result
  };
  static async eventLimits(eventId: string) {
    const response = await instance.post(`/${PATH.EXCHANGE}/${PATH.ORDER}/${PATH.EVENT_LIMITS}/?eventId=${eventId}`);

    return response.data.result
  };

  static async matchTrackerAccessToken(id:string, eventId: string) {
    let response
    const MemberCode = localStorage.getItem(MEMBER_CODE)


    if (MemberCode) {
      response = await MatchCenterInstance.post(`/${PATH.MATCH_CENTER}/${PATH.STATS}/${PATH.ACCESS_TOKEN}`,  {eventTypeId: id, eventId: eventId});
    } else {
      response = await MatchCenterInstance.post(`/${PATH.MATCH_CENTER}/${PATH.STATS}/${PATH.ACCESS_TOKEN}`,  {eventTypeId: id, eventId: eventId});
    }
    const virtualsGamesId = ["98792", "56966", "92036", "98793", "56766", "56769", "56969", "67563", "87565", "92036", "98795", "67566", "98794"]
 
    if (virtualsGamesId.includes(eventId)) {
      const virtualUrl = "https://gemexch-stream.d3t8fb5vyy9m9n.amplifyapp.com/#/sevens/stream/";
      return { ...response.data.result, url: virtualUrl + response.data.result.url.split('/').slice(-1).toString() }
    } else {
      return response.data.result
    }
  };

  static async getBetsList(params?: string, id?: number| string, ) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    if (MemberCode) {
      const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.ORDER_LIST}?${params}&eventId=${id}`);
      return response.data
    }
    return null
  };

  static async delUnmatchedBet( apolloBetId: string) {
    const response = await instance.delete(`/${PATH.EXCHANGE}/${PATH.ORDER}/${apolloBetId}`, );
    return response.data
  };

  static async getExchangeGameOdds( id: string) {
    const response = await instance.get(`/${PATH.EXCHANGE_GAMES}/${PATH.ODDS}/${id}`, );
    return response.data
  };

  static async getPastResults( id: string) {
    const response = await instance.get(`/${PATH.EXCHANGE_GAMES}/${PATH.RESULTS}/${id}`, );
    return response.data
  };

  static async depositJili( data?: JiliDeposit[]) {
    const response = await GamesJiliInstance.post(`/${PATH.CASINO}/${PATH.LIVE}/${PATH.FUNDS}/${PATH.DEPOSIT}`, data);
    return response.data
  };

  static async urlJili( numId: string ) {
    const response = await GamesJiliInstance.get(`/${PATH.CASINO}/${PATH.LIVE}/${PATH.TABLE}/${numId}`, );
    return response.data
  };
}
