import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface ExposurePnlList {
  eventId: string
  pnlBySelection: {} | any;
}

const INIT_EXPOSURE_PNL = 'INIT_EXPOSURE_PNL';

type InitExposureAction = Action<typeof INIT_EXPOSURE_PNL> & {
  exposurePnl: ExposurePnlList;
};

export const initExposurePnl = (exposurePnl: ExposurePnlList): InitExposureAction => ({
  type: INIT_EXPOSURE_PNL,
  exposurePnl,
});

// type AlowwedActions = InitExposureAction;

const ExposureReducer = (exposurePnl: ExposurePnlList =  {} as ExposurePnlList, action: AnyAction): ExposurePnlList => {
  switch (action.type) {
    case INIT_EXPOSURE_PNL:
      return action.exposurePnl;
    default:
      return exposurePnl;
  }
};

export default ExposureReducer;
