import { Helmet } from "react-helmet-async";
import { useHelmetDataContext } from "./HelmetDataContext";
import { IMeta } from "./meta-types";

export const HelmetWrapper = () => {
  const { helmetData } = useHelmetDataContext();

  return (
    <>
      <Helmet title={helmetData.title}>
        {helmetData.meta.map((one: IMeta) => <meta name={one.name} content={one.content} />)}
        {helmetData?.link &&  <link rel={helmetData.link.rel} href={helmetData.link.href} />}
      </Helmet>
      {helmetData?.header && <h1 style={{height: 0, width: 0, opacity: 0, overflow: 'hidden' }}>{helmetData?.header}</h1>}
    </>
  );
};
