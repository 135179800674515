import { PATH } from "./constants";

const servers = {
  // STAGE_API: "https://dapi.gemexch.bet",   //prod
  // STAGE_API: "https://asdf9.com",   //dev
  STAGE_API: process.env.REACT_APP_BASE_DEV_URL,   //dev
  // PUBLIC_API: "https://asdf9.com",   //prod
  PUBLIC_API: process.env.REACT_APP_BASE_DEV_URL,   //prod

  // BANNERS_API:'https://gemexch.bet',
  BANNERS_API: process.env.REACT_APP_BASE_DEV_URL,

  // temporary this one for development
   //STAGE_API: "https://dapi.gemexch.games",  //test

  IFRAME: "https://iframeapi.route2222.com",
  GAMES_JILI: "https://casino-l.route2222.com",
}

export const API_URL = `${servers.STAGE_API}/${PATH.API}`;

export const PUBLIC_URL = `${servers.PUBLIC_API}/${PATH.API}/${PATH.PUBLIC}`;

export const PUBLIC_URL_MATCH_CENTER = `${servers.PUBLIC_API}/${PATH.API}/open`;


export const BANERS_API = `${servers.BANNERS_API}/${PATH.API}`;

export const IFRAME = `${servers.IFRAME}`;

export const GAMES_GILI = `${servers.GAMES_JILI}/${PATH.API}`;
