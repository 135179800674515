import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface BetInfoItem {
  back: boolean | null | undefined,
  event_name: string | null | undefined,
  selection: string | null | undefined,
  mType: string | null | undefined,
  odds: number | null | undefined,
  runs: string | null | undefined,
  runerId?: string | null | undefined,
  runners?: any | null | undefined,
  item?: any | null | undefined,
}

const INIT_BET_INFO = 'INIT_BET_INFO';

type InitBetInfoAction = Action<typeof INIT_BET_INFO> & {
  betInfo: BetInfoItem;
};

export const InitBetInfo = (betInfo: BetInfoItem ): InitBetInfoAction => ({
  type: INIT_BET_INFO,
  betInfo,
});

// type AlowwedActions = InitBetInfoAction;

const BetInfoReducer = (betInfo: BetInfoItem = {} as BetInfoItem, action: AnyAction): BetInfoItem => {
  switch (action.type) {
    case INIT_BET_INFO:
      return action.betInfo;
    default:
      return betInfo;
  }
};

export default BetInfoReducer;
