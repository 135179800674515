import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface BetErrMsg {
  errMsg: string
}

const INIT_ERR_MSG = 'INIT_ERR_MSG';

type InitErrMsgAction = Action<typeof INIT_ERR_MSG> & {
  errMsg: BetErrMsg;
};

export const initErrMsg = (errMsg: BetErrMsg ): InitErrMsgAction => ({
  type: INIT_ERR_MSG,
  errMsg,
});

// type AlowwedActions = InitErrMsgAction;

const ErrMsgReducer = (errMsg: BetErrMsg = {} as BetErrMsg, action: AnyAction): BetErrMsg => {
  switch (action.type) {
    case INIT_ERR_MSG:
      return action.errMsg;
    default:
      return errMsg;
  }
};

export default ErrMsgReducer;
