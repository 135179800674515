import React, { ReactNode, useState } from "react";
import {  HelmetProvider } from "react-helmet-async";
import { HelmetDataContext } from "./HelmetDataContext";
import { IHelmetData, MetaPages } from "./meta-types";

export const HelmetDataProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

  const defaultMeta = {
    title: "FunExch - India's Biggest Online Casino & Sportsbook",
    meta: [
      { name: "description", content: "The best online crypto exchange in the world" },
      { name: "author", content: "AURA e-gaming" },
    ],
  };

  const [helmetData, setHelmetData] = useState<IHelmetData>(defaultMeta);

  const setDefault = () => {
    setHelmetData(defaultMeta);
  };

  return (
    <HelmetDataContext.Provider value={{ helmetData, setHelmetData, setDefault, MetaPages }}>
      <HelmetProvider>
        {children}
      </HelmetProvider>
    </HelmetDataContext.Provider>
  );
};
