import { createPortal } from "react-dom"
import cn from "classnames"
import { useEffect, useRef, useState } from "react"
import { useHistory } from "react-router-dom"
import arrowImg from "../../static/images/arrowDown.svg"
import "./competitionList.scss"
import { useMediaQuery } from "@material-ui/core"

export const CompetitionList = ({ competitionFilter, setCompetitionFilter, eventsForFilter }: any) => {
  const history = useHistory()
  const [isClosed, setIsClosed] = useState(true)
  const [animation, setAnimation] = useState(false)
  const [title, setTitle] = useState("All Events")
  const id = history?.location?.pathname?.split("/")?.slice(-1)[0] || "4"
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const dropDownRef = useRef<HTMLDivElement>(null);
  const element= document.querySelectorAll("[title='activeItem']")[0];

  useEffect(() => {
    setIsClosed(false)
  }, [])

  const chooseItem = (item: any) => {
    setCompetitionFilter(item.id)
    setTimeout(() => setIsClosed(false), 200)

    if (!item.name) {
      setTitle("All Events")
      return
    }
    if (id === "4339" || id === "7") {
      setTitle(`${startTime(item.startTime)}  ${item?.venue} (${item.countryCode})`)
    } else {
      setTitle(item?.name || "All Events")
    }
  }

  const handler = (event: any) => {
    if( dropDownRef.current && !dropDownRef.current.contains(event.target)) {
      setTimeout(() => setIsClosed(false), 200)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handler, true)
    return () => {
      document.removeEventListener("click", handler, true)
    }
  }, [])

  const startTime = (date: any) => {
    const newDate = new Date(date)
    return newDate.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" })
  }

  useEffect(() => {
    setTitle('All Events')
    chooseItem("")
  }, [history.location.pathname])


  useEffect(() => {
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, [element]);

  return (
    <div className="global-desktop-container">
      {eventsForFilter?.length > 0 && <div ref={dropDownRef} className="competition-list">
      <div className="competition-list__dropdown" onClick={() => setIsClosed(!isClosed)}>
        <div className="competition-list__dropdown-content">
          <span>{title}</span>
            <div className={cn("competition-list__dropdown-content-image", {
              active: isClosed
            })}>
            <img src={arrowImg} alt="arrowImg" />
          </div>
        </div>
      </div>
        {isPhone && isClosed && createPortal(
        <>
          {
            <div className="competitionList__pop-up mobile"
                 onClick={e => {
                   setAnimation(true)
                   setTimeout(() => {
                     setIsClosed(false)
                     setAnimation(false)
                   }, 300)
                 }}
            >
              <div
                className={cn("competitionList__pop-up-content", {
                  "closed": animation
                })}
              >
                <div className="competitionList__pop-up-content_items">
                  <div
                    className={cn("competitionList__pop-up-content_items-item", {
                      active: !competitionFilter
                    })}
                    onClick={() => chooseItem("")}
                  >
                    <span>All Events</span>
                  </div>
                    {eventsForFilter?.map((item: any) => {
                    return (
                      <div
                          key={item.id + new Date()}
                          title={item.id?.split('.')?.[0] === competitionFilter?.split('.')?.[0] ? "activeItem" : "item"}
                        className={cn("competitionList__pop-up-content_items-item", {
                            active: item.id?.split('.')?.[0] === competitionFilter?.split('.')?.[0]
                        })}
                        onClick={() => chooseItem(item)}
                      >
                        {(id === "4339" || id === "7") &&
                          <span>{startTime(item.startTime)} {item?.venue} ({item.countryCode})</span>}
                        {(id !== "4339" && id !== "7") && <span>{item?.name}</span>}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          }
        </>,
        // @ts-ignore
        modalRoot
      )}
        {!isPhone &&
          <div  className={cn("competition-list__dropdown-content-items", {
            active: isClosed
          })}>
            <div
              className={cn("competition-list__dropdown-content-items-one", {
                active: !competitionFilter
              })}
              onClick={() => chooseItem("")}
            >
              <span>All Events</span>
            </div>
            {eventsForFilter?.map((item: any) => {
              return (
                <div
                  key={item.id + new Date()}
                  className={cn("competition-list__dropdown-content-items-one", {
                    active: item?.id?.split('.')?.[0] === competitionFilter?.split('.')?.[0]
                  })}
                  onClick={() => chooseItem(item)}
                >
                  {(id === "4339" || id === "7") &&
                    <span>{startTime(item.startTime)} {item?.venue} ({item.countryCode})</span>}
                  {(id !== "4339" && id !== "7") && <span>{item?.name}</span>}
                </div>
              )
            })}
          </div>
        }
      </div>}
    </div>
  )
}

const modalRoot = document.querySelector("#modal-root")
