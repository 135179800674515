import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MarketPopUpList {
  result: [];
  status: {};
  success: boolean;
}
export interface FailInterface {
  isOpenFail?: boolean;
  errMsg?: string;
}
export interface GlobalRulesInterface {
  isOpenPopUp?: boolean;
  data?: any;
}
export interface OpenPopUpsInterface {
  isOpenLoginPopUp: boolean;
  isOpenSignupPopUp: boolean;
  isOpenPopUp: boolean;
  isOpenForgotPasswordPopUp: boolean;
  isOpenSideBar: boolean;
  marketPopUp: MarketPopUpList | null;
  fail: FailInterface | null;
  globalRules: GlobalRulesInterface |null;
}

const uiInitialState: OpenPopUpsInterface = {
  isOpenLoginPopUp: false,
  isOpenSignupPopUp: false,
  isOpenPopUp: false,
  isOpenForgotPasswordPopUp: false,
  marketPopUp: null,
  fail: null,
  isOpenSideBar: false,
  globalRules: null,
};

const openPopUpsSlice = createSlice({
  name: "openPopUps",
  initialState: uiInitialState,
  reducers: {
    setIsOpenLoginPopUp: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenLoginPopUp = payload;
    },
    setIsOpenSignupPopUp: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSignupPopUp = payload;
    },
    setIsOpenPopUp: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPopUp = payload;
    },
    setIsOpenForgotPassword: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenForgotPasswordPopUp = payload;
    },
    setIsOpenSideBar: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSideBar = payload;
    },
    setMarketPopUp: (state, { payload }: PayloadAction<MarketPopUpList>) => {
      state.marketPopUp = payload;
    },
    setFailPopUp: (state, { payload }: PayloadAction<FailInterface>) => {
      state.fail = {...state.fail, ...payload};
    },
    setGlobalRules: (state, { payload }: PayloadAction<GlobalRulesInterface>) => {
      state.globalRules = {...state.globalRules, ...payload};
    },
  },
});

export const {
  setIsOpenLoginPopUp,
  setIsOpenSignupPopUp,
  setIsOpenPopUp,
  setIsOpenForgotPassword,
  setMarketPopUp,
  setFailPopUp,
  setIsOpenSideBar,
  setGlobalRules,
} = openPopUpsSlice.actions;

export default openPopUpsSlice.reducer;
