import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface ITransaction {
    requestId: number;
    status: string;
    type: string;
    utr: null | string;
    amount: number;
    remarks: null | string;
    createdAt: string;
    lastUpdateTime: null | string;
    IP: string;
    fileName: null | string;
    account: {
        IFSC: string,
        accountNo: string,
        accountHolderName: string
    }
    gateway: {
        name: string,
        acNo: string,
    }
}


const INIT_DEPOSIT_TRANSACTION = 'INIT_DEPOSIT_TRANSACTION';

type InitDepositTransactionAction = Action<typeof INIT_DEPOSIT_TRANSACTION> & {
    detailedDepositTransaction: ITransaction;
};

export const initDepositTransaction = (detailedDepositTransaction: ITransaction ): InitDepositTransactionAction => ({
  type: INIT_DEPOSIT_TRANSACTION,
  detailedDepositTransaction,
});

// type AlowwedActions = InitDepositTransactionAction;

const DepositTransactionReducer = (detailedDepositTransaction: ITransaction = {} as ITransaction, action: AnyAction): ITransaction => {
  switch (action.type) {
    case INIT_DEPOSIT_TRANSACTION:
      return action.detailedDepositTransaction;
    default:
      return detailedDepositTransaction;
  }
};

export default DepositTransactionReducer;
