import { createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface FooterBets {
  footerBets: boolean,
}

const uiInitialState: FooterBets = {
  footerBets: false
}

export const footerBetsSlice = createSlice({
  name: 'footerBets',
  initialState: uiInitialState,
  reducers: {
    setFooterBets: (state, {payload}: PayloadAction<any>) => {
      state.footerBets = payload
    },
  },
})

export const { setFooterBets } = footerBetsSlice.actions

export default footerBetsSlice.reducer
