import { memo } from "react";
import loadingGif from "../../static/images/loaderFX.svg";
import "./Loading.scss";

const Loading = memo((props: any) => {
    return (
      <div className="loading-page">
        <img src={loadingGif} alt="loadingGif" />
      </div>
    );
});

export default Loading;