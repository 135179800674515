import "./PointPopUp.scss"
import { useState, memo } from "react"
import cn from "classnames"
import { createPortal } from "react-dom"
import closeButton from "../../../static/images/pointsPopUp/closeButton.svg"

const modalRoot = document.querySelector("#modal-root")

const PointPopUp = memo(({ setShowPointPopUp }: any) => {
  const [animClose, setAnimClose] = useState(false)

  const handleClose = () => {
    setAnimClose(true)
    setShowPointPopUp(false)
  }

  const handleBackgroundClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.currentTarget === e.target) {
      handleClose()
    }
  }
  console.log('asssssss')

  return createPortal(
    <div className="pointPopUp-wrapper" onClick={handleBackgroundClick}>
      <div
        className={cn("pointPopUp", {
          close: animClose
        })}
      >
        <>
          <div className="pointPopUp__content">
            <div className="pointPopUp__content-img"></div>
            <img className="pointPopUp__content-closeButton" src={closeButton} alt="" onClick={handleClose} />
            <div className="pointPopUp__content-title">PLEASE NOTE</div>
            <div className="pointPopUp__content-descr">(1 Point = ₹ 100)</div>
            <div className="pointPopUp__content-welcome">Welcome to your new casino lobby</div>
            <div className="pointPopUp__content-checkout">
              Checkout the exciting Live Casino tables, Slots and much more, upto 200+ game and counting...
            </div>
            <div className="pointPopUp__content-button" onClick={handleClose}>
              OK, I AGREE !
            </div>
          </div>
        </>
      </div>
    </div>,
    modalRoot as HTMLDivElement
  )
})

export default PointPopUp