import { ReactNode, useEffect, useState } from "react"

const CountdownTimer = ({ time, className, children }: { time: number; className?: string, children?: ReactNode}) => {
  const [count, setCount] = useState(time);
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCount((prevCount) => (prevCount > 0 ? prevCount - 1 : 0));
      }, 1000);

      return () => clearInterval(intervalId);

    }, []);

    return (
    <div className={className}>
      {count >= 1 ? count : children}
    </div>
  );
};

export default CountdownTimer;
