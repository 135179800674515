import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface StatsList {
  hasVideo: boolean;
  showVisualisation: boolean;
  stats: {} | any;
  scorecard: {};
}

const INIT_STATS = 'INIT_STATS';

type InitStatsAction = Action<typeof INIT_STATS> & {
  stats: StatsList;
};

export const initStats = (stats: StatsList): InitStatsAction => ({
  type: INIT_STATS,
  stats,
});

// type AlowwedActions = InitStatsAction;

const StatsReducer = (stats: StatsList =  {} as StatsList, action: AnyAction): StatsList => {
  switch (action.type) {
    case INIT_STATS:
      return action.stats;
    default:
      return stats;
  }
};

export default StatsReducer;
