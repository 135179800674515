import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { initJiliPopUp } from "../../redux/pages/jiliPopUp";
import { GeneralHandler } from "../../api/general"
import { initLiveList } from "../../redux/menu/liveCategories";
import { useState, useEffect } from "react"
import LoadingSpinner from "../../components/loadingSpinner";
import PointPopUp from "./PointPopUp/PointPopUp";
import "./styles/index.scss"
import { setIsOpenLoginPopUp, setIsOpenPopUp } from "../../redux/UI/openPopUps.slice"
import { MetaPages, useHelmetDataContext } from "../../utils/Meta"


const LiveCasinoGames = (props: any) => {
  const liveCategories = (localStorage.getItem('liveCasino_categories'))
  const categories = useSelector((state: IRootState) => state.liveList).sort((a, b) => a.name.localeCompare(b.name))
  const location = useLocation();
  const dispatch = useDispatch()
  const id = location.pathname.split("/").slice(-1)[0] || location.pathname.split("/").slice(-2)[0];
  const [currentCategories, setCurrentCategories] = useState(liveCategories)
  const [loading, setLoading] = useState(false)
  const [notMoreGame, setNotMoreGame] = useState(false)
  const [gamesList, setGamesList] = useState<any>([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isFetching, setIsFetching] = useState(false)
  const {user: { isAuthenticated }} = useSelector((state: IRootState) => ({ user: state.user }))
  const [showPointPopUp, setShowPointPopUp] = useState(false)
  const history = useHistory();
  useHelmetDataContext(MetaPages.LIVE_CASINO_PAGE);
  const initData = () => {
    GeneralHandler.getLiveCategories(isAuthenticated).then(res => {
      dispatch(initLiveList(res))
      const check = res.filter((item:any) => item.id === id)
      if (id && check.length) {
        setCurrentCategories(check[0].id)
        localStorage.setItem('liveCasino_categories', id)
      } else {
        localStorage.setItem('liveCasino_categories', `${res[0].id}`)
        setCurrentCategories(res[0].id)
        setNotMoreGame(false)
      }
    })
  }

  const GamesListHandler = (id: string, page: number) => {
    GeneralHandler.getLiveGames(id, isAuthenticated).then(res => {
      let result = []
      if (page === 1) {
        result = [...res]
      } else {
        result = [...gamesList, ...res]
      }


      setGamesList(result)
      setLoading(false)
    })
    setIsFetching(false)
    setLoading(false)
  }

  useEffect(() => {
    if (categories.length > 0) {
      if(currentCategories) {
        GamesListHandler(currentCategories, currentPage)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categories, currentPage, currentCategories])

  useEffect(() => {
    const check = categories.filter(item => item.id === id)
    if(id && check.length) {
      setCurrentCategories(id)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  useEffect(() => {
    if (!isFetching) return;
    if (gamesList.length > 0) {
      setCurrentPage(currentPage + 1)
    }
  }, [isFetching]);


  useEffect(() => {
    setLoading(true)
    initData()
    setShowPointPopUp(true)
    return (() => {
      localStorage.removeItem('liveCasino_categories')
    })
  }, [])

  return (
    <div className="grouped-games">
        {loading
            ? <div className="centered">
              <LoadingSpinner />
            </div>
            : <>
               <div className="live-casino__game">
                <p className="live-casino__game-title">Live Casino</p>
                <p className="live-casino__game-descr">Take a seat and play against all real dealers to enjoy casino games</p>
                <p className="live-casino__game-text">Discover our very own Live Casino with Teen Patti, Rummy, Andar Bahar, Dragon Tiger, Crazy Time, Blackjack, Roulette and more. Meet our professional live dealers, including Hindi speaking dealers for Andar Bahar and Roulette, and enjoy the real deal!</p>
              </div>
            <div className="grouped-games__bodyy">
              {gamesList.map((item: any, index: number) => {

                return (
                  <div key={index}>
                    {(item.id.includes("JILI") || item.id.includes("jili") || item.type === 'CASINO_JILI')
                      ? (
                        <Link
                          to="#"
                          onClick={() => {
                            if (!isAuthenticated) {
                              dispatch(setIsOpenLoginPopUp(true))
                              return
                            }
                            dispatch(setIsOpenPopUp(true))
                            dispatch(initJiliPopUp(item));
                          }}
                        >
                          <div className="slot__body-cards-wrapp">
                            <img src={item.image} alt="" className="slot__body-cards-image" />
                            {item.image === '' ? item.name : ''}
                            {/*<div className="slot__body-cards-btn">Play</div>*/}
                          </div>
                          <p className="slot__body-cards-name">
                            {item.name}
                          </p>
                        </Link>
                      )
                      : (
                        <Link
                          to="#"
                          onClick={() => {
                            if (!isAuthenticated) {
                              dispatch(setIsOpenLoginPopUp(true))
                              return

                            }
                            history.push(`/casino-game/${item.id}`, {
                                gameName: `${item.name}`,
                                backPath: `${location.pathname}`,
                            })
                          }}
                          className="slot__body-cards" key={index}
                        >
                          <div className="slot__body-cards-wrapp">
                            <img src={item.image} alt="" className="slot__body-cards-image" />
                            {item.image === '' ? <div className="slot__body-cards-second-name">{item.name}</div> : ''}
                            {/*<div className="slot__body-cards-btn">Play</div>*/}
                          </div>
                          <p className="slot__body-cards-name">
                            {item.name}
                          </p>
                        </Link>
                      )
                    }
                  </div>
                )
              })}
            </div>
              <div
                style={{display: 'none'}}
                className={`slot__bottom-load`}>
                {!notMoreGame ? <LoadingSpinner /> : ''}
                {notMoreGame ? <button className={`slot__button `} disabled={true}>No more games</button> : ''}
              </div>
            </>
          }
          {isAuthenticated && showPointPopUp && <PointPopUp setShowPointPopUp={setShowPointPopUp}/>}
      </div>
  )
}

export default LiveCasinoGames
