import { createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface ResetPasswordInterface {
  reset: boolean;
}

const uiInitialState: ResetPasswordInterface = {
    // confirmation: JSON.parse(localStorage.getItem('preferences'))
    reset: false
}

export const resetPasswordSlice = createSlice({
  name: 'preferences',
  initialState: uiInitialState,
  reducers: {
    setResetPassword: (state, {payload}: PayloadAction<boolean>) => {
      state.reset = payload
    },
  },
})

export const { setResetPassword } = resetPasswordSlice.actions

export default resetPasswordSlice.reducer
