import { memo, useEffect, useMemo } from "react"
import { useDispatch } from "react-redux"
import { SportHome } from "../Sport"

import "../styles/index.scss"
import { HomeGames } from "../HomeGames"
import { useHistory } from "react-router"
import { GeneralHandler } from "../../../api/general"
import { initCards } from "../../../redux/menu/cards"

import cardsImg from "../../../static/images/homeMenu/slotGamesImg.webp"
import cardsImgDesk from "../../../static/images/homeMenu/slotGamesImg.webp"
import liveGamesImg from "../../../static/images/homeMenu/liveGamesImg.webp"
import liveGamesImgDesk from "../../../static/images/homeMenu/liveGamesImg.webp"
import slotGamesImg from "../../../static/images/homeMenu/slotGames.webp"
import slotGamesImgDesk from "../../../static/images/homeMenu/slotGamesDesk.webp"
import { Celebrity } from "../HomeGames/Celebrity"

export const HomeContent = memo(() => {
  const history = useHistory()
  const dispatch = useDispatch()

  const initGames = () => {
    GeneralHandler.getMenuList().then(list => {
      dispatch(initCards(list))
    })
  }


  useEffect(() => {
    initGames()
  }, [])

  const gamesProps = useMemo(() => ({
    exchange: {
      name: "Indian Card Games",
      key: "cards",
      image: cardsImg,
      imageDesk: cardsImgDesk,
      action: () => history.push("/home/card-games" )
    },
    liveCasino: {
      name: "Live Casino",
      key: "liveGames",
      image: liveGamesImg,
      imageDesk: liveGamesImgDesk,
      action: () => history.push("/home/live-casino-page")
    },
    slots: {
      name: "Slot Games",
      key: "slotGames",
      image: slotGamesImg,
      imageDesk: slotGamesImgDesk,
      action: () => history.push("/home/slots-page")
    }
  }), [])
  return (
    <>
      <SportHome />
      <div className="home-page__games-wrapper">
        <HomeGames gamesProps={gamesProps.exchange} />
        <HomeGames gamesProps={gamesProps.liveCasino} />
        <HomeGames gamesProps={gamesProps.slots} />
        <Celebrity/>
      </div>
    </>
  )
})
