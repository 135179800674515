import makeStyles from "@material-ui/core/styles/makeStyles"
import { ReactNode } from "react"

import { useSelector } from "../../redux"
import LoadingSpinner from "../loadingSpinner"

const useStyles = makeStyles({
  loader: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
})

const AuthWrapper = ({ children }: { children: ReactNode }): JSX.Element => {
  const classes = useStyles()
  const { isLoadingAuthentication } = useSelector(state => state.user)

  if (isLoadingAuthentication)
    return (
      <div className={classes.loader}>       
        <LoadingSpinner />
      </div>
    )
  return children as JSX.Element
}

export default AuthWrapper
