import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useMediaQuery } from "@material-ui/core"
import { PromotionBaners } from "./Promotions"
import { HomeMenuNew } from "./HomeMenuNew"
import { Footer } from "../../components/Footer"
import { GeneralHandler } from "../../api/general"
import { initCards } from "../../redux/menu/cards"
import { Redirect, Route, Switch } from "react-router-dom"
import LiveCasinoGames from "../LiveCasinoGames"
import Slots from "../Slots"
import CardGames from "../LiveCardGames"
import Ticker from "../../components/Ticker/Ticker"
import { HomeContent } from "./HomeContent/HomeContent"
import { ModalButtons } from "../../components/ModalButtons"
import "./styles/index.scss"

const HomePage = () => {
  const isMessages = useSelector((state: IRootState) => state.notification.messages).length > 0
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const dispatch = useDispatch()
  const ref = useRef<null | HTMLDivElement>(null);

  const initGames = () => {
    GeneralHandler.getMenuList().then(list => {
      dispatch(initCards(list))
    })
  }

  useEffect(() => {
    initGames()
  }, [])

  return (
    <>
      <div
        className={`home-page overflow ${isMessages ? "isMessages" : ""} ${!isPhone ? " iPhone" : ""}`}>
        <div ref={ref} className="home-page__content">
          <>
            <div className="home-page__header-wrapper">
              {isPhone && <Ticker/>}
              <PromotionBaners />
              <HomeMenuNew/>
            </div>
            <Switch>
              <Route path="/home/card-games" render={() => <CardGames />} />
              <Route path="/home/sports" render={() => <HomeContent />} />
              <Route path="/home/slots-page" render={() => <Slots />} />
              <Route path="/home/live-casino-page" render={() => <LiveCasinoGames />} />
              <Route path="/home/event-list" render={() => <HomeContent />} />

              <Redirect to="/home/sports/4" />
            </Switch>
            <ModalButtons/>
            <Footer />
          </>
        </div>
      </div>
    </>
  )
}

export default HomePage