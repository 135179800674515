import "./style/index.scss"
import { useEffect, useState } from "react"
import { GeneralHandler } from "../../api/general"
import ScrollSlider from "../../components/ScrollSlider/ScrollSlider"
import cn from "classnames"
import { useHistory } from "react-router"
import { useMediaQuery } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import LoadingSpinner from "../../components/loadingSpinner"
import { setIsOpenLoginPopUp } from "../../redux/UI/openPopUps.slice"
import { MetaPages, useHelmetDataContext } from "../../utils/Meta"


interface ICategory {
  gameCount: number;
  id: string;
  image: string;
  name: string
  sort: number
}


const CardGames = () => {
  const [games, setGames] = useState<any[] | null>(null)
  const [sortGames, setSortGames] = useState<any[] | null>(null)
  const [categories, setCategories] = useState<ICategory[] | null>(null)
  const [sortId, setSortId] = useState<string>("1")
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const history = useHistory()
  const key = history?.location?.pathname.includes("virtual") ? "virtual" : "liveGames"
  const { isAuthenticated } = useSelector((state: IRootState) => state.user)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [hideFooter, setHideFooter] = useState(false)
  useHelmetDataContext(MetaPages.CARD_GAMES);

  useEffect(() => {
    setLoading(true)
    GeneralHandler.getCardGames().then(res => {
      setGames(res)
    })
    GeneralHandler.getCardGamesTeenpattiCatgories().then(res => {
      setCategories(res)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    setHideFooter(true)
    setTimeout(() => {
      if (games) {
        setSortGames(games[key].filter((item: any) => item.Categories.includes(sortId)))
        setHideFooter(false)
      }
    }, 500)

  }, [sortId, games, key])

  const openGame = (game: any) => {
    if (isAuthenticated) {
      history.push(`/card-game/${game.event.altId}`,
        {
          game,
          backPath: history.location.pathname
        }
      )
    } else {
      dispatch(setIsOpenLoginPopUp(true))
    }
  }

  return loading ?
    <div className="centered">
      <LoadingSpinner/>
    </div>
    :
    <div className="exchange-games-page global-desktop-container">
      <ScrollSlider className="exchange-games-page__menu">
        {categories?.map(one =>
          <div key={one.id}
               className={cn("exchange-games-page__menu-item", {
                 active: sortId === one.id
               })}
               onClick={() => {
                 setSortId(one.id)
               }}
          >
            <span>{one.name}</span>
          </div>
        )}
      </ScrollSlider>
      <div className="exchange-games-page__info">
        <div className="exchange-games-page__info-title">
          Indian Card Games
        </div>
        <div className="exchange-games-page__info-sub-title">
          Take a seat and play against all real dealers to enjoy casino games
        </div>
        <div className="exchange-games-page__info-text">
          Discover our very own Live Casino with Teen Patti, Rummy, Andar Bahar, Dragon Tiger, Crazy Time, Blackjack,
          Roulette and more. Meet our professional live dealers, including Hindi speaking dealers for Andar Bahar and
          Roulette, and enjoy the real deal!
        </div>
      </div>
      <div className={cn("exchange-games-page__games", {
        "no-data": hideFooter
      })}>
        {sortGames && sortGames.map((one: any) =>
          <div className="exchange-games-page__games-item" key={one.event.id} onClick={() => openGame(one)}>
            <div className="exchange-games-page__games-item-img-body">
              <img src={isPhone ? one?.mobileImage :( one?.image || one?.desktopImage)} alt=""  />
            </div>
          </div>
        )}
      </div>
    </div>
}

export default CardGames
