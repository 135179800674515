import lottie from 'lottie-web';
import lottieAnimation from './FX.json'
import "./style.scss"
import { useRef, useEffect } from "react";
export default function LoadingSpinner() {
  const lottieContainer = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (lottieContainer.current) {
      const animation = lottie.loadAnimation({
        container: lottieContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: lottieAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice',
        }
      });
      return () => animation.destroy();
    } else {
      return
    }
  }, []);


  return (
    <div>
      <div ref={lottieContainer} style={{ width: 200, height: 240 }} />
    </div>
  );
}
