import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Checkbox, Paper } from "@material-ui/core"
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import ReCAPTCHA from "react-google-recaptcha";
import CustomButton from "../../components/UI/Button"
import useAuth from "../../utils/useAuth"
import FormikField from "../../pages/Login/components/FormikField"
import { initStakesSettings } from "../../redux/login/stakeSettings"
import { LoginHandler } from "../../api/login"
import { createPortal } from "react-dom";
import { GeneralHandler } from "../../api/general"
import { setPreferences } from "../../redux/login/preferences.slice";
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { Snackbar } from "@material-ui/core"
import { setResetPassword } from "../../redux/login/resetPassword.slice";
import cn from "classnames"
import {DropDownLogin} from '../DropDownLogin'
import { useHistory } from "react-router-dom";
import LoginUpLogoIcon from './loginUpLogo.svg'
import MobileIcon from "./mobileIcon.svg";
import ArrowIcon from "./arrow.svg";
import GoogleIcon from './gmailIcon.svg';
import WhatsAppIcon from './WhatsApp-Logo.wine.svg'
import { setIsOpenForgotPassword, setIsOpenLoginPopUp, setIsOpenSignupPopUp } from "../../redux/UI/openPopUps.slice"
import "./index.scss"

const modalRoot = document.querySelector("#modal-root");

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
interface FormValues {
  username: string;
  pasword: string;
}

const initialValues: FormValues = {
  username: '',
  pasword: ''
}
interface CustomWindow extends Window {
  srtmCommands: { push: (arg: any) => void };
}
declare const window: CustomWindow;
declare const google: any;

const LoginPopUp = () => {
  const [alert, setAlert] = useState(false)
  const [recaptchaSiteKey, setRecaptchaSiteKey] = useState("")
  const dispatch = useDispatch();
  const isOpenLoginPopUp = useSelector((state: IRootState) => state.openPopUps.isOpenLoginPopUp)
  const { login } = useAuth()
  const recaptchaRef = useRef(null);
  const config = useSelector((state: IRootState) => state.config)
  const successReset = useSelector((state: IRootState) => state.resetPassword.reset)
  const [animation, setAnimation] = useState(false)
  const ref = useRef<null | HTMLDivElement>(null);
  const [isTel, setIsTel] = useState(true)
  const history = useHistory()
  const [error, setError] = useState('');
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const idRegex = /^[a-zA-Z0-9]+$/;
  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(1);
  const [googleButtonWrapper, setGoogleButtonWrapper] = useState<any>()
  const [googleLoginWrapper, setGoogleLoginWrapper] = useState<any>()

  const LoginSchema = Yup.object().shape({
    username: Yup.string()
      .required('This field is required')
      .matches(isTel ? phoneRegExp : idRegex, isTel ? 'Phone number is invalid.' : 'ID must be only alphanumeric symbols.')
      .min(isTel ? 4 : 3, isTel ? 'Mobile number must be 10 digits' : 'ID must be 3 characters atleast.')
      .max(isTel ? 10 : 20, isTel ? 'Mobile number must be 10 digits' : 'ID must be maximum of 20 characters.'),
    pasword: Yup.string()
      .required('This field is required')
      .matches(/^[\w-!$%^&*#@()_+|~=`{}/:";'<>?,.]+$/i, 'Contain letters(a-z), numbers(0-9) or special symbols')
      .min(6, 'Password contains minimum 6 characters')
  });

  useEffect(() => {
    LoginHandler.googleConfig().then(res => {
      setRecaptchaSiteKey(res.recaptchaConfig.recaptchaSiteKey)
    })
    setGoogleLoginWrapper(createGoogleWrapper());
  }, [])


  const createOneSignalUser = (data: string, memberCode: string) => {
    try {
      const options = {
        method: 'POST',
        headers: {accept: 'application/json', 'content-type': 'application/json'},
        body: JSON.stringify({
          properties: {
          },
          subscriptions: [
            {
              type: 'SMS',
              token: data,
              enabled: true,
            }
          ],
          identity: {external_id: memberCode}
        })
      };

      fetch('https://onesignal.com/api/v1/apps/018e93f9-23f1-4ca8-bfc7-9a7c98290223/users', options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));
    } catch (e) {
      console.log('ONESIGNAL user creating error: ', e)
    }
  }

  const clickLoginButton = async (username: string, pasword: string) => {

    try {
      let deviceId;
      if (localStorage.getItem("deviceId") !== null) {
        // @ts-ignore
        deviceId = localStorage.getItem("deviceId");
      }
      let userNameForReq = config.isB2C && isTel ? `91_${username}` : username
      // @ts-ignore
      const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
      // @ts-ignore
      const response = await LoginHandler.LoginRequest( userNameForReq, pasword, recaptchaResponse, deviceId)
      const stakes = response.data.result
      window.srtmCommands.push({
        event: "track.user.login",
        payload: {
            action: "complete",
            userId: response.data.result.memberCode
          }
        });

        createOneSignalUser(`+91${username}`, response.data.result.memberCode)
      dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
      dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
      login(
        response.headers.authorization, response.data.result.memberCode,
        response.data.result.loginName, stakes.settings.presetStakeSettings,
        stakes.memberId, stakes?.settings?.preferences?.confirmation
      )
      setLoading(false)
      GeneralHandler.getWithdrawalAccounts().then(acc => {
          if (history.location.pathname.includes('FAWK')){
            history.push('/')
          }
      }).catch(() => {}
      )
    } catch (error:any) {
      if (error) {
        if (error.response) {
          if (error.response.data) {
            setError(error.response.data?.error)
            setTimeout(() => {
              setError('')
            }, 3100)
          }
        }
      }

      setAlert(true)
      setTimeout(() => {
        setAlert(false)
      }, 3000);
      setLoading(false)
      // @ts-ignore
      recaptchaRef?.current?.reset()
    }
  }

  const handleClose = () => {
    setTimeout(() =>{
      dispatch(setResetPassword(false))
    }, 2000)
  }

  const createGoogleWrapper = () => {
    google?.accounts?.id?.initialize({
      client_id: "978590584847-gci18nvvja97am4076p1prkjgs1o8r0o.apps.googleusercontent.com",
      callback: async (response: any) => {
        const token = response.credential;

        // @ts-ignore
        const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
        GeneralHandler.credentialResponse({
          "token": token,
          "recaptchaResponse": recaptchaResponse,
        }).then((response) => {
          const stakes = response.data.result
          window.srtmCommands.push({
            event: "track.user.login",
            payload: {
                action: "complete",
                userId: response.data.result.memberCode
              }
            });
          dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
          dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
          login(
            response.headers.authorization, response.data.result.memberCode,
            response.data.result.loginName, stakes.settings.presetStakeSettings,
            stakes.memberId, stakes?.settings?.preferences?.confirmation
          )
          setLoading(false)
        })
      },
      auto_select: false,
      ux_mode: 'popup'
    });
    const btn = document.createElement('div');
    btn.style.display = 'none';
    btn.classList.add('custom-google-button');
    document.body.appendChild(btn);
    google?.accounts?.id?.renderButton(btn, {
      theme: 'outline',
      size: 'large',
      text: 'continue_with',
      width: '100%',
    });
    const googleLoginWrapper = btn.querySelector("div[role=button]") as HTMLElement;
    setGoogleButtonWrapper(btn.querySelector("div[role=button]"))
    return {
      click: () => {
        googleLoginWrapper.click();
      }
    }
  }

  const handleSubmit = (values: FormValues): void => {
    const { username, pasword } = values;
    setLoading(true)
    clickLoginButton(username, pasword)
  };
  if (!isOpenLoginPopUp) return <></>

  const glogin = async () => {
      googleButtonWrapper.click();
      googleLoginWrapper.click();
  }

  return createPortal(
    <div className="loginPop-up">
       {successReset && (
        <Snackbar
                open={!!successReset}
                className="betslip_message betslip_message-success"
                onClose={handleClose}
                disableWindowBlurListener={true}
                autoHideDuration={2000}
        >
          <Alert onClose={handleClose} severity="success">
            Password Reset Sucessfully
          </Alert>
        </Snackbar>
      )}
      <div className="loginPop-up_wrapper">
            <Paper
              variant="outlined"
              className="loginPop-up-form__alert"
              style={{ display: alert ? 'flex' : 'none' }}>
              <p>{ error ? error : "This ID doesn't exist. Enter correct ID or Signup for new account."}</p>
            </Paper>
        <div className={cn("loginPop-up", {
                  "closed": animation
                })}>
          <div className="loginPop-up-content">
            <div className="loginPop-up-closeIcon"
              onClick={() => {
                setLoading(false);
                setAnimation(true)
                setTimeout(() => {
                  dispatch(setIsOpenLoginPopUp(false))
                  setAnimation(false)
                }, 300)
              }}
              >
              <svg
                width="31"
                height="31"
                viewBox="0 0 31 31"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="15.5" cy="15.5" r="15.5" fill="#fff" />
                <path
                  d="M11.2045 10.0005C10.9659 10.0008 10.7328 10.0722 10.5349 10.2057C10.3371 10.3391 10.1835 10.5285 10.0938 10.7496C10.0042 10.9707 9.98244 11.2136 10.0314 11.4471C10.0804 11.6806 10.1979 11.8943 10.3689 12.0607L13.7205 15.4122L10.3689 18.7638C10.2538 18.8744 10.1618 19.0068 10.0985 19.1534C10.0351 19.2999 10.0017 19.4576 10.0001 19.6173C9.99844 19.7769 10.0287 19.9353 10.089 20.0831C10.1494 20.2309 10.2386 20.3652 10.3515 20.4781C10.4644 20.591 10.5987 20.6802 10.7465 20.7406C10.8943 20.8009 11.0527 20.8312 11.2123 20.8296C11.372 20.8279 11.5297 20.7945 11.6763 20.7311C11.8228 20.6678 11.9552 20.5758 12.0658 20.4607L15.4174 17.1091L18.7689 20.4607C18.8795 20.5758 19.012 20.6678 19.1585 20.7311C19.3051 20.7945 19.4628 20.8279 19.6224 20.8296C19.7821 20.8312 19.9405 20.801 20.0883 20.7406C20.2361 20.6803 20.3704 20.591 20.4833 20.4781C20.5962 20.3652 20.6854 20.2309 20.7457 20.0831C20.8061 19.9353 20.8363 19.7769 20.8347 19.6173C20.8331 19.4577 20.7996 19.2999 20.7363 19.1534C20.6729 19.0068 20.581 18.8744 20.4658 18.7638L17.1143 15.4122L20.4658 12.0607C20.6392 11.8921 20.7577 11.6751 20.8056 11.4381C20.8536 11.2011 20.8288 10.9551 20.7345 10.7324C20.6403 10.5097 20.481 10.3206 20.2774 10.19C20.0739 10.0595 19.8357 9.99339 19.5939 10.0005C19.2822 10.0098 18.9863 10.1401 18.7689 10.3638L15.4174 13.7154L12.0658 10.3638C11.9539 10.2487 11.8199 10.1573 11.672 10.0949C11.524 10.0325 11.3651 10.0004 11.2045 10.0005V10.0005Z"
                  fill="#00A826"
                />
              </svg>
            </div>
            {step === 2 && <div className="loginPop-up-backIcon" onClick={() => {
              setStep(1);
              }}>
              <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.58337 7H13.5M8.91671 1.5L13.7685 6.35182C14.1265 6.7098 14.1265 7.2902 13.7685 7.64818L8.91671 12.5" stroke="#F8F9FB" stroke-width="1.5" stroke-linecap="round"/>
              </svg>
            </div>}
            <div ref={ref} className="loginPop-up-header">
            {(step === 1 || step === 2) && <div className="loginPop-up-logo">
              <img src={LoginUpLogoIcon} alt="" />
              </div>}
              {step === 2 ?
              <div className="animatedText">
              <div className="loginPop-up-form__text">Login</div>
              <div className="loginPop-up-form__description">Fill the following information to enter into your account</div>
              </div>
              :
              <div className="animatedText1">
              <div className="loginPop-up-form__text">Login with</div>
              <div className="loginPop-up-form__description">Choose any one of the following options to login</div>
              </div>}
            </div>
            {step === 1 &&
              <div  className="loginPop-upWith">
                <div className="loginPop-upWith-container">
                <div className="loginPop-upWith-container-row" onClick={() => {
                      window.location.href = `https://funexch.authlink.me/?redirectUri=http://${window.location.host}`
                    }}>
                    <div className="iconTitle">
                      <img className="WAIcon" src={WhatsAppIcon} alt="" />
                      <div className="title">WhatsApp</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>

                    {/* <GoogleLogin
                        onSuccess={async (credentialResponse: any) => {
                          if(credentialResponse) {
                          // @ts-ignore
                          const recaptchaResponse = await recaptchaRef?.current?.executeAsync();
                          GeneralHandler.credentialResponse({
                            "token": credentialResponse?.credential,
                            "recaptchaResponse": recaptchaResponse,
                          }).then((response) => {
                            const stakes = response.data.result
                            window.srtmCommands.push({
                              event: "track.user.login",
                              payload: {
                                  action: "complete",
                                  userId: response.data.result.memberCode
                                }
                              });
                            dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
                            dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
                            login(
                              response.headers.authorization, response.data.result.memberCode,
                              response.data.result.loginName, stakes.settings.presetStakeSettings,
                              stakes.memberId, stakes?.settings?.preferences?.confirmation
                            )
                            setLoading(false)
                            GeneralHandler.getWithdrawalAccounts().then(acc => {
                                dispatch(setWithdrawalAccounts(acc));
                            }).catch(() =>
                              dispatch(setWithdrawalAccounts(null))
                            )
                          })
                          }
                          console.log('google', credentialResponse)
                          }}
                          shape='rectangular'
                          onError={() => {
                            console.log('Login Failed');
                        }}
                      /> */}

                  <div className="loginPop-upWith-container-row" onClick={() => glogin()}>
                    <div className="iconTitle">
                      <img className="icon" src={GoogleIcon} alt="" />
                      <div className="title">Gmail</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>
                  <div className="loginPop-upWith-container-row" onClick={() => setStep(2)}>
                    <div className="iconTitle">
                      <img className="icon" src={MobileIcon} alt="" />
                      <div className="title">Mobile Number/ User ID</div>
                    </div>
                    <img className="arrow" src={ArrowIcon} alt="" />
                  </div>
                </div>
                <div className="loginPop-upWith-redirect" onClick={() => {
                      dispatch(setIsOpenLoginPopUp(false));
                      dispatch(setIsOpenSignupPopUp(true));
                  }}>
                  Do not have an account? <span>Signup</span>
                </div>
              </div>
            }
            {step === 2 && <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
              validationSchema={LoginSchema}
            >
              {({ dirty, isValid, errors, setFieldValue }) => {
                return (
                  <Form className='loginPop-up-form'>
                    <div className="loginPop-up-form__content">
                      <div className="loginPop-up-form__content-wrapp">
                      <DropDownLogin
                        values={["Mobile number", "ID",]}
                        setChosen={(item: string) => {
                          setIsTel(item !== 'ID')
                          setFieldValue('username', '')
                      }}
                        chosen={isTel ? 'Mobile number' : "ID"}
                      />
                      <div className="loginPop-up-form__content-text">{isTel ? 'Mobile number' : 'ID'}</div>
                        {isTel ?
                          <FormikField
                            errors={errors}
                            name="username"
                            onlyNumber={true}
                            label='Mobile number'
                            type='tel'
                            inputMode='numeric'
                          />
                          :
                          <FormikField
                            errors={errors}
                            name="username"
                            label='ID'
                            type='text'
                            inputMode='text'
                          />
                        }
                        </div>
                        <div className="loginPop-up-form__content-text">Password</div>
                        <FormikField
                          errors={errors}
                          name="pasword"
                          label="*********"
                          type="password"
                        />
                        <CustomButton
                          className={!loading ? "loginPop-up-form__log-in-button arrow" : "loginPop-up-form__log-in-button loading"}
                          fullWidth={true}
                          type="submit"
                        >
                          LOG IN
                        </CustomButton>
                        <div className="loginPop-up-form__privacy-policy" >
                        <Checkbox checked disabled />
                          <div>
                            <span className="privacyText">This site is protected by reCAPTCHA and the Google. </span>
                            <span className="loginPop-up-form__privacy-policy--allocated"
                              onClick={() => {
                                setAnimation(true)
                                setTimeout(() => {
                                  dispatch(setIsOpenLoginPopUp(false))
                                  setAnimation(false)
                                  history.push("/privacy-policy")
                                }, 300)
                              }}
                            >Privacy Policy</span>
                            <span className="privacyText"> and </span>
                            <span className="loginPop-up-form__privacy-policy--allocated"
                              onClick={() => {
                                setAnimation(true)
                                setTimeout(() => {
                                  dispatch(setIsOpenLoginPopUp(false))
                                  setAnimation(false)
                                  history.push("/privacy-policy")
                                }, 300)
                              }}
                            >Terms of Service</span>
                            <span className="privacyText"> apply.</span>
                          </div>
                        </div>
                    </div>
                    <div className="click-loginn" onClick={() => {
                      dispatch(setIsOpenLoginPopUp(false));
                      dispatch(setIsOpenForgotPassword(true));
                      }}>Forgot password? <span className="click-loginn-span">Reset password</span>
                    </div>
                  </Form>
                )
              }}
            </Formik>}
          </div>
        </div>

        {recaptchaSiteKey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={recaptchaSiteKey}
            size="invisible"
          />
        )}
      </div>
    </div>,
    // @ts-ignore
    modalRoot
  )
}

export default LoginPopUp
