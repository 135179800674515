import * as Yup from 'yup';

export const betInfo = (name?:string, back?: boolean, event_name?:string, m_type?: string, item?:any, runerId?:any, runners?:any, itemOne?:any) => {
  const betInfo = {
    back: null || back, // if back - true
    event_name: null || event_name, // result[i].event.name
    selection:  null || name, // result[i].event.runners[i].name
    mType:  null || m_type, //  result[i].event.mType
    odds:  null || item?.price,  // result[i].runners.[0].back/lay.price
    runs:  null || item?.line, //  result[i].runners.[0].back/lay.line
    runerId:  null || runerId, //  result[i].runners.[i].id
    runners:  null || runners, //  result[i].runners
    item: null || itemOne
  }
  return betInfo
}

export const placeBet = (item?:any, back?:boolean, bType?:string, oddsType?:string, eventTypeId?:string, id?:string, betDelay?:number, betLine?:any, sectionId?:any) => {
  const bet = {
    side : back ? 0 : 1, //  if back, then 0; if lay, then 1
    price : null, // taken from Bet Slip’s odds
    sectionId : null || sectionId || item?.id, // result[i].runners[i].id
    bType : null || bType, // result[i].bType
    oddsType : null || oddsType, //  result[i].oddsType
    eventTypeId : null || eventTypeId, //  result[i].eventTypeId
    marketId : null || id, // result[i].id
    totalSize: null, // stake from betSlip
    betSlipRef : 0, // [In diamond, it will always be 0]
    fromOneClick : 0,
    betDelay : null || betDelay, // result[i].betDelay
    line : null || betLine?.line, // result[i].event.runners[i].back/lay[i].line
    eventId: null || undefined || item?.event?.id
  }
  return bet
}

export const changeOdds = (odd: number, plus: any) => {
  console.log('res', odd, plus);
  const setValue = () => {
    let value = 0
    if(odd >= 1 && odd < 2) return value = 0.01;
    if(odd >= 2 && odd < 3) return value = 0.02;
    if(odd >= 3 && odd < 4) return value = 0.05;
    if(odd >= 4 && odd < 6) return value = 0.1;
    if(odd >= 6 && odd < 10) return value = 0.2;
    if(odd >= 10 && odd < 20) return value = 0.5;
    if(odd >= 20 && odd < 30) return value = 1;
    if(odd >= 30 && odd < 50) return value = 2;
    if(odd >= 50 && odd < 100) return value = 5;
    if(odd >= 100) return value = 10;
    return value
  }

  if (plus) {
    let result = +odd + setValue()
    console.log('result', result);
    if(result < 10) return +result.toFixed(2)
    if(result >= 10) return +result.toFixed(1)
    if(result >= 20) return +result.toFixed(0)
    return result
  } 
  if (!plus) {
    let result = +odd - setValue()
    console.log('result1', result);
    if(result < 10) return +result.toFixed(2)
    if(result >= 10) return +result.toFixed(1)
    if(result >= 20) return +result.toFixed(0)
    return result
  }
  return +odd
}


export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  const newString = s.toLowerCase()
  return newString.replace(/(^|\s)\S/g, function (a) { return a.toUpperCase() })
}

export const FormikInputSchema = Yup.object().shape({
  odd: Yup.number()
    .required("Value can't be empty")
    .min(1.01, 'min. 1.01')
    .max(999.99, 'max. 999.99'),
  stake: Yup.string()
    .required("Value can't be empty")
    .matches(/^[1-9][0-9]*$/, 'Only digits')
    .min(0, 'min 0 characters')
    .max(6, 'max 6 symbols'),
});

export const projectionSelected = (outcome: number, odds: number, stake:number, back:number | null | undefined) => {
  const isBack = back === 0 ? true : false;
  const result = isBack
                  ? outcome + (odds - 1) * stake 
                  : outcome + ((odds - 1) * stake * -1)
  if(result !== outcome) return " » " + result.toFixed(2)
  console.log('propro',result);
  return ""
}

export  const projectionNotSelected = (outcome: number, odds: number, stake:number, back:number | null | undefined) => {
  const isBack = back === 0 ? true : false;
  const result = isBack
                  ? outcome - stake 
                  : outcome - stake * -1
  if(result !== outcome) return " » " + result.toFixed(2)
  return ""
}
