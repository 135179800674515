export enum PATH {
  API = 'api',
  AUTH = 'auth',
  CONFIG = "config",
  GOOGLE_CONFIG = 'google-config',
  LOGIN = 'login',
  SINGUP = 'registerNumber',
  REGISTR = 'verifyOtp',
  EXCHANGE = 'exchange',
  AGENCY = 'agency',
  ODDS ="odds",
  MENU = 'd-sma-menu',
  MESSAGE= 'message',
  ACCOUNT = 'account',
  ACCOUNT_LIST = 'account-list',
  NOTIFICATION = 'notification',
  BALANCE = 'balance',
  BALANCE_SETTINGS = 'balance-settings',
  REPORTS = 'reports',
  STATEMENT = 'statement',
  TRANSFER = 'transfer',
  BONUS_STATEMENT = 'bonus-statement',
  ORDER_LIST = 'order-list',
  PNL = 'pnl',
  CASINO_REPORT_HISTORY = 'casinoReportHistory',
  SETTINGS = 'settings',
  PASSWORD = 'password',
  STAKES = 'stakes',
  EVENT_TYPE = 'eventType',
  RACES = 'races',
  MATCH_CENTER = 'match-center',
  STATS = 'stats',
  D_ACCESS_TOKEN = 'd-access-token',
  ACCESS_TOKEN = 'access-token',
  SMA_EVENT = 'd-sma-event',
  EXPOSURE = "exposure",
  EVENT = 'event',
  RULES = 'rules',
  ORDER_COUNT = 'order-count',
  EVENT_LIMITS = 'event-limits',
  D_VIDEO = "video",
  ORDER = 'order',
  CASINO = 'casino',
  LIVE = 'live',
  SESSION = 'session',
  BET_HISTORY = 'bet-history',
  OG = 'og',
  RESULTS = 'results',
  CATEGORIES = 'categories',
  MAIN = 'main',
  MARKETS = 'markets',
  LADDER = "ladder",
  TABLE = 'table',
  FUNDS = 'funds',
  DEPOSIT = 'deposit',
  EXCHANGE_GAMES = 'exchange-games',
  D_RULES = 'd-rules',
  BANNERS = 'mbanners',
  PUBLIC = 'public',
  RESENDOTP = 'resendOtp',
  FORGOT = 'forgotPassword',
  VERIFYFORGOT = 'verifyforgotPassword',
  PAYMENTS = 'payments',
  USERID = 'userid',
  TXNS = 'txns',
  REQUESTID = 'requestId',
  UPDATE_DEPOSIT_REQUEST = 'update-deposit-request',
  CANCEL_REQUEST = 'cancel-request',
  SWITCH_WALLET = 'switch-wallet',
  USE_BONUS_WALLET = 'useBonusWallet',
  WALLET_CODE = 'walletCode',
  WAGERING_DETAILS = 'wagering-details',
  WALLET_TYPE = 'walletType',
  SERVER_TIME = 'server-time',
  PREFERENCES = 'preferences',
  PROMOTIONS = 'promotions',
  ADD_BANK_ACC = 'add-bank-account',
  CASHOUT = 'cashout',
}

export enum PARAMS {
  BET_HISTORY = 'orderStatus=settled',
  UNSETTLED_BETS = "orderStatus=matched-unmatched",
  ACCOUNT_STATEMENT_DETAILS = "orderStatus=settled",
  OPEN_BETS = "orderStatus=open"
}

export const ID_TOKEN = 'id_token'
export const MEMBER_CODE = 'memberCode'
export const USERNAME = 'userName'
export const STAKES = 'stakes'
export const SPORT_ID = 'sportId'
export const EVENT_NAME = 'eventName'
export const MARKET_ID = 'marketId'
export const DETAIL_ACTION = 'detatlAction'
export const MEMBER_ID = 'memberId'
export const PREFERENCES = 'preferences'


export const MEDIA_QUERY_PARAM = 'all and (max-width: 851px)'
