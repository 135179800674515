import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface GamesPastResultsList {
  result: []
  status: {}
  success: boolean
}

const INIT_GAMES_PAST_RESULT = 'INIT_GAMES_PAST_RESULT';

type InitGamesPastResultsAction = Action<typeof INIT_GAMES_PAST_RESULT> & {
  gamesPastResults: GamesPastResultsList;
};

export const initGamesPastResults = (gamesPastResults: GamesPastResultsList): InitGamesPastResultsAction => ({
  type: INIT_GAMES_PAST_RESULT,
  gamesPastResults,
});

// type AlowwedActions = InitGamesPastResultsAction;

const GamesPastResultsReducer = (gamesPastResults: GamesPastResultsList =  {} as GamesPastResultsList, action: AnyAction): GamesPastResultsList => {
  switch (action.type) {
    case INIT_GAMES_PAST_RESULT:
      return action.gamesPastResults;
    default:
      return gamesPastResults;
  }
};

export default GamesPastResultsReducer;
