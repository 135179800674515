// import { TouchEvent } from 'react';
import { useState, useEffect, useRef } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery, Snackbar } from "@material-ui/core"
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { OpenBets } from "../OpenBets";
import { useHistory } from "react-router-dom";
import CloseIcon from "../../../static/images/eventTable/close-icon.svg"
// import noData from "../../../static/images/noData.svg"
import "./styles/index.scss"
import { PARAMS, STAKES } from "../../../utils/constants";
import { EventDetailHandler } from "../../../api/event-details";
import { initStakesSettings } from "../../../redux/login/stakeSettings";
import { InitBetInfo } from "../../../redux/pages/EventDetail/betInfo";
import { InitBet } from "../../../redux/pages/EventDetail/placeBet";
import {
  placeBet, betInfo,
  changeOdds,FormikInputSchema, projectionSelected, projectionNotSelected} from "./BetHelpers";
import { OddBtn } from "../../../components/OddBtn";
import { initBets } from "../../../redux/pages/EventDetail/myBets";
import { EventHandler } from "../../../api/events";
import { initExposurePnl } from "../../../redux/pages/EventDetail/exposure";
import { InitProjection } from "../../../redux/pages/EventDetail/projectionBetSlip";
import { setIsOpenSuccess } from "../../../redux/UI/betSuccess.slice";
import { initErrMsg } from "../../../redux/UI/betErrMsg";
import betslipIcon from '../../../static/images/betSlipIcon.svg'
// import betslipArrowIcon from '../../../static/images/betSlipArrowIcon.svg'
// import ArrowIconBetslip from '../../../static/images/arrowIconBetslip.svg'
import LogoIconLoading from '../../../static/images/logoIconLoading.svg'
import { getCurrencyFormat } from "../../../utils/getCurrencyFormat.helper";
import buttonArrow from '../../../components/SignupPopUp/arrowIcon.svg'
import { setFooterBets } from "../../../redux/menu/footerBets.slice";
import { GeneralHandler } from "../../../api/general";
import { setPreferences } from "../../../redux/login/preferences.slice";
import CloseBet from '../../../static/images/crossBet.svg';
import ClearInput from '../../../static/images/clearInput.svg'
import EmptyBetslipIcon from '../../../static/images/emptyBetslip.svg'
import useCheckBonusWallets from "../../../hooks/useCheckBonusWallets";



// import { setIntervalAsync, clearIntervalAsync } from "set-interval-async/dynamic";


import { createPortal } from "react-dom";
import { setFailPopUp, setIsOpenLoginPopUp } from "../../../redux/UI/openPopUps.slice"
import CountdownTimer from "./CountdownTimer"

const modalRoot = document.querySelector("#modal-root");

function Alert(props: AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

interface FormValues {
  odd: number;
  stake: string;
}

interface Iprops {
  id: string;
}

export const BetSlip = ({ id }: Iprops) => {
  const dispatch = useDispatch();
  //const isPhone = useMediaQuery("all and (max-width: 851px)")
  const isPhone = useMediaQuery("all and (max-width: 1300px)")
  const slider = useRef<any>(null)


  const [loader, setLoader] = useState(false)
  const [loading, setLoading] = useState(false)
  const [successBet, setSuccessBet] = useState(false)
  const [openBetslipMob, setOpenBetslipMob] = useState(false)
  const [openBetslipDesk, setOpenBetslipDesk] = useState(false)
  const [count, setCount] = useState<any>(0)
  const [fancyCount, setFancyCount] = useState<any>(0)
  const [valueStake, setValueStake] = useState<string>("");
  const [errMsg, setErrMsg] = useState("");
  const [, setOpenLoadingDesktop] = useState(false)
  const history = useHistory()
  const [closeBetDeskAnimation, setCloseBetDeskAnimation] = useState(true)
  // const [closeBetDeskAnimation, setCloseBetDeskAnimation] = useState(true)

  const [successCancel, setSuccessCancel] = useState(false)
  // const successs = useSelector((state: IRootState) => state.success.isOpenSuccess)


  // for fancy or bookrmaker or matchOddsSb bets update
  const [oldOdds, setOldOdds] = useState<null | number>()
  const [oldBack, setOldBack] = useState<null | boolean>()
  const [oldName, setOldName] = useState<null | string>()
  const [oldSelection, setOldSelection] = useState<null | string>()
  const { user: { isAuthenticated } } = useSelector((state: IRootState) => ({ user: state.user }))
  const preferences = useSelector((state: IRootState) => state.preferences.confirmation)
  // const [confirmedBet, setConfirmedBet] = useState(preferences)
  const [confirmBetOpen, setConfirmBetOpen] = useState(false)
  const [closeAnimationConfirm, setCloseAnimationConfirm] = useState(true)

  // const [swipes, setSwipes] = useState<any>(0)
  const [isDown, setIsDown] = useState(false)
  const [startX, setStartX] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<any>()
  const [isSlide, setIsSlide] = useState<any>(0)
  const [touchMove, setTouchMove] = useState<any>(false)



  const [showMyBets, setShowMyBets] = useState<any>(false)

  const bet = useSelector((state: IRootState) => state.bet)

  const orderListCount = useSelector((state: IRootState) => state.orderList.orderCount)

  const betInfoData = useSelector((state: IRootState) => state.betInfo)
  const stakes = useSelector((state: IRootState) => state.stakes);
  const initialValues: FormValues = {
    odd: (betInfoData.mType === "INNINGS_RUNS" || betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER") ? fancyCount : count,
    stake: valueStake
  }

  if (!stakes?.settings) {
    //@ts-ignore
    const set = JSON.parse(localStorage.getItem(STAKES))
    dispatch(initStakesSettings(set))
  }

  // const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  const [warn, setWarn] = useState(false)
  const projection = useSelector((state: IRootState) => state.projection)
  const {footerBets} = useSelector((state: IRootState) => state.footerBets)

  const availBalance = useSelector((state: IRootState) => state.balance.availBalance);

  const sportIdEvent = localStorage.getItem('sportId')
  const sportIdHome = history?.location?.pathname?.slice(-1)

  const currentWalletState = useCheckBonusWallets()



  const balanceSettings = useSelector((state: IRootState) => state.balanceSettings)
  const [eventLimitsList, setEventLimits] = useState([])
  const limitsMarket = (eventLimitsList && eventLimitsList?.length > 0 && eventLimitsList?.filter((item: any) => item.marketId === bet.marketId)[0]) || balanceSettings?.settings?.betLimits['1']
  //@ts-ignore
  const minMaxStake = limitsMarket ? `min: ${limitsMarket?.minBet}, max: ${limitsMarket?.maxBet.toFixed(0).toString().split('').reverse().map((char, i) => char + (i % 3 ? '' : ' ')).reverse().join('')}` : ''

  useEffect(() => {
    if (isAuthenticated) {
      EventDetailHandler.eventLimits(id).then(res => {
        setEventLimits(res)
      })
    }
  }, [bet.marketId])

  // const [isChecked, setIsChecked] = useState(true);
  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  useEffect(() => {
    if(openBetslipDesk || openBetslipMob) {
      setCloseBetDeskAnimation(false)
    }
  },[openBetslipDesk, openBetslipMob])




  /// fancy or bookrmaker or matchOddsSb update check

  useEffect(() => {
    if (betInfoData.mType === "INNINGS_RUNS"
      || betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER"
    ) {
      if (betInfoData?.odds !== oldOdds && betInfoData?.back === oldBack
        && betInfoData?.event_name === oldName && betInfoData?.selection === oldSelection) {
        setWarn(true)
      }
      setOldOdds(betInfoData?.odds)
      setOldBack(betInfoData?.back)
      setOldName(betInfoData?.event_name)
      setOldSelection(betInfoData?.selection)
    }
    return () => {
      setOldOdds(null)
      setOldBack(null)
      setOldName(null)
      setOldSelection(null)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betInfoData.mType, betInfoData.odds, betInfoData.back, betInfoData.event_name])


  const [betDetailsBack, setBetDetailsBack] = useState<any>()
  const [betDetailsRunner, setBetDetailsRunner] = useState<any>()
  const [betDetailsSelection, setBetDetailsSelection] = useState<any>()
  const [betValueStake, setBetValueStake] = useState<any>('')

  useEffect(() => {
      setTimeout(() => {
        setBetDetailsBack(betInfoData?.back)
        setBetDetailsRunner(betInfoData?.runerId)
        setBetDetailsSelection(betInfoData?.selection)
      }, 2000)
      setBetValueStake(valueStake)
  }, [openBetslipMob, openBetslipDesk])


  useEffect(() => {
    const value = betDetailsBack === betInfoData?.back
                    && betDetailsRunner === betInfoData?.runerId
                      && betDetailsSelection === betInfoData?.selection
                       && count
                        ? count
                        : betInfoData.odds
    if (value) {
      if(betInfoData.mType === "INNINGS_RUNS"
        || betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER"
      ) {
        // @ts-ignore
        setFancyCount(betInfoData?.odds)
      } else {
        setCount(value)
      }
    }
    setValueStake(betDetailsBack === betInfoData?.back && betDetailsRunner === betInfoData?.runerId && betDetailsSelection === betInfoData?.selection ? betValueStake : '')
    setErrMsg("")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betInfoData?.odds, betInfoData?.event_name, betInfoData?.mType])

  useEffect(() => {
    if (betInfoData.odds && isPhone) {
      setOpenBetslipMob(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [betInfoData.odds, isPhone])

  useEffect(() => {
    if(footerBets){
      setOpenBetslipMob(true)
      setOpenBetslipDesk(true)
    }
  },[footerBets])


  useEffect(() => {
    if (betInfoData.odds && !isPhone) {
      setOpenBetslipDesk(true)
    }
    // eslint
  }, [betInfoData.odds, !isPhone])

  // handle change inputs values for projection

  useEffect(() => {
    const res = {
      event_name: betInfoData.event_name,
      selection: betInfoData.selection,
      odds: count ? count : fancyCount,
      stake: +valueStake,
      marketId: bet.marketId,
      back: bet.side
    }
    dispatch(InitProjection(res))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueStake, count, fancyCount, betInfoData.mType])

  // useEffect(() => {
  //   if(successBet){
  //     setOpenLoadingDesktop(false)
  //   }
  // }, [successBet])

  const clickConfirmBet = () => {
    setConfirmBetOpen(true)
    setCloseAnimationConfirm(false)
  }

  const clickPlaceBetAfterConfirm = async (odd: any, stake: any) => {
    setTimeout(() => {
      setConfirmBetOpen(false)
    },300)
    // setConfirmedBet(false)
    const res: any = []
    const betItem = {
      side: bet.side,
      price: odd,
      selectionId: bet.sectionId,
      bType: bet.bType,
      oddsType: bet.oddsType,
      eventTypeId: bet.eventTypeId,
      marketId: bet.marketId,
      totalSize: stake,
      betSlipRef: bet.betSlipRef,
      fromOneClick: bet.fromOneClick,
      betDelay: bet.betDelay,
      line: bet.line
    }
    res.push(betItem)
      setLoading(true)
      setLoader(true)

    try {
      await EventDetailHandler.placeBet(res)
      const params = PARAMS.UNSETTLED_BETS;
      EventDetailHandler.getBetsList(params, id).then(res => {
        dispatch(initBets(res))
        if (isPhone) {
          setSuccessBet(true)
      }
      // else {
      //   setSuccess(true);
      // }
      dispatch(setIsOpenSuccess(true))
      setLoading(false)
      setSuccessBet(true)
      setTimeout(() => {
        setSuccessBet(false)
      }, 700)
      })
      EventHandler.getEventExposureRequest(id).then(res => {
        dispatch(initExposurePnl(res.result))
      })



    } catch (error: any) {

      // its not our proplem, worst Api ever, so u see code below
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.error["0"]) {
              if (error.response.data.error["0"][0]) {
              dispatch(initErrMsg({ errMsg: error.response.data.error["0"][0].description }))
              setErrMsg(error.response.data.error["0"][0].description)
              dispatch(setFailPopUp({isOpenFail: true}));
              setFail(true);

                // if (isPhone) {
                //   setTimeout(() => dispatch(initErrMsg({ errMsg: error.response.data.error["0"][0].description })), 100)
                // } else {
                //   setTimeout(() => setErrMsg(error.response.data.error["0"][0].description), 100)
                // }
              }
            }
          }
      }
      setLoading(false)
        handleClose()
      }

    }
  }

  const clickPlaceBet = async (values: FormValues) => {
    const res: any = []
    const betItem = {
      side: bet.side,
      price: values.odd,
      selectionId: bet.sectionId,
      bType: bet.bType,
      oddsType: bet.oddsType,
      eventTypeId: bet.eventTypeId,
      marketId: bet.marketId,
      totalSize: values.stake,
      betSlipRef: bet.betSlipRef,
      fromOneClick: bet.fromOneClick,
      betDelay: bet.betDelay,
      line: bet.line
    }
    res.push(betItem)
      setLoading(true)
      setLoader(true)

    try {
      await EventDetailHandler.placeBet(res)
      const params = PARAMS.UNSETTLED_BETS;
      EventDetailHandler.getBetsList(params, id).then(res => {
        dispatch(initBets(res))
      if (isPhone) {
          setSuccessBet(true)
      }
        // else {
          // setSuccess(true);
        // }
        dispatch(setIsOpenSuccess(true))
      setLoading(false)
      setSuccessBet(true)
      setTimeout(() => {
        setSuccessBet(false)
      }, 700)
      })
      EventHandler.getEventExposureRequest(id).then(res => {
        dispatch(initExposurePnl(res.result))
      })


    } catch (error: any) {

      // its not our proplem, worst Api ever, so u see code below
      if (error) {
        if (error.response) {
          if (error.response.data) {
            if (error.response.data.error["0"]) {
              if (error.response.data.error["0"][0]) {
                dispatch(initErrMsg({ errMsg: error.response.data.error["0"][0].description }))
                setErrMsg(error.response.data.error["0"][0].description)
                dispatch(setFailPopUp({isOpenFail: true}));
                setFail(true);

                // if (isPhone) {
                //   setTimeout(() => dispatch(initErrMsg({ errMsg: error.response.data.error["0"][0].description })), 100)
                // } else {
                //   setTimeout(() => setErrMsg(error.response.data.error["0"][0].description), 100)
                // }
              }
      }
          }
        }
        handleClose()
      setLoading(false)
    }

    }
  }

  // useEffect(() => {
  //   if(successs && (openBetslipDesk || openBetslipMob)){
  //     setTimeout(() => {
  //       setShowMyBets(true)
  //     },700)
  //   } else {
  //     setShowMyBets(false)
  //   }
  // }, [successs])

  // count

  const handleChangeCount = (e: any) => {
    setCount(e.target.value);
  }

  const handleIncrementCount = () => {
    if(count < 999.99){
    setCount(+changeOdds(count, true))
    }
  };

  const handleDecrementCount = () => {
    if(count > 1.01){
    setCount(+changeOdds(count, false))
  }
  }


  // stake

  const setStake = (e: any) => {
    const value = e.target.innerHTML;
    const newValue = ((+valueStake) + (+value)).toString()
    setValueStake(newValue)
  }

  const maxStake = (e: any) => {
    if(isAuthenticated){
      let newValue
      if(availBalance < limitsMarket.maxBet){
        newValue = availBalance.toFixed(0)
      } else {
        newValue = limitsMarket.maxBet.toString()
      }
      setValueStake(newValue)
    }
  }

  // const inputRegex = /^([0-9]+(\.[0-9]*)?|[0-9]*\.[0-9]+)$/;


  // const handleChangeStake = (e: any) => [
  //   // if (!inputRegex.test(e.target.value)) {
  //   //   return false;
  //   // }
  //   setValueStake(e.target.value)
  //   // return true;
  // ]

  // betslip

  const handleSubmit = (values: FormValues) => {
    isAuthenticated && preferences ? clickConfirmBet() : isAuthenticated && !preferences ? clickPlaceBet(values) : dispatch(setIsOpenLoginPopUp(true))
  }


  const handleClose = () => {
    setCount(null)
    setShowMyBets(false)
    setFancyCount(0)
    setTimeout(() => {
      setOpenBetslipDesk(false)
    }, 400)
    // setSuccess(false)
    setFail(false)
    setTimeout(() => {
      dispatch(InitBet(placeBet()))
      dispatch(InitBetInfo(betInfo()))
      setOpenBetslipMob(false)
    }, 400)
    setErrMsg("")
    setLoader(false)
    setConfirmBetOpen(false)
    // setConfirmedBet(false)
    setOpenLoadingDesktop(false)
    dispatch(setFooterBets(false))
    setIsSlide(0)
    setCloseBetDeskAnimation(true)
    setSuccessBet(false)
  }

  const handleReset = () => {
    handleClose()
  }

  // const handleCloseMob = () => {
  //   setOpenBetslipMob(false)
  //   setTimeout(handleClose, 1000);
  // }

  const handleCloseAlert = () => {
    // setSuccess(false)
    setFail(false)
    setWarn(false)
    setErrMsg("")
    // handleClose()
    setSuccessCancel(false)
  }

  const handleCloseAlertWarn = () => {
    // setSuccess(false)
    setFail(false)
    setWarn(false)
    setErrMsg("")
  }

  const betslipMobRef = useRef<HTMLDivElement>(null)
  const handler = (event: any) => {
    if (betslipMobRef.current && !betslipMobRef.current.contains(event.target)) {
      // setOpenBetslipMob(false)
      handleClose()
    }
  }

  useEffect(() => {
  if(successBet) {
    setTimeout(() => {
      setShowMyBets(true)
    },600)
  }
    // setShowMyBets(false)
  }, [successBet])

  const clickPreferences = () => {
    GeneralHandler.setPreferensesReq(!preferences).then((response) => {
      if(response) {
        // setConfirmedBet(!preferences)
        dispatch(setPreferences(!preferences))
        localStorage.setItem('preferences', JSON.stringify(!preferences))
      }
    })
  }

  // const [countDownTime, setCountDownTime] = useState<any>()

  // useEffect(() => {
  //   setCountDownTime(bet?.betDelay)
  // }, [confirmBetOpen])


  // useEffect(() => {
  //   let intervalId:any = null;
  //   const startCountdown = async () => {
  //     if (countDownTime > 0) {
  //       intervalId = await setIntervalAsync(() => {
  //         setCountDownTime((prevState: any) => prevState - 1);
  //       }, 1000);
  //       // setClearCountdown(intervalId)
  //     }
  //   };
  //   startCountdown();

  //   return () => {
  //     if (intervalId) {
  //       clearIntervalAsync(intervalId);
  //     }
  //   };
  // }, [countDownTime]);

  useEffect(() => {
    if (isSlide === 1) {
      setTimeout(() => setIsSlide(0), 200)
      setTouchMove(false)
    }
    if(isSlide === 2){
      handleClose()
    }
  }, [isSlide])




  useEffect(() => {
      // setOpenBetslipMob(false)
      // setOpenBetslipDesk(false)
      // document.addEventListener("click", handler, true)


      return () => {
        document.removeEventListener("click", handler, true)
      }
  }, [])

  useEffect(() => {
    if(showMyBets){
      setLoader(false)
    }
  }, [showMyBets])

  const exposure = useSelector((state: IRootState) => state.exposurePnl)
  // const projection = useSelector((state: IRootState) => state.projection)
  const [findExposure, setFindExposure] = useState(false)
  useEffect(() => {
    if (exposure && exposure.pnlBySelection && Object.keys(exposure.pnlBySelection).length !== 0) {
      setFindExposure(true)
    } else setFindExposure(false)

    return () => {
      setFindExposure(false)
    }
  }, [exposure])

  const rippleRef = useRef<any>(null);
  const onRippleStart = (e: any) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop = (e: any) => {
    rippleRef?.current?.stop(e);
  };

  const profit = () => {
    const type = 'Profit'
    const isFancy = betInfoData.mType == 'INNINGS_RUNS' && bet.bType == 'LINE'
    let stake
    if (valueStake) {
      if (!isFancy && betInfoData.mType?.includes('_SB')){
        if(betInfoData?.back){
        stake = getCurrencyFormat((+fancyCount - 1) * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
      if(isFancy){
        if(betInfoData?.back){
          // @ts-ignore
          stake = getCurrencyFormat(betInfoData?.odds / 100 * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
      if (!isFancy && !betInfoData.mType?.includes('_SB')){
        if(betInfoData?.back){
          // @ts-ignore
          stake = getCurrencyFormat((count - 1) * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
    } else {
      stake = '0:00'
    }
    // @ts-ignore
    return `${type}: ${stake}`
  }


  const liability = () => {
    const type = 'Liability'
    const isFancy = betInfoData.mType == 'INNINGS_RUNS' && bet.bType == 'LINE'
    let stake
    if (+valueStake) {
      if (!isFancy && betInfoData.mType?.includes('_SB')){
        if(!betInfoData?.back){
          stake = getCurrencyFormat((fancyCount - 1) * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
      if(isFancy){
        if(!betInfoData?.back){
          // @ts-ignore
          stake = getCurrencyFormat(betInfoData?.odds / 100 * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
      if (!isFancy && !betInfoData.mType?.includes('_SB')) {
        if(!betInfoData?.back){
    // @ts-ignore
          stake = getCurrencyFormat((count - 1) * +valueStake)
        } else {
          stake =  +valueStake
        }
      }
    } else {
      stake = '0:00'
    }
    return `${type}: ${stake}`
  }

  return createPortal(
    (openBetslipDesk || openBetslipMob) &&
    <div className={`${openBetslipDesk ? 'betSlipPopUp' : ''}`} onClick={() => setCloseBetDeskAnimation(true)}
    ref={slider}
    // @ts-ignore
    onTouchStart={e => {
      setIsDown(true)
      setStartX(e.touches[0].pageY - slider.current.offsetTop)
      setScrollLeft(slider.current.scrollTop)
    }}
    onTouchEnd={(e) => {
      if(touchMove){
        setIsSlide(isSlide + 1)
      }
      setIsDown(false)
    }}
    onTouchMove={e => {
      if (!isDown) return
      e.preventDefault()
      const x = e.touches[0].pageY - slider.current.offsetTop
      const walk = (x - startX) * 3 //scroll-fast
      slider.current.scrollTop = scrollLeft - walk
      if(e.type === "touchmove"){
        setTouchMove(true)
      }
    }}
    >

    <div className={`betslip sportEventDetailsBetslip ${isPhone ? (closeBetDeskAnimation ? 'close' : openBetslipMob ? 'open' : '') :
    !isPhone ? (closeBetDeskAnimation ? 'closeDesk' : openBetslipDesk ? 'openDesk' : '') 
    : ''}`} onClick={() => handleClose()}>
         {errMsg && <Snackbar open={fail}
            className="betslip_message betslip_message-error"
            onClose={handleCloseAlert}
            disableWindowBlurListener={true}
            autoHideDuration={6000}
          >
            <Alert onClose={handleCloseAlert} severity="error">
              {errMsg
                ? <div>{errMsg}</div>
                : "Placing bet error. Please try again"
              }
            </Alert>
          </Snackbar>}
          <Snackbar open={warn}
            className="betslip_message betslip_message-warning"
            onClose={handleCloseAlertWarn}
            disableWindowBlurListener={true}
          >
            <Alert onClose={handleCloseAlertWarn} severity="warning">
              The Odds of your selection have changed
            </Alert>
          </Snackbar>
          <Snackbar open={successCancel}
            className="betslip_message betslip_message-success"
            onClose={handleCloseAlert}
            disableWindowBlurListener={true}
            autoHideDuration={2000}
          >
            <Alert onClose={handleCloseAlert} severity="success">
              Bet Cancelled Successfully
            </Alert>
          </Snackbar>
      <div className="wrapper" style={footerBets ? {height: 'auto'} : {}}  ref={betslipMobRef} onClick={(e) => e.stopPropagation()}>
      {Object.keys(bet).length !== 0 && Object.keys(betInfoData).length !== 0 && betInfoData.event_name && betInfoData.selection && betInfoData.mType !== 'INNINGS_RUNS' && bet.bType !== 'LINE' &&(
        <div className="betslip-showOdds">
           {betInfoData.event_name === projection.event_name && <div className={`betSlipExposure
           ${history?.location?.pathname?.includes('event-detail') && betInfoData?.runners?.length !== 2 && (sportIdEvent != '1' || sportIdHome != '1') 
           ? 'singleBet'
           : (sportIdEvent == '1' || sportIdHome == '1' ) && betInfoData?.runners?.length > 2 
           ? 'tripleBet'
           : ''
          }`}>
              {betInfoData.runners && (history?.location?.pathname?.includes('event-detail') ? betInfoData.runners : (sportIdEvent =='1' || sportIdHome == '1') && betInfoData?.runners?.length > 2 ?  [betInfoData.runners[0], betInfoData.runners[2], betInfoData.runners[1]] : betInfoData.runners.slice(0, 2)).map((item: any, index: number) => {
                let outcome = ""
                if (findExposure) {
                  const objectKey = `${bet?.marketId}-${item.id}`
                  const valueExposure = exposure.pnlBySelection[`${objectKey}`]
                  if (valueExposure && valueExposure.pnl) {
                    outcome = valueExposure.pnl
                  }
                }
                return (
                  !history?.location?.pathname?.includes('event-detail') || (history?.location?.pathname?.includes('event-detail') && betInfoData?.runners?.length <= 2) ?
                  <div>
                    {(betInfoData.event_name === projection.event_name && bet.marketId === projection.marketId) && (
                      <>
                        {betInfoData.event_name === projection.event_name && item.name === projection.selection
                          ? (
                            <span className={`outcome ${+(projectionSelected(+outcome, projection.odds, projection.stake, projection.back).substring(2)) > 0 ? "plus" : "minus"}`}>
                              {projectionSelected(+outcome, projection.odds, projection.stake, projection.back)}
                            </span>
                          )
                          :
                          (
                            <span className={`outcome ${+(projectionNotSelected(+outcome, projection.odds, projection.stake, projection.back).substring(2)) > 0 ? "plus" : "minus"}`}>
                              {projectionNotSelected(+outcome, projection.odds, projection.stake, projection.back)}
                            </span>
                          )
                        }
                      </>
                    )}
                    </div>
                    :
                    <div>
                    {(betInfoData.event_name === projection.event_name && bet.marketId === projection.marketId) && (
                      <>
                        {betInfoData.event_name === projection.event_name && item.name === projection.selection
                          && (
                            <span className={`outcome ${+(projectionSelected(+outcome, projection.odds, projection.stake, projection.back).substring(2)) > 0 ? "plus" : "minus"}`}>
                              {projectionSelected(+outcome, projection.odds, projection.stake, projection.back)}
                            </span>
                          )
                        }
                      </>
                    )}
                  </div>
                )
              })}
            </div>}
          <div className="betslip-showOdds-titleContainer">
            <div className="betslip-showOdds-title">{betInfoData?.event_name}</div>
            <div className="betslip-showOdds-titleContainer-group">
              <div className="betslip-showOdds-oddsName">Back</div>
              <div className="betslip-showOdds-oddsName">Lay</div>
            </div>
          </div>
          {!history?.location?.pathname?.includes('event-detail') || (history?.location?.pathname?.includes('event-detail') && betInfoData?.runners?.length <= 2) ?
          <div>
            <div className="betslip-showOdds-oddsBox">
              <div className="betslip-showOdds-oddsBox-team">
                <span>
                  {betInfoData?.runners?.[0]?.name}
                </span>
              </div>
              <div className="betslip-showOdds-oddsBox-oddsItems">
                <div className="betslip-showOdds-oddsBox-oddsItems-itemBack">
                  <OddBtn runner={betInfoData?.runners?.[0]} isBack={true} />
                </div>
                <div className="betslip-showOdds-oddsBox-oddsItems-itemLay">
                  <OddBtn runner={betInfoData?.runners?.[0]} isBack={false}/>
                </div>
              </div>
            </div>
            {sportIdEvent == '1' || sportIdHome == '1' && betInfoData?.runners?.length > 2 && <div className="betslip-showOdds-oddsBox">
              <div className="betslip-showOdds-oddsBox-team">
                <span>
                  {betInfoData?.runners?.[2]?.name}
                </span>
              </div>
              <div className="betslip-showOdds-oddsBox-oddsItems">
                <div className="betslip-showOdds-oddsBox-oddsItems-itemBack">
                  <OddBtn runner={betInfoData?.runners?.[2]} isBack={true} />
                </div>
                <div className="betslip-showOdds-oddsBox-oddsItems-itemLay">
                  <OddBtn runner={betInfoData?.runners?.[2]} isBack={false}/>
                </div>
              </div>
            </div>}
            <div className="betslip-showOdds-oddsBox">
              <div className="betslip-showOdds-oddsBox-team">
                <span>
                  {betInfoData?.runners?.[1]?.name}
                </span>
              </div>
              <div className="betslip-showOdds-oddsBox-oddsItems">
                <div className="betslip-showOdds-oddsBox-oddsItems-itemBack">
                  <OddBtn runner={betInfoData?.runners?.[1]} isBack={true} />
                </div>
                <div className="betslip-showOdds-oddsBox-oddsItems-itemLay">
                  <OddBtn runner={betInfoData?.runners?.[1]} isBack={false}/>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="betslip-showOdds-oddsBox">
              <div className="betslip-showOdds-oddsBox-team">
                <span>
                    {betInfoData?.item?.name}
                </span>
              </div>
              <div className="betslip-showOdds-oddsBox-oddsItems">
                <div className="betslip-showOdds-oddsBox-oddsItems-itemBack">
                  <OddBtn runner={betInfoData?.item} isBack={true} />
                </div>
                <div className="betslip-showOdds-oddsBox-oddsItems-itemLay">
                  <OddBtn runner={betInfoData?.item} isBack={false}/>
                </div>
              </div>
            </div>}
        </div>
      )}
      <div style={{background: '#fff', borderRadius: '20px'}}>
        <div className="betslip__header">
          <div style={{display: 'flex'}}>
            <img src={betslipIcon} alt="" />
            <div className="betslip__header-name">
              Betslip
            </div>
          </div>
          <button className="betslip__header-close" onClick={() => {
            setCloseBetDeskAnimation(true)
            handleClose()
            }} >
            <img src={CloseBet} title="close" alt="close" />
          </button>
        </div>

        {/* <div className={`betslip__body ${isPhone ? (betInfoData.back ? 'back' : 'lay') : ''}`}> */}

        <div className='betslip__body' style={footerBets && !showMyBets ? {height: '556px'} : {}}>
          {Object.keys(bet).length !== 0 && Object.keys(betInfoData).length !== 0 && betInfoData.event_name && betInfoData.selection || footerBets ? (
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={(values) => {
                handleSubmit(values)
              }}
              validateOnBlur={false}
              validationSchema={FormikInputSchema}
            >
              {({ isValid }) => {
                return (
                  <>
                  <div className='betPlacingPop-up__body__buttons'>
                    <div className={`betPlacingPop-up__body__buttons-button${!showMyBets ? '-active' : ''}`} onClick={() => setShowMyBets(false)}>Betslip</div>
                    <div className={`betPlacingPop-up__body__buttons-button${showMyBets ? '-active' : ''}`} onClick={() => setShowMyBets(true)}>My Bets
                      <div style={showMyBets ? {background: '#000000'} : { background: '#00A826'}} className="showMyBetsLength">{orderListCount}</div>
                    </div>
                  </div>
                  {!showMyBets && loader &&
                    <div className="loadingPlaceBet">
                      <div className="loaderBet">
                        {loading && <>
                          <div className="spinnerBet"></div>
                          <CountdownTimer className={"loaderBetTime"} time={bet?.betDelay  || 0}>
                            <img className={`loadingPlaceBetIcon`} src={LogoIconLoading} alt="" />
                          </CountdownTimer>
                        </>}
                        {successBet && !loading && <div className="success-checkmark">
                          <div className="check-icon">
                            <span className="icon-line line-tip"></span>
                            <span className="icon-line line-long"></span>
                            <div className="icon-circle"></div>
                            <div className="icon-fix"></div>
                          </div>
                        </div>}
                      </div>
                      <div className={`loader-title`}>{loading ? 'Processing' : 'Success!'}</div>
                      <div className="loader-descr1">Please wait. We are processing your bets.</div>
                      <div className="loader-descr2">It will take few seconds</div>

                    </div>}
                  {!showMyBets ? <>
                  {!footerBets && !loader ? <Form>
                    <div className="betslipAnimation">
                    <div className={`betslip__body-place-bet ${betInfoData.back ? 'back' : 'lay'}`}>
                      {/* <div className="redCross">
                        <img className='redCross-icon' src={CloseBet} alt=''/>
                      </div> */}
                      <div className="betslipTeamMarket">
                        <div style={{display: 'flex'}}>
                          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" y="1.66248" width="18" height="18" rx="9" fill={`${betInfoData?.back ? '#72BBEF' : '#f5bdc6'}`} stroke={`${betInfoData?.back ? "#47A2E2" : '#f0aab6'}`} stroke-width="2"/>
                            <path d="M12.9724 7.93872C13.1162 7.93872 13.2498 7.96306 13.3732 8.01173C13.4999 8.05736 13.6095 8.12124 13.702 8.20337C13.7945 8.2855 13.8664 8.38284 13.9178 8.49539C13.9726 8.6049 14 8.72353 14 8.85129C14 8.97297 13.9743 9.09008 13.9229 9.20263C13.8715 9.31519 13.7979 9.41405 13.702 9.49922L9.62235 13.1267C9.52644 13.2119 9.41511 13.2788 9.28838 13.3275C9.16164 13.3731 9.02976 13.3959 8.89274 13.3959C8.75573 13.3959 8.62385 13.3731 8.49711 13.3275C8.37037 13.2788 8.25905 13.2119 8.16313 13.1267L6.30315 11.4749C6.20724 11.3898 6.13188 11.2909 6.07707 11.1784C6.02569 11.0658 6 10.9487 6 10.827C6 10.6993 6.0274 10.5806 6.08221 10.4711C6.13702 10.3586 6.21066 10.2612 6.30315 10.1791C6.39563 10.097 6.50353 10.0331 6.62685 9.98745C6.75359 9.93878 6.88889 9.91444 7.03276 9.91444C7.16977 9.91444 7.30165 9.93726 7.42839 9.98289C7.55513 10.0285 7.66645 10.0939 7.76236 10.1791L8.89274 11.1829L12.2428 8.20337C12.3387 8.11819 12.45 8.05279 12.5768 8.00716C12.7035 7.96154 12.8354 7.93872 12.9724 7.93872Z" fill="#FFF"/>
                          </svg>
                          <div style={{marginLeft: '4px'}} className="betslip-team">{betInfoData?.selection}</div>
                        </div>
                        <div className="betslip-maxMarket">Max Market: {getCurrencyFormat(limitsMarket?.maxPerMarket)}</div>
                      </div>
                      <div style={{display: 'flex', justifyContent: 'space-between'}}>
                      <div className={`betslip__body-place-bet_group betslip__body-place-bet_odd ${betInfoData.mType === "INNINGS_RUNS" ? 'fancy' : ''}`}>
                        {betInfoData.mType === "INNINGS_RUNS" ? (
                          <>
                            <div className="fancy__item fancy__item--odds">
                              <div className="betslip__body-place-bet_group-title">Odds(H-J)</div>
                              <div className="betslip__body-place-bet_group-result">{betInfoData.odds}</div>
                            </div>
                            <div className="fancy__item fancy__item--runs">
                              <div className="betslip__body-place-bet_group-title">Runs</div>
                              <div className="betslip__body-place-bet_group-result">{betInfoData.runs}</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="betslip__body-place-bet_group-title">Odds</div>
                            <div style ={{marginRight: '10px'}}className={`betslip__body-place-bet_group-input`}>
                              <div className={`place-bet_input-odd-minuss ${betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER" ? 'disabledd' : ''}`}onClick={() => handleDecrementCount()}>
                                {/* <img style={{transform: 'rotate(180deg)'}} src={ArrowIconBetslip} alt='' /> */}
                                -
                              </div>
                              <Field name="odd" type="number"
                                value={(betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER")
                                  ? (bet.marketId && bet.marketId.slice(bet.marketId.length - 3) === '_SB' ? ((fancyCount - 1) * 100).toFixed(2).replace('.00', '') : fancyCount)
                                  : count
                                } step="any" onChange={handleChangeCount} disabled={betInfoData?.mType?.includes('SB') || betInfoData?.mType?.includes('BOOKMAKER')} />
                              <div className={`place-bet_input-odd-pluss ${betInfoData.mType === "MATCH_ODDS_SB" || betInfoData.mType === "BOOKMAKER" ? 'disabledd' : ''}`} onClick={() => handleIncrementCount()}>
                                {/* <img src={ArrowIconBetslip} alt='' /> */}
                                +
                              </div>
                            </div>
                            <div className='betslip__body-place-bet_error'>
                              <ErrorMessage className='change-password__form_box_error' name="odd" />
                            </div>
                          </>
                        )}
                      </div>

                      <div className="betslip__body-place-bet_group betslip__body-place-bet_stake">
                        <div className="betslip__body-place-bet_group-title">Stake</div>
                        <div className="betslip__body-place-bet_group-input">
                          <Field
                            name="stake"
                            type="text"
                            placeholder="Amount"
                            onChange={(e: any) => {
                             if (/^[1-9]\d*$/.test(e.target.value)) {
                              setValueStake(e.target.value)
                             } else if (e.target.value === '') {
                              setValueStake('')
                             }
                            }}
                            value={valueStake ? valueStake : ''}
                            // onInput={(e: any) => e.target.value = e.target.value.slice(0, 6)}
                            minlength="0"
                            maxlength="6"
                            />
                          <div className='betslip__body-place-bet_error'>
                            <ErrorMessage className='change-password__form_box_error' name="stake" />
                          </div>
                          <img className="clearInput" src={ClearInput} alt="" onClick={() => setValueStake('')} />
                        </div>
                      </div>
                      </div>
                      </div>
                      <div className="betslipButtonsContainer">
                        <div className="betslipButtonsBox">
                          <>
                          {(stakes?.settings || ["100", "200", "500", "1000", "5000", "10000"]).slice(0,3).map((item, index) => {
                            return (
                                <div
                                  className="betslip__body-place-bet_item"
                                  key={index}
                                  onClick={setStake}
                                >
                                  {item}
                                </div>
                            )
                          })}
                          <div className="betslip__body-place-bet_itemm" onClick={maxStake}>
                          Max
                        </div>
                          </>
                        </div>
                        <div className="betslipButtonsBox">
                          <>
                          {(stakes?.settings || ["100", "200", "500", "1000", "5000", "10000"]).slice(3, 6).map((item, index) => {
                            return (
                                <div
                                  className="betslip__body-place-bet_item"
                                  key={index}
                                  onClick={setStake}
                                >
                                  {item}
                                </div>
                            )
                          })}

                          <div style={{}} className="betslip__body-place-bet_itemm"
                            onClick={() => {
                              if(isAuthenticated) {
                                handleClose()
                                setTimeout(() => {
                                  history.push('/user/set-button-values')
                                }, 400)
                              }
                            }}>
                          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.566772 8.88623L7.0199 2.43311L9.2074 4.62061L2.75427 11.0737H0.566772V8.88623ZM10.9027 2.92529L9.8363 3.9917L7.6488 1.8042L8.71521 0.737793C8.82458 0.628418 8.9613 0.57373 9.12537 0.57373C9.28943 0.57373 9.42615 0.628418 9.53552 0.737793L10.9027 2.10498C11.0121 2.21436 11.0668 2.35107 11.0668 2.51514C11.0668 2.6792 11.0121 2.81592 10.9027 2.92529Z" fill="#00A826"/>
                          </svg>
                        </div>
                          </>
                      </div>
                      </div>
                      <div className="betslipConfirmBox">
                        <div className="betslipConfirmBox-confirm">
                        <div className="checkBoxWrapperBet">
                          {/* <input type="checkbox" onClick={() => setConfirmedBet(!confirmedBet)}/>
                          <svg viewBox="0 0 35.6 35.6">
                            <circle className="background" cx="17.8" cy="17.8" r="17.8"></circle>
                            <circle className="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                            <polyline className="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                          </svg> */}
                          {/* @ts-ignore */}


                          <label htmlFor="cbxx" className="cbxx">
                            <div className="checkmarkk">
                              <input
                                type="checkbox"
                                id="cbxx"
                                checked={preferences}
                                onClick={() => {
                                  // setConfirmedBet(!confirmedBet)
                                  clickPreferences()
                                }}
                              />
                              <div className="flipp">
                                <div className="frontt"></div>
                                <div className="backk">
                                  <svg viewBox="0 0 16 14" height="14" width="16">
                                    <path d="M2 8.5L6 12.5L14 1.5"></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </label>


                        </div>
                          <div>Confirm bet</div>
                        </div>
                        <div>Liability:
                          {/* {profit().split(':')[1]} */}
                          {liability().split(':')[1]}
                          </div>
                      </div>

                      <button
                        style={{display: 'none'}}
                        className="betslip__body-place-bet_reset"
                        onClick={handleReset}
                      >
                        Cancel
                      </button>
                      <button
                        onMouseDown={onRippleStart}
                        onMouseUp={onRippleStop}
                        className="betslip__body-place-bet_submit"
                        type="submit"
                        disabled={isAuthenticated ? !isValid : false}
                        onClick={() => {
                          if(!isAuthenticated) {
                            dispatch(setIsOpenLoginPopUp(true))
                            setCloseBetDeskAnimation(true)
                            handleClose()
                          }
                        }}
                      >
                        {/* <TouchRipple ref={rippleRef} center={false} />
                        {isAuthenticated ? " Place bet" : "Login"} */}
                {isAuthenticated && <div className="confirmCountDown">{bet?.betDelay}s</div>}

                        <div  className="placeBetTitle">
                          {isAuthenticated ? " Place bet" : "Login"}
                          <img src={buttonArrow} alt="" />
                        </div>
                        {isAuthenticated && <div style={!isValid ? {color: '#fff'} : {}} className="placeBetDescr">Place Bet now & get {profit()}</div>}

                      </button>

                      {/* {!isPhone && */}
                      <div className="betSlipFoot">
                        <div className="betSlipFooter">Your wallet health getting poor due to a low balance</div>
                        <div className="betSlipFooterAddMore" onClick={() => {
                          handleClose()
                          setTimeout(() => {
                            history.push('/user/deposit')
                          }, 400)
                        }}>Add More Funds</div>
                      </div>
                    </div>
                  </Form>
                  :
                  !loader && <div className="betslip__body-nodata">
                    <img src={EmptyBetslipIcon} alt="no data" className="betslip__body-nodata-image" />
              <p className="betslip__body-nodata-text">
                      <span>Betslip is empty!</span>
                      Please select a match to make a bet
              </p>
                    {!currentWalletState && <div className="betslip__body-nodata-button" onClick={() => handleClose()}>Select Match</div>}
                    {<div className="betslip__body-nodata-button" style={{marginTop: '10px'}} onClick={() => {
                          handleClose()
                          setTimeout(() => {
                            history.push('/user/deposit')
                          }, 400)
                        }}>Add More Funds</div>}
                  </div>}
                  </>
                  :
                  <OpenBets setSuccessCancel={setSuccessCancel}/>}
                  </>
                )
              }}
            </Formik>
          ) : (
            null
          )}
        </div>
        </div>
      </div>
    </div>



    {openBetslipMob && confirmBetOpen && <div className={`betSlipCondirm`}
    onClick={(e) => {
      e.stopPropagation()
      setTimeout(() => {
        setConfirmBetOpen(false)
      },300)
      setCloseAnimationConfirm(true)
      }}>
     {Object.keys(bet).length !== 0 && Object.keys(betInfoData).length !== 0 && betInfoData.event_name && betInfoData.selection &&(
      <div className={`betSlipCondirm-wrapper ${closeAnimationConfirm ? 'closeConfirm' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="betSlipCondirm-wrapper-content">
            <div className="betPlacingCondirm-header">
              <div>
                {betInfoData.mType !== 'INNINGS_RUNS' ?
                  <div className="betPlacingCondirm-header-team">{betInfoData?.runners[0].name} vs {betInfoData?.runners[1].name}</div>
                  :
                  <div className="betPlacingCondirm-header-team">{betInfoData?.event_name}</div>
                }
                <div className="betPlacingCondirm-header-descr"> </div>
              </div>
              <img style={{cursor: 'pointer'}} src={CloseIcon} alt="" onClick={() => {
                setTimeout(() => {
                  setConfirmBetOpen(false)
                },300)
                setCloseAnimationConfirm(true)
                }} />
            </div>
            <div className="betPlacingCondirm-body">
              <div className="betPlacingCondirm-body-table">
                <div className="betPlacingCondirm-body-table-content">
                  <div className="betPlacingCondirm-body-table-content-title">ODDS</div>
                  <div className="betPlacingCondirm-body-table-content-number">{count ? count : fancyCount}</div>
                </div>
                <div className="betPlacingCondirm-body-table-content borderCell">
                  <div className="betPlacingCondirm-body-table-content-title">Stake</div>
                  <div className="betPlacingCondirm-body-table-content-number">{valueStake}</div>
                </div>
                <div className="betPlacingCondirm-body-table-content">
                  <div className="betPlacingCondirm-body-table-content-title" style={{color: "#00A826"}}>{betInfoData?.back ? 'Profit' : 'Liability'}</div>
                  <div className="betPlacingCondirm-body-table-content-number" style={
                    profit().split(" ")[1]?.length > 11 || liability().split(" ")[1]?.length > 11
                    ? {color: "#00A826", fontSize: '16px'}
                    : profit().split(" ")[1]?.length > 9 || liability().split(" ")[1]?.length > 9
                    ? {color: "#00A826", fontSize: '17px'}
                    : {color: "#00A826"}}>{betInfoData?.back ? profit().split(':')[1] : liability().split(':')[1]
                     }</div>
                </div>
              </div>
              <button
                onMouseDown={onRippleStart}
                onMouseUp={onRippleStop}
                className="betslip__body-place-bet_submit"
                type="submit"
                onClick={() => {
                  clickPlaceBetAfterConfirm(count ? count : fancyCount, valueStake)
                  setCloseAnimationConfirm(true)
                }}
                // disabled={!isValid}
              >
                {/* <TouchRipple ref={rippleRef} center={false} />
                {isAuthenticated ? " Place bet" : "Login"} */}
                <div className="confirmCountDown">{bet?.betDelay}s</div>
                <div  className="placeBetTitle">
                  {isAuthenticated ? " Place bet" : "Login"}
                  <img src={buttonArrow} alt="" />
                </div>
                {isAuthenticated && <div className="placeBetDescr">Place Bet now & get {profit()}</div>}

              </button>
            </div>
        </div>
      </div>)}
      </div>}

    {confirmBetOpen && openBetslipDesk && <div className="betSlipCondirm"
    onClick={(e) => {
      //   setTimeout(() => {
      //   setConfirmBetOpen(false)
      // },300)
      // setCloseAnimationConfirm(true)
      e.stopPropagation()
    }}
    >
     {Object.keys(bet).length !== 0 && Object.keys(betInfoData).length !== 0 && betInfoData.event_name && betInfoData.selection &&(
      <div className={`betSlipCondirm-wrapper ${closeAnimationConfirm ? 'closeConfirm' : ''}`} onClick={(e) => e.stopPropagation()}>
        <div className="betSlipCondirm-wrapper-content">
            <div className="betPlacingCondirm-header">
              <div>
                {betInfoData.mType !== 'INNINGS_RUNS' ?
                  <div className="betPlacingCondirm-header-team">{betInfoData?.runners[0].name} vs {betInfoData?.runners[1].name}</div>
                  :
                  <div className="betPlacingCondirm-header-team">{betInfoData?.event_name}</div>
                }
                <div className="betPlacingCondirm-header-descr"> </div>
              </div>
              <img style={{cursor: 'pointer'}} src={CloseIcon} alt="" onClick={() => {
                 setTimeout(() => {
                  setConfirmBetOpen(false)
                },300)
                setCloseAnimationConfirm(true)
              }} />
            </div>
            <div className="betPlacingCondirm-body">
              <div className="betPlacingCondirm-body-table">
                <div className="betPlacingCondirm-body-table-content">
                  <div className="betPlacingCondirm-body-table-content-title">ODDS</div>
                  <div className="betPlacingCondirm-body-table-content-number">{count ? count : fancyCount}</div>
                </div>
                <div className="betPlacingCondirm-body-table-content borderCell">
                  <div className="betPlacingCondirm-body-table-content-title">Stake</div>
                  <div className="betPlacingCondirm-body-table-content-number">{valueStake}</div>
                </div>
                <div className="betPlacingCondirm-body-table-content">
                  <div className="betPlacingCondirm-body-table-content-title" style={{color: "#00A826"}}>{betInfoData?.back ? 'Profit' : 'Liability'}</div>
                  <div className="betPlacingCondirm-body-table-content-number" style={
                    profit().split(" ")[1]?.length > 11 || liability().split(" ")[1]?.length > 11
                    ? {color: "#00A826", fontSize: '16px'}
                    : profit().split(" ")[1]?.length > 9 || liability().split(" ")[1]?.length > 9
                    ? {color: "#00A826", fontSize: '17px'}
                    : {color: "#00A826"}}
                    >{betInfoData?.back ? profit().split(':')[1] : liability().split(':')[1]}</div>
                </div>
              </div>
              <button
                onMouseDown={onRippleStart}
                onMouseUp={onRippleStop}
                className="betslip__body-place-bet_submit"
                type="submit"
                onClick={() => {
                  clickPlaceBetAfterConfirm(betInfoData?.odds, valueStake)
                  setCloseAnimationConfirm(true)
                  setOpenLoadingDesktop(true)
                }}
                // disabled={!isValid}
              >
                {/* <TouchRipple ref={rippleRef} center={false} />
                {isAuthenticated ? " Place bet" : "Login"} */}
                <div className="confirmCountDown">{bet?.betDelay}s</div>
                <div  className="placeBetTitle">
                  {isAuthenticated ? " Place bet" : "Login"}
                  <img src={buttonArrow} alt="" />
                </div>
                {isAuthenticated && <div className="placeBetDescr">Place Bet now & get {profit()}</div>}

              </button>
            </div>
        </div>
      </div>)}
      </div>
      }
     </div>,
    // @ts-ignore
    modalRoot
    )
  }
