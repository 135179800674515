import React, { useEffect, useState } from "react"
import { Field, ErrorMessage, useFormikContext } from "formik"
import { getCurrencyFormat } from "../../../../utils/getCurrencyFormat.helper";
import { FormHelperText, TextField } from "@material-ui/core"
import VisibilityOffIcon from '../../../../static/images/eye-active.svg';
import eye from '../../../../static/images/eye.svg'
import phoneIcon from '../../../../static/images/phone.svg';
import './styles/index.scss'

interface FormikFieldProps {
  id?: any;
  name: string;
  label: string;
  type?: string;
  errors?: any;
  value?: any;
  onlyNumber?: boolean
  inputMode?: any;
  min_max?: {
    min: number;
    max: number;
  };
  setValues?: any;
  style?: any
}

const FormikField: React.FC<FormikFieldProps> = ({ id = "formic-field", onlyNumber = false, name, label, type = "text", errors, value, min_max, setValues, style }) => {
  const [showPassword, setshowPassword] = useState(() => type === "text" || type === 'deposit' || type === 'otp' || type === 'tel' ? true : false)
  const [showPass, setShowPass] = useState(false)
  const [showMinMax, setShowMinMax] = useState(true)
  const [dirtyInput, setDirtyInput] = useState(true)
  const { setFieldError } = useFormikContext();
  let srcIcon
  if (type === "text" && !name.includes("assword")) {
    srcIcon = phoneIcon
  } else if (type === "text" && name.includes("assword")){
    srcIcon = showPass ? VisibilityOffIcon : eye
  } else if (type === 'tel') {
    srcIcon = phoneIcon
  } else {
    srcIcon = showPassword ? VisibilityOffIcon : eye
  }

  // @ts-ignore
  const fieldElement: HTMLInputElement | null = document.getElementById(id);

  useEffect(() => {
    const inputHandler = (event: Event) => {
        const target = event.target as HTMLInputElement;
      if (fieldElement && target !== null) {
          const { value } = target;
        if (type === 'UTR' && /[^\d]/.test(value)){
          fieldElement.value = value.replace(/[^\d]/g, "");
          return
        }
        if (type !== 'UTR' && (isNaN(Number(value)) || value.length > 10) || value.includes(' ')) {
            fieldElement.value = value.slice(0, -1);
          }
        }
    };

    const pasteInputHandler = (event: Event) => {
      const target = event.target as HTMLInputElement;
      if (fieldElement && target !== null) {
        // @ts-ignore
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedText = clipboardData.getData('text/plain');

        if (type === 'UTR' && /[^\d]/.test(pastedText)) {
          setFieldError(name, "You can paste only positive numbers");
          event.preventDefault();
        }
      }
    };

    if (fieldElement !== null && onlyNumber && label !== 'ID') {
      fieldElement.addEventListener('input', inputHandler);
      fieldElement.addEventListener('paste', pasteInputHandler);
    }

    return () => {
      if (fieldElement !== null && onlyNumber && label !== 'ID') {
        fieldElement.removeEventListener('input', inputHandler);
        fieldElement.removeEventListener('paste', pasteInputHandler);

      }
    };
  }, [fieldElement, onlyNumber, label]);

  //hide\show password only type password
  const handleShowPassword = () => {
    if (type === "password") {
      setshowPassword((prevValue) => !prevValue)
    }
  }

  const handleShowPass = () => {
    if (type === "text" && name.includes("assword")) {
      setShowPass((prevValue) => !prevValue)
    }
  }

  //error: if name input == errors.name
  const chekingError = (errors: any, name: string) => {
    let err: boolean = false;
    const keysArr = Object.keys(errors);

    for(let i = 0; i <= keysArr.length; i++) {
      if (keysArr[i] === name) {
        return err = true;
      }
    }
    return err
  }

  const getType = () => {
    if (type === 'deposit' || type === 'amount' || type === 'otp' || type === "number") {
      return 'number'
    } 
    if (type === 'tel') {
      return 'tel'
    }
    if (showPass || label === 'ID' || type === 'UTR') {
      return 'text'
    }
    if (showPassword || type === "text") {
      return 'text'
    }
    if (!showPass || !showPassword) {
      return 'password'
    }
    return type
  }

  return (
    <div className={`formik-field ${!showPass ? 'hidden-custom' : ''}`}style={style}>
      {(name === "mobileNumber" || name === "username") && type === 'tel'
        ?  (
        <div>
          <p className={`formik-field__icon text reset`}>+91</p>
          <div className={`formik-field__icon code line reset`}/>     
        </div>
      ) : null}
      <Field
        className={`${(name === "mobileNumber" || name === "username") && type === 'tel' ? 'countryCode' : ''}`}
        error={dirtyInput ? chekingError(errors, name) : type === 'OTP' ? chekingError(errors, name) : null}
        autoComplete='on'
        as={TextField}
        inputProps={type === 'deposit' || type === 'UTR' ? { inputMode: 'numeric' } : {}}
        onFocus={() => {
          setDirtyInput(true)
          console.log("onFocus")
          setShowMinMax(false)
          window.scrollTo(0, 0);
          document.body.scrollTop = 0;
        }}
        placeholder={type === 'UTR' ? '6 to 22 Digit UTR Number'
          : ''
        }
        onInput={(e: any) => type === 'otp' ? e.target.value = e.target.value.slice(0, 1) : e.target.value = e.target.value.slice(0, 40)}
        label={label}
        name={name}
        id={id}
        type={getType()}
        variant="outlined"
        setValues={(e: any) => console.log('eventt')}
      />
       <FormHelperText ><ErrorMessage name={name}/></FormHelperText>
        {(type === 'deposit' || type === 'amount') && <span className={`formik-field__rupee`} >₹</span>}
        {type === 'deposit' && showMinMax && <span className={`formik-field__minMax`} >{`Min: ${getCurrencyFormat(min_max?.min) || ''} - Max: ${getCurrencyFormat(min_max?.max) || ''}`}</span>}
        {type !== 'deposit' && type !== 'UTR' && type !== 'amount' && type !== 'otp' && <img className={`formik-field__icon ${name}`} onClick={type === 'text' && name.includes('assword') ? handleShowPass : handleShowPassword} src={srcIcon} alt="eye" />}
    </div>
  )
}

export default FormikField