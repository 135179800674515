import { FC, memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useIntercom } from "react-use-intercom"
import { GeneralHandler } from "../../api/general"
import { setFooterBets } from "../../redux/menu/footerBets.slice"
import { setOrderList } from "../../redux/menu/orderList.slice"
import { setOrderCount } from "../../redux/menu/orderList.slice"
import { initOrder } from "../../redux/pages/orderList"
import betslipIcon from "../../static/images/betslipIconDesktop.svg"
// import messageIcon from "../../static/images/messageIconDesktop.svg";
import whatsAppImg from "../../static/images/watsApp.svg"
import { PARAMS } from "../../utils/constants"
import "./styles/index.scss"
import cn from "classnames"

interface Intercom extends Window {
  Intercom: any;
}

declare const window: Intercom
export const ModalButtons: FC = memo(() => {
  const [animAppOpen, setAnimAppOpen] = useState(false)
  const [animAppClose, setAnimAppClose] = useState(false)
  const orderListCount = useSelector((state: IRootState) => state.orderList.orderCount)
  const dispatch = useDispatch()
  const success = useSelector((state: IRootState) => state.success.isOpenSuccess)
  const {
    user: { isAuthenticated }
  } = useSelector((state: IRootState) => ({ user: state.user }))
  const isAndroid = navigator.userAgent.includes("Android")

  // const { boot, show, isOpen } = useIntercom();
  const { boot, isOpen } = useIntercom()


  useEffect(() => {
    setTimeout(() => {
      setAnimAppOpen(true)
      setTimeout(() => {
        setAnimAppClose(true)
        setAnimAppOpen(false)

      }, 20000)
    }, 6500)
    boot()
    try {
      window.Intercom("update", {
        "hide_default_launcher": true
      })
    } catch (e: any) {
      console.error(e)
    }
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      const params = PARAMS.OPEN_BETS
      GeneralHandler.getOrderList1(params).then((list: any) => {
        dispatch(initOrder(list))
        const listMatched = Object.values(list?.result?.matched)
          ?.map((item: any) => item?.markets?.map((market: any) => market?.selections))
          ?.flat(3)
        const listUnmatched = Object.values(list?.result?.unmatched)
          ?.map((item: any) => item?.markets?.map((market: any) => market?.selections))
          ?.flat(3)
        dispatch(
          setOrderList({
            matched: listMatched,
            unmatched: listUnmatched
          })
        )
        if (success) {
          dispatch(setOrderCount(listMatched?.length + listUnmatched?.length))
        }
      })
    }
  }, [success])

  useEffect(() => {
    const element = document.querySelector(".intercom-dfosxs") as HTMLElement

    if (element) {
      if (isOpen) {
        setTimeout(() => element.style.opacity = "1", 500)
      } else {
        element.style.opacity = "0"
      }
    }
  }, [isOpen])

  return (
    <div className={cn("modalButtons", {
      "android": isAndroid
    })}
    >
      <div className="whatsApp" onClick={() => window.open(`https://go.wa.link/funexchofficial`)}>
        <img className="whatsApp_img" src={whatsAppImg} alt="" />
        <div className={`circleIconPanel ${animAppOpen ? "showMessage" : animAppClose ? "hideMessage" : ""}  `}>
          <div>Play and win big. Get a new</div>
          <div> ID instantly on WhatsApp</div>
        </div>
      </div>
      {<div className="betslipButtonDesktop"
            onClick={() => {
              dispatch(setFooterBets(true))
              // }
            }}>
        <div className="betslipButtonDesktop-content">
          <img className="betslipButtonDesktop-content-img" src={betslipIcon} alt="" />
          <div className="betslipButtonDesktop-content-title">BETSLIP</div>
          <div className="betslipButtonDesktop-content-count">{orderListCount}</div>

        </div>
      </div>}
      {/*{<div className="messageButtonDesktop">*/}
      {/*  <div className="messageButtonDesktop-content">*/}
      {/*    <img className="messageButtonDesktop-content-img" onClick={show} src={messageIcon} alt="" />*/}
      {/*  </div>*/}
      {/*</div>}*/}
    </div>
  )
})
