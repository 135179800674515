import { useState, useEffect, useRef, useCallback, useMemo, memo } from "react";
import { useDispatch } from "react-redux";
import { GeneralHandler } from "../../../api/general";
import searchImg from "./icons/searchMobile.svg";
import searchArrowImg from "../../../static/images/header/search-arrow.svg";
import { initEventMarketData } from "../../../redux/pages/EventDetail/eventMarketData";
import useSearchDebounce from "../../../hooks/useSearchDebounce";
import useRedirect from "../../SideMenu/OthersList/Item/useRedirect";
import cricketIcon from './icons/sideBarCricket.svg'
import footballIcon from './icons/sideBarFootball.svg'
import tennisIcon from './icons/sideBarTennis.svg'
import greyhoundIcon from './icons/sideBarGreyhound.svg'
import horseIcon from './icons/sideBarHorse.svg'
import exchangeIcon from './icons/sideBarExchange.svg'
import sportsbookIcon from './icons/sideBarSportsbook.svg'
import "./styles/index.scss";


interface Iprops {
  openSearch?: any;
  closeSearch?: any;
  isPhone?: boolean;
}

const iconMap = {
  '4': cricketIcon,
  '1': footballIcon,
  '2': tennisIcon,
  '7': horseIcon,
  '4339': greyhoundIcon,
  '1444001': exchangeIcon,
  '1444005': sportsbookIcon,
};

export const Search = memo(({
  openSearch,
  closeSearch,
  isPhone = false,
}: Iprops) => {
  const handleRedirect = useRedirect();
  const [menu, setMenu] = useState([]);
  const [search, setSearch] = useState("");
  const container = useRef(null);
  const dispatch = useDispatch();
  const resultRef = useRef(null);

  const searchArray = useMemo(() => {
   return collectChildren(menu)
  }, [menu])


  function collectChildren(data: any, eventTypeId: string = '') {
    return data.flatMap((item: any) => {
      const updatedItem = { ...item };
      updatedItem.eventTypeId = eventTypeId || updatedItem.id;

      if (item.children && item.children.length > 0) {
        return collectChildren(item.children, updatedItem.eventTypeId);
      } else {
        return updatedItem;
      }
    });
  }

  const searchResult = useSearchDebounce(searchArray, search, 200, [], ['name'], 0.2)

  const renderIcon = (id: string) => {
    const iconSrc = iconMap[id];
    if (iconSrc) {
      return <img src={iconSrc} alt={id} />;
    }
    return null;
  };


  useEffect(() => {
  const initData = () => {
      GeneralHandler.getMenuList().then((res) => {
        setMenu(res.general);
      });
    };

    initData();
  }, []);

  const closeFunction = useCallback(
    (e: any) => {
      if (!e?.path?.includes(container.current)) {
        setSearch("");
      }
    },
    []
  );

  useEffect(() => {
    window.addEventListener("click", closeFunction);
    return () => window.removeEventListener("click", closeFunction);
  }, [closeFunction]);

  const clearSearch = useCallback(
    (e: any) => {
      if (closeSearch) {
        closeSearch();
    }
    },
    [closeSearch]
  );

  useEffect(() => {
    window.addEventListener("click", clearSearch);
    return () => window.removeEventListener("click", clearSearch);
  }, [clearSearch]);

  const competionNames = (playerNames: any, eventTypeId: any) => {
    const eventsArray: any = menu.find((item: any) => item.id == eventTypeId);
    const competitionName = eventsArray?.children.find((el: any) =>
      el.children.find((item: any) => item.id === playerNames)
    );
    // setSportId()
    return competitionName?.name;
  };

  const gotoEvent = (item: any) => {
    dispatch(initEventMarketData(item));
    handleRedirect({name: item.name, type: item.type, Id: item.id, eventType: item.eventTypeId, item})
  };

  const renderResult = () => {
    if(search.length > 2) {
      return (
        searchResult && searchResult.length ? (
        <div>
            <div ref={resultRef} className="searchEvent-container__eventBox">
              {searchResult.map((eventElement: any) => (
                <div
                  className="event-container"
                  onClick={() => {
                    gotoEvent(eventElement);
                    setSearch("");
                  }}
                >
                  <div className="event-container__leftside">
                  {renderIcon(eventElement.eventTypeId)}
                    <div className="event-container__title">
                      <div className="event-container__title-event">
                        {eventElement.name.length > 25
                          ? eventElement.name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')?.slice(0, 25) + "..."
                          : eventElement.name?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')}
                      </div>
                      <div className="event-container__title-competition">
                        {competionNames(
                          eventElement.id,
                          eventElement.eventTypeId
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="event-container__rightside">
                    <img src={searchArrowImg} alt="searchArrowImg" />
                  </div>
                </div>
              ))}
          </div>
        </div>
        ) : (
        <>
            <div className="searchEvent-container__eventBox">
              <div className="event-container">
                <div className="noEventFound">
                  <div className="event-container__title">
                    <div className="event-container__title-event">
                      No events found matching for
                    </div>
                    <div className="event-container__title-competition">
                      {search.length > 30 ? search.slice(0, 30) + "..." : search}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )
      );
    } else {
      return null;
    }
  };

  return (
    <div className="searchEvent-container" ref={container}>
      <img className="searchEvent-container-img" src={searchImg} alt="searchImg" />
      <input
        className="searchEvent-container__input"
        type="text"
        placeholder="Search"
        autoFocus={isPhone}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        />
      <>
        {renderResult()}
      </>
    </div>
  );
});
