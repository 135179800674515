import { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import menuCloseArrow from "../../static/images/header/menuCloseArrow.svg";
import { ItemList } from "./ItemList";
import { OthersList } from "./OthersList";
import cn from "classnames";
import "./styles/index.scss";
import { setIsOpenSideBar } from "../../redux/UI/openPopUps.slice"

const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isOpenSideBar = useSelector((state: IRootState) => state?.openPopUps?.isOpenSideBar);
  const sideMenu = useSelector((state: IRootState) => state.sideMenu);
  const others = useSelector((state: IRootState) => state.sideMenuOthers);
  const menuRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(window.innerWidth);
  const [animation, setAnimation] = useState(false);

  const handleResize = () => setWidth(window.innerWidth);
  const closeSideBar = () => {
    setAnimation(true);
    setTimeout(() => {
      dispatch(setIsOpenSideBar(false));
      setAnimation(false);
    }, 300);
  };

  const handler = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      closeSideBar();
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if(width <= 1024) {
        closeSideBar();
      } else if (location.pathname === "/sportsbook") {
        closeSideBar();
      } else {
        closeSideBar();
      }
    });
  }, [history, width]);

  useEffect(() => {
    closeSideBar();
    document.addEventListener("click", handler, true);

    return () => {
      document.removeEventListener("click", handler, true);
    };
  }, [width]);

  const sidebarContent = useMemo(() => {
    if (!isOpenSideBar) return null;

  return (
      <aside className="side-menu">
        <div className={cn("wrapper", { close: animation })} ref={menuRef}>
          <div className="top" onClick={closeSideBar}>
            <div className="logo"></div>
            <div className="close" onClick={closeSideBar}>
              <img src={menuCloseArrow} alt="close" />
            </div>
          </div>
          <div className="sections">
          <div className="section-wrapper">
            <OthersList
              name="Popular"
              list={others}
            />
          </div>
          <div className="section-wrapper">
            <ItemList
              name="All Sports"
              list={sideMenu}
            />
          </div>
          </div>
        </div>
      </aside>
    );
  }, [isOpenSideBar, animation, menuRef, closeSideBar, others, sideMenu]);

  return sidebarContent;
};

export default SideMenu;
