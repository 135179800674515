import { lazy, Suspense, useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import FooterBar from "./components/FooterBar/FooterBar";
import HeaderNew from "./components/HeaderNew";
import HeaderNewUser from "./components/HeaderNew/headerUser";
import SideMenu from "./components/SideMenu";
import { PrivateRoute } from "./utils/privateRoute";

// import { useMediaQuery } from "@material-ui/core";
import cn from "classnames";
// import ApkPopUP from "./components/ApkPopUP/ApkPopUP";
import SpecialOffers from "./pages/SpecialOffers";
import Loading from "./pages/Loading/Loading"

import { GeneralHandler } from "./api/general";
import { initStakesSettings } from "./redux/login/stakeSettings";
import { setPreferences } from "./redux/login/preferences.slice";
import useAuth from "./utils/useAuth";
import { setIsOpenLoginPopUp, setIsOpenSignupPopUp } from "./redux/UI/openPopUps.slice"
import HomePage from "./pages/HomePage";


const UserTimeSettings = lazy(() => import("./components/UserTimeSettings"));
const BonusStatementDetails = lazy(() => import("./pages/User/BonusStatementDetails"));
const TransferStatement = lazy(() => import("./pages/User/TransferStatement/TransferStatement"));
const WithdrawalNew = lazy(() => import("./pages/User/WithdrwalNew"));
const AccountStatement = lazy(() => import("./pages/User/AccountStatement"));
const CardGames = lazy(() => import("./pages/LiveCardGames"));
const LiveCasinoGames = lazy(() => import("./pages/LiveCasinoGames"));
const Slots = lazy(() => import("./pages/Slots"));
const Sportbook = lazy(() => import("./pages/Sportbook/Sportbook"));
const Game = lazy(() => import("./pages/SportEvents"));
const SportsEventDetail = lazy(() => import("./pages/SportsEventDetails"));
const AccountStatementDetails = lazy(() => import("./pages/User/AccountStatementDetails"));
const BetHistory = lazy(() => import("./pages/User/BetHistory"));
const BonusPage = lazy(() => import("./pages/User/BonusPage"));
const ChangePassword = lazy(() => import("./pages/User/ChangePassword"));
const Deposit = lazy(() => import("./pages/User/Deposit"));
const DetailedDepositTransaction = lazy(() => import("./pages/User/DetailedDepositTransaction/DetailedDepositTransaction"));
const SetButtonValues = lazy(() => import("./pages/User/SetButtonValues"));
const TransactionsNew = lazy(() => import("./pages/User/TransactionsNew/TransactionsNew"));
const UnsettledBets = lazy(() => import("./pages/User/UnsettledBet"));
const ThanksWithdrawal = lazy(() => import("./pages/User/WithdrwalNew/ThanksWithdrawal"));
const ExchangeGameIframe = lazy(() => import("./pages/ExchangeGameIframe"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const RulesandRegulations = lazy(() => import("./pages/RulesandRegulations"));
const SportbookNotAuth = lazy(() => import("./pages/Sportbook/SportbookNotAuth"));
const UserBonus = lazy(() => import("./pages/User/BonusPage/UserBonus"));
const BonusStatement = lazy(() => import("./pages/User/BonusStatement"));
const SpecialOffersRealCash = lazy(() => import("./pages/SpecialOffers/realCashPage"));
const CasinoGame = lazy(() => import("./pages/CasinoGame"));

const href = window.location.href

declare const window: CustomWindow;
interface CustomWindow extends Window {
  srtmCommands: { push: (arg: any) => void };
}


const UserWrapper = () => {
  const { pathname } = useLocation();
  const isMessages = useSelector((state: IRootState) => state.notification.messages).length > 0;
  const dispatch = useDispatch()
  const [isPhone, setIsPhone] = useState(window.innerWidth <= 1024);

  useEffect(() => {
    if(href.includes("login")) {
      dispatch(setIsOpenLoginPopUp(true))
    }
    if(href.includes("register")) {
      dispatch(setIsOpenSignupPopUp(true))
    }
  }, [])


  const pagesWithoutFooterBar = [
    "sportsbook",
    "user/change-password",
    "/user/time-settings",
    "/user/transfer-statement",
    "user/withdrawal",
    "user/deposit",
    "user/withdrawal",
    "user/account-statement",
    "user/bonus-statement",
    "set-button-values",
    "card-game/",
    "rules-regulations",
    "unsettled-bet",
    "privacy-policy",
    "specialoffers",
    'intercom',
    'realCash',
    'casino-game/'
  ];
  const ref = useRef<null | HTMLDivElement>(null);
  const { login } = useAuth()

  useEffect(() => {
    if (isPhone) {
      if (ref.current) {
        const parent = ref?.current?.parentElement;
        setTimeout(() => {
          parent?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
    } else {
      if (ref) {
        setTimeout(() => {
          // @ts-ignore
          ref.current?.childNodes[0]?.scrollIntoView({ behavior: "smooth", block: "start" });
        }, 0);
      }
    }

  }, [pathname, isPhone]);

  const WALogin = async () => {
    try{
      const response = await GeneralHandler.whatsAppReq(href.split("=")[1].split("&")[0]);

      const stakes = response.data.result
      window.srtmCommands.push({
        event: "track.user.login",
        payload: {
            action: "complete",
            userId: response.data.result.memberCode
          }
        });
      dispatch(initStakesSettings(stakes.settings.presetStakeSettings))
      dispatch(setPreferences(stakes?.settings?.preferences?.confirmation))
      login(
        response.headers.authorization, response.data.result.memberCode,
        response.data.result.loginName, stakes.settings.presetStakeSettings,
        stakes.memberId, stakes?.settings?.preferences?.confirmation
      )

      console.log("@@@@@@🚀 ~ file: routes-new.tsx:120 ~ useEffect ~ response:", response)
    } catch {

    }
  }

  useEffect( () => {
    if (href.includes("waId")) {
      localStorage.setItem("waId", href.split("=")[1]);
      // GeneralHandler.whatsAppReq(href.split("=")[1]);
      WALogin()
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname])


  const { isAuthenticated } = useSelector((state: IRootState) => state.user);
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const userPages = [
    "unsettled-bet",
    "change-password",
    "time-settings",
    "transfer-statement",
    "account-statement",
    "rules-regulations",
    "bonus-statement",
    "set-button-values",
    "transactions",
    "withdrawal",
    "privacy-policy",
    'realCash'
  ];

  const componentsHeight: any = useCallback(() => {
    const phone = window.innerWidth <= 1024;
    const footerBarHeight = pagesWithoutFooterBar.filter((item) => pathname.includes(item)).length ? 0 : phone ? 60 : 0;
    const headerBarHeight = phone ? 60 : 70;
    const tickerHeight = (!phone && isMessages) ? 48 : 0;
    const footerIFrame =  phone && (pathname.includes('casino-game/') || pathname.includes('card-game/')) ? 0 : -10
    return footerBarHeight + headerBarHeight + tickerHeight + footerIFrame;
  }, [pagesWithoutFooterBar, pathname, isPhone, isMessages, window.innerHeight]);

  const [contentWrapperHeight, setContentWrapperHeight] = useState(window.innerHeight - componentsHeight())

  useEffect(() => {
    handleResize()
  }, [componentsHeight(), contentWrapperHeight]);
  const handleResize = async () => {
    setIsPhone(window.innerWidth <= 1024);
    setContentWrapperHeight(window.innerHeight - componentsHeight());
  };

  useEffect(() => {
    window.addEventListener('orientationchange', handleResize);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('orientationchange', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <div className={`${isIOS ? "ios" : ""}`}>
        {(userPages.filter(item => pathname.includes(item)).length && isPhone)
          ? <HeaderNewUser />
          : <HeaderNew />
        }
        {!pathname.includes('intercom')}
        <SideMenu />
        <div
          style={{height: contentWrapperHeight}}
          ref={ref} className={cn("main-content-wrapper", {
          "main-content-wrapper-mobile": isPhone,
          "without-footer-bar": pagesWithoutFooterBar.filter((item) => pathname.includes(item)).length,
          "with-ticker": isMessages,
        })}>
          <Suspense fallback={<Loading></Loading>}>
            <Switch>
              <Route path="/sports" component={Game} />
              <Route exact path="/user/account-statement" component={AccountStatement} />
              <Route exact path="/user/transfer-statement" component={TransferStatement} />
              <Route path="/user/account-statement-details" component={AccountStatementDetails} />
              <Route path="/user/bonus-statement-details" component={BonusStatementDetails} />
              <Route exact path="/user/bet-history" component={BetHistory} />
              <Route exact path="/user/unsettled-bet" component={UnsettledBets} />
              <Route exact path="/user/deposit" component={Deposit} />
              <Route exact path="/user/withdrawal" component={WithdrawalNew} />
              <Route exact path="/user/profile" component={UserBonus} />
              <Route exact path="/user/thanksWithdrawal" component={ThanksWithdrawal} />
              <Route exact path="/user/transactions" component={TransactionsNew} />
              <Route exact path="/user/detailedDepositTransaction" component={DetailedDepositTransaction} />
              <Route exact path="/user/bonus-statement" component={BonusStatement} />
              <Route exact path="/user/change-password" component={ChangePassword} />
              <Route exact path="/user/time-settings" component={UserTimeSettings} />
              <Route exact path="/user/set-button-values" component={SetButtonValues} />
              <Route path="/sport-event-detail" component={SportsEventDetail} />
              <Route path="/home" render={() => <HomePage />} />
              <Route path="/live-casino-page" component={LiveCasinoGames} />
              <Route path="/card-games" component={CardGames} />
              <Route path="/slots-page" component={Slots} />
              <Route path="/card-game" component={ExchangeGameIframe} />
              <Route path="/user-bonus" component={BonusPage} />
              <Route exact path="/sportsbook" component={isAuthenticated ? Sportbook : SportbookNotAuth} />
              <Route exact path="/rules-regulations" component={RulesandRegulations} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route exact path="/specialoffers" component={SpecialOffers} />
              <Route exact path="/realCash" component={SpecialOffersRealCash} />
              <Route path="/casino-game" component={CasinoGame} />

              <Redirect to="/home/sports/4" />
            </Switch>
          </Suspense>
        </div>
        {!(pagesWithoutFooterBar.filter((item) => pathname.includes(item)).length)
          ? <FooterBar />
          : null}

      </div>
    </>
  );
};

const Routes = () => (
  <Switch>
    <PrivateRoute path="/" component={UserWrapper} />
    <Redirect to="/" />
  </Switch>
);

export default Routes;
