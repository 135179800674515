import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface SuccessInterface {
  isOpenSuccess: boolean
}

const uiInitialState: SuccessInterface = {
  isOpenSuccess: false,
}

const successSlice = createSlice({
  name: "success",
  initialState: uiInitialState,
  reducers: {
    setIsOpenSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenSuccess = payload
    }
  }
})

export const { setIsOpenSuccess } = successSlice.actions

export default successSlice.reducer
