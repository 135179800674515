import axios from "axios";
import { MEMBER_CODE, PATH } from "../utils/constants";
import { IframeGamesInstance, instance, instanceGemexch } from "./instance";

// const MemberCode = localStorage.getItem(MEMBER_CODE)

export class GeneralHandler {

  static async getMenuList() {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    //
    const response = await instance.get(`${MemberCode ? `/${PATH.AGENCY}` : ""}/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.MENU}`);

    // const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.MENU}`);

    return response.data.result
  };

  static async getMessage() {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.NOTIFICATION}/${PATH.MESSAGE}`);
    return response.data.result
  };

  static async getBalance() {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.BALANCE}`);
    return response.data.result
  };
  static async getWithdrawalAccounts() {
    const response = await instance.get(`/${PATH.PAYMENTS}/${PATH.USERID}/${PATH.ACCOUNT_LIST}`);
    return response.data.result
  };

  static async getServerTime() {
    const response = await instance.get(`/${PATH.SERVER_TIME}`);
    return response.data.result
  };
  static async changeWalletStatus(memberCode: string, bool: boolean, walletCode: string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.put(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.SETTINGS}/${PATH.SWITCH_WALLET}?${PATH.USE_BONUS_WALLET}=${bool}&${PATH.WALLET_CODE}=${walletCode}`);
    return response.data
  };
  static async getWalletDetails(walletType: string) {
    const response = await instance.get(`/${PATH.ACCOUNT}/${PATH.WAGERING_DETAILS}?${PATH.WALLET_TYPE}=${walletType}`);
    return response?.data?.result
  };
  static async getGamesRules(id: string, sport: string) {
    const response = await instance.get(`/${PATH.RULES}/${PATH.EVENT}/Z501/${id}/${sport}`);
    return response?.data?.result
  };

  static async getStatement(from?: number, to?: number) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.REPORTS}/${PATH.STATEMENT}?from=${from}&to=${to}`);
    return response.data.result
  };

  static async getTransfers(from?: number, to?: number) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.REPORTS}/${PATH.TRANSFER}?from=${from}&to=${to}`);
    return response.data.result
  };

  static async getBonusStatement(from?: number, to?: number, type?: string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.REPORTS}/${PATH.BONUS_STATEMENT}?from=${from}&to=${to}&${PATH.WALLET_CODE}=${type}`);
    return response.data.result
  };

  static async getBalanceSettings() {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.BALANCE_SETTINGS}`);
    return response.data.result
  };

  static async getOrderList(params?: string, from?: number, to?: number) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    //todo need check
    if (MemberCode) {
      const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.ORDER_LIST}?${params}&from=${from}&to=${to}`);
      return response.data
    }
    return null
  };

  static async getOrderList1(params?: string, from?: number, to?: number) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    //todo need check
    if (MemberCode) {
      const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.ORDER_LIST}?${params}`);
      return response.data
    }
    return null
  };

  static async setPreferensesReq(value?: boolean) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    //todo need check
    if (MemberCode) {
      const response = await instance.post(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.SETTINGS}/${PATH.PREFERENCES}`, {"confirmation":value});
      return response.data.success
    }
  };

  static async getOrderList2(id?: any) {
    const response = await instance.delete(`/exchange/order/${id}`);
    return response.data
  };

  static async getAccountStatementDetails(params?: string, marketId?: string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.ORDER_LIST}?${params}&marketId=${marketId}`);
    return response.data
  };
  static async getAccountStatementDetailsCasino(params?: string, marketId?: string) {
    const response = await instance.get(`/${PATH.CASINO}/${PATH.LIVE}/${PATH.SESSION}/${PATH.BET_HISTORY}?${params}&sessionId=${marketId}`);
    return response.data
  };

  static async getProfitLossList(from?: number, to?: number, eventTypeId?: number) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.REPORTS}/${PATH.PNL}?from=${from}&to=${to}&eventTypeId=${eventTypeId}`);
    return response.data.result
  };

  static async requestCashOut(marketId?: string) {
    try {
      
      const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ORDER}/${PATH.CASHOUT}?marketId=${marketId}`,{
          headers: {
            'x-app-version': '4.0.19.2',
            'x-client': 'mobile',
            'x-client-id': '451012928.1605029998',
            'x-client-info': 'e883e9a15db99de2fdd0579c576a0693',
            'x-log-timing': true,
            'x-xid': '6b76ff17-70d2-b5fe-2373-08f62ee312af',
          }
        })
        return response
      } catch (error: any) {
          return error
      }
  };

  // uncoment with caSinoReportHistory page in next version

  // static async getCasinoReportHistoryList(from?: number, to?: number, gameId?: number) {
  //   const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.CASINO_REPORT_HISTORY}?from=${from}&to=${to}&gameId=${gameId}`);
  //   return response.data.result
  // };

  static async updateStakes(settings?: number[]) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.post(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.SETTINGS}/${PATH.STAKES}`, { settings });
    return response.data
  };

  static async changePassword(oldPassword: string, password: string, passVerify: string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.put(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.SETTINGS}/${PATH.PASSWORD}`, { oldPassword, password, passVerify});
    return response.data
  };

  static async getSlotsCategories() {
    const response = await IframeGamesInstance.get(`${PATH.API}/${PATH.CATEGORIES}`, );
    return response.data.result
  };

  static async getCardGames() {
    const response = await instance.get(`/exchange/odds/exchangeGames`);
    return response.data.result
  };

  static async liveSession() {
    const response = await instance.post(`/casino/live/session/open`,
      {
        "id":"1444012",
        "type":"FAWK"
      }
    );
    return response.data
  };

  static async whatsAppReq(id: any) {
    const response = await instance.post(`/auth/walogin`,
      {
        "otp": id
      }
    );
    return response
  };

  static async credentialResponse(data: any) {
    const response = await instance.post(`/auth/glogin`, data);
    return response
  };

  static async liveSessionRedirect(id: string, type: string) {
    const response = await instance.post(`/casino/live/session/open`,
      {
        "id": `${id}`,
        "type": `${type}`
      }
    );
    return response.data
  };


  static async getCardGamesTeenpattiCatgories() {
    const response = await instance.get(`/public/casino/teenpattiCategory`);
    return response.data.result
  };

  static async saveWithdrawAccounts(data: any) {
    const response = await instance.post(`${PATH.PAYMENTS}/${PATH.USERID}/${PATH.ADD_BANK_ACC}`, data);
    return response
  };


  static async getofferslist() {

    const response = await instance.get(`${PATH.ACCOUNT}/${PATH.PROMOTIONS}?type=DEPOSIT`);

    return response.data.result
  };


  static async getSlotsAndLiveCategories(isAuthenticated: boolean) {
    let live = await instance.get(`/casino/v2/categories`, );
    let slotsCat = await instance.get(`/casino/v2/slot-categories`, );

    return {slots : slotsCat.data.result, live: live.data.result}
  };
  static async getLiveCategories(isAuthenticated: boolean) {
    let live = await instance.get(`/casino/v2/categories`, );
    return live.data.result
  };

  static async getSlotsAndLiveGames(category: string, isSlots: boolean, isAuthenticated: boolean) {

    let response = await instance.get(`/casino/v2/${isSlots ? "slot-games" : "games"}/${category}`, );

    return response.data.result
  };

  static async getLiveGames(category: string, isAuthenticated: boolean) {
    let response = await instance.get(`/casino/v2/games/${category}`);
    console.log("!!!!here1", response)
    return response.data.result
  };

  // static async getSlotsGames(category: string, page: number, pageSize: number) {
  //   const response = await IframeGamesInstance.get(`${PATH.API}/${PATH.MAIN}/get?category=${category}&page=${page}&pageSize=${pageSize}`, );
  //   return response.data.result
  // };

  // static async getSlotsGameById(gameId: string | null) {
  //   const response = await IframeGamesInstance.get(`${PATH.API}/${PATH.CASINO}/${PATH.LIVE}/${PATH.TABLE}/?gameId=${gameId}`, );
  //   return response.data.result
  // };

  static async getLiveGameById(gameId: string | null) {
    const response = await IframeGamesInstance.get(`https://diamondexchange9.com/api/casino/live/table-w/${gameId}`, );
    return response.data.result
  };


  static async getGeneralRules() {
    const response = await instance.get(`${PATH.D_RULES}`, );
    return response.data.result
  };

  static async requestCasinoStart(id = '1444000', type = "LIVE_CASINO") {
    const response = await instance.post(`/${PATH.CASINO}/${PATH.LIVE}/${PATH.SESSION}/open`, { id, type });
    return response.data.result
  };
  static async requestCasinoEnd() {
    const response = await instance.post(`/${PATH.CASINO}/${PATH.LIVE}/${PATH.SESSION}/close`);
    return response.data.result
  };
  static async getAllGameTeenAptti(){
    const response= await instance.get(`https://justin.fawk.app/games/list`)
    return response.data.result
  }
  static async requestRoundMarkets(roundId: string) {
    // const response = await instance.post(`/exchange-games/round/${roundId}`);
    // return response.data.result
    const response = await axios.get(`https://fawk.app/api/exchange/odds/roundmarkets/${roundId}`, {
      headers: {
        'Content-Type': 'application/json',
        'X-access-token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJvcGVyYXRvcklkIjoiOTk5OSIsInVzZXJJZCI6Ijk5OTlfMTIzNCIsInVzZXJuYW1lIjoidXNlcjEiLCJwbGF5ZXJUb2tlbkF0TGF1bmNoIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5IiwidG9rZW4iOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkiLCJiYWxhbmNlIjoxMDAwMDAwLCJleHBvc3VyZSI6MCwiY3VycmVuY3kiOiJJTlIiLCJsYW5ndWFnZSI6ImVuIiwidGltZXN0YW1wIjoiMTQzODg2NDAyOTkxNSIsImNsaWVudElQIjpbIjEiXSwiVklQIjoiMyIsImVycm9yQ29kZSI6MCwiZXJyb3JEZXNjcmlwdGlvbiI6Im9rIiwiaXAiOiI3OC4xNTQuMTg0LjE5NCIsInN5c3RlbVVzZXJJZCI6IjYxOGZkNDI0ZmEzOGFkZDdhZGQyOGIzOSIsImlhdCI6MTY2NDk0OTgwOCwiZXhwIjoxNjY0OTYwNjA4fQ.wbLTNH98nItm_GbGanCkcoz8oKfqGw972lErIgQPJMk",
        'X-server': "uy0yAd5aeYW6LBjQ5p+1udeBPh9iNV5qKsWcEZ+UcBiC4fHjqzHPqBUuyWBzclC+dFhlceGLo0u8ngZLDLImYA=="
      }
    })
    return response.data.result }

    static async getPromoBaners() {
    const response = await instanceGemexch.get(`/${PATH.BANNERS}`, );
    return response.data
  };
}
