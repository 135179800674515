import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

interface ICard {
  competitionId: string;
  eventTypeId: string;
  id: string;
  image: string;
  name: string;
  primaryColour: string;
  startTime: string;
  type: string;
}
export interface cardsList {
  general : any[];
  cards: ICard[];
  banners: any;
  liveGames: any[];
  main_heading: any[];
  others: any[];
  slotGames: any[];
}

const INIT_CARDS = 'INIT_CARDS';

type InitCardsAction = Action<typeof INIT_CARDS> & {
  cards: cardsList;
};

export const initCards = (cards: cardsList ): InitCardsAction => ({
  type: INIT_CARDS,
  cards,
});

// type AlowwedActions = InitCardsAction;

const CardsReducer = (cards: cardsList = { general: [], cards: [], banners: {}, liveGames: [], main_heading: [], others: [], slotGames: [] }, action: AnyAction): cardsList => {
  switch (action.type) {
    case INIT_CARDS:
      return action.cards;
    default:
      return cards;
  }
};

export default CardsReducer;
