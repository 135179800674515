import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface IAddAccount {
    IFSCCode: string;
    accountId: number;
    accountName: string;
    accountNo: string;
    bankName: string;
    branchName: string;
}

const INIT_ADD_ACCOUNT = 'INIT_ADD_ACCOUNT';

type InitAddAccountAction = Action<typeof INIT_ADD_ACCOUNT> & {
    addAccount: IAddAccount ;
};

export const initAddAccount = (addAccount: IAddAccount): InitAddAccountAction => ({
  type: INIT_ADD_ACCOUNT,
  addAccount,
});

// type AlowwedActions = InitAddAccountAction;

const AddAccountReducer = (addAccount: IAddAccount = {} as IAddAccount, action: AnyAction): IAddAccount => {
  switch (action.type) {
    case INIT_ADD_ACCOUNT:
      return action.addAccount;
    default:
      return addAccount;
  }
};

export default AddAccountReducer;
