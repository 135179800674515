import { Action } from 'redux';
import { AnyAction } from "@reduxjs/toolkit"

export interface SlotsItem {
  id: string;
  name: string;
  order: string;
  gamesCount: number;
  image: string;
  columns: number
}

const INIT_SLOTS = 'INIT_SLOTS';

type InitSlotsAction = Action<typeof INIT_SLOTS> & {
  slotsList: SlotsItem[];
};

export const initSlotsList = (slotsList: SlotsItem[]): InitSlotsAction => ({
  type: INIT_SLOTS,
  slotsList,
});

// type AlowwedActions = InitSlotsAction;

const SlotsListReducer = (slotsList: SlotsItem[] = [], action: AnyAction): SlotsItem[] => {
  switch (action.type) {
    case INIT_SLOTS:
      return action.slotsList;
    default:
      return slotsList;
  }
};

export default SlotsListReducer;
