import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import "./index.scss"
import { GeneralHandler } from "../../../api/general"
// import arrowIcon from "./images/arrowDown.svg"
import checkedIcon from "./images/checked.svg"
import notCheckedIcon from "./images/notChecked.svg"
import { getCurrencyFormat } from "../../../utils/getCurrencyFormat.helper"
import { setOrderList } from "../../../redux/menu/orderList.slice"
// import closeIcon from "./images/close.svg"
import deleteIcon from "./images/delete.svg"
import { PARAMS } from "../../../utils/constants"
import { initOrder } from "../../../redux/pages/orderList"
import { setOrderCount } from "../../../redux/menu/orderList.slice"

interface Iprops {
  setSuccessCancel?: any
}

export const OpenBets = ({ setSuccessCancel }: Iprops) => {
  const bet = useSelector((state: IRootState) => state.bet)
  const dispatch = useDispatch()
  const [betInfo, setBetInfo] = useState<any>(false)
  const [betEvent, setBetEvent] = useState<any>(false)
  const [averageOdds, setAverageOdds] = useState<any>(false)
  const [consolidate, setConsolidate] = useState<any>(false)
  const [avergeArrays, setAverageArrays] = useState<any>()
  const [avergeArraysUnmatched, setAverageArraysUnmatched] = useState<any>()

  const [hideUnmachedBets, setHideUnmachedBets] = useState(false)
  const [hideMachedBets, setHideMachedBets] = useState(false)

  const success = useSelector((state: IRootState) => state.success.isOpenSuccess)

  const { orderList } = useSelector((state: IRootState) => state.orderList)
  const {
    user: { isAuthenticated }
  } = useSelector((state: IRootState) => ({ user: state.user }))

  const date = (date: any) => {
    const newDate = new Date(date)
    return newDate.toLocaleString("en-GB")
  }

  const startTime = (date: any) => {
    const newDate = new Date(date)
    return newDate.toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit", second: "2-digit" })
  }

  const profit = (odd: any, stake: any, back: boolean, marketType: any, marketBettingType: any) => {
    const type = back ? "Profit" : "Liability"
    const isFancy = marketType == "INNINGS_RUNS" && marketBettingType == "LINE"
    // @ts-ignore
    const profit = isFancy ? getCurrencyFormat((odd * +stake) / 100) : getCurrencyFormat(odd * stake - stake)
    return ` ${type}: ${profit}`
  }

  // const profit = (odd: any, stake: any, back: boolean, mType:any, bType: any) => {
  //   const type = back ? 'Profit' : 'Liability'
  //   const isFancy = mType == 'INNINGS_RUNS' && bet.bType == 'LINE'
  //   // @ts-ignore
  //   const stakeVal = valueStake ? (isFancy ? getCurrencyFormat(odd * +stake / 100) : getCurrencyFormat(+stake * count- +stake)) : '0.00'
  //   return `${type}: ${stakeVal}`
  // }

  const cancelOrder = (id: any) => {
    GeneralHandler.getOrderList2(id)
    dispatch(
      setOrderList({ ...orderList, unmatched: orderList?.unmatched.filter((item: any) => item.apolloBetId !== id) })
    )
    setSuccessCancel(true)
  }

  useEffect(() => {
    const allArrays = orderList?.matched?.reduce((acc: any, obj: any) => {
      const sel = obj?.selectionId
      if (!acc[sel]) {
        acc[sel] = []
      }
      acc[sel].push(obj)
      return acc
    }, {})
    setAverageArrays(
      allArrays &&
        Object.keys(allArrays).map((key: any) => {
          return {
            back: allArrays && allArrays[key].filter((val: any) => val.side === "Back"),
            lay: allArrays && allArrays[key].filter((val: any) => val.side === "Lay")
          }
        })
    )
  }, [orderList?.matched])

  useEffect(() => {
    const allArrays = orderList?.unmatched?.reduce((acc: any, obj: any) => {
      const sel = obj?.selectionId
      if (!acc[sel]) {
        acc[sel] = []
      }
      acc[sel].push(obj)
      return acc
    }, {})
    setAverageArraysUnmatched(
      allArrays &&
        Object.keys(allArrays).map((key: any) => {
          return {
            back: allArrays && allArrays[key].filter((val: any) => val.side === "Back"),
            lay: allArrays && allArrays[key].filter((val: any) => val.side === "Lay")
          }
        })
    )
  }, [orderList?.unmatched])

  const average = (odd: any) => {
    const odds = (odd.reduce((a: any, b: any) => a + b.averagePrice, 0) / odd?.length).toFixed(2)
    const stake = (odd.reduce((a: any, b: any) => a + b.sizeMatched, 0) / odd?.length).toFixed(2)
    return (
      <div className={`openBets-placedBets ${odd[0]?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
        {!consolidate && (
          <div className="openBets-placedBets-title">
            {startTime(odd[0]?.lastMatchedDate)} {odd[0]?.side} {odd[0]?.selectionName}
          </div>
        )}
        {odd[0]?.marketType !== "INNINGS_RUNS" && <div className="openBets-placedBets-title">{odd[0]?.marketName}</div>}
        <div className="openBets-placedBets-descr">
          {odd[0]?.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {odds} | Stake: {stake} {odd[0]?.line ? `| runs: ${odd[0]?.line}` : ""} |
          {profit(odds, stake, odd[0]?.side === "Back", odd[0]?.marketType, odd[0]?.marketBettingType)}
        </div>
        {betInfo && (
          <div className="openBets-placedBets-betInfo">
            <div>Ref: {odd[0].apolloBetId}</div>
            <div>Placed: {date(odd[0].betPlacedDate)}</div>
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    if (isAuthenticated) {
      const params = PARAMS.OPEN_BETS
      GeneralHandler.getOrderList1(params).then((list: any) => {
        dispatch(initOrder(list))
        const listMatched = Object.values(list?.result?.matched)
          ?.map((item: any) => item?.markets?.map((market: any) => market?.selections))
          ?.flat(3)
        const listUnmatched = Object.values(list?.result?.unmatched)
          ?.map((item: any) => item?.markets?.map((market: any) => market?.selections))
          ?.flat(3)
        dispatch(
          setOrderList({
            matched: listMatched,
            unmatched: listUnmatched
          })
        )
        if(success){
        dispatch(setOrderCount(listMatched?.length + listUnmatched?.length))
        }
      })
    }
  }, [success])

  const averageUnmatched = (odd: any) => {
    const odds = (odd.reduce((a: any, b: any) => a + b.averagePrice, 0) / odd?.length).toFixed(2)
    const stake = (odd.reduce((a: any, b: any) => a + b.sizeRemaining, 0) / odd?.length).toFixed(2)
    return (
      <div className={`openBets-placedBets ${odd[0]?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
        {!consolidate && (
          <div className="openBets-placedBets-title">
            {startTime(odd[0]?.lastMatchedDate)} {odd[0]?.side} {odd[0]?.selectionName}
          </div>
        )}
        {odd[0]?.marketType !== "INNINGS_RUNS" && <div className="openBets-placedBets-title">{odd[0]?.marketName}</div>}
        <div className="openBets-placedBets-descr">
          {odd[0]?.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {odds} | Stake: {stake} {odd[0]?.line ? `| runs: ${odd[0]?.line}` : ""} |
          {profit(odds, stake, odd[0]?.side === "Back", odd[0]?.marketType, odd[0]?.marketBettingType)}
        </div>

        {/* {!consolidate && <div className='openBets-placedBets-title'>{startTime(odd[0].marketTime)} {odd[0].selectionName}</div>}
      <div className='openBets-placedBets-descr'> Match Odds: {odd[0].competitionName} | 
        Odds: {odds} | 
        Stake: {stake}| 
         {profit(odds, stake, odd[0]?.side === 'Back', odd[0]?.marketType, odd[0]?.marketBettingType)}
      </div> */}
        {betInfo && (
          <div className="openBets-placedBets-betInfo">
            <div>Ref: {odd[0].apolloBetId}</div>
            <div>Placed: {date(odd[0].betPlacedDate)}</div>
          </div>
        )}
        <div className="openBets-placedBets-button">Cancel</div>
      </div>
    )
  }

  return (
    <div className="openBetsContent">
      <div className="openBets-options">
        {/* <div className="showModalOptions">
          <span className="openBetsShow">Show</span>
          <img src={arrowIcon} alt="" />
        </div> */}

        <div className="openBetsOptionsRow">
          <div className="openBets-checkedOption" onClick={() => setBetInfo(!betInfo)}>
            <img src={betInfo ? checkedIcon : notCheckedIcon} alt=""/>
            <span>Bet Info</span>
            {/* <img src={closeIcon} alt="" /> */}
          </div>
          <div
            className="openBets-checkedOption"
            onClick={() => {
              setBetEvent(!betEvent)
              setAverageOdds(false)
            }}
          >
            <img src={betEvent ? checkedIcon : notCheckedIcon} alt=""/>
            <span>Bets for this event</span>
            {/* <img src={closeIcon} alt="" /> */}
          </div>
        </div>
        <div className={`openBets-unmachedContainer ${hideUnmachedBets && 'hideUnmachedContainer'}`}>
          <div className="openBets-unmachedContainer-row">
            <div className="row-title">Unmatched Bets</div>
            {!hideUnmachedBets 
              ? <img style={{cursor: 'pointer'}} src={deleteIcon} alt="" onClick={() => setHideUnmachedBets(true)}/>
              : <div style={{cursor: 'pointer'}} className="hideOpenBetsIcon" onClick={() => setHideUnmachedBets(false)}>+</div>
            }
          </div>
          {!averageOdds && !betEvent && orderList?.unmatched?.length ? (
            orderList?.unmatched?.map((item: any) => {
              return (
                <div className={`openBets-placedBets ${item?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
                  {!consolidate && (
                    <div className="openBets-placedBets-title">
                      {startTime(item?.lastMatchedDate)} {item.side} {item?.selectionName}
                    </div>
                  )}
                  {item.marketType !== "INNINGS_RUNS" && (
                    <div className="openBets-placedBets-title">{item?.marketName}</div>
                  )}
                  <div className="openBets-placedBets-descr">
                    {item.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {item?.averagePrice} | Stake: {item?.sizeRemaining}{" "}
                    {item?.line ? `| runs: ${item?.line}` : ""} |{" "}
                    {profit(
                      item.averagePrice,
                      item.sizeRemaining,
                      item.side === "Back",
                      item.marketType,
                      item.marketBettingType
                    )}
                  </div>

                  {betInfo && (
                    <div className="openBets-placedBets-betInfo">
                      <div>Ref: {item.apolloBetId}</div>
                      <div>Placed: {date(item.betPlacedDate)}</div>
                    </div>
                  )}
                  <div
                    className="openBets-placedBets-button"
                    onClick={() => {
                      cancelOrder(item.apolloBetId)
                    }}
                  >
                    Cancel
                  </div>
                </div>
              )
            })
          ) : !averageOdds &&
            betEvent &&
            orderList?.unmatched?.filter((item: any) => item.eventId === bet?.eventId)?.length ? (
            orderList?.unmatched
              ?.filter((item: any) => item.eventId === bet?.eventId)
              .map((item: any) => {
                return (
                  <div className={`openBets-placedBets ${item?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
                    {!consolidate && (
                      <div className="openBets-placedBets-title">
                        {startTime(item?.lastMatchedDate)} {item.side} {item?.selectionName}
                      </div>
                    )}
                    {item.marketType !== "INNINGS_RUNS" && (
                      <div className="openBets-placedBets-title">{item?.marketName}</div>
                    )}
                    <div className="openBets-placedBets-descr">
                      {item.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {item?.averagePrice} | Stake: {item?.sizeRemaining}{" "}
                      {item?.line ? `| runs: ${item?.line}` : ""} |{" "}
                      {profit(
                        item.averagePrice,
                        item.sizeRemaining,
                        item.side === "Back",
                        item.marketType,
                        item.marketBettingType
                      )}
                    </div>

                    {betInfo && (
                      <div className="openBets-placedBets-betInfo">
                        <div>Ref: {item.apolloBetId}</div>
                        <div>Placed: {date(item.betPlacedDate)}</div>
                      </div>
                    )}
                    <div className="openBets-placedBets-button">Cancel</div>
                  </div>
                )
              })
          ) : averageOdds && avergeArraysUnmatched?.length ? (
            Object.values(avergeArraysUnmatched).map((item: any) =>
              Object.values(item).map((side: any) => !!side.length && averageUnmatched(side))
            )
          ) : (
            <div className="openBets-unmachedContainer-noBets">You have no unmatched bets</div>
          )}
        </div>
        <div className={`openBets-unmachedContainer ${hideMachedBets && 'hideMachedContainer'}`}>
          <div className="openBets-unmachedContainer-row">
            <div className="row-title">Matched Bets</div>
            {!hideMachedBets 
              ? <img style={{cursor: 'pointer'}} src={deleteIcon} alt="" onClick={() => setHideMachedBets(true)}/>
              : <div style={{cursor: 'pointer'}} className="hideOpenBetsIcon" onClick={() => setHideMachedBets(false)}>+</div>
            }
          </div>
          {/* <div className="matchedType">
            <span>Type</span>
            <img src={arrowIcon} alt="" />
          </div> */}
          <div className="openBetsOptionsRow">
            <div className="openBets-checkedOption matched" onClick={() => setConsolidate(!consolidate)}>
              <img src={consolidate ? checkedIcon : notCheckedIcon} alt=""/>
              <span>Consolidate</span>
              {/* <img src={closeIcon} alt="" /> */}
            </div>
            <div
              className="openBets-checkedOption matched"
              onClick={() => {
                setAverageOdds(!averageOdds)
                setBetEvent(false)
                setBetInfo(false)
              }}
            >
              <img src={averageOdds ? checkedIcon : notCheckedIcon} alt="" />
              <span>Average Odds</span>
              {/* <img src={closeIcon} alt="" /> */}
            </div>
          </div>
          {!averageOdds && !betEvent && orderList?.matched?.length ? (
            orderList?.matched?.map((item: any) => {
              return (
                <div className={`openBets-placedBets ${item?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
                  {!consolidate && (
                    <div className="openBets-placedBets-title">
                      {startTime(item?.lastMatchedDate)} {item.side} {item?.selectionName}
                    </div>
                  )}
                  {item.marketType !== "INNINGS_RUNS" && (
                    <div className="openBets-placedBets-title">{item?.marketName}</div>
                  )}
                  <div className="openBets-placedBets-descr">
                    {item.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {item?.averagePrice} | Stake: {item?.sizeMatched}{" "}
                    {item?.line ? `| runs: ${item?.line}` : ""} |{" "}
                    {profit(
                      item.averagePrice,
                      item.sizeMatched,
                      item.side === "Back",
                      item.marketType,
                      item.marketBettingType
                    )}
                  </div>
                  {betInfo && (
                    <div className="openBets-placedBets-betInfo">
                      <div>Ref: {item.apolloBetId}</div>
                      <div>Placed: {date(item.betPlacedDate)}</div>
                    </div>
                  )}
                </div>
              )
            })
          ) : !averageOdds &&
            betEvent &&
            orderList?.matched?.filter((item: any) => item.eventId === bet?.eventId)?.length ? (
            orderList?.matched
              ?.filter((item: any) => item.eventId === bet?.eventId)
              .map((item: any) => {
                return (
                  <div className={`openBets-placedBets ${item?.side === "Back" ? "backOpenBets" : "layOpenBets"}`}>
                    {!consolidate && (
                      <div className="openBets-placedBets-title">
                        {startTime(item?.lastMatchedDate)} {item.side} {item?.selectionName}
                      </div>
                    )}
                    {item.marketType !== "INNINGS_RUNS" && (
                      <div className="openBets-placedBets-title">{item?.marketName}</div>
                    )}
                    <div className="openBets-placedBets-descr">
                      {item.eventName?.replace(' v ', ' vs ')?.replace(' vs. ', ' vs ')} | Odds: {item?.averagePrice} | Stake: {item?.sizeMatched}{" "}
                      {item?.line ? `| runs: ${item?.line}` : ""} |{" "}
                      {profit(
                        item.averagePrice,
                        item.sizeMatched,
                        item.side === "Back",
                        item.marketType,
                        item.marketBettingType
                      )}
                    </div>

                    {betInfo && (
                      <div className="openBets-placedBets-betInfo">
                        <div>Ref: {item.apolloBetId}</div>
                        <div>Placed: {date(item.betPlacedDate)}</div>
                      </div>
                    )}
                  </div>
                )
              })
          ) : averageOdds && avergeArrays?.length ? (
            Object.values(avergeArrays).map((item: any) =>
              Object.values(item).map((side: any) => !!side.length && average(side))
            )
          ) : (
            <div className="openBets-unmachedContainer-bets">You have no matched bets</div>
          )}
        </div>
      </div>
    </div>
  )
}
