import { useDispatch, useSelector } from "react-redux"
import "./styles/index.scss"
import { useLocation } from "react-router-dom"
import { initJiliPopUp } from "../../../redux/pages/jiliPopUp"
import { useMediaQuery } from "@material-ui/core"
import viewAllImg from "../../../static/images/viewAll.svg"
import { useHistory } from "react-router"
import { memo, useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { setIsOpenLoginPopUp, setIsOpenPopUp } from "../../../redux/UI/openPopUps.slice"

interface IProps {
  gamesProps?: {
    name: string,
    key: string,
    action: () => void;
    image: any;
    imageDesk: any;
  };
}
const ImageChecker = ({ link }: any) => {
  const [imageElement, setImageElement] = useState<JSX.Element | null>(null);
  const [errorElement, setErrorElement] = useState<JSX.Element | null>(null);
  const checkImageLink = (url: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        resolve(url);
      };

      img.onerror = () => {
        reject(new Error("Bad Image URL"));
      };

      img.src = url;
    });
  };

  useEffect(() => {
    checkImageLink(link)
      .then((url) => {
        const newImageElement = <img loading="lazy" src={url} alt={`${url}`} />;
        setImageElement(newImageElement);
      })
      .catch((error) => {
        const newErrorElement = <Skeleton />;
        setErrorElement(newErrorElement);
      });
  }, []);

  return (
    <div>
      {imageElement && imageElement}
      {errorElement && errorElement}
    </div>
  );
};
export default ImageChecker;
export const HomeGames = memo( ({ gamesProps }: IProps) => {
  const location = useLocation()
  const isPhone = useMediaQuery("all and (max-width: 1024px)")
  const cards = useSelector((state: IRootState) => state.cards)
  const { isAuthenticated } = useSelector((state: IRootState) => state.user)
  const dispatch = useDispatch()
  const history = useHistory()

  const renderCard = (item: any) => {
    const urlImg = (item?.image || item.ImageURL)?.replace(/\s/g, "%20").replace("https:", "");
    const handleClickJili = () => {
      if (!isAuthenticated) {
        dispatch(setIsOpenLoginPopUp(true));
        return;
      }
      dispatch(setIsOpenPopUp(true));
      dispatch(initJiliPopUp(item));
    };
    const handleClick = () => {
      if (!isAuthenticated) {
        dispatch(setIsOpenLoginPopUp(true));
        return;
      }

      if (item?.id?.toLowerCase().includes("jili") || item?.ID?.toLowerCase().includes("jili") || item.type === "CASINO_JILI") {
        dispatch(setIsOpenPopUp(true));
        dispatch(initJiliPopUp(item));
      }

      if (item.eventTypeId === "1444001") {
        history.push(`/card-game/${item.id}`, {
          game: item,
          backPath: location.pathname,
        });
        return;
      }
      if (isAuthenticated && item.eventTypeId !== "1444001") {
          history.push(`/casino-game/${item.ID}`, {
            sportId: item.eventTypeId,
            eventName: item.en,
            backPath: location.pathname,
          });
        return;
      }
    }

    return (
      <>
      <div key={Math.random().toFixed(6) + item?.id} className={`${item.isTrending ? "trending-for-casino" : ""}`}>
        <div className={`home-games__item `}>
          {item?.id?.toLowerCase().includes("jili") || item?.ID?.toLowerCase().includes("jili") || item.type === "CASINO_JILI" ? (
            <div onClick={handleClickJili}>
              <div className="home-games__img-body">
                <ImageChecker link={urlImg}/>
              </div>
            </div>
          ) : (
            <div onClick={handleClick}>
              <div className="home-games__img-body">
                <ImageChecker link={urlImg}/>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
    )
  }

  return (
    <>
      <div className={`home-games ${gamesProps?.key}`}>
        <div className="global-desktop-container">
          <div className="home-games__header">
            <div className="home-games__header-info-wrapper">
              <div className="home-games__header-title">
                {gamesProps?.name}
              </div>
              <div className="home-games__header-sub-title">
                Take a seat and play against all real dealers to enjoy casino games
              </div>
              <div className="home-games__header-info">
                100+ live casino games are waiting for you with lots of prizes
              </div>
              <div className="home-games__header-info-desk">
                Discover our very own Live Casino with Teen Patti, Rummy, Andar Bahar, Dragon Tiger, Crazy Time,
                Blackjack, Roulette and more. Meet our professional live dealers, including Hindi speaking dealers for
                Andar Bahar and Roulette, and enjoy the real deal!
              </div>
            </div>
            <div className="home-games__header-img">
              <img  loading="lazy" src={isPhone ? gamesProps?.image : gamesProps?.imageDesk} alt={gamesProps?.key} />
            </div>
          </div>

          {isPhone ?
            <div className="home-games__list">
              {cards[gamesProps?.key]?.slice(0, 6).map((item: any) => renderCard(item))}
            </div>
            :
            <div className="home-games__list">
              {cards[gamesProps?.key]?.slice(0, 10).map((item: any) => renderCard(item))}
            </div>}
          <div className="home-games__button-wrapper">
            <div className="home-games__button-info">
              <span>Every day 1000+ users are getting great offers/deals on Live Casino Games...! </span>
              <span>Hurry up to get yours</span>
            </div>
            <div className="home-games__button"
                 onClick={() => gamesProps?.action()}
            >
              <img  loading="lazy" src={viewAllImg} alt="viewAllImg" />
              <span>View all</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});