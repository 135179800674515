import { useState, useMemo, memo } from "react";
import { Item } from "./Item";
import { ItemsList } from "./Item";
import "./styles/index.scss";

interface List {
  type: string;
  name: string;
  id: string;
  startTime?: string;
  eventType?: string;
  competitionId?: string;
  children?: ItemsList[] | undefined;
  sortOrder?: number;
}

interface Iprops {
  name: string;
  list: List[];
}

export const ItemList = memo(({ name, list }: Iprops): JSX.Element => {
  const [menuOpen, setMenuOpen] = useState(true);

  const memoizedList = useMemo(() => {
    return list.filter((item) => item.name !== "Sportsbook");
  }, [list]);

  return (
    <>
      <div className="menu-section">
        <div
          className={menuOpen ? "menu-section__title menu-section__title--active" : "menu-section__title" }
          onClick={() => setMenuOpen((prevValue) => !prevValue)}
        >
          <span className="icon icon-Cricket"></span>
          {name}
        </div>
        <ul className="menu-section__list">
          {menuOpen &&
            memoizedList.map((item) => (
                <li key={item.id} className={item.children ? "menu-section__list-item menu-section__list-item--parent" : "menu-section__list-item"}>
                  <Item name={item.name} list={item.children} sportId={item.id}/>
                </li>
              ))}
        </ul>
      </div>
    </>
  );
});
