import {  MEMBER_CODE, PATH } from "../utils/constants";
import { instance } from "./instance";

// const MemberCode = localStorage.getItem(MEMBER_CODE)

export class EventHandler {
  static async getEventsListRequest(id:string) {
    const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.EVENT_TYPE}/${id}`, );
    return response.data
  };
  static async getEventsHorseListRequest(id:string) {
    const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.RACES}/${id}`, );
    return response.data
  };
  static async getEventsListInPlayRequest(id:string, inPlay:boolean) {
    const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.EVENT_TYPE}/${id}?inPlay=${inPlay}`, );
    return response.data
  };

  static async getEventStatsRequest(id:string, eventId: string) {
    const response = await instance.get(`/${PATH.MATCH_CENTER}/${PATH.STATS}/${id}/${eventId}`, );
    return response.data.result
  };

  static async getEventMarketDatatRequest(id:string, eventId: string) {
    const response = await instance.get(`/${PATH.EXCHANGE}/${PATH.ODDS}/${PATH.SMA_EVENT}/${id}/${eventId}`, );
    return response.data
  };

  static async getEventExposureRequest(eventId:string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.EXPOSURE}/${PATH.PNL}/${MemberCode}/${PATH.EVENT}/${eventId}`, );
    return response.data
  };

  static async getEventOrderCountRequest(eventId:string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.ACCOUNT}/${MemberCode}/${PATH.ORDER_COUNT}?${eventId}`, );
    return response.data
  };

  static async getMarketLadderRequest(eventId:string) {
    const MemberCode = localStorage.getItem(MEMBER_CODE)
    const response = await instance.get(`/${PATH.EXPOSURE}/${PATH.PNL}/${MemberCode}/${PATH.MARKETS}/${eventId}/${PATH.LADDER}`, );
    return response.data.result
  };
}