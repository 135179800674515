import { useSelector } from "react-redux";
import { useMemo } from "react";

const useCheckBonusWallets = () => {
  const balance = useSelector((state: IRootState) => state.balance);
  return useMemo(() => {
    if (!Object.keys(balance).length) return false;
    if (balance?.useBWallet || balance?.useSWallet || balance?.useCWallet) {
      return false
    } else if (balance?.availBalance === 0) {
      return true
    } else {
      return false
    }
  }, [balance?.availBalance, balance?.bWallet?.availBalance, balance?.sWallet?.availBalance, balance?.cWallet?.availBalance, balance?.useBWallet, balance?.useSWallet, balance?.useCWallet]);
};

export default useCheckBonusWallets;
