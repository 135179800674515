import React from "react";

export interface IMeta {
  name: string;
  content: string;
}
export interface IHelmetData {
  title: string;
  meta: IMeta[];
  link?: {
    rel: string;
    href: string;
  };
  header?: string;
}

export enum MetaPages {
  // from SEO
  SPORTS_CRICKET = "sports-cricket",
  SPORTS_TENNIS = "sports-tennis",
  SPORTS_FOOTBALL = "sports-football",
  CASINOS = "casinos",
  AVIATOR = "aviator",
  HOME = "home",
  POLITICS = "politics",
  SPORTS_HORSERACING = "sports-horse-racing",
  SPORTS_GREYHOUND = "sports-greyhound",
  LIVE_CASINO_PAGE = "live-casino-page",
  CARD_GAMES = "card-games",
  SLOTS_PAGE = "slots-page",
  USER_BONUS = "user-bonus",
  SPORTSBOOK = "sportsbook",
  BLOG = "blog",
  // default
  RULES_REGULATIONS = "rules-regulations",
  ACCOUNT_STATEMENT = "accountStatement",
  TRANSFER_STATEMENT = "transfer-statement",
  BET_HISTORY = "bet-history",
  UNSETTLED_BET = "unsettled-bet",
  DEPOSIT = "deposit",
  WITHDRAWAL = "withdrawal",
  TRANSACTIONS = "transactions",
  BONUS_STATEMENT = "bonus-statement",
  CHANGE_PASSWORD = "change-password",
  SET_BUTTON_VALUES = "set-button-values",
  SPORT_EVENT_DETAIL = "sport-event-detail",
  PRIVACY_POLICY = "privacy-policy",
  SPECIAL_OFFERS = "specialoffers",
  REAL_CASH = "realCash",
  CASINO_GAME = "casino-game",
  ROUND_RESULTS = "round-results",
}

export type HelmetDataContextType = {
  helmetData: IHelmetData;
  setHelmetData: React.Dispatch<React.SetStateAction<IHelmetData>>;
  setDefault: () => void;
  MetaPages: typeof MetaPages;
};
